import {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {RequestContext} from '../App';

export default function VerifyAccount() {
  const search = useLocation().search;
  const dispatch = useDispatch();
  const history = useHistory();
  const {api: socket} = useContext(RequestContext);
  const {currentUser} = useSelector((state) => state.auth);
  const [message, setMessage] = useState();

  useEffect(() => {
    const get = async () => {
      const token = new URLSearchParams(search).get('token');
      const email = new URLSearchParams(search).get('email');

      await socket.confirmEmail({email, token}, dispatch, history, setMessage);
    };
    get();
  }, []);

  return (
    <section className="section">
      <div className="container">
        <div className="jumbotron">
          <h2>{currentUser?.confirmed ? 'Vaš nalog je već potvrđen!' : message}</h2>
        </div>
      </div>
    </section>
  );
}

import {useContext} from 'react';
import {Link} from 'react-router-dom';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import {dateFormat} from '../helpers/utilities';
import {RequestContext} from '../App';
import {useSelector} from 'react-redux';

function HeaderNotifications({notifications, isGuestUser, userUuid}) {
  const {api: socket} = useContext(RequestContext);
  const {user} = useSelector((state) => state.auth);
  const {buttonProps, isOpen, setIsOpen} = useDropdownMenu();

  const close = () => {
    setIsOpen(false);
  };

  const handleNotification = async (uuid) => {
    close();
    await socket.setSeenNotification(uuid, user?.token);
  };

  return (
    <div className="header_indicator header_notifications">
      {isGuestUser && (
        <button className="header_indicator_btn" {...buttonProps}>
          <>
            <img src="/images/bell.svg" alt="bell" />
            {!!notifications?.length && <span>{notifications?.length}</span>}
          </>
        </button>
      )}
      <div className={'default_dropdown right notifications_dropdown ' + (isOpen ? 'visible' : '')} role="menu">
        {notifications?.map((notification) => (
          <Link onClick={() => handleNotification(notification.uuid)} to={`${notification.link}`}>
            {/* <img src="/images/logo_circle.svg" alt="logo" /> */}
            <span>
              {notification.body}
              <time>{dateFormat(notification.createdAt)}</time>
            </span>
          </Link>
        ))}
        {!notifications?.length && (
          <span>
            <strong>Nemate novih obavještenja</strong>
          </span>
        )}
        <div className="default_dropdown_more">
          <Link onClick={() => setIsOpen(false)} to={`/notifikacije`}>
            POGLEDAJ SVE
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HeaderNotifications;

import {useState, useContext, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import MessagesLeft from '../components/messagesLeft';
import '../styles/messages.scss';
import {dateFormat, extractTime} from '../helpers/utilities';
import {RequestContext} from '../App';
import {SMALL_IMAGE} from '../api/apiBase';

function Poruka() {
  const {api: socket} = useContext(RequestContext);
  const dispatch = useDispatch();
  const {id} = useParams();
  const {currentUser, user} = useSelector((state) => state.auth);
  const {messages} = useSelector((state) => state.chats.chat);
  const {senderId} = useSelector((state) => state.chats.chat);
  const {sender, reciever} = useSelector((state) => state.chats.chat);
  const otherUser = currentUser.id === senderId ? reciever : sender;
  const {chats} = useSelector((state) => state.chats);
  const unread = chats?.filter((chat) => chat?.messages[0]?.seen === false && chat?.messages[0]?.senderId !== currentUser?.id);
  const unanswered = chats?.filter((chat) => chat?.messages[0]?.senderId === currentUser?.id);
  const [message, setMessage] = useState('');

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const getLastSeen = (messages) => {
    const lastSeen = messages?.filter((message) => message?.senderId === user?.id && !message?.seen).slice?.(-1)?.[0]?.updatedAt;
    return lastSeen
      ? new Intl.DateTimeFormat('sr-Sr', {dateStyle: 'short', timeStyle: 'short'}).format(new Date(lastSeen))
      : new Intl.DateTimeFormat('sr-Sr', {dateStyle: 'short', timeStyle: 'short'}).format(new Date());
  };

  const scrollToBottom = () => {
    document.querySelector('.message_items').scrollTo({
      left: 0,
      top: document.querySelector('.message_items').scrollHeight - document.querySelector('.message_items').clientHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const get = async () => {
      if (id && user?.id) await socket.getChat(id, dispatch, user?.token);
    };
    get();
  }, [id, user?.id]);

  useEffect(() => {
    const get = async () => {
      const unreadMessages = messages?.filter((message) => !message.seen && message.senderId !== user?.id).map((message) => message.uuid);
      if (unreadMessages?.length) {
        await socket.setSeenMessage(user?.id, unreadMessages, user?.token);
        scrollToBottom();
      }
      if (user?.id) await socket.getChats(user.id, {page: 0, count: 20}, dispatch, user.token);
    };
    get();
  }, [messages, currentUser, user]);

  const sendMessage = async () => {
    scrollToBottom();

    await socket.createMessageFromChat(
      {senderId: currentUser.id, recieverId: otherUser.id, body: message, title: 'Privatna poruka'},
      dispatch,
      user?.token
    );

    setMessage('');
  };

  return (
    <>
      <section className="section message">
        <div className="container">
          <div className="row">
            <MessagesLeft inbox={unread.length} unanswered={unanswered?.length} />
            <div className="col-md-8">
              <div className="message_wrap">
                <div className="message_user">
                  <figure>
                    <Link to={otherUser?.isShop ? `/prodavnica/${otherUser?.uuid}` : `/user/${otherUser?.uuid}`}>
                      <img
                        src={otherUser?.profileImage ? SMALL_IMAGE + otherUser?.profileImage : '/images/user_placeholder.svg'}
                        alt="user"
                      />
                    </Link>
                  </figure>
                  <h4>
                    <Link to={`/user/${otherUser?.uuid}`}>{otherUser?.userName}</Link>
                  </h4>
                  <p>zadnji put vidjen {getLastSeen(messages ?? [])}</p>
                </div>
                <div className="message_items">
                  {messages?.map((message) => {
                    const isMyMessage = currentUser.id === message.senderId;
                    return (
                      <div key={message.id}>
                        <div className="message_date">{dateFormat(message.createdAt)}</div>
                        <div className={`message_item message_item_${isMyMessage ? 'right' : 'left'}`}>
                          <div className={`message_bubble ${isMyMessage ? 'blue' : ''}`}>
                            <p>{message.body}</p>
                            <time>{extractTime(message.createdAt)}</time>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="message_type">
                  <textarea rows="3" placeholder="Unesite odgovor" value={message} onChange={handleMessageChange}></textarea>
                  <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6 text-right">
                      <button disabled={message.length === 0} onClick={sendMessage} className="button">
                        Pošaljite poruku
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Poruka;

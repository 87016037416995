import {useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {MEDIUM_IMAGE} from '../api/apiBase';
import {RequestContext} from '../App';
import ProfileHead from '../components/profileHead';
import ProfileInfo from '../components/profileInfo';
import ProfileList from '../components/profileList';
import ProfileReviews from '../components/profileReviews';
import withSeo from '../components/seo';
import {dateFormat} from '../helpers/utilities';
import '../styles/profile.scss';

function User({setTitle}) {
  const {api: socket} = useContext(RequestContext);
  const {userProfile, currentUser} = useSelector((state) => state.auth);
  const {impressionsReceiver} = userProfile;
  const {locations} = useSelector((state) => state.locations);
  const dispatch = useDispatch();
  const {id} = useParams();
  const isMyProfile = currentUser?.uuid === id;

  const image = userProfile?.profileImage ? MEDIUM_IMAGE + userProfile?.profileImage : '/images/user_placeholder.svg';
  const articlesSoldOrAcitve = (status) =>
    userProfile?.articles?.filter((article) => !!article.soldAt === status && article.status !== 'blokiran');
  const location = locations?.find((location) => location.id === userProfile.cityId);

  useEffect(() => {
    async function callUserApi() {
      await socket.getUserProfile(id, dispatch);
      setTitle('Profil korisnika');
    }
    callUserApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const positiveImpressions = impressionsReceiver?.filter((i) => i.rating === 'pozitivan').length;
  const neutralImpressions = impressionsReceiver?.filter((i) => i.rating === 'neutralan').length;
  const negativeImpressions = impressionsReceiver?.filter((i) => i.rating === 'negativan').length;

  return (
    <>
      <ProfileHead username={userProfile?.userName} online={false} myProfile={isMyProfile} image={image} />
      <section className="section profile">
        <div className="container">
          <ProfileList
            active={articlesSoldOrAcitve(false)}
            sold={articlesSoldOrAcitve(true)}
            uuid={isMyProfile ? currentUser?.uuid : userProfile?.uuid}
          />
          <div className="row">
            <div className="col-lg-7">
              <ProfileReviews positive={positiveImpressions} neutral={neutralImpressions} negative={negativeImpressions} uuid={id} />
            </div>
            <div className="col-lg-5">
              <ProfileInfo location={location?.name} createdAt={dateFormat(userProfile.createdAt)} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withSeo(User);

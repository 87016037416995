import {useContext, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {RequestContext} from '../App';
import {Input} from '../components/inputs';

export default function ChangePassword() {
  const {currentUser, user} = useSelector((state) => state.auth);
  const [editUser, setEditUser] = useState({currentPassword: '', newPassword: ''});
  const {api: socket} = useContext(RequestContext);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setEditUser((prev) => ({...prev, ...user}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentUser)]);

  const handleChange = (e) => {
    e.preventDefault();
    setEditUser((prev) => ({...prev, [e.target.name]: e.target.value}));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await socket.changePassword(editUser, dispatch, history, user?.token);
  };
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="profile_settings mt-30">
            <h3>Promjenite vašu lozinku</h3>
            <div className="mb-4">
              <Input
                type="password"
                name={'currentPassword'}
                value={editUser?.currentPassword}
                label={'Trenutna lozinka'}
                handleChange={handleChange}
              />
              <Input
                type="password"
                name={'newPassword'}
                value={editUser?.newPassword}
                label={'Nova lozinka'}
                handleChange={handleChange}
              />
            </div>
            <div className="row">
              <div className="col-lg-4">
                <button
                  className="button w-100"
                  onClick={handleSubmit}
                  disabled={editUser?.currentPassword?.length < 6 || editUser?.newPassword?.length < 6}
                >
                  Promjeni šifru
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

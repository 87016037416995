export default function ProfileInfo({location, createdAt}) {
  return (
    <div className="profile_info">
      <p>
        <b>Lokacija</b> {location}
      </p>
      <p>
        <b>Datum registracije</b> {createdAt}
      </p>
    </div>
  );
}

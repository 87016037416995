import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  chats: [],
  chat: {},
};

export const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    setChats: (state, action) => {
      state.chats = [...action.payload];
      return state;
    },
    setChat: (state, action) => {
      state.chat = action.payload;
      return state;
    },
    addMessageFromChat: (state, action) => {
      state.chat.messages = [...state.chat.messages, action.payload];
      return state;
    },
    resetState: (state) => {
      state = initialState;
      return state;
    },
  },
});

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  user: {
    token: undefined,
    role: undefined,
    id: undefined,
    confirmed: undefined,
  },
  currentUser: {},
  userProfile: {},
  hasMorePikArticles: undefined,
  users: [],
  pikArticles: [],
  localPikArticles: [],
  unConfirmedUser: {
    email: '',
    tokenValid: true,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
      return state;
    },
    addUserArticles: (state, action) => {
      state.pikArticles = action.payload.pik;
      state.hasMorePikArticles = !!action?.payload?.pik?.artikli?.length ?? false;
      state.localPikArticles = action.payload.local;
      return state;
    },
    addMoreUserArticles: (state, action) => {
      state.pikArticles.artikli = state.pikArticles?.artikli?.concat(action.payload.pik.artikli);
      state.hasMorePikArticles = !!action?.payload?.pik?.artikli.length ?? false;

      return state;
    },
    updateUsers: (state, action) => {
      const userIndex = state.users.findIndex(({uuid}) => action.payload.uuid === uuid);
      state.users.splice(userIndex, 1, action.payload);
      return state;
    },
    setUser: (state, action) => {
      state.user = {...action.payload};
      return state;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = {...action.payload};
      return state;
    },
    addProfileImage: (state, action) => {
      state.currentUser.profileImage = action.payload;
      return state;
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
      return state;
    },
    setUnconfirmedUser: (state, action) => {
      state.unConfirmedUser = action.payload;
      return state;
    },
    addMessage: (state, action) => {
      const message = action.payload;
      let chatIndex;
      if (state.currentUser?.sender?.length) {
        chatIndex = state.currentUser.sender.findIndex((chat) => chat.id === message.chatId);
        if (chatIndex > -1) {
          state.currentUser.sender[chatIndex].messages = [...state.currentUser.sender[chatIndex].messages, message];
          return state;
        }
      }
      if (state.currentUser?.reciever?.length) {
        chatIndex = state.currentUser.reciever.findIndex((chat) => chat.id === message.chatId);
        state.currentUser.reciever[chatIndex].messages = [...state.currentUser.reciever[chatIndex].messages, message];
        return state;
      }
      return state;
    },
    updateLocalPikArticles: (state, action) => {
      state.localPikArticles = state.localPikArticles.concat(action.payload);
      return state;
    },
    resetUserProfile: (state) => {
      state.userProfile = initialState.userProfile;
      return state;
    },
    resetState: (state) => {
      state = {...initialState};
      return state;
    },
  },
});

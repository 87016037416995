import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
  userId: undefined,
  locationId: undefined,
  categoryId: undefined,
  subcategoryId: undefined,
  subsubcategoryId: undefined,
  subsubsubcategoryId: undefined,
  featured: undefined,
  conditions: undefined,
  price: undefined,
  loanInstallment: undefined,
  description: undefined,
  title: undefined,
  articleType: 'prodaja',
  exchange: undefined,
  urgent: undefined,
  negotable: undefined,
  fixed: undefined,
};

export const newArticleSlice = createSlice({
  name: 'newArticle',
  initialState,
  reducers: {
    updateState: (state, action) => {
      state = {...action.payload};
      return state;
    },
    resetState: (state) => {
      state = initialState;
      return state;
    },
  },
});

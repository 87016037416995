import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  notifications: [],
  unreadNotifications: [],
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
      return state;
    },
    setUnreadNotifications: (state, action) => {
      state.unreadNotifications = [...action.payload];
      return state;
    },
    setSeenNotification: (state, action) => {
      state.unreadNotifications = state.unreadNotifications?.filter((n) => n.uuid !== action.payload);
      return state;
    },
    deleteNotification: (state, action) => {
      state.notifications.notifications = state.notifications?.notifications?.filter((n) => n.uuid !== action.payload);
      state.notifications.total = state.notifications?.total - 1;
      return state;
    },
    resetState: (state) => {
      state = initialState;
      return state;
    },
  },
});

import React, {useState, useEffect, useContext} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import useMedia from 'use-media';
import HeaderUser from './headerUser';
import HeaderNotifications from './headerNotifications';
import '../styles/components/header.scss';
import '../styles/home.scss';
import {useSelector, useDispatch} from 'react-redux';
import {RequestContext} from '../App';
import {dateFormat} from '../helpers/utilities';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';

function Header() {
  const dispatch = useDispatch();
  const {api: socket} = useContext(RequestContext);
  const loggedIn = useSelector((state) => state.auth.user.token);
  const history = useHistory();
  const {unreadNotifications} = useSelector((state) => state.notifications);
  const {user, currentUser} = useSelector((state) => state.auth);
  const [fixed, setFixed] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const {auth} = useSelector((state) => state);
  const isAdmin = auth.user.role === 'admin';
  const min992 = useMedia({minWidth: 992});
  const min768 = useMedia({minWidth: 768});
  const categories = useSelector((state) => state.categories.categories);
  const [isOpenAllCategories, openAllCategories] = useState(false);
  const {pathname} = useLocation();
  const dontHaveImage = [];
  const {chats} = useSelector((state) => state.chats);
  const unreadMessages = chats?.filter((chat) => chat?.messages[0]?.seen === false && chat?.messages[0]?.senderId !== currentUser?.id);
  const {buttonProps, isOpen, setIsOpen} = useDropdownMenu();
  const [categoriesToShow, setCategoriesToShow] = useState([]);

  const toggleFixed = () => {
    window.pageYOffset > 200 ? setFixed(true) : setFixed(false);
  };

  const fetchMyApi = async (currentUser, user) => {
    if (currentUser?.id && user?.token) {
      if (user?.id) {
        await socket.getUnreadNotifications(currentUser?.id, dispatch, user.token);
        await socket.getChats(user.id, {page: 0, count: 20}, dispatch, user.token);
      }
    }
  };
  useEffect(() => {
    if (user.token) {
      fetchMyApi(currentUser, user);
    }
    window.addEventListener('scroll', toggleFixed);
    return () => window.removeEventListener('scroll', toggleFixed);
  }, [currentUser, user]);

  useEffect(() => {
    var offHeight = document.getElementById('mainheader').offsetHeight;
    document.getElementById('mainheader_parent').style.height = offHeight + 'px';
  }, [isOpenAllCategories, categoriesToShow, pathname]);

  useEffect(() => {
    let categoriesWithImage = [];
    categories?.forEach((category) => {
      categoriesWithImage.push(category);
    });
    setCategoriesToShow(categoriesWithImage);
  }, [categories]);

  const handleClick = (e) => {
    e.preventDefault();
    history.push('/objava-1');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (searchTerm.length) {
        history.push(`/search?title=${searchTerm}`);
        setSearchTerm('');
      }
    }
  };

  const handleTitleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.length) {
      history.push(`/search?title=${searchTerm}`);
      setSearchTerm('');
    }
  };

  const handleAdminClick = () => {
    history.push('/admin');
  };

  return (
    <div className="header_wrap" id="mainheader_parent">
      <header className={'header ' + (fixed ? ' header_fixed' : '')} id="mainheader">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col header_left d-flex">
              <Link key="homepage-key" to="/">
                <img src="/images/new_logo.svg" alt="logo" className="header-new-logo" />
              </Link>

              <div className="header_search">
                <button onClick={handleTitleSearch}>
                  <img src="/images/search.svg" alt="search" />
                </button>
                <input
                  type="text"
                  placeholder="Pretraga"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </div>

              <HeaderNotifications notifications={unreadNotifications} isGuestUser={loggedIn} userUuid={currentUser?.uuid} />

              {loggedIn && min992 && (
                <>
                  <div className="header_indicator header_messages">
                    <MessagesDesktop
                      userId={user.uuid}
                      unreadMessages={unreadMessages}
                      min992={min992}
                      dateFormat={dateFormat}
                      setIsOpen={setIsOpen}
                      isOpen={isOpen}
                      buttonProps={buttonProps}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="col-auto header_right d-flex justify-content-end">
              {min992 ? (
                <>
                  {isAdmin && (
                    <button className={`button_w_icon button_red header_add`} onClick={handleAdminClick}>
                      <span>
                        <img src="/images/plus.svg" alt="plus" />
                      </span>
                      Admin Panel
                    </button>
                  )}
                  {loggedIn && !isAdmin && (
                    <>
                      <button
                        className={`button_w_icon header_add ${currentUser?.articles?.length > 5 && !currentUser?.isShop ? '' : ''}`}
                        onClick={handleClick}
                      >
                        <span>
                          <img src="/images/plus.svg" alt="plus" />
                        </span>
                        Objavite
                      </button>
                    </>
                  )}
                  {!loggedIn && (
                    <Link to="/login" className="button_w_icon header_add">
                      <span>
                        <img src="/images/plus.svg" alt="plus" />
                      </span>
                      Prijavite se
                    </Link>
                  )}
                </>
              ) : null}

              {min992 && loggedIn ? <HeaderUser /> : null}
            </div>
          </div>
        </div>
        {pathname === '/' && (
          <section className="section section_white home_categories ">
            <div className="container header_container">
              <div className="row row_10 categories_row">
                {categoriesToShow.slice(0, min768 ? 12 : 5).map((category) => (
                  <div key={category.uuid} className="col-lg-2 col-4 home_category">
                    <Link to={`/kategorija/${category.uuid}`}>
                      <img src={'images/cat' + category.id + '.svg'} className="img-fluid" alt={category.name} />
                      <h3>{category.name}</h3>
                    </Link>
                  </div>
                ))}
              </div>
              <div className={isOpenAllCategories ? 'row row_10 categories_row' : 'row row_10 d-none'}>
                {categoriesToShow.slice(min768 ? 12 : 5, categoriesToShow.length).map((category) => (
                  <>
                    {dontHaveImage.indexOf(category.name) === -1 && (
                      <div key={category.uuid} className="col-lg-2 col-4 home_category">
                        <Link to={`/kategorija/${category.uuid}`}>
                          <img src={'images/cat' + category.id + '.svg'} className="img-fluid" alt={category.name} />
                          <h3>{category.name}</h3>
                        </Link>
                      </div>
                    )}
                  </>
                ))}
              </div>
              {categoriesToShow.length > 0 && (
                <div className="arrow_categories_holder top0">
                  <div className="arrow_categories">
                    <img
                      src={'images/wave_arrow.svg'}
                      className={isOpenAllCategories ? 'arrow_categories_up arrows_down' : 'arrows_down'}
                      onClick={() => openAllCategories(!isOpenAllCategories)}
                      alt=""
                    />
                  </div>
                </div>
              )}
            </div>
          </section>
        )}
      </header>
    </div>
  );
}

export const Messages = ({messages, userId, icon, classNameProp, min992, text = null}) => (
  <Link to={`/poruke`} className="col">
    {icon ? icon() : <img src="/images/message.svg" alt="message" height={min992 ? '30' : '20'} />}
    {!!messages.length && userId && (
      <div className="message_indicator_holder">
        <span>{messages.length}</span>
      </div>
    )}
    <span>{text}</span>
  </Link>
);

export const MessagesDesktop = ({
  unreadMessages,
  userId,
  icon,
  classNameProp,
  min992,
  dateFormat,
  setIsOpen,
  isOpen,
  buttonProps,
  text = null,
}) => (
  <div>
    <button className={`col header_indicator_btn`} {...buttonProps}>
      <>
        {icon ? icon() : <img src="/images/message.svg" alt="message" height={min992 ? '30' : '20'} />}
        {unreadMessages && !!unreadMessages.length && <span>{unreadMessages.length}</span>}
      </>
    </button>

    <div className={'default_dropdown right notifications_dropdown ' + (isOpen ? 'visible' : '')} role="menu">
      {unreadMessages &&
        unreadMessages?.map((message) => (
          <Link key={message.uuid} onClick={() => setIsOpen(false)} to={`/poruka/${message.uuid}`}>
            <span>
              {message.messages[0]?.body}
              <time>{dateFormat(message.messages[0]?.createdAt)}</time>
            </span>
          </Link>
        ))}
      {!unreadMessages?.length && (
        <span>
          <strong>Nemate novih poruka</strong>
        </span>
      )}
      <div className="default_dropdown_more">
        <Link onClick={() => setIsOpen(false)} to={`/poruke`}>
          POGLEDAJ SVE
        </Link>
      </div>
    </div>
  </div>
);

export default Header;

import {authSlice} from '../store/reducers/authReducer';
import {categoriesSlice} from '../store/reducers/categoriesReducer';
import {commonSlice} from '../store/reducers/commonReducer';
import {articlesSlice} from '../store/reducers/articlesReducer';
import {locationsSlice} from '../store/reducers/locationsReducer';
import {imagesSlice} from '../store/reducers/imagesReducer';
import {newUserSlice} from '../store/reducers/newUserReducer';
import {attributesSlice} from '../store/reducers/attributesReducer';
import {userLogout, setLocalStorage} from '../helpers/utilities';
import {messagesSlice} from '../store/reducers/messagesReducer';
import {newArticleSlice} from '../store/reducers/newArticleReducers';
import {chatsSlice} from '../store/reducers/chatsReducer';
import {notificationsSlice} from '../store/reducers/notificationsReducer';
import {impressionsSlice} from '../store/reducers/impressionsReducer';
export default class RequestApi {
  constructor(request) {
    this.request = request;
    this.dispatch = null;
    this.redirect = null;
    this.token = null;
    this.refreshTokenInterval();
    this.refreshPending = false;
    this.interval = null;
    this.addInterceptors();
  }

  addInterceptors() {
    const pending = {};
    this.request.interceptors.request.use(
      (req) => {
        const controller = new AbortController();
        let originalRequest = req;

        if (pending[req.url]) {
          delete pending[req.url];
          controller.abort();
        }
        pending[req.url] = 'source';

        // here you could add the authorization header to the request

        return originalRequest;
      },
      (err) => {
        return Promise.reject(err);
      }
    );

    this.request.interceptors.response.use(
      (response) => {
        const parsedUrl = response.request.responseURL.split('https://www.lista.ba:8443').slice(1).join('');
        if (pending[parsedUrl]) {
          // here you clean the request

          delete pending[parsedUrl];
        }
        return response;
      },
      (error) => {
        const {response} = error;
        const parsedUrl = response.request.responseURL.split('https://www.lista.ba:8443').slice(1).join('');
        console.log('error', error);

        if (this.request.isCancel(error)) {
          // here you check if this is a cancelled request to drop it silently (without error)
          return new Promise(() => {});
        }

        if (pending[parsedUrl]) {
          // here you clean the request
          delete pending[parsedUrl];
        }

        // here you could check expired token and refresh it if necessary

        return Promise.reject(error);
      }
    );
  }

  addToken(token, dispatch, redirect) {
    this.dispatch = dispatch;
    this.token = token;
    this.redirect = redirect;
    // this.request.interceptors.request.use(function (config) {
    //   config.headers.Authorization = `Bearer ${token}`;
    //   return config;
    // });

    this.request.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response?.status === 403 && !this.refreshPending) {
          this.refreshPending = true;
          await this.refresh(this.dispatch, this.redirect);
          this.refreshPending = false;
          //   dispatch(authSlice.actions.resetState());
          //   dispatch(
          //     commonSlice.actions.setNotification({
          //       type: 'warning',
          //       message: 'Sesija je istekla, molimo prijavite se',
          //     })
          //   );
          //   userLogout();
          Promise.reject(error);
        } else {
          if (error?.response?.data?.message === 'Sesija je istekla, molimo prijavite se') {
            dispatch(
              commonSlice.actions.setNotification({
                type: 'warning',
                message: error?.response?.data?.message ?? 'Doslo je do greske',
              })
            );
          }
        }
      }
    );
  }

  refreshTokenInterval() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    // Set next invocation
    this.interval = setInterval(
      (dispatch, redirect) => {
        this.refresh(dispatch, redirect).then();
      },
      4 * 60 * 1000,
      this.dispatch,
      this.redirect
    );
    if (!this.token) {
      clearInterval(this.interval);
    }
  }

  addUtilities(dispatch, redirect) {
    this.dispatch = dispatch;
    this.redirect = redirect;
  }

  async nagradnaIgraUcesnici(token) {
    try {
      const {data} = await this.request({
        url: '/nagradna-igra-ucesnici',
        method: 'get',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async nagradnaIgra(token) {
    try {
      const {data} = await this.request({
        url: '/nagradna-igra',
        method: 'get',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async nagradnaIgraPocetak(payload, token) {
    try {
      const {data} = await this.request({
        url: '/nagradna-igra',
        method: 'post',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {
          ...payload,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }
  async nagradnaIgraKraj(payload, token) {
    try {
      const {data} = await this.request({
        url: '/nagradna-igra-kraj',
        method: 'post',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {
          ...payload,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  // user
  async login(payload, dispatch, redirect, location, fallbackLocation) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request.post('/auth/login', {
        ...payload,
      });
      this.addToken(data.token, dispatch);
      dispatch(
        authSlice.actions.setUser({
          token: data.token,
          role: data.role,
          uuid: data.uuid,
          confirmed: data.confirmed,
          id: data.userId,
        })
      );
      window?.Android?.onSuccessLogin?.(data.uuid);
      window?.webkit?.messageHandlers?.onSuccessLogin?.postMessage?.({
        id: data.uuid,
      });
      await this.me(data.uuid, dispatch, data.token);
      setTimeout(() => {
        dispatch(
          commonSlice.actions.setNotification({
            type: 'success',
            message: data.message,
          })
        );
      }, 300);
      setLocalStorage(data);
      this.refreshTokenInterval();
      if (location && data.role !== 'admin') redirect(location);
      else if (data.role === 'admin') redirect('/admin');
      else redirect('/');
    } catch (data) {
      if (JSON.stringify(data.message).includes('425')) {
        const email = data.message.split(' ').slice(-1)[0];
        dispatch(authSlice.actions.setUnconfirmedUser({email}));
        dispatch(
          commonSlice.actions.setNotification({
            type: 'warning',
            message: 'Molimo potvrdite Vašu email adresu!',
          })
        );
        redirect('/potvrda-email');
      } else {
        dispatch(
          commonSlice.actions.setNotification({
            type: 'warning',
            message: 'Pogrešni korisnički podaci!',
          })
        );

        dispatch(authSlice.actions.resetState());
        if (data.confirm) {
          redirect(fallbackLocation);
        } else {
          userLogout(dispatch, authSlice.actions.resetState);
        }
      }
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async refresh() {
    try {
      const {data} = await this.request({url: '/auth/renewToken', method: 'post', timeout: 1000 * 60});
      this.dispatch(
        authSlice.actions.setUser({
          token: data.token,
          role: data.role,
          uuid: data.uuid,
          confirmed: data.confirmed,
          id: data.userId,
        })
      );
      setLocalStorage(data);
      this.addToken(data.token, this.dispatch);
      window?.Android?.onSuccessLogin?.(data.uuid);
      window?.webkit?.messageHandlers?.onSuccessLogin?.postMessage?.({
        id: data.uuid,
      });
      await this.me(null, this.dispatch, data.token);
    } catch (error) {
      this.dispatch(authSlice.actions.resetState());
      this.dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Sesija je istekla, molimo prijavite se 787',
        })
      );
      clearInterval(this.interval);
      userLogout();
      this.redirect('/');
    }
    // window.location.reload();
  }

  async logout(_, dispatch, redirect, location) {
    await this.request.post('/auth/logout');
    dispatch(authSlice.actions.resetState());
    userLogout();
    dispatch(
      commonSlice.actions.setNotification({
        type: 'success',
        message: 'Uspješno ste se odjavili!',
      })
    );
    dispatch(authSlice.actions.resetState());
    dispatch(chatsSlice.actions.resetState());
    dispatch(impressionsSlice.actions.resetState());
    dispatch(messagesSlice.actions.resetState());
    dispatch(notificationsSlice.actions.resetState());
    clearInterval(this.interval);
    redirect(location);
  }
  async forgotPassword(payload, dispatch, redirect, location) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request.post('/auth/resetToken', {
        ...payload,
      });

      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Zahtjev za reset lozinke je upućen na vaš email. Molimo da provjerite email',
        })
      );
      redirect(location);
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Korisnik nije pronadjen. Molimo provjerite vaše podatke.',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async checkResetToken(payload, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request.post('/auth/resetToken', {
        ...payload,
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: data.message,
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async newPassword(payload, dispatch, history) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request.post('/auth/resetPassword', {
        ...payload,
      });
      dispatch(
        authSlice.actions.setUser({
          token: data.token,
          role: data.role,
          uuid: data.uuid,
          confirmed: data.confirmed,
          id: data.userId,
        })
      );
      setLocalStorage(data);
      await this.me(null, dispatch, data.token);
      setTimeout(() => {
        dispatch(
          commonSlice.actions.setNotification({
            type: 'success',
            message: data.message,
          })
        );
      }, 300);
      setTimeout(() => {
        history.push('/');
      }, 500);
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Pogirjesan token, molimo provjerite email.',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async renewEmailToken(payload, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request.post('/auth/renewEmailToken', {
        ...payload,
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: data.message,
        })
      );
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    } finally {
      // dispatch(commonSlice.actions.setIsLoading(true));
    }
  }
  async register(payload, dispatch, redirect, location) {
    try {
      dispatch(commonSlice.actions.setIsLoading(true));
      await this.request.post('/auth/register', {
        ...payload,
      });
      dispatch(imagesSlice.actions.resetState());
      dispatch(newUserSlice.actions.setEmail(payload.email));
      dispatch(authSlice.actions.setUnconfirmedUser({email: payload.email}));
      redirect(location);
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async confirmEmail(payload, dispatch, history, setMessage) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request.post('/auth/confirmUser', {
        ...payload,
      });
      setMessage(data.message);
      dispatch(
        authSlice.actions.setUser({
          token: data.token,
          role: data.role,
          uuid: data.uuid,
          confirmed: data.confirmed,
          id: data.userId,
        })
      );
      await this.me(null, dispatch, data.token);
      setTimeout(() => {
        dispatch(
          commonSlice.actions.setNotification({
            type: 'success',
            message: data.message,
          })
        );
      }, 300);
      setTimeout(() => {
        setLocalStorage(data);
        history.push('/');
      }, 500);
    } catch (data) {
      setMessage(data.message);
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getUser(payload, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request(`/users/${payload}`);
      dispatch(authSlice.actions.setCurrentUser({...data}));
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }
  async me(payload, dispatch, token) {
    try {
      const {data} = await this.request({
        url: '/users/me',
        method: 'get',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
      });
      dispatch(authSlice.actions.setCurrentUser({...data}));
      dispatch(authSlice.actions.setUser({...data, token: this.token}));
      await this.refreshTokenInterval();
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data,
        })
      );
    }
  }

  async getUsers(payload, dispatch) {}

  async addUsers(payload, dispatch) {}

  async getUserProfile(payload, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request(`/users/${payload}`);
      dispatch(authSlice.actions.setUserProfile({...data}));
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async editUser(payload, dispatch, token) {
    try {
      dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: `/users/${payload.uuid}`,
        method: 'patch',
        data: {
          ...payload,
        },
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješna izmjena!',
        })
      );
      dispatch(authSlice.actions.setCurrentUser({...data}));
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async changePassword(payload, dispatch, history, token) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: `/users/changePassword/${payload.uuid}`,
        method: 'post',
        data: {
          ...payload,
        },
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
      });

      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno promijenjena lozinka!',
        })
      );
      history.push('/postavke');
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async deleteUser(payload, dispatch, history, token) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: `/auth/deleteMe/${payload.uuid}`,
        method: 'post',
        data: {
          ...payload,
        },
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
      });
      dispatch(authSlice.actions.resetState());
      userLogout();
      dispatch(authSlice.actions.resetState());
      dispatch(chatsSlice.actions.resetState());
      dispatch(impressionsSlice.actions.resetState());
      dispatch(messagesSlice.actions.resetState());
      dispatch(notificationsSlice.actions.resetState());
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Dovidjenja',
        })
      );
      history.push('/');
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Pogriješna lozinka',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  // categories
  async getCategories(payload = null, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request('/category');
      dispatch(categoriesSlice.actions.setCategories(data));
    } catch (error) {
      console.log(error);
      dispatch(categoriesSlice.actions.resetState());
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getSubCategories(payload, dispatch) {
    try {
      dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request(`/subcategory/${payload}`);
      dispatch(categoriesSlice.actions.setSubcategories(data));
    } catch (error) {
      dispatch(categoriesSlice.actions.resetState());
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async addNewCategory(categoryName, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: '/category',
        method: 'post',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...categoryName,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste dodali novu kategoriju!',
        })
      );
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async deleteExistCategory(categoryUuid, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: `/category/${categoryUuid}`,
        method: 'delete',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste obrisali kategoriju!',
        })
      );
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async updateExistCategory(uuid, payload, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: `/category/${uuid}`,
        method: 'put',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...payload,
        },
      });
      commonSlice.actions.setNotification({
        type: 'success',
        message: 'Uspješno ste promijenili naziv kategorije!',
      });
      await this.getCategories(null, dispatch);
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async addSubCategory(payload, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: '/subcategory',
        method: 'post',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste dodali novu podkategoriju!',
        })
      );
      await this.getCategories(null, dispatch);
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async updateSubCategory(uuid, payload, dispatch, catId) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: `/subcategory/${uuid}`,
        method: 'put',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste obnovili podkategoriju!',
        })
      );
      await this.getCategories(null, dispatch);
      await this.getSubCategories(data.categoryId, dispatch);
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async deleteSubCategory(uuid, dispatch, catId) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: `/subcategory/${uuid}`,
        method: 'delete',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste obnovili podkategoriju!',
        })
      );
      await this.getCategories(null, dispatch);
      await this.getSubCategories({categoryId: catId}, dispatch);
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async addNewAtribute(payload, dispatch, attributeValues) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: '/attribute',
        method: 'post',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...payload,
          attributeValues,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste dodali novi atribut!',
        })
      );
      await this.getAttributes(
        {
          parentId: payload.parentId,
          subSubCategoryId: payload.subSubCategoryId,
          subsubSubCategoryId: payload.subsubSubCategoryId,
        },
        dispatch
      );
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async addNewAttributeValue(payload, dispatch, catId) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: '/attribute/values',
        method: 'post',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste dodali novu vrijednost za atribut!',
        })
      );
      await this.getAttributes({categoryId: catId}, dispatch);
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async updateExistAttribute(uuid, payload, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: `/attribute/${uuid}`,
        method: 'put',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste dodali novi atribut!',
        })
      );
      await this.getAttributes(
        {
          parentId: payload.parentId,
          subSubCategoryId: payload.subSubCategoryId,
          subsubSubCategoryId: payload.subsubSubCategoryId,
        },
        dispatch
      );
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async deleteAttribute(uuid, dispatch, parentId) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: `/attribute/${uuid}`,
        method: 'delete',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste obrisali  atribut!',
        })
      );
      await this.getAttributes({categoryId: parentId});
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async deleteAttributeValue(uuid, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: `/attribute/value/${uuid}`,
        method: 'delete',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste obrisali vrijednost za željeni atribut!',
        })
      );
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getLocations(_, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request('/city');
      dispatch(locationsSlice.actions.setLocations(data));
    } catch (error) {
      dispatch(locationsSlice.actions.setLocations([]));
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getArticle(payload, dispatch) {
    try {
      const {data} = await this.request(`/articles/${payload}`);
      dispatch(articlesSlice.actions.setArticle(data));
    } catch (error) {
      dispatch(articlesSlice.actions.resetArticle());
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getArticles(payload, dispatch, store) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request.post('/articles/search', {
        ...payload,
      });
      dispatch(articlesSlice.actions.setNewest(data));
    } catch (error) {
      dispatch(articlesSlice.actions.resetState());
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getMoreArticles(payload, dispatch, store) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request.post('/articles/search', {
        ...payload,
      });
      dispatch(articlesSlice.actions[store](data));
    } catch (error) {
      dispatch(articlesSlice.actions.resetState());
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getFilteredByCategory(payload, dispatch, store) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request.post('/articles/search', {
        ...payload,
      });
      dispatch(articlesSlice.actions.setFiltered(data));
    } catch (error) {
      dispatch(articlesSlice.actions.resetState());
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async updateArticle(payload, dispatch, history, token) {
    try {
      dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: `/articles/${payload.uuid}`,
        method: 'put',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Čestitamo, uspješno ste uredili artikal',
        })
      );
      setTimeout(() => {
        history.goBack();
      }, 300);
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'wraning',
          message: data.message,
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async markAsSold(payload, dispatch, history, token) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: `/articles/${payload.uuid}/markAsSold`,
        method: 'put',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: data.message,
        })
      );
      setTimeout(() => {
        history.goBack();
      }, 300);
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'wraning',
          message: data.message,
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getAttributes(payload, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request.post('/attribute/all', {
        cat: payload.parentId,
        subcat: payload.subSubCategoryId,
        subsubcat: payload.subsubSubCategoryId,
      });
      dispatch(attributesSlice.actions.setAttributes(data));
    } catch (error) {
      dispatch(attributesSlice.actions.resetState());
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getRequiredAttributes(categoryId, subSubCategoryId, subsubSubCategoryId, dispatch) {
    try {
      dispatch(commonSlice.actions.setIsLoading(false));
      const {data} = await this.request.post('/attribute/required/', {
        cat: categoryId,
        subcat: subSubCategoryId,
        subsubcat: subsubSubCategoryId,
      });
      dispatch(attributesSlice.actions.setRequiredAttributes(data));
    } catch (error) {
      dispatch(attributesSlice.actions.resetState());
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async createArticle(payload, dispatch, redirect, location, token) {
    try {
      dispatch(commonSlice.actions.setIsLoading(true));

      const {data} = await this.request({
        url: '/articles',
        method: 'post',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(newArticleSlice.actions.resetState());
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: data.message,
        })
      );
      dispatch(imagesSlice.actions.resetState());
      dispatch(articlesSlice.actions.addNewArticle(data));
      redirect(location.concat(data.uuid));
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async softDeleteArticle(payload, dispatch, history) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));

      const {data} = await this.request({
        url: `/articles/${payload}/softDelete`,
        method: 'put',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: data.message,
        })
      );
      history.push('/moj-profil');
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  // images

  async createImage(payload, dispatch, token) {
    try {
      dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: '/image/',
        method: 'post',
        data: payload,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token || this.token}`,
        },
      });
      dispatch(imagesSlice.actions.setProfileImage(data.split('/').slice(-1)[0]));
      dispatch(authSlice.actions.addProfileImage(data.split('/').slice(-1)[0]));
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }
  async addImages(payload, dispatch) {
    try {
      dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: '/image/add',
        method: 'post',
        data: payload,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.token}`,
        },
      });

      dispatch(imagesSlice.actions.setImages(data.split('/').slice(-1)[0]));
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async appendImage(payload, dispatch, previousState, token, setNewImages) {
    try {
      dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: `/image/${payload.articleId}/append`,
        method: 'post',
        data: payload.form,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token || this.token}`,
        },
      });
      setNewImages((prev) => [...prev, data.uuid]);
      dispatch(articlesSlice.actions.addImage(data));
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async editProfileImage(payload, dispatch, token) {
    try {
      dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: '/image/profile',
        method: 'put',
        data: payload.imageForm,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token || this.token}`,
        },
      });
      await this.editUser(
        {
          uuid: payload.userId,
          payload: {
            profileImage: data,
            email: payload.userEmail,
            userName: payload.userName,
            name: payload.name,
            telephone: payload.telephone,
            address: payload.address,
            cityId: payload.cityId,
            companyName: payload.companyName,
            companyPin: payload.companyPin,
            vat: payload.vat,
          },
        },
        dispatch,
        token
      );
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async deleteImage(payload, dispatch, token) {
    try {
      dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: `/image/${payload.uuid}`,
        method: 'delete',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
      });
      dispatch(articlesSlice.actions.removeImage(payload.uuid));
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  // utils

  async isTaken(payload, dispatch, updateError) {
    const fieldRequest = payload.fieldName;
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request.post('/auth/isTaken', {
        ...payload,
      });
      updateError((prev) => [...prev.concat(payload.fieldName)]);
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: `${data.aleradyTakenColumn} je već zauzeto`,
        })
      );
    } catch (data) {
      updateError((prev) => [...prev.filter((field) => field !== fieldRequest)]);
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async createFavorites(payload, dispatch, token) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: '/favorite',
        method: 'post',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {
          ...payload,
        },
      });
      if (!data) {
        dispatch(articlesSlice.actions.removeFavorites(payload.entityId));
        dispatch(
          commonSlice.actions.setNotification({
            type: 'success',
            message: 'Više ne pratite ovaj oglas!',
          })
        );
      } else {
        dispatch(articlesSlice.actions.addFavorites(data));
        dispatch(
          commonSlice.actions.setNotification({
            type: 'success',
            message: 'Uspješno ste dodali u listu oglasa koje pratite!',
          })
        );
      }
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getFavorites({userId}, dispatch, token) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: `/favorite/${userId}`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
      });
      dispatch(articlesSlice.actions.setFavorites(data));
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getMessages(payload, dispatch, token) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: `/messages/${payload.uuid}`,
        method: 'post',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(messagesSlice.actions.setMessages(data));
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getChats(userId, payload, dispatch, token) {
    try {
      const {data} = await this.request({
        url: `/chat/${userId}`,
        method: 'post',
        data: {
          ...payload,
        },
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        timeout: 1000 * 6000,
      });
      dispatch(chatsSlice.actions.setChats(data));
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      // dispatch(
      //   commonSlice.actions.setNotification({
      //     type: 'warning',
      //     message: 'Doslo je do greske',
      //   })
      // );
    }
  }

  async getChat(chatUuid, dispatch, token) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: `/chat/${chatUuid}`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
      });
      dispatch(chatsSlice.actions.setChat(data));
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async setSeenMessage(userId, messages, token) {
    try {
      await this.request({
        url: `/messages/${userId}`,
        method: 'put',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {messages},
      });
    } catch (data) {
      console.log(data);
    }
  }

  async createMessage(payload, dispatch, token) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));

      await this.request({
        url: '/messages',
        method: 'post',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste poslali poruku.',
        })
      );
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async createMessageFromChat(payload, dispatch, token) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));

      const {data} = await this.request({
        url: '/messages',
        method: 'post',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(chatsSlice.actions.addMessageFromChat(data));
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async addQuestion(payload, dispatch, token) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: '/articleQuestion',
        method: 'post',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(articlesSlice.actions.addNewQuestion(data));
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste postavili pitanje!',
        })
      );
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async addComplain(payload, dispatch, token) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: '/impression/complain/admin',
        method: 'post',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Vaša žalba je proslijeđena administratoru stranice. Hvala vam',
        })
      );
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async askQuestion(payload, dispatch, token) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: '/impression/question/admin',
        method: 'post',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Pitanje je uspješno proslijeđeno našem administartoru. Bićete kontaktirani u najkarćem mogućem roku. Hvala vam!',
        })
      );
    } catch (error) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greške',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }
  //   authError() {
  //     this.request.once('connect_error', (data) => {
  //       this.dispatch(
  //         commonSlice.actions.setNotification({
  //           type: 'warning',
  //           message: data.message,
  //         })
  //       );
  //       this.dispatch(commonSlice.actions.setIsLoading(false));
  //     });
  //     this.request.once('error', ({ error }) => {
  //       if (error.name === 'ExpiredToken') {
  //         this.dispatch(
  //           commonSlice.actions.setNotification({
  //             type: 'warning',
  //             message: error.data.message,
  //           })
  //         );
  //         userLogout(this.dispatch, authSlice.actions.resetState);
  //         this.redirect('/login');
  //       }
  //       this.dispatch(commonSlice.actions.setIsLoading(false));
  //     });
  //     this.request.once('auth_error', (data) => {
  //       this.dispatch(
  //         commonSlice.actions.setNotification({
  //           type: 'warning',
  //           message: data.message,
  //         })
  //       );
  //       this.dispatch(commonSlice.actions.setIsLoading(false));
  //     });
  //   }

  async searchByTitle({where, payload, order}, dispatch) {
    // dispatch(commonSlice.actions.setIsLoading(true));
    // this.request.emit('getFiltered', { where, payload, order });
    // return new Promise((resolve, reject) => {
    //   this.request.once('getFiltered', (data) => {
    //     if (data.status === 'success') {
    //       dispatch(articlesSlice.actions.setSearchByTitleArticles(data));
    //       resolve(1);
    //     } else {
    //       dispatch(articlesSlice.actions.resetState());
    //       reject(1);
    //     }
    //     dispatch(commonSlice.actions.setIsLoading(false));
    //   });
    // });
  }

  async createNotification(payload, token) {
    try {
      await this.request({
        url: `/notifications/${payload.userId}/new`,
        method: 'post',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {
          ...payload,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getNotifications(userId, payload, dispatch, token) {
    try {
      const {data} = await this.request({
        url: `/notifications/${userId}`,
        method: 'post',
        data: {...payload},
        headers: {
          Authorization: `Bearer ${token || this.token}`, //the token is a variable which holds the token
          //   },
        },
      });
      dispatch(notificationsSlice.actions.setNotifications(data));
    } catch (data) {
      if (data?.response?.data?.name === 'TokenExpiredError' || data?.response?.data?.message === 'Token invalid') return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
    }
  }

  async getUnreadNotifications(userId, dispatch, token) {
    try {
      const {data} = await this.request({
        url: `/notifications/${userId}/unread`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        timeout: 1000 * 60,
      });
      dispatch(notificationsSlice.actions.setUnreadNotifications(data));
    } catch (data) {
      console.log(data);
      /*
      if (
        data?.response?.data?.name === 'TokenExpiredError' ||
        data?.response?.data?.message === 'Token invalid'
      )
        return;
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Doslo je do greske',
        })
      );
      */
    }
  }

  async setSeenNotification(uuid, token) {
    try {
      await this.request({
        url: `/notifications/seen/${uuid}`,
        method: 'put',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
      });
      this.dispatch(notificationsSlice.actions.setSeenNotification(uuid));
    } catch (error) {
      console.log(error);
    }
  }

  async deleteNotification(uuid, dispatch, token) {
    try {
      await this.request({
        url: `/notifications/${uuid}`,
        method: 'delete',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
      });
      dispatch(notificationsSlice.actions.deleteNotification(uuid));
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste obrisali notifikaciju!',
        })
      );
    } catch (error) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Došlo je do greške',
        })
      );
    }
  }

  async createSaleRequest(data, dispatch, token) {
    try {
      await this.request({
        url: '/salerequest',
        method: 'post',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {
          ...data,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste kreirali zahtjev za kupovinu ovog artikla!',
        })
      );
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    }
  }

  async updateSaleRequest(data, message, dispatch, token) {
    try {
      await this.request({
        url: '/salerequest',
        method: 'put',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {
          ...data,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message,
        })
      );
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    }
  }

  async createImpressions(data, dispatch, token) {
    try {
      await this.request({
        url: '/impression',
        method: 'post',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {
          ...data,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste ostavili dojam!',
        })
      );
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    }
  }

  async getImpressions(userId, payload, dispatch, token) {
    try {
      if (userId) {
        const {data} = await this.request({
          url: `/impression/${userId}`,
          method: 'post',
          headers: {
            Authorization: `Bearer ${this.token || token}`,
          },
          data: {
            ...payload,
          },
        });

        dispatch(impressionsSlice.actions.setImpressions(data));
      }
    } catch (data) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: data.message,
        })
      );
    }
  }

  async addNewModel(modelName, uuid, dispatch, subCatId) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: '/attribute/model',
        method: 'post',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          name: modelName,
          id: uuid,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste dodali novi model! Molimo da kliknete na dugme "Prikazi model" da učitate promjene!',
        })
      );
      await this.getAttributes({categoryId: subCatId}, dispatch);
    } catch (error) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Došlo je do greške!',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async deleteModel(uuid, dispatch, subCatId) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: `/attribute/model/${uuid}`,
        method: 'delete',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });

      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste dodali novi model! Molimo da kliknete na dugme "Prikazi model" da učitate promjene!',
        })
      );
      await this.getAttributes({categoryId: subCatId}, dispatch);
    } catch (error) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Došlo je do greške!',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getStoresArticles(payload, dispatch, token) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: '/articles/search',
        method: 'post',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(articlesSlice.actions.setStoresArticles(data));
    } catch (error) {
      dispatch(articlesSlice.actions.resetState());
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async featureArticle(uuid, isShop, dispatch, token) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      await this.request({
        url: `/articles/${uuid}/featureArticle`,
        method: 'put',
        headers: {
          Authorization: `Bearer ${token || this.token}`,
        },
        data: {
          ...isShop,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste izdvojili artikal!',
        })
      );
      await this.getArticle(uuid, dispatch);
    } catch (error) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Došlo je do greške!',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async addNewSubsubcategory(data, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));

      await this.request({
        url: '/subsubcategory',
        method: 'post',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...data,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste dodali novu potpotkategoriju!',
        })
      );
    } catch (error) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Došlo je do greške!',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getSubSubCategories(subCategoryId, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));

      const {data} = await this.request(`/subsubcategory/${subCategoryId}`);
      dispatch(categoriesSlice.actions.setSubSubCategories(data));
    } catch (error) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Došlo je do greške!',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async editSubSubCategory(uuid, payload, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));

      const {data} = await this.request({
        url: `/subsubcategory/${uuid}`,
        method: 'put',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(categoriesSlice.actions.setSubSubCategories(data));
    } catch (error) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Došlo je do greške!',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async deleteSubSubCategory(uuid, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));

      await this.request.delete({
        url: `/subsubcategory/${uuid}`,
        method: 'delete',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno brisanje!',
        })
      );
    } catch (error) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Došlo je do greške!',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
    // dispatch(commonSlice.actions.setIsLoading(true));
  }

  async addNewSubSubSubCategory(payload, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));

      await this.request({
        url: '/subsubsubcategory',
        method: 'post',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno ste dodali novu potpotkategoriju!',
        })
      );
    } catch (error) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Došlo je do greške!',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async updateSubSubSubCategory(id, payload, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));

      await this.request({
        url: `/subsubsubcategory/${id}`,
        method: 'put',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješna izmjena',
        })
      );
    } catch (error) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Došlo je do greške!',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async deleteSubSubSubCategory(id, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));

      await this.request({
        url: `/subsubsubcategory/${id}`,
        method: 'delete',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješno brisanje!',
        })
      );
    } catch (error) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Došlo je do greške!',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async addPikName(payload, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: '/users/addExternalName',
        method: 'post',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(authSlice.actions.updateUsers(data));
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Uspješna akcija!',
        })
      );
    } catch (error) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Došlo je do greške!',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }
  async getPikUserArticles(payload, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: '/users/externalUserArticles',
        method: 'post',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(
        authSlice.actions.addUserArticles({
          pik: data?.pik ?? [],
          local: data?.local ?? [],
        })
      );
    } catch (error) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Došlo je do greške!',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getPikUserArticlesByCategory(payload, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: '/users/externalUserArticlesByCategory',
        method: 'post',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(
        authSlice.actions.addUserArticles({
          pik: data?.pik ?? [],
          local: data?.local ?? [],
        })
      );
    } catch (error) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Došlo je do greške!',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getMorePikUserArticlesByCategory(payload, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: '/users/externalUserArticlesByCategory',
        method: 'post',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(
        authSlice.actions.addMoreUserArticles({
          pik: data?.pik ?? [],
          local: data?.local ?? [],
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Došlo je do greške!',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getMorePikUserArticles(payload, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: '/users/externalUserArticles',
        method: 'post',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(
        authSlice.actions.addMoreUserArticles({
          pik: data?.pik ?? [],
          local: data?.local ?? [],
        })
      );
    } catch (error) {
      console.log(error);

      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Došlo je do greške!',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getExternalArticle(payload, dispatch, token) {
    try {
      dispatch(commonSlice.actions.setIsLoading(true));

      const result = await Promise.all(
        payload.ids.map(async (id) => {
          const {data} = await this.request({
            url: `/articles/${id}/getExternalArticle`,
            method: 'post',
            headers: {
              Authorization: `Bearer ${token ?? this.token}`,
            },
            data: {
              userId: payload.userId,
            },
          });
          return +data;
        })
      );
      dispatch(authSlice.actions.updateLocalPikArticles(result));
      dispatch(articlesSlice.actions.resetQueueForDownload());
      dispatch(
        commonSlice.actions.setNotification({
          type: 'success',
          message: 'Akcija uspijesno zavrsena!',
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }

  async getUserAdmin(payload, dispatch) {
    try {
      // dispatch(commonSlice.actions.setIsLoading(true));
      const {data} = await this.request({
        url: '/users/getUsers',
        method: 'post',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        data: {
          ...payload,
        },
      });
      dispatch(authSlice.actions.setUsers(data));
    } catch (error) {
      dispatch(
        commonSlice.actions.setNotification({
          type: 'warning',
          message: 'Došlo je do greške!',
        })
      );
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }
}

import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import AsideSearch from '../components/aside-search';
import {RequestContext} from '../App';
import Post from '../components/post';
import Pagination from '../components/pagination';
import useDidMountEffect from '../hooks/useDidMountEffect';
import MobileFilters from '../components/mobileFilters';

function Pretraga() {
  const search = useLocation().search;
  const title = new URLSearchParams(search).get('title');
  const {api: socket} = useContext(RequestContext);
  const dispatch = useDispatch();
  const {filtered: articles} = useSelector((state) => state.articles);
  const [count, setCount] = useState(20);
  const [order, setOrder] = useState('newest');
  const [page, setPage] = useState(1);
  const [searchState, setSearchState] = useState({title});

  const [openModalFilter, setOpenModalFilter] = useState();

  const closeModalFilters = () => {
    setOpenModalFilter(false);
  };

  useEffect(() => {
    const get = async () => {
      await socket.getFilteredByCategory({where: {title}, payload: {page, count}, order}, dispatch);
    };
    get();
  }, [title]);

  useDidMountEffect(() => {
    const get = async () => {
      if (JSON.stringify(searchState).length && !articles.length) {
        await socket.getFilteredByCategory(
          {
            where: searchState,
            payload: {
              page,
              count,
            },
            order,
          },
          dispatch,
          'setFiltered'
        );
      }
    };
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(searchState), count, order, page]);

  useDidMountEffect(() => {
    setSearchState({title});
  }, [title]);

  const filterMobile = (e) => {
    e.preventDefault();
    setOpenModalFilter(true);
  };

  return (
    <>
      <section className="section category">
        <div className="container">
          <div className="row row_20">
            <div className="col-md-5 category_results"></div>
            <div className="col-md-7 category_options">
              <div className="category_option">
                <label>Sortiraj:</label>
                <select
                  value={order}
                  onChange={(e) => {
                    setOrder(e.target.value);
                    setPage(1);
                  }}
                >
                  <option value={'newest'}>Najnovije</option>
                  <option value={'oldest'}>Najstarije</option>
                  <option value={'lowest'}>Cijena - najnižoj</option>
                  <option value={'highest'}>Cijena - najvišoj</option>
                </select>
              </div>
              <div className="category_option">
                <label>Prikaži:</label>
                <select
                  value={count}
                  onChange={(e) => {
                    setCount(e.target.value);
                    setPage(1);
                  }}
                >
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 category_left">
              <button className="category_aside_toggle" onClick={filterMobile}>
                <img src="/images/filter.svg" alt="filter" /> Filtriraj rezultate
              </button>
              <AsideSearch count={count} order={order} setPage={setPage} currentPage={page} title={title} setSearchState={setSearchState} />
            </div>
            <div className="col-lg-9 col-md-8 category_right">
              <div className="row row_20 posts_4">
                {articles?.articles?.map((article) => (
                  <Post key={article.id} classProps="col no-wrap" articleprops={{...article}} />
                ))}
              </div>
              {!!articles?.total && <Pagination articles={articles} setPage={setPage} page={page} perPage={count} title={title} />}
            </div>
          </div>
        </div>
      </section>

      <MobileFilters
        isOpen={openModalFilter}
        onRequestClose={closeModalFilters}
        setSearchState={setSearchState}
        setPage={setPage}
        count={count}
        searchState={searchState}
      />
    </>
  );
}
export default Pretraga;

import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import {isMobile} from 'react-device-detect';

export default class ImageGallaryComponent extends React.Component {
  render() {
    const {setOpenGalleryModal, setSelectedImage, isEdit} = this.props;
    return (
      <div className="text-md-center">
        <Carousel
          onClickItem={(index) => {
            setSelectedImage(index);
            setOpenGalleryModal(true);
          }}
          onChange={(index) => {
            if (this.props.setSelectedImage) {
              setSelectedImage(index);
            }
          }}
          thumbWidth={100}
          showThumbs={isEdit ? true : false}
          width={isMobile ? 200 : '100%'}
          useKeyboardArrows={true}
          showIndicators={false}
          showArrows={false}
          showStatus={false}
          infiniteLoop={true}
          selectedItem={this.props.selectedImage}
          interval="5000"
          transitionTime="200"
          dynamicHeight={isEdit ? false : true}
        >
          {this.props.children}
        </Carousel>
      </div>
    );
  }
}

export class ImageModalGallery extends React.Component {
  render() {
    const {selectedImage} = this.props;
    return (
      <Carousel
        className="w-100 p-0"
        useKeyboardArrows={true}
        infiniteLoop={true}
        showStatus={true}
        interval="5000"
        transitionTime="500"
        showArrows={true}
        dynamicHeight={true}
        selectedItem={selectedImage}
        showThumbs={true}
      >
        {this.props.children}
      </Carousel>
    );
  }
}

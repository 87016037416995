import {useState, useContext, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {RequestContext} from '../App';
import Underhead from '../components/underhead';
function ResetPassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {api: socket} = useContext(RequestContext);
  const state = useSelector((state) => state.resetPassword);
  const [resetStateInputs, setResetStateInputs] = useState(state);

  useEffect(() => {
    if (!resetStateInputs.email) history.push('/');
  }, [resetStateInputs.email, history]);

  const handleReset = async (e) => {
    e.preventDefault();
    await socket.newPassword({...resetStateInputs}, dispatch, history);
  };
  const onChange = (e) => {
    setResetStateInputs({...resetStateInputs, [e.target.name]: e.target.value});
  };

  const isDisabled = !resetStateInputs?.token?.length || resetStateInputs?.password?.length < 6;
  return (
    <>
      <Underhead title="Reset šifre" items={[{link: '', title: 'Reset šifre'}]} />
      <section className="section">
        <div className="container">
          <div className="jumbotron">
            <h2 className="title">Unesite novu šifru</h2>
            <div className="separator"></div>
            <div className="row">
              <div className="col-lg-6">
                <h5 className="form_default_label">Kod za reset</h5>
                <label className="form_default_input form_default_w_icon">
                  <input type="text" onChange={onChange} name="token" placeholder="Unesite šestocifreni kod" />
                </label>
              </div>
              <div className="col-lg-6">
                <h5 className="form_default_label">Unesite novu šifru</h5>
                <label className="form_default_input form_default_w_icon">
                  <input type="password" name="password" onChange={onChange} placeholder="Password" />
                  <img src="images/icon_lock.svg" alt="lock" />
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <button disabled={isDisabled} className="button w-100" onClick={handleReset}>
                  <b>Potvrdite</b>
                </button>
              </div>
              <div className="col-lg-6">
                <Link to="/registracija" className="button button_light w-100">
                  Nemate nalog? Registrujte se
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ResetPassword;

import {Link} from 'react-router-dom';
import {useState, useContext} from 'react';
import {RequestContext} from '../App';
import {useDispatch, useSelector} from 'react-redux';
import {SMALL_IMAGE} from '../api/apiBase';
import {dateFormat} from '../helpers/utilities';
import Modal from '../components/modal';

export default function ProfileReview({currentUserId, id, user, article, comment, createdAt, rating, currentUser, impressionsSender}) {
  const [impressionModal, setImpressionModal] = useState(false);
  const [impression, setImpression] = useState('');
  const {user: loggedUser} = useSelector((state) => state.auth);
  const [ratingType, setRatingType] = useState('neutralan');
  const dispatch = useDispatch();
  const {api: socket} = useContext(RequestContext);
  console.log(impressionsSender);
  const impressionReplied = impressionsSender?.find((i) => i.senderId === loggedUser?.id && i.articleId === article?.id);
  const [replied, setReplied] = useState(impressionReplied);

  const handleReply = (e) => {
    setImpressionModal(true);
  };

  const closeImpressionModal = () => setImpressionModal(false);

  const handleImpression = async (e) => {
    if (impression) {
      await socket.createImpressions(
        {senderId: currentUser?.id, receiverId: user?.id, articleId: article?.id, rating: ratingType, comment: impression},
        dispatch,
        loggedUser?.token
      );
      await socket.createNotification(
        {
          body: `Korisnik ${currentUser?.userName} Vam je ostavio dojam!`,
          notificationType: 'article',
          userId: user?.id,
          seen: false,
          link: `/dojmovi/${user?.uuid}`,
        },
        loggedUser?.token
      );
      setReplied(true);
      closeImpressionModal();
    }
  };

  const handleRating = (e) => {
    setRatingType(e.target.alt);
  };

  return (
    <>
      <div className="row profile_review_item">
        <div className="col-lg-3 col-md-4 col-12 profile_review_item_user">
          <Link to={user?.isShop ? `/prodavnica/${user.uuid}` : `/user/${user.uuid}`}>
            <figure>
              <img
                className="detail_user_image"
                src={user.profileImage ? SMALL_IMAGE + user.profileImage : '/images/user_placeholder.svg'}
                alt="user"
              />
            </figure>
          </Link>
          <h3>
            <Link to={user?.isShop ? `/prodavnica/${user.uuid}` : `/user/${user.uuid}`}>{user.userName}</Link>
          </h3>
          {/* <div>
          <span>
            <img src="/images/profile_icon1.svg" alt="icon" />
          </span>
          <span>
            <img src="/images/profile_icon2.svg" alt="icon" />
          </span>
          <span>
            <img src="/images/profile_icon3.svg" alt="icon" />
          </span>
          <span>
            <img src="/images/profile_icon4.svg" alt="icon" />
          </span>
          <span>
            <img src="/images/profile_icon5.svg" alt="icon" />
          </span>
        </div> */}
        </div>
        <div className="col-lg-3 col-md-6 col-12 profile_review_item_desc">
          <h4>
            <Link to={`/detail/${article.uuid}`}>{article.title}</Link>
          </h4>
          <p>{comment}</p>
        </div>
        <div className="col-lg-3 col-md-6 col-12 profile_review_item_desc">
          {id === currentUserId && !replied ? (
            <button className="button button_green" onClick={handleReply}>
              <strong>Uzvratite dojam!</strong>
            </button>
          ) : (
            ''
          )}
        </div>
        <div className="col-lg-3 col-md-2 profile_review_item_date">
          <time>{dateFormat(createdAt)}</time>
        </div>
        <span className="profile_review_item_icon">
          {rating === 'pozitivan' ? (
            <img src="/images/like_green.svg" alt="like" />
          ) : rating === 'negativan' ? (
            <img src="/images/dislike_red.svg" alt="dislike" />
          ) : (
            <img src="/images/neutral_blue.svg" alt="neutral" />
          )}
        </span>
      </div>

      <Modal onRequestClose={closeImpressionModal} isOpen={impressionModal}>
        <div className="col-md-12">
          <label className="form_default_input">
            <textarea
              type="text"
              placeholder="Ostavite dojam o poslovanju sa ovim korisnikom, te na taj način pomozite ostalim kupcima!"
              name="question"
              onChange={(e) => setImpression(e.target.value)}
            ></textarea>
            <div className="row row_10">
              <div className="col-4">
                <figure>
                  <img
                    src={ratingType === 'pozitivan' ? `/images/like.svg` : `/images/like_green.svg`}
                    alt="pozitivan"
                    onClick={handleRating}
                  />
                </figure>
              </div>
              <div className="col-4  ">
                <figure>
                  <img
                    src={ratingType === 'neutralan' ? `/images/neutral.svg` : `/images/neutral_blue.svg`}
                    alt="neutralan"
                    onClick={handleRating}
                  />
                </figure>
              </div>
              <div className="col-4">
                <figure>
                  <img
                    src={ratingType === 'negativan' ? `/images/dislike.svg` : `/images/dislike_red.svg`}
                    alt="negativan"
                    onClick={handleRating}
                  />
                </figure>
              </div>
            </div>
            <button className="button w-100" onClick={handleImpression}>
              Ostavite dojam!
            </button>
          </label>
        </div>
      </Modal>
    </>
  );
}

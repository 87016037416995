import Symbol_observable from 'symbol-observable';

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import './styles/global.scss';
import {Provider as ReduxProvider} from 'react-redux';
import {store} from './store';
import ToastMessage from './components/toastContainer';
import * as serviceWorker from './serviceWorker';
import 'lightgallery.js/dist/css/lightgallery.css';
import {LightgalleryProvider} from 'react-lightgallery';

ReactDOM.render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <LightgalleryProvider>
        <ToastMessage />
        <App />
      </LightgalleryProvider>
    </BrowserRouter>
  </ReduxProvider>,
  document.getElementById('root')
);

// serviceWorker.register()

import {createContext, useState, useEffect, useRef} from 'react';
import {Switch, Route, useLocation, useHistory} from 'react-router-dom';
import useMedia from 'use-media';
import Header from './components/header';
import Footer from './components/footer';
import MobileTabs from './components/mobileTabs';
import NotFound from './pages/404';
import Home from './pages/home';
import Objava from './pages/objava';
import Detail from './pages/detail';
import Poruke from './pages/poruke';
import Poruka from './pages/poruka';
import MojProfil from './pages/moj-profil';
import AktivneObjave from './pages/aktivne-objave';
import User from './pages/user';
import Dojmovi from './pages/dojmovi';
import Login from './pages/login';
import ZaboravljenaSifra from './pages/zaboravljena-sifra';
import Registracija from './pages/registracija';
import DetailInfo from './pages/detaljne-informacije';
import UseOfTerms from './pages/uslovi-koristenja';
import PrivacyPolicy from './pages/zastita-privatnosti';
import Objava1 from './pages/objava-1';
import Objava2 from './pages/objava-2';
import Objava3 from './pages/objava-3';
import Objava4 from './pages/objava-4';
import Notifikacije from './pages/notifikacije';
import DetailComments from './pages/pitanja-odgovori';
import Postavke from './pages/postavke';
import Kategorija from './pages/kategorija';
import {useDispatch, useSelector} from 'react-redux';
import {authSlice} from './store/reducers/authReducer';
import RequestApi from './api/endpointsMethods';
import {PrivateRoute, OnlyPublicRoute} from './helpers/auth';
import ResetPassword from './pages/reset-sifre';
import VerifyEmail from './pages/potvrda-adrese';
import Loading from './components/loading';
import Favoriti from './pages/favoriti';
import ChangePassword from './pages/change-password';
import {EditArticle} from './pages/edit-article';
import KategorijaSve from './pages/category-all';
import Potkategorija from './pages/subcategory';
import Pretraga from './pages/pretraga';
import NeodgovorenePoruke from './pages/neodgovorene';
import Admin from './pages/admin';
import Prodavnica from './pages/prodavnica';
import VerifyAccount from './pages/potvrda-naloga';
import Admin1 from './pages/admin-1';
import Admin2 from './pages/admin-2';
import DownloadApps from './components/downloadapps';
import AdminKorisnici from './pages/admin-korisnici';
import AdminKorisnik from './pages/admin-korisnik';
import {request} from './api/endpoints';
import DeleteMe from './pages/delete-me';
// scroll to top after route change
function ScrollToTop() {
  const {pathname} = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}
const RequestClient = new RequestApi(request);
const api = {api: RequestClient};
export const RequestContext = createContext(api);
function App() {
  const pollNotifications = useRef();
  const pollMessages = useRef();

  const history = useHistory();
  const min992 = useMedia({minWidth: 992});
  const dispatch = useDispatch();
  const {auth} = useSelector((state) => state);
  const {pathname} = useLocation();
  const [showMobileApps, setShowMobileApps] = useState(false);
  const isDetailAndMobile = !min992 && pathname.includes('/detail/');

  useEffect(() => {
    RequestClient.addUtilities(dispatch, history.push);
    RequestClient.getCategories({}, dispatch);
    RequestClient.getLocations({}, dispatch);
  }, []);
  useEffect(() => {
    const startingData = async () => {
      const token = localStorage.getItem('token');
      const uuid = localStorage.getItem('uuid');
      const confirmed = localStorage.getItem('confirmed');
      dispatch(authSlice.actions.setUser({token, uuid, confirmed}));
      if (auth.user.token || token) {
        await RequestClient.refresh();
        RequestClient.addToken(auth.user.token || token, dispatch, history.push);
        await RequestClient.me(auth.user.uuid || uuid, dispatch, auth.user.token);

        auth?.user?.id && (await RequestClient.getFavorites({userId: auth.user.id}, dispatch, auth.user.token));
      }
    };
    startingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    var iswebview = require('is-webview');

    if (window.innerWidth < 768 && !iswebview(navigator.userAgent)) {
      setShowMobileApps(true);
    }
  }, [auth.currentUser.id]);

  const fetchMessages = async (user) => {
    if (user?.token) {
      if (user?.id) {
        await RequestClient.getChats(user.id, {page: 0, count: 20}, dispatch, user.token);
      }
    }
  };

  const fetchNotifications = async (user) => {
    if (user?.token) {
      if (user?.id) {
        await RequestClient.getUnreadNotifications(user?.id, dispatch, user.token);
      }
    }
  };
  useEffect(() => {
    // update axios with new token
    auth?.user?.token && RequestClient.addToken(auth.user.token, dispatch, history.push);
    clearInterval(pollNotifications.current);
    clearInterval(pollMessages.current);
    RequestClient.refreshTokenInterval();
    if (auth?.user?.id && auth?.user?.token) {
      pollNotifications.current = setInterval(async () => {
        await fetchMessages(auth.user);
      }, 30000);
      pollMessages.current = setInterval(async () => {
        await fetchNotifications(auth.user);
      }, 60000);
    }
    return () => {
      clearInterval(pollNotifications.current);
      clearInterval(pollMessages.current);
    };
  }, [auth.user.token]);

  return (
    <div className="App app_wrapper">
      <RequestContext.Provider value={api}>
        {!isDetailAndMobile && <Header />}
        {showMobileApps && <DownloadApps setShowMobileApps={setShowMobileApps} />}
        <Loading />
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/search" component={Pretraga} />
          <PrivateRoute exact path="/objava" component={Objava} />
          <PrivateRoute exact path="/objava-1" component={Objava1} />
          <PrivateRoute exact path="/objava-2" component={Objava2} />
          <PrivateRoute exact path="/objava-3" component={Objava3} />
          <PrivateRoute exact path="/objava-4" component={Objava4} />
          <Route exact path="/kategorija/:id/potkategorija/:subCategoryId" component={Potkategorija} />
          <Route path="/kategorija/:id" component={Kategorija} />
          <Route path="/kategorija" component={KategorijaSve} />
          <Route path="/detail/:id/edit" component={EditArticle} />
          <Route exact path="/detail" component={Detail} />
          <Route path="/detail/:id" component={Detail} />
          <Route exact path="/detaljne-informacije" component={DetailInfo} />
          <Route exact path="/pitanja-odgovori/:id" component={DetailComments} />
          <PrivateRoute exact path="/poruke" component={Poruke} />
          <PrivateRoute exact path="/poruka/:id" component={Poruka} />

          <PrivateRoute exact path="/neodgovorene" component={NeodgovorenePoruke} />
          <PrivateRoute exact path="/notifikacije" component={Notifikacije} />
          <PrivateRoute exact path="/moj-profil" component={MojProfil} />
          <PrivateRoute exact path="/postavke" component={Postavke} />
          <PrivateRoute exact path="/favoriti/:id" component={Favoriti} />
          <PrivateRoute exact path="/promjena-sifre" component={ChangePassword} />
          <PrivateRoute exact path="/obrisi-me" component={DeleteMe} />
          <Route exact path="/aktivne-objave/:id" component={AktivneObjave} />
          <Route exact path="/zavrsene-objave/:id" component={AktivneObjave} />
          <Route exact path="/dojmovi/:id" component={Dojmovi} />
          <Route exact path="/dojmovi" component={Dojmovi} />
          <Route exact path="/user/:id" component={User} />
          <Route exact path="/prodavnica/:id" component={Prodavnica} />
          <OnlyPublicRoute exact path="/login" component={Login} />
          <OnlyPublicRoute exact path="/zaboravljena-sifra" component={ZaboravljenaSifra} />
          <OnlyPublicRoute exact path="/ponistavanje-lozinke" component={ResetPassword} />
          <Route exact path="/potvrda-email" component={VerifyEmail} />
          <Route exact path="/potvrda-naloga" component={VerifyAccount} />
          <OnlyPublicRoute exact path="/registracija" component={Registracija} />
          <Route exact path="/uslovi-koristenja" component={UseOfTerms} />
          <Route exact path="/zastita-privatnosti" component={PrivacyPolicy} />
          <PrivateRoute exact path="/admin/subsubsub" component={Admin2} onlyAdmin={true} />
          <PrivateRoute exact path="/admin/subsub" component={Admin1} onlyAdmin={true} />
          <PrivateRoute exact path="/admin" component={Admin} onlyAdmin={true} />
          <PrivateRoute exact path="/admin/korisnici" component={AdminKorisnici} onlyAdmin={true} />
          <PrivateRoute exact path="/admin/korisnici/:id" component={AdminKorisnik} onlyAdmin={true} />

          <Route path="*" component={NotFound} />
        </Switch>
        {min992 ? <Footer /> : <MobileTabs />}
      </RequestContext.Provider>
    </div>
  );
}
export default App;

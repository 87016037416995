import {Link} from 'react-router-dom';
import ProfileList from '../components/profileList';
import ProfileHead from '../components/profileHead';
import ProfileInfo from '../components/profileInfo';
import ProfileReviews from '../components/profileReviews';
import '../styles/profile.scss';
import {useDispatch, useSelector} from 'react-redux';
import {dateFormat} from '../helpers/utilities';
import {MEDIUM_IMAGE} from '../api/apiBase';
import {useEffect} from 'react';
import {RequestContext} from '../App';
import {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {authSlice} from '../store/reducers/authReducer';
import withSeo from '../components/seo';
function Profile({setTitle}) {
  const {api: socket} = useContext(RequestContext);
  const {user, currentUser} = useSelector((state) => state.auth);
  const {locations} = useSelector((state) => state.locations);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    setTitle('Moj profil');
    // if (user.uuid) socket.getUser(user.uuid, dispatch);
    dispatch(authSlice.actions.resetUserProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(user)]);

  const handleLogout = () => {
    socket.logout({uuid: currentUser.uuid, id: currentUser.id}, dispatch, history.push, '/');
    socket.addToken(null);
  };

  const articlesSoldOrAcitve = (status) =>
    currentUser?.articles?.filter((article) => !!article.soldAt === status && article.status !== 'blokiran');
  // const impressionByRating = (ratings) => currentUser?.impressions?.filter((impression) => ratings.indexOf(impression.rating) > -1);
  const location = locations?.find((location) => location.id === currentUser.cityId);
  const image = currentUser?.profileImage ? MEDIUM_IMAGE + currentUser?.profileImage : '/images/user_placeholder.svg';

  const positiveImpressions = currentUser?.impressionsReceiver?.filter((i) => i.rating === 'pozitivan').length;
  const neutralImpressions = currentUser?.impressionsReceiver?.filter((i) => i.rating === 'neutralan').length;
  const negativeImpressions = currentUser?.impressionsReceiver?.filter((i) => i.rating === 'negativan').length;

  return (
    <>
      <ProfileHead username={currentUser.userName} online={true} myProfile={true} image={image} />
      <section className="section profile">
        <div className="container">
          <ProfileList active={articlesSoldOrAcitve(false)} sold={articlesSoldOrAcitve(true)} uuid={currentUser?.uuid} />
          <div className="row">
            <div className="col-lg-7">
              <ProfileReviews
                positive={positiveImpressions}
                neutral={neutralImpressions}
                negative={negativeImpressions}
                uuid={currentUser?.uuid}
              />
              {/* <ProfileReviews
                positive={impressionByRating([4, 5])}
                neutral={impressionByRating([3])}
                negative={impressionByRating([1, 2])}
              /> */}
            </div>
            <div className="col-lg-5">
              <ProfileInfo location={location?.name} createdAt={dateFormat(currentUser.createdAt)} />
            </div>
          </div>
          <ul className="profile_list">
            <li>
              <Link to="/postavke">
                <img src="/images/cog.svg" alt="icon" />
                Postavke
              </Link>
            </li>
            <li>
              <Link to="../promjena-sifre">
                <img src="/images/cog.svg" alt="icon" />
                Promjena šifre
              </Link>
            </li>
            <li>
              <Link to={'/favoriti/' + user?.id}>
                <img src="/images/cog.svg" alt="icon" />
                Oglasi koje pratim
              </Link>
            </li>
            <li>
              <Link to="/obrisi-me">
                <img src="/images/cog.svg" alt="icon" />
                Obriši nalog
              </Link>
            </li>
            {/* <li>
              <Link to="#">
                <img src="/images/cog.svg" alt="icon" />
                SMS notifikacije
              </Link>
            </li>
            <li>
              <Link to="#">
                <img src="/images/cog.svg" alt="icon" />
                Email notifikacije
              </Link>
            </li> */}
            {/* <li>
              <Link to="/potvrda-email">
                <img src="/images/cog.svg" alt="icon" />
                Verifikacija
              </Link>
            </li> */}
            <li>
              <Link onClick={handleLogout} to="/login">
                Odjava
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}

export default withSeo(Profile);

import {useState, useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {dateFormat} from '../helpers/utilities';
import Modal from './modal';
import {RequestContext} from '../App';
export default function Comments({numberOfComments}) {
  const dispatch = useDispatch();
  const {api: socket} = useContext(RequestContext);
  const {currentUser, user} = useSelector((state) => state.auth);
  const {article} = useSelector((state) => state.articles);
  const {questions} = useSelector((state) => state?.articles?.article) || {article: {questions: []}};
  const userQuestions = questions?.filter(
    (q) => q.parentQuestionId === null && (q.userId === currentUser.id || article?.userId === currentUser.id)
  );
  numberOfComments(userQuestions?.length);
  const userAnswers = questions?.filter((q) => q.parentQuestionId);
  const isLoggedOwnerOfArticle = article?.userId === currentUser?.id;
  const [isOpen, setIsOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [question, setQuestion] = useState();
  const [replyTo, setReplyTo] = useState();

  const handleNewReply = (id, replyToUserId) => {
    setCurrentId(id);
    setReplyTo(replyToUserId);
    setIsOpen((prev) => !prev);
  };

  const handleAddReply = async (e) => {
    e.preventDefault();
    await socket.addQuestion(
      {articleId: article.id, userId: currentUser.id, body: question, parentQuestionId: currentId},
      dispatch,
      user?.token
    );

    await socket.createNotification(
      {
        body: `Korisnik ${currentUser.userName} je odgovorio na Vaše pitanje!`,
        notificationType: 'question',
        userId: replyTo,
        seen: false,
        link: window.location.pathname,
      },
      user?.token
    );
    setIsOpen(false);
  };

  const closeModal = () => setIsOpen(false);

  return (
    <div className="comments">
      {userQuestions?.map((question) => (
        <div className="mb-4">
          <div key={question.id} className="media comments_item">
            <figure>
              <Link to={`/user/${question?.user?.uuid}`}>
                <img src="/images/user_placeholder.svg" alt="user" />
              </Link>
            </figure>
            <div className="media-body">
              <div className="row">
                <div className="col-md-auto">
                  <h5>
                    <Link to={`/user/${question?.user?.uuid}`}>
                      {question?.user?.isShop ? `${question.user.companyName} (${question.user.name})` : question.user.name}
                    </Link>
                  </h5>
                  <time>{dateFormat(question.createdAt)}</time>
                </div>
                {isLoggedOwnerOfArticle && !article?.soldAt && (
                  <div className="col-md-auto my-auto comments_item_reply">
                    <button onClick={() => handleNewReply(question.id, question?.user?.id)}>
                      <img src="/images/reply.svg" alt="reply" /> Odgovori
                    </button>
                  </div>
                )}
              </div>
              <p>{question.body}</p>
            </div>
          </div>
          {userAnswers?.map((answer) => {
            if (answer.parentQuestionId === question.id)
              return (
                <div key={answer.id} className="media pl-5 comments_item">
                  <figure>
                    <Link to="/moj-profil">
                      <img src="/images/user_placeholder.svg" alt="user" />
                    </Link>
                  </figure>
                  <div className="media-body">
                    <div className="row">
                      <div className="col-md-auto">
                        <h5>
                          <Link to={`/user/${answer?.user?.uuid}`}>
                            {answer?.user?.isShop ? `${answer.user.companyName} (${answer.user.name})` : answer.user.name}
                          </Link>
                        </h5>
                        <time>{dateFormat(answer.createdAt)}</time>
                      </div>
                    </div>
                    <p>{answer.body}</p>
                  </div>
                </div>
              );
            return null;
          })}
        </div>
      ))}
      <Modal isOpen={isOpen} onRequestClose={closeModal}>
        <div className="col-md-12">
          <label className="form_default_input">
            <textarea type="text" placeholder="Upišite vaš odgovor" onChange={(e) => setQuestion(e.target.value)}></textarea>
            <button className="button w-100" onClick={handleAddReply}>
              Odgovori na pitanje
            </button>
          </label>
        </div>
      </Modal>
    </div>
  );
}

import {useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RequestContext} from '../App';
import {categoriesSlice} from '../store/reducers/categoriesReducer';
import Attribute from './attribute';

const initialState = {
  name: '',
  alias: '',
  categoryId: null,
  subCategoryParent: null,
  orderNumber: 0,
  subCategoryId: null,
};

const initialStateEdit = {
  uuid: '',
  name: '',
  alias: '',
};

const Admin1 = () => {
  const {api: socket} = useContext(RequestContext);
  const dispatch = useDispatch();
  const {categories, subCategories, subSubCategories: subSubCategoriesRedux} = useSelector((state) => state.categories);
  const [subcategories, setSubcategories] = useState(subCategories ?? []);
  const [subsubcategory, setsubsubcategory] = useState(initialState);
  const [subSubCategoryEdit, setSubSubCategoryEdit] = useState(initialStateEdit);
  const [subSubCategoryDelete, setSubSubCategoryDelete] = useState(null);
  const [, setSubSubCategories] = useState(subSubCategoriesRedux ?? []);

  const handleChangeCategory = (e, flag) => {
    if (flag === 'new') {
      setsubsubcategory({
        ...subsubcategory,
        categoryId: Number(e.target.value),
        subCategoryParent: null,
        subCategoryId: null,
      });
      if (e.target.value) setSubcategories(categories?.filter((category) => category.id === Number(e.target.value))[0].subcategories);
    } else if (flag === 'edit') {
      dispatch(
        categoriesSlice.actions.setSubcategories(categories?.filter((category) => category.id === Number(e.target.value))[0]?.subcategories)
      );
      setSubSubCategories([]);
      setSubSubCategoryDelete(null);
      setSubSubCategoryEdit(initialStateEdit);
    }
  };

  const handleChangeSubCategory = async (e, flag) => {
    if (flag === 'new') {
      setsubsubcategory({
        ...subsubcategory,
        subCategoryParent: Number(e.target.value),
        subCategoryId: Number(e.target.value),
      });
    } else if (flag === 'edit') {
      await socket.getSubSubCategories(e.target.value, dispatch);
    }
  };

  const handleChangeSubSubCategory = (e) => {
    const name = subSubCategoriesRedux?.filter((s) => s.uuid === e.target.value)[0].name;
    setSubSubCategoryDelete(e.target.value);
    setSubSubCategoryEdit({
      ...subSubCategoryEdit,
      uuid: e.target.value,
      name,
      alias: name,
    });
  };

  const addNewSubSubCategory = async () => {
    await socket.addNewSubsubcategory(subsubcategory, dispatch);
  };

  const editSubSubCategory = async () => {
    await socket.editSubSubCategory(subSubCategoryEdit.uuid, subSubCategoryEdit, dispatch);
    handleChangeCategory({target: {value: 'error'}}, 'edit');
    setSubSubCategoryDelete(null);
    setSubSubCategoryEdit(initialStateEdit);
    setSubSubCategories([]);
  };

  const deleteSubSubCategory = async () => {
    await socket.deleteSubSubCategory(subSubCategoryDelete, dispatch);
    handleChangeCategory({target: {value: 'error'}}, 'edit');
    setSubSubCategoryDelete(null);
    setSubSubCategoryEdit(initialStateEdit);
    setSubSubCategories([]);
  };

  return (
    <section className="section category">
      <div className="container container-admin">
        <div className="col-12">
          <h2>POTPOTKATEGORIJE</h2>
          <br />
          <h3>Dodaj novu potpotkategoriju</h3>
        </div>
        <div className="row">
          <div className="col-3">
            <label className="form_default_select">
              <select name="categories" onChange={(e) => handleChangeCategory(e, 'new')}>
                <option value="">Izaberite kategoriju</option>
                {categories?.map((category) => (
                  <option value={category.id} key={category.uuid}>
                    {category.name}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className="col-3">
            <label className="form_default_select">
              <select name="categories" onChange={(e) => handleChangeSubCategory(e, 'new')}>
                <option value={''}>Izaberite potkategoriju</option>
                {subcategories.length &&
                  subcategories?.map((subcategory) => (
                    <option key={subcategory.uuid} value={subcategory.id}>
                      {subcategory.name}
                    </option>
                  ))}
              </select>
            </label>
          </div>
          <div className="col-4">
            <label className="form_default_input">
              <input
                type="text"
                maxLength={50}
                placeholder="Unesite naziv"
                onChange={(e) => {
                  setsubsubcategory({
                    ...subsubcategory,
                    name: e.target.value,
                    alias: e.target.value,
                  });
                }}
              />
            </label>
          </div>
          <div className="col-2">
            <button
              disabled={!subsubcategory.name.length || !subsubcategory.categoryId || !subsubcategory.subCategoryId}
              className="button button_blue"
              onClick={addNewSubSubCategory}
            >
              Potvrdi
            </button>
          </div>
        </div>
        <div className="separator"></div>
        <div className="col-12">
          <h3>Izmijeni/Obriši potpotkategoriju</h3>
        </div>
        <div className="row">
          <div className="col-2">
            <label className="form_default_select">
              <select onChange={(e) => handleChangeCategory(e, 'edit')}>
                <option value="">Izaberite kategoriju</option>
                {categories?.map((category) => (
                  <option value={category.id} key={category.uuid}>
                    {category.name}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className="col-2">
            <label className="form_default_select">
              <select onChange={(e) => handleChangeSubCategory(e, 'edit')}>
                <option value="">Izaberite potkategoriju</option>
                {subCategories?.map((subCategory) => (
                  <option value={subCategory.id} key={subCategory.uuid}>
                    {subCategory.name}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className="col-2">
            <label className="form_default_select">
              <select onChange={handleChangeSubSubCategory}>
                <option value="">Izaberite potpotkategoriju</option>
                {subSubCategoriesRedux?.map((subSubCategory) => (
                  <option value={subSubCategory.uuid} key={subSubCategory.uuid} name={subSubCategory.name}>
                    {subSubCategory.name}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className="col-4">
            <label className="form_default_input">
              <input
                value={subSubCategoryEdit.name}
                onChange={(e) =>
                  setSubSubCategoryEdit({
                    ...subSubCategoryEdit,
                    name: e.target.value,
                    alias: e.target.value,
                  })
                }
                type="text"
                maxLength={50}
                placeholder="Unesite novi naziv potpotkategorije"
              />
            </label>
          </div>
          <div className="col-2">
            <button
              onClick={editSubSubCategory}
              className="button button_orange"
              disabled={!subSubCategoryEdit.name.length || !subSubCategoryEdit.uuid.length}
            >
              Izmijeni
            </button>
          </div>
          <div className="offset-10 col-2">
            <button onClick={deleteSubSubCategory} className="button button_red" disabled={!subSubCategoryDelete}>
              Obriši
            </button>
          </div>
        </div>
        <Attribute isSubSub />
      </div>
    </section>
  );
};

export default Admin1;

import {Link} from 'react-router-dom';
import '../styles/components/underhead.scss';

function Underhead(props) {
  return (
    <div className="underhead">
      <div className="container">
        <h1>{props.title}</h1>
        <ul>
          <li>
            <Link to="/">Početna</Link>
          </li>
          {props.items.map((item, i) => (
            <li key={i}>{item.link ? <Link to={item.link}>{item.title}</Link> : item.title}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Underhead;

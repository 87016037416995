import {Link} from 'react-router-dom';
import useMedia from 'use-media';

function MessagesLeft({inbox, unanswered}) {
  const min768 = useMedia({minWidth: 768});
  return (
    <aside className="col-md-4 messages_left">
      {min768 ? (
        <ul>
          <li className={window.location.pathname === '/poruke' ? 'active' : ''}>
            <Link to="/poruke">
              Inbox <span className="badge">{inbox}</span>
            </Link>
          </li>
        </ul>
      ) : (
        <Link className="messages_left_mobile" to="/poruke">
          Inbox <span className="badge">{inbox}</span>
        </Link>
      )}
    </aside>
  );
}

export default MessagesLeft;

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  articles: [],
  popularArticles: [],
  featuredArticles: [],
  newestArticles: {},
  storesArticles: [],
  discounts: [],
  filtered: [],
  searchByTitleArticles: [],
  favorites: [],
  article: null,
  setForDownload: {
    userId: null,
    articles: [],
  },
};

export const articlesSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    setArticles: (state, action) => {
      state.articles = action.payload;
      return state;
    },
    setFeatured: (state, action) => {
      state.featuredArticles = action.payload;
      return state;
    },
    addNewArticle: (state, action) => {
      state.newestArticles.articles = [action.payload, ...state.newestArticles.articles];
      return state;
    },
    setNewest: (state, action) => {
      state.newestArticles = action.payload;
      return state;
    },
    setStoresArticles: (state, action) => {
      state.storesArticles = action.payload;
      return state;
    },
    setNewestLoadMore: (state, action) => {
      state.newestArticles = {
        articles: state.newestArticles.articles.concat(action.payload.articles),
        hasNext: action.payload.hasNext,
        total: action.payload.total,
      };
      return state;
    },
    setFiltered: (state, action) => {
      state.filtered = action.payload;
      return state;
    },
    setSearchByTitleArticles: (state, action) => {
      state.searchByTitleArticles = action.payload;
      return state;
    },
    setArticle: (state, action) => {
      state.article = action.payload;
      return state;
    },
    addNewQuestion: (state, action) => {
      state.article.questions = [...state.article.questions, action.payload];
      return state;
    },
    setFavorites: (state, action) => {
      state.favorites = action.payload;
      return state;
    },
    addFavorites: (state, action) => {
      state.favorites.push(action.payload);
      return state;
    },
    queueForDownload: (state, action) => {
      state.setForDownload.userId = action.payload.userId;
      if (state.setForDownload.articles.find((id) => id === action.payload.article[0])) {
        state.setForDownload.articles = state.setForDownload.articles.filter((id) => id !== action.payload.article[0]);
      } else {
        state.setForDownload.articles = state.setForDownload.articles.concat(action.payload.article);
      }
      return state;
    },
    resetQueueForDownload: (state) => {
      state.setForDownload.userId = null;
      state.setForDownload.articles = [];
      return state;
    },
    removeFavorites: (state, action) => {
      state.favorites = state.favorites.filter(({id}) => id !== action.payload);
      return state;
    },
    addImage: (state, action) => {
      state.article = {
        ...state.article,
        images: [...state.article.images, action.payload],
      };
    },
    removeImage: (state, action) => {
      state.article = {
        ...state.article,
        images: state.article.images.filter((image) => image.uuid !== action.payload),
      };
      return state;
    },
    resetNewest: (state) => {
      state = {...state, newestArticles: {}};
      return state;
    },
    resetState: (state) => {
      state = {...initialState};
      return state;
    },
    resetArticle: (state) => {
      state = {...state, article: null};
      return state;
    },
  },
});

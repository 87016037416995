import {useContext, useEffect, useRef, useState} from 'react';
import ReactModal from 'react-modal';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {RequestContext} from '../App';
import Collapse from './collapse';

export default function MobileFilters({
  isOpen = false,
  onRequestClose,
  count,
  setSearchState,
  setPage,
  requiredAttributes,
  setCurrentSubSubCategory,
  setcurentSubSubSubCategory,
  handleSearch,
  searchState,
}) {
  const {api: socket} = useContext(RequestContext);
  const dispatch = useDispatch();
  const {categories, subSubCategories} = useSelector((state) => state.categories);
  const {locations} = useSelector((state) => state.locations);
  const {id, subCategoryId} = useParams();
  const [categoryList, setCategoryList] = useState();
  const categoryId = categories.find((category) => category.uuid === id);
  const subCategories = categories.find(({uuid}) => uuid === id)?.subcategories;
  const isNotSearch = window.location.pathname !== '/search';
  const subcategoryId = subCategories?.find((subCategory) => subCategoryId === subCategory?.uuid)?.id;
  const [selectedSubcategory, setSelectedSubCategory] = useState();
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState();
  const [selectedSubSubSubCategory, setSelectedSubSubSubCategory] = useState();
  const [subSubSubCategories, setSubSubSubCategories] = useState([]);
  const [showModel, setShowModel] = useState(null);

  useEffect(() => {
    if (!id && !subCategoryId) {
      setCategoryList(categories);
    } else if (id && !subCategoryId) {
      setCategoryList(subCategories);
    } else if (id && subCategoryId) {
      setCategoryList(subCategories);
    }
  }, [categories?.length]);

  useEffect(() => {
    const fetchMyApi = async () => {
      if (subCategoryId && categories.length) await socket.getSubSubCategories(subcategoryId, dispatch);
    };
    fetchMyApi();
  }, [subCategoryId]);

  useEffect(() => {
    if (isOpen) {
      document.querySelector('.header').style.zIndex = 0;
      document.querySelector('.mobile_tabs').style.zIndex = 0;
      document.querySelector('.category_aside_toggle').style.zIndex = 0;
    } else {
      document.querySelector('.header').style.zIndex = 1001;
      document.querySelector('.category_aside_toggle').style.zIndex = 1000;
    }
  }, [isOpen]);

  const pickSubSubCategory = async (subSubCategory) => {
    setSubSubSubCategories(subSubCategory.subsubsubcategories);
    setSelectedSubSubCategory(subSubCategory.uuid);
    setSelectedSubSubSubCategory(null);
    setCurrentSubSubCategory(subSubCategory.id);
    setcurentSubSubSubCategory(null);
    setSearchState((prev) => ({
      ...prev,
      subsubcategoryId: subSubCategory.id,
      subsubsubcategoryId: undefined,
    }));
    await handleSearch(null, {...searchState, subsubcategoryId: subSubCategory.id, subsubsubcategoryId: undefined});
  };

  const pickSubsubSubCategory = async (subSubSubCategory) => {
    setSelectedSubSubSubCategory(subSubSubCategory.uuid);
    setcurentSubSubSubCategory(subSubSubCategory.id);
    setSearchState((prev) => ({
      ...prev,
      subsubsubcategoryId: subSubSubCategory.id,
    }));
    await handleSearch(null, {...searchState, subsubsubcategoryId: subSubSubCategory.id});
  };

  const min = useRef(null);
  const max = useRef(null);

  useEffect(() => {
    setShowModel(null);
  }, [subCategoryId]);

  const handleFilterLocation = (e) => {
    setSearchState((prev) => ({...prev, cityId: e.target.value}));
    setPage(1);
  };

  const handlePrice = (e) => {
    if (e.target.name === 'price_min') {
      setSearchState((prev) => ({...prev, payload: {count}, price: {...prev.price, min: min.current.value}}));
    } else {
      setSearchState((prev) => ({...prev, payload: {count}, price: {...prev.price, max: max.current.value}}));
    }
    setPage(1);
  };

  const handlePriceOrNegotiable = (e) => {
    if (e.target.value === 'all') {
      setSearchState((prev) => ({...prev, price: {min: 0, max: Number.MAX_VALUE}}));
    } else if (e.target.value === 'price') {
      setSearchState((prev) => ({...prev, price: {min: Number.MIN_VALUE, max: Number.MAX_VALUE}}));
    } else {
      setSearchState((prev) => ({...prev, price: {min: 0, max: Number.MIN_VALUE}}));
      min.current.value = undefined;
      max.current.value = undefined;
    }
    setPage(1);
  };

  const hadleSellingOrBuying = (e) => {
    if (e.target.value === 'all') {
      setSearchState((prev) => ({...prev, articleType: ''}));
    } else if (e.target.value === 'selling') {
      setSearchState((prev) => ({...prev, articleType: 'prodaja'}));
    } else if (e.target.value === 'renting') {
      setSearchState((prev) => ({...prev, articleType: 'iznajmljivanje'}));
    } else {
      setSearchState((prev) => ({...prev, articleType: 'kupovina'}));
    }
    setPage(1);
  };

  const handleTerms = (e) => {
    const attributeId = e.target.name.split('_')[0];
    if (e.target.name.includes('min')) {
      setSearchState((prev) => ({
        ...prev,
        payload: {count},
        terms: {...prev.terms, [attributeId]: {...prev.terms[attributeId], min: e.target.value}},
      }));
    } else {
      setSearchState((prev) => ({
        ...prev,
        payload: {count},
        terms: {...prev.terms, [attributeId]: {...prev.terms[attributeId], max: e.target.value}},
      }));
    }
  };

  const handleTermsSelect = (e) => {
    const attributeId = e.target.name.split('_')[0];
    if (e.target.name.includes('min')) {
      setSearchState((prev) => ({
        ...prev,
        payload: {count},
        terms: {...(prev.terms ?? {}), [attributeId]: {...(prev.terms?.[attributeId] ?? {}), min: e.target.value}},
      }));
      return;
    }
    if (e.target.name.includes('max')) {
      setSearchState((prev) => ({
        ...prev,
        payload: {count},
        terms: {...(prev.terms ?? {}), [attributeId]: {...(prev.terms?.[attributeId] ?? {}), max: e.target.value}},
      }));
      return;
    }
    if (e?.target?.dataset?.name === 'Marka') {
      setShowModel(requiredAttributes.find((attr) => attr.name === 'Marka').values.find((attr) => attr.name === e.target.value));
    }
    setSearchState((prev) => ({...prev, payload: {count}, terms: {...prev.terms, [e.target.name]: {value: e.target.value}}}));
  };

  const handleConditions = (e) => {
    setSearchState((prev) => ({...prev, conditions: e.target.value}));
  };

  const showModelM = () => {
    const a = {...showModel, values: showModel.modelAttributeValues, name: 'Model', type: 'select'};

    return a;
  };
  const renderAttributes = () => {
    if (showModel) {
      const findMake = requiredAttributes.findIndex((make) => make.name === 'Marka');
      showModelM();
      return [requiredAttributes[findMake], showModelM(), ...requiredAttributes.slice(findMake + 1)].map((attr) => {
        return renderByType(attr, handleTerms, handleTermsSelect, handleSearch);
      });
    }
    return requiredAttributes?.map((attr) => {
      return renderByType(attr, handleTerms, handleTermsSelect, handleSearch);
    });
  };

  return (
    <>
      {!!isOpen && (
        <ReactModal
          style={{
            content: {position: 'unset', inset: 'unset', padding: '0px', width: '80%', margin: '0px', height: '100vh', alignItems: 'start'},
          }}
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          preventScroll={true}
          ariaHideApp={false}
        >
          <div className="w-100">
            {isNotSearch && !id && !subCategoryId && (
              <Collapse key="collapse-1" title="Kategorije" elementsCount={categories?.length || 0}>
                <ul className="category_aside_list">
                  {categoryList?.map(({name, uuid}) => {
                    return (
                      <li className="d-flex justify-content-between align-items-center" key={uuid}>
                        <Link to={`/kategorija/${uuid}`}>{name}</Link>
                      </li>
                    );
                  })}
                </ul>
              </Collapse>
            )}

            {isNotSearch && id && !subCategoryId && (
              <Collapse key="collapse-1" title="Kategorije" elementsCount={categories?.length} open={false}>
                <ul className="category_aside_list">
                  {categories?.map?.(({name, uuid}) => {
                    return (
                      <li className={`d-flex justify-content-between align-items-center ${uuid === id ? 'has-children' : ''}`} key={uuid}>
                        {categoryId.uuid === uuid && <Link to={`/kategorija/${uuid}`}>{name}</Link>}
                        {uuid === id && (
                          <ul className="category_aside_list">
                            {categoryList?.map?.(({name, uuid}) => {
                              return (
                                <li className="d-flex justify-content-between align-items-center" key={uuid}>
                                  -<Link to={`/kategorija/${id}/potkategorija/${uuid}`}>{name}</Link>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </Collapse>
            )}

            {isNotSearch && id && subCategoryId && (
              <Collapse key="collapse-1" title="Kategorije" elementsCount={categories?.length + (categoryId?.subcategories?.length || 0)}>
                <ul className="category_aside_list">
                  {categories?.map?.(({name, uuid}) => {
                    return (
                      <li className={`d-flex justify-content-between align-items-center ${uuid === id ? 'has-children' : ''}`} key={uuid}>
                        {uuid === id && <Link to={`/kategorija/${uuid}`}>{name}</Link>}
                        {uuid === id && (
                          <ul className="category_aside_list">
                            {subCategories?.map?.(({name, uuid}) => {
                              return (
                                <li
                                  className={`d-flex justify-content-between align-items-center ${
                                    uuid === subCategoryId ? 'has-children' : ''
                                  }`}
                                  key={uuid}
                                >
                                  <Link
                                    to={`/kategorija/${id}/potkategorija/${uuid}`}
                                    onClick={() => {
                                      setSearchState({});
                                      setSelectedSubSubCategory(null);
                                      setSelectedSubSubSubCategory(null);
                                      setSubSubSubCategories([]);
                                      setSelectedSubCategory(uuid);
                                      setCurrentSubSubCategory(null);
                                      setcurentSubSubSubCategory(null);
                                      min.current.value = undefined;
                                      max.current.value = undefined;
                                    }}
                                  >
                                    {name}
                                  </Link>

                                  {uuid === selectedSubcategory && (
                                    <ul>
                                      {subSubCategories.map((ssc) => (
                                        <li className={selectedSubSubCategory === ssc.uuid ? 'has-children' : ''}>
                                          <Link onClick={() => pickSubSubCategory(ssc)}>{ssc.name}</Link>
                                          <ul>
                                            {!!subSubSubCategories.length &&
                                              ssc.uuid === selectedSubSubCategory &&
                                              subSubSubCategories.map((sssc) => (
                                                <li className={selectedSubSubSubCategory === sssc.uuid ? 'has-children' : ''}>
                                                  <Link onClick={() => pickSubsubSubCategory(sssc)}>{sssc.name}</Link>
                                                </li>
                                              ))}
                                          </ul>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </Collapse>
            )}

            <Collapse key="collapse-2" title="Lokacija/Grad">
              <div className="collapse_default_wrap">
                <label className="form_default_select mb-0 w-100">
                  <select onChange={handleFilterLocation}>
                    <option value="">Sve</option>
                    {locations.length &&
                      locations.map(({name, id, uuid}) => (
                        <option key={uuid} value={id}>
                          {name}
                        </option>
                      ))}
                  </select>
                </label>
                <button className="button w-100 btn-search" onClick={handleSearch}>
                  Pretraži
                </button>
              </div>
            </Collapse>
            <Collapse key="collapse-11" title="Stanje">
              <div className="collapse_default_wrap">
                <label className="form_default_select mb-0">
                  <select onChange={handleConditions}>
                    <option value="">Sve</option>
                    <option key={'novo-uuid'} value={'novo'}>
                      Novo
                    </option>
                    <option key={'polovno-uuid'} value={'polovno'}>
                      Polovno
                    </option>
                  </select>
                </label>
                <button className="button w-100 btn-search" onClick={handleSearch}>
                  Pretraži
                </button>
              </div>
            </Collapse>
            <Collapse key="collapse-3" title="Cijena">
              <div className="category_aside_range">
                <div className="row row_10">
                  <div className="col-6">
                    <label>Od (KM)</label>
                    <input type="number" name="price_min" ref={min} onBlur={handlePrice} />
                  </div>
                  <div className="col-6">
                    <label>Do (KM)</label>
                    <input type="number" name="price_max" ref={max} onBlur={handlePrice} />
                  </div>
                </div>
                <button className="button w-100 btn-search" onClick={handleSearch}>
                  Pretraži
                </button>
              </div>
            </Collapse>

            <Collapse key="collapse-4" title="Rata">
              <div className="category_aside_range">
                <div className="row row_10">
                  <div className="col-6">
                    <label>Od (KM)</label>
                    <input type="number" name="loan_min" />
                  </div>
                  <div className="col-6">
                    <label>Do (KM)</label>
                    <input type="number" name="loan_max" />
                  </div>
                </div>
                <button className="button w-100 btn-search" onClick={handleSearch}>
                  Pretraži
                </button>
              </div>
            </Collapse>

            <Collapse key="collapse-5" title="Ostali filteri" elementsCount={5}>
              <div className="collapse_default_wrap" onChange={handlePriceOrNegotiable}>
                <label className="form_default_check radio">
                  <input type="radio" value="all" name="type-1" defaultChecked />
                  <span></span> Sve
                </label>
                <label className="form_default_check radio d-block">
                  <input type="radio" value="price" name="type-1" />
                  <span></span> Sa unesenom cijenom
                </label>
                <label className="form_default_check radio d-block">
                  <input type="radio" value="negotiable" name="type-1" />
                  <span></span> Cijena po dogovoru
                </label>
                <button className="button w-100 btn-search" onClick={handleSearch}>
                  Pretraži
                </button>
              </div>
            </Collapse>

            <Collapse key="collapse-6" title="Vrsta artikla" elementsCount={5}>
              <div className="collapse_default_wrap" onChange={hadleSellingOrBuying}>
                <label className="form_default_check radio">
                  <input type="radio" value="all" name="type" defaultChecked />
                  <span></span> Sve
                </label>
                <label className="form_default_check radio d-block">
                  <input type="radio" value="selling" name="type" />
                  <span></span> Samo prodaja
                </label>
                <label className="form_default_check radio d-block">
                  <input type="radio" name="type" value="renting" />
                  <span></span> Samo iznajmljivanje
                </label>
                <label className="form_default_check radio d-block">
                  <input type="radio" value="buying" name="type" />
                  <span></span> Samo potražnja
                </label>
                <button className="button w-100 btn-search" onClick={handleSearch}>
                  Pretraži
                </button>
              </div>
            </Collapse>
            {isNotSearch && id && subCategoryId && renderAttributes()}
          </div>

          <button onClick={onRequestClose} className="btn-close-modal"></button>
        </ReactModal>
      )}
    </>
  );
}

const renderByType = (attribute, handleTerms, handleTermsSelect, handleSearch) => {
  if (attribute.type === 'select') {
    return CollapseOptions(attribute, handleTermsSelect, handleSearch);
  }
  if (attribute.type === 'number') {
    return CollapseRange(attribute, handleTerms, handleSearch);
  }
};

const CollapseOptions = (attribute, handleTermsSelect, handleSearch) => (
  <Collapse key={attribute.id} title={attribute.name} open={false}>
    <div className="collapse_default_wrap">
      <label className="form_default_select mb-0">
        <select data-name={attribute.name} name={attribute.id} defaultValue onChange={handleTermsSelect}>
          <option value="">Sve</option>
          {attribute.values.map(({name, id, uuid}) => (
            <option key={uuid} value={name}>
              {name}
            </option>
          ))}
        </select>
      </label>
      <button className="button w-100 btn-search" onClick={handleSearch}>
        Pretraži
      </button>
    </div>
  </Collapse>
);

const CollapseRange = (attribute, handleTerms, handleSearch) => (
  <Collapse key={attribute.id} title={attribute.name} open={false}>
    <div className="category_aside_range">
      <div className="row row_10">
        <div className="col-6">
          <label>Od ({attribute.name})</label>
          <input type="number" name={`${attribute.id}_min`} onBlur={handleTerms} />
        </div>
        <div className="col-6">
          <label>Do ({attribute.name})</label>
          <input type="number" name={`${attribute.id}_max`} onBlur={handleTerms} />
        </div>
      </div>
      <button className="button w-100 btn-search" onClick={handleSearch}>
        Pretraži
      </button>
    </div>
  </Collapse>
);

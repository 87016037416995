import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import '../styles/components/mobileTabs.scss';
import {Messages} from './header';
import {SvgHome, SvgMessage, SvgSearch, SvgUserDark} from './svg';

function MobileTabs() {
  const {user} = useSelector((state) => state.auth);
  const {chats} = useSelector((state) => state.chats);

  const unreadMessages = useMemo(() => {
    return chats?.filter((chat) => chat?.messages[0]?.seen === false && chat?.messages[0]?.senderId !== user?.id);
  }, [JSON.stringify(chats)]);

  return (
    <div className="mobile_tabs">
      <div className="d-flex justify-content-between align-items-center">
        <NavLink to="/" className="col mobile_tabs_home" activeClassName="active" exact>
          <SvgHome />
          <span>Početna</span>
        </NavLink>
        <NavLink to="/kategorija" className="col mobile_tabs_search" activeClassName="active" exact>
          <SvgSearch />
          <span>Pretraga</span>
        </NavLink>
        <div className="col mobile_tabs_add">
          <NavLink to="/objava-1" activeClassName="active" exact>
            <img src="/images/plus.svg" alt="plus" />
          </NavLink>
        </div>
        <NavLink to="/moj-profil" className="col mobile_tabs_profile" activeClassName="active" exact>
          <SvgUserDark />
          <span>Profil</span>
        </NavLink>
        <Messages userId={user.uuid} messages={unreadMessages} icon={SvgMessage} classNameProp="col mobile_tabs_profile" text="Poruke" />
      </div>
    </div>
  );
}

export default MobileTabs;

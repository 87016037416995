import {useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {RequestContext} from '../App';

export default function Attribute({isSubSub, isSubSubSub}) {
  const {api: socket} = useContext(RequestContext);
  const dispatch = useDispatch();

  const [, setCatIdForUpdatesub] = useState();
  const [, setSubCatForUpdate] = useState();
  const [, setSubCatForDelete] = useState();
  const [, setCatIdForDeleteSub] = useState();
  //ATTRIBUTES STATES
  const [catIdForNewAttribute, setCatIdForNewAttribute] = useState();
  const [subCatIdForNewAttribute, setSubCatIdForNewAttribute] = useState();
  const [attributeName, setAttributeName] = useState();
  const [isAttributeRequired, setIsAttributeRequired] = useState(false);
  const [isAttributeForUpdateRequired, setIsAttributeForUpdateRequired] = useState(false);
  const [typeOfNewAttribute, setTypeOfNewAttribute] = useState();
  const [catIdUpdateAttribute, setCatIdUpdateAttribute] = useState();
  const [subCatIdUpdateAttribute, setSubCatIdUpdateAttribute] = useState();
  const [attrUuIdForUpdate, setAttrUuIdForUpdate] = useState();
  const [newAttrName, setNewAttrName] = useState();
  const [catIdDeleteAttribute, setCatIdDeleteAttribute] = useState();
  const [subCatIdDeleteAttribute, setSubCatIdDeleteAttribute] = useState();
  const [attrUuidForDelete, setAttrUuidForDelete] = useState();

  const [attributeValues, setAttributeValues] = useState([]);
  const [tempAttributeValues, setTempAttributeValues] = useState([]);
  const [currentValue, setCurrentValue] = useState();

  const [modelName, setModelName] = useState();
  const [models, setModels] = useState();
  const [showModelList, setShowModelList] = useState(false);
  const [attrModelsForShow, setAttrModelsFowShow] = useState();

  const [manipulateWithModels, setManipulateWithModels] = useState(false);

  const categories = useSelector((state) => state.categories?.categories);
  const {subCategories: localSubcategories, subSubCategories: localSubSub} = useSelector((state) => state.categories);
  const [subCategories, setSubCategories] = useState([]);
  const attributes = useSelector((state) => state.attributes?.attributes);
  const [subsubCategories, setsubsubCategories] = useState([]);
  const [subsubId, setSubsubid] = useState(null);
  const [subsubsubId, setSubsubsubId] = useState(null);
  const [stateSubSubSubCategories, setStateSubSubSubCategories] = useState([]);
  const handleChangeSubSubSubCategory = async (e) => {
    setSubsubsubId(+e.target.value);
    await socket.getAttributes(
      {
        parentId: null,
        subSubCategoryId: null,
        subsubSubCategoryId: +e.target.value,
      },
      dispatch
    );
  };
  const handleChangeCatForNewAttribute = async (e) => {
    setCatIdForNewAttribute(e.target.value);
    setCatIdForUpdatesub('');
    setSubCatForUpdate('');
    setCatIdForDeleteSub('');
    setSubCatForDelete('');
    setTypeOfNewAttribute('');
    setCatIdUpdateAttribute('');
    setSubCatIdUpdateAttribute('');
    setAttrUuIdForUpdate('');
    setCatIdDeleteAttribute('');
    setSubCatIdDeleteAttribute('');
    setAttrUuidForDelete('');

    setTempAttributeValues([]);
    setSubCatIdForNewAttribute('');
    setSubCategories(categories.find((category) => category.id === +e.target.value)?.subcategories ?? []);
    if (isSubSub || isSubSubSub) {
      await socket.getSubCategories(e.target.value, dispatch);
    }
  };

  const addNewAtribute = async (e) => {
    let orderNumber = 0;
    if (typeOfNewAttribute === 'select') orderNumber = 1;
    else if (typeOfNewAttribute === 'checkbox') orderNumber = 3;
    else if (typeOfNewAttribute === 'Number') orderNumber = 2;
    await socket.addNewAtribute(
      {
        name: attributeName,
        parentId: isSubSub || isSubSubSub ? null : Number(subCatIdForNewAttribute),
        type: typeOfNewAttribute,
        required: isAttributeRequired,
        subSubCategoryId: isSubSubSub ? null : subsubId,
        subsubSubCategoryId: subsubsubId,
        orderNumber,
      },
      dispatch,
      attributeValues
    );
    setAttributeName('');
    setAttributeValues([]);
    setCurrentValue('');
    setSubsubid('');
    setSubsubsubId('');
  };

  const handleChangeCategoryForUpdateAttribute = async (e) => {
    setCatIdUpdateAttribute(e.target.value);
    setCatIdForUpdatesub('');
    setSubCatForUpdate('');
    setCatIdForDeleteSub('');
    setSubCatForDelete('');
    setCatIdForNewAttribute('');
    setSubCatIdForNewAttribute('');
    setTypeOfNewAttribute('');
    setSubCatIdUpdateAttribute('');
    setAttrUuIdForUpdate('');
    setCatIdDeleteAttribute('');
    setSubCatIdDeleteAttribute('');
    setAttrUuidForDelete('');

    setTempAttributeValues([]);
    setSubCategories(categories.find((category) => category.id === +e.target.value)?.subcategories ?? []);
    if (isSubSub || isSubSubSub) {
      await socket.getSubCategories(e.target.value, dispatch);
    }
  };

  const handleChangeSubCatForUpdateAttribute = async (e, parentId) => {
    setSubCatIdUpdateAttribute(e.target.value);
    setAttrUuIdForUpdate('');
    setsubsubCategories(localSubcategories.find((sub) => sub.id === +e.target.value)?.subsubcategories);
    if (isSubSub || isSubSubSub) {
      await socket.getSubCategories(parentId, dispatch);
    } else {
      await socket.getAttributes({parentId: e.target.value}, dispatch);
    }
  };

  const hadleChnageSubSubCatForUpdateAttribute = async (e) => {
    await socket.getAttributes({parentId: null, subSubCategoryId: e.target.value}, dispatch);
  };

  const updateAttribute = async (e) => {
    await socket.updateExistAttribute(
      attrUuIdForUpdate,
      {
        name: newAttrName,
        parentId: subCatIdUpdateAttribute,
        subSubCategoryId: subsubId,
        subsubSubCategoryId: subsubsubId,
        required: isAttributeForUpdateRequired,
      },
      dispatch
    );
  };

  const handleChangeAttrValue = (e) => {
    setCurrentValue(e.target.value);
  };

  const handleChnageAttributeForUpdate = (e) => {
    setAttrUuIdForUpdate(e.target.value);
    setTempAttributeValues([]);
    let localAttribute;
    let parentId;
    if (e.target.value) {
      localAttribute = attributes?.filter((attr) => attr.uuid === e.target.value);
      parentId = localAttribute[0].id;
    }

    if (localAttribute) {
      if (localAttribute[0]?.name === 'Marka*') setManipulateWithModels(true);
      else setManipulateWithModels(false);
      setIsAttributeForUpdateRequired(localAttribute[0].required);
      setNewAttrName(localAttribute[0].name);

      localAttribute[0].values?.forEach((value) => {
        setTempAttributeValues((prev) => [...prev, {name: value.name, uuid: value.uuid, parentId}]);
      });
    }
  };

  const addNewAttrValue = (e) => {
    setAttributeValues([...attributeValues, currentValue]);
    setCurrentValue('');
  };

  const addNewAttrValueFromUpdate = async (e, id) => {
    setTempAttributeValues((prev) => [...prev, {name: currentValue, parentId: id}]);
    await socket.addNewAttributeValue({name: currentValue, attributeId: id}, dispatch, subCatIdUpdateAttribute);
    setCurrentValue('');
  };

  const dropAttrValue = (e, index) => {
    setAttributeValues(attributeValues.filter((attr, id) => id !== index));
  };

  const deleteAttributeValue = async (e, index, uuid) => {
    await socket.deleteAttributeValue(uuid, dispatch);
    setTempAttributeValues(tempAttributeValues.filter((attr, id) => id !== index));
  };

  const handleChangeCatIdDeleteAttr = async (e) => {
    setCatIdDeleteAttribute(e.target.value);
    setCatIdForUpdatesub('');
    setSubCatForUpdate('');
    setCatIdForDeleteSub('');
    setSubCatForDelete('');
    setCatIdForNewAttribute('');
    setSubCatIdForNewAttribute('');
    setTypeOfNewAttribute('');
    setSubCatIdDeleteAttribute('');
    setCatIdUpdateAttribute('');
    setSubCatIdUpdateAttribute('');
    setAttrUuIdForUpdate('');
    setAttrUuidForDelete('');

    setTempAttributeValues([]);
    setSubCategories(categories.find((category) => category.id === +e.target.value)?.subcategories ?? []);
    if (isSubSub || isSubSubSub) {
      await socket.getSubCategories(e.target.value, dispatch);
    }
  };

  const handleChangeSubCatDeleteAttr = async (e, parentId) => {
    setSubCatIdDeleteAttribute(e.target.value);
    setAttrUuidForDelete('');
    if (isSubSub || isSubSubSub) {
      await socket.getSubCategories(parentId, dispatch);
      await socket.getSubSubCategories(Number(e.target.value), dispatch);

      setsubsubCategories(localSubcategories.find((sub) => sub.id === +e.target.value)?.subsubcategories);
    } else {
      await socket.getAttributes({parentId: e.target.value}, dispatch);
    }
  };

  const deleteAttribute = async (e) => {
    await socket.deleteAttribute(attrUuidForDelete, dispatch, subCatIdDeleteAttribute);
    setAttrUuidForDelete('');
  };

  const showModels = (uuid) => {
    const localAttribute = attributes?.filter((attr) => attr.uuid === attrUuIdForUpdate);
    const localMarks = localAttribute[0].values?.filter((mark) => mark.uuid === uuid);
    setModels(localMarks[0]?.modelAttributeValues);
    setAttrModelsFowShow(uuid);
    setShowModelList(true);
  };

  const addNewModel = async (e, parentId) => {
    await socket.addNewModel(modelName, parentId, dispatch, subCatIdUpdateAttribute);
    setModelName('');
  };

  const deleteModel = async (uuid) => {
    await socket.deleteModel(uuid, dispatch, subCatIdUpdateAttribute);
  };

  const handleSubSub = async (e) => {
    console.log(localSubcategories);
    setsubsubCategories(localSubcategories?.find((sub) => sub.id === +e.target.value)?.subsubcategories);
    setSubCatIdForNewAttribute(e.target.value);
    setSubsubid(null);
  };

  console.log(isSubSub, isSubSubSub);

  return (
    <div className="row">
      <div className="col-md-6">
        <h2>ATRIBUTI</h2>
        <br />
        <h3>Dodaj novi atribut</h3>
        <div className="row">
          <div className="col-md-4">
            <label className="form_default_select">
              <select value={catIdForNewAttribute} onChange={handleChangeCatForNewAttribute} className="attribute">
                <option value="">Izaberite kategoriju</option>
                {categories.map((category) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="col-md-4">
            <label className="form_default_select">
              <select disabled={!catIdForNewAttribute} value={subCatIdForNewAttribute} className="attribute" onChange={handleSubSub}>
                <option value="">Izaberite podkategoriju</option>
                {subCategories.map((subCategory) => {
                  return (
                    <option key={subCategory.id} value={subCategory.id}>
                      {subCategory.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          {(isSubSub || isSubSubSub) && !!subsubCategories?.length && (
            <div className="col-md-4">
              <label className="form_default_select">
                <select
                  disabled={!catIdForNewAttribute}
                  value={subsubId}
                  className="attribute"
                  onChange={async (e) => {
                    setSubsubid(+e.target.value);
                    !(isSubSub || isSubSubSub) && setSubCatIdForNewAttribute(e.target.value);
                    isSubSubSub &&
                      setStateSubSubSubCategories(localSubSub.find((a) => a.id === +e.target.value)?.subsubsubcategories ?? []);
                  }}
                >
                  <option value="">Izaberite podpodkategoriju</option>
                  {subsubCategories.map((subCategory) => {
                    return (
                      <option key={subCategory.id} value={subCategory.id}>
                        {subCategory.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          )}
          {isSubSubSub && !!stateSubSubSubCategories.length && (
            <div className="col-3">
              <label className="form_default_select">
                <select onChange={handleChangeSubSubSubCategory}>
                  <option value="">Izaberite potpotpotkategoriju</option>
                  {stateSubSubSubCategories.map((sssc) => (
                    <option key={sssc.uuid} value={sssc.id}>
                      {sssc.name}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          )}
          <div className="col-md-4">
            <label className="form_default_select">
              <select
                disabled={!subCatIdForNewAttribute}
                value={typeOfNewAttribute}
                onChange={(e) => {
                  setTypeOfNewAttribute(e.target.value);
                }}
              >
                <option value="">Tip atributa</option>
                <option value="select">Select</option>
                <option value="checkbox">Checkbox</option>
                <option value="Number">Number</option>
              </select>
            </label>
          </div>
        </div>
        {(typeOfNewAttribute === 'select' || typeOfNewAttribute === 'radio') && (
          <>
            <h5>Dodajte vrijednosti za novi atribut</h5>
            <div className="row">
              <div className="col-md-6">
                <label className="form_default_input">
                  <input
                    value={currentValue}
                    type="text"
                    maxLength={50}
                    placeholder="Dodajte vrijednost za Vaš atribut"
                    onChange={handleChangeAttrValue}
                  />
                </label>
              </div>
              <div className="col-md-6">
                <button onClick={addNewAttrValue} disabled={!currentValue} className="button_select button_select_blue">
                  <span>
                    <img src="/images/plus_2.svg" alt="plus" />
                  </span>
                </button>
              </div>
            </div>
            {attributeValues.map((attr, index) => (
              <div key={index} className="row">
                <div className="col-md-6 align-self-center attribute-new">
                  <span>{attr}</span>
                </div>
                <div className="col-md-6">
                  <button onClick={(e) => dropAttrValue(e, index)} className="button_select button_select_red">
                    <span>
                      <img src="/images/minus.svg" alt="minus" />
                    </span>
                  </button>
                </div>
              </div>
            ))}
          </>
        )}

        <br />
        <div className="row">
          <div className="col-md-6">
            <label className="form_default_option w-100">
              <input
                type="checkbox"
                name="requiredAttribute"
                checked={isAttributeRequired}
                onChange={() => setIsAttributeRequired(!isAttributeRequired)}
              />
              <span>Obavezno polje? </span>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <label className="form_default_input">
              <input
                type="text"
                value={attributeName}
                onChange={(e) => {
                  setAttributeName(e.target.value);
                }}
                maxLength={50}
                placeholder="Unesite naziv atributa"
              />
            </label>
          </div>
          <div className="col-md-4">
            <button
              className="button"
              onClick={addNewAtribute}
              disabled={
                !attributeName ||
                !subCatIdForNewAttribute ||
                !catIdForNewAttribute ||
                !typeOfNewAttribute ||
                (typeOfNewAttribute === 'select' && attributeValues?.length === 0)
              }
            >
              Dodaj
            </button>
          </div>
        </div>
        <br />
      </div>
      <div className="col-md-6">
        <br />
        <br />
        <h3>Promijeni atribut</h3>
        <div className="row">
          <div className="col-md-4">
            <label className="form_default_select">
              <select value={catIdUpdateAttribute} className="attribute" onChange={handleChangeCategoryForUpdateAttribute}>
                <option value={''}>Izaberite kategoriju</option>
                {categories.map((category) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="col-md-4">
            <label className="form_default_select">
              <select
                disabled={!catIdUpdateAttribute}
                value={subCatIdUpdateAttribute}
                className="attribute"
                onChange={async (e) => {
                  await handleChangeSubCatForUpdateAttribute(e, subCategories[0].categoryId);
                  await socket.getSubSubCategories(Number(e.target.value), dispatch);
                }}
              >
                <option value={''}>Izaberi podkategoriju</option>
                {subCategories.map((subCategory) => {
                  return (
                    <option key={subCategory.id} value={subCategory.id}>
                      {subCategory.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>

          {(isSubSub || isSubSubSub) && !!subsubCategories?.length && (
            <div className="col-md-4">
              <label className="form_default_select">
                <select
                  disabled={!subCatIdUpdateAttribute}
                  value={subCatIdForNewAttribute}
                  className="attribute"
                  onChange={(e) => {
                    setSubsubid(+e.target.value);
                    setSubCatIdForNewAttribute(e.target.value);
                    setStateSubSubSubCategories(localSubSub.find((a) => a.id === +e.target.value)?.subsubsubcategories ?? []);
                    hadleChnageSubSubCatForUpdateAttribute(e);
                  }}
                >
                  <option value="">Izaberite podpodkategoriju</option>
                  {subsubCategories.map((subCategory) => {
                    return (
                      <option key={subCategory.id} value={subCategory.id}>
                        {subCategory.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          )}
          {isSubSubSub && !!stateSubSubSubCategories.length && (
            <div className="col-3">
              <label className="form_default_select">
                <select onChange={handleChangeSubSubSubCategory}>
                  <option value="">Izaberite potpotpotkategoriju</option>
                  {stateSubSubSubCategories.map((sssc) => (
                    <option key={sssc.uuid} value={sssc.id}>
                      {sssc.name}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          )}
          <div className="col-md-4">
            <label className="form_default_select">
              <select
                value={attrUuIdForUpdate}
                disabled={!subCatIdUpdateAttribute}
                onChange={(e) => {
                  handleChnageAttributeForUpdate(e);
                }}
                className="attribute"
              >
                <option value="">Izaberite atribut</option>
                {attributes?.map((attribute) => {
                  return (
                    <option key={attribute.id} value={attribute.uuid}>
                      {attribute.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        </div>
        {tempAttributeValues.length ? (
          <div>
            <div className="row">
              <div className="col-md-6">
                <label className="form_default_input">
                  <input type="text" maxLength={50} placeholder="Vrijednost atributa?" onChange={(e) => setCurrentValue(e.target.value)} />
                </label>
              </div>
              <div className="col-md-6">
                <button
                  onClick={(e) => addNewAttrValueFromUpdate(e, tempAttributeValues[0].parentId)}
                  className="button_select button_select_blue"
                >
                  <span>
                    <img src="/images/plus_2.svg" alt="plus" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {tempAttributeValues?.length ? <h4>Lista vrijednosti za izabrani atribut:</h4> : ''}
        {tempAttributeValues?.map((attrValue, index) => {
          return (
            <div key={attrValue.uuid}>
              <div className="row">
                <div className="col-md-6 align-self-center">
                  <span>{attrValue.name}</span>
                </div>
                <div className="col-md-6">
                  <button className="button_select button_select_red" onClick={(e) => deleteAttributeValue(e, index, attrValue.uuid)}>
                    <span>
                      <img src="/images/minus.svg" alt="minus" />
                    </span>
                  </button>
                </div>
              </div>
              {manipulateWithModels && attrValue.uuid && (
                <>
                  <div className="row">
                    <div className="col-md-4 offset-md-2">
                      <label className="form_default_input">
                        <input
                          type="text"
                          maxLength={50}
                          placeholder="Dodajte novi model"
                          onChange={(e) => {
                            setModelName(e.target.value);
                          }}
                        />
                      </label>
                    </div>
                    <div className="col-md-6">
                      <button
                        disabled={!modelName}
                        className="button_select button_select_blue button_model"
                        onClick={(e) => addNewModel(e, attrValue.uuid)}
                      >
                        <span>
                          <img src="/images/plus_2.svg" alt="plus" />
                        </span>
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="offset-md-3">
                      <button className="button_show_models mb-2 p-2" onClick={(e) => showModels(attrValue.uuid)}>
                        Prikaži modele
                      </button>
                    </div>
                  </div>
                  {showModelList && attrValue.uuid === attrModelsForShow ? (
                    <div className="row mb-4">
                      {models?.map((model) => {
                        return (
                          <div className="col-md-3" key={model.uuid}>
                            {model.name}
                            <button className="button_delete_model" onClick={(e) => deleteModel(model.uuid)}>
                              -
                            </button>
                          </div>
                        );
                      })}
                      {!models?.length && <span>Nije dodat nijedan model za ovu marku!</span>}
                    </div>
                  ) : (
                    ''
                  )}
                </>
              )}
            </div>
          );
        })}
        {attrUuIdForUpdate && (
          <div className="row">
            <div className="col-md-6">
              <label className="form_default_option w-100">
                <input
                  type="checkbox"
                  name="requiredAttribute"
                  checked={isAttributeForUpdateRequired}
                  onChange={() => setIsAttributeForUpdateRequired(!isAttributeForUpdateRequired)}
                />
                <span>Obavezno polje? </span>
              </label>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-8">
            <label className="form_default_input">
              <input
                type="text"
                maxLength={50}
                value={newAttrName}
                onChange={(e) => {
                  setNewAttrName(e.target.value);
                }}
                placeholder="Unesite novi naziv atributa"
              />
            </label>
          </div>
          <div className="col-md-4">
            <button className="button button_orange" onClick={updateAttribute} disabled={!newAttrName || !attrUuIdForUpdate}>
              Promijeni
            </button>
          </div>
        </div>
        <h3>Obriši atribut</h3>
        <div className="row">
          <div className="col-md-4">
            <label className="form_default_select">
              <select value={catIdDeleteAttribute} onChange={handleChangeCatIdDeleteAttr} className="attribute">
                <option value="">Izaberi kategoriju</option>
                {categories.map((category) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="col-md-4">
            <label className="form_default_select">
              <select
                value={subCatIdDeleteAttribute}
                onChange={(e) => handleChangeSubCatDeleteAttr(e, subCategories[0].categoryId)}
                disabled={!catIdDeleteAttribute}
                className="attribute"
              >
                <option value="">Izaberite podkategoriju</option>
                {subCategories.map((subCategory) => {
                  return (
                    <option key={subCategory.id} value={subCategory.id}>
                      {subCategory.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          {(isSubSub || isSubSubSub) && !!subsubCategories?.length && (
            <div className="col-md-4">
              <label className="form_default_select">
                <select
                  value={subCatIdForNewAttribute}
                  className="attribute"
                  onChange={(e) => {
                    setSubsubid(+e.target.value);
                    setSubCatIdForNewAttribute(e.target.value);
                    hadleChnageSubSubCatForUpdateAttribute(e);
                    isSubSubSub &&
                      setStateSubSubSubCategories(localSubSub.find((a) => a.id === +e.target.value)?.subsubsubcategories ?? []);
                  }}
                >
                  <option value="">Izaberite podpodkategoriju</option>
                  {subsubCategories.map((subCategory) => {
                    return (
                      <option key={subCategory.id} value={subCategory.id}>
                        {subCategory.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          )}
          {isSubSubSub && !!stateSubSubSubCategories.length && (
            <div className="col-3">
              <label className="form_default_select">
                <select onChange={handleChangeSubSubSubCategory}>
                  <option value="">Izaberite potpotpotkategoriju</option>
                  {stateSubSubSubCategories.map((sssc) => (
                    <option key={sssc.uuid} value={sssc.id}>
                      {sssc.name}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          )}
          <div className="col-md-4">
            <label className="form_default_select">
              <select
                value={attrUuidForDelete}
                onChange={(e) => {
                  setAttrUuidForDelete(e.target.value);
                }}
                disabled={!subCatIdDeleteAttribute}
                className="attribute"
              >
                <option value={''}>Izaberite atribut</option>
                {attributes?.map((attribute) => {
                  return (
                    <option key={attribute.uuid} value={attribute.uuid}>
                      {attribute.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 offset-md-8">
            <button disabled={!attrUuidForDelete} onClick={deleteAttribute} className="button button_red">
              Obriši
            </button>
          </div>
        </div>
      </div>

      <div className="col-12">
        <Link to="/admin/subsub" style={{fontWeight: 'bold'}}>
          Admin subsubcategories
        </Link>
      </div>
      <div className="col-12">
        <Link to="/admin/subsubsub" style={{fontWeight: 'bold'}}>
          Admin subsubsubcategories
        </Link>
      </div>

      <div className="col-12">
        <Link to="/admin/korisnici" style={{fontWeight: 'bold'}}>
          Admin korisnici
        </Link>
      </div>
    </div>
  );
}

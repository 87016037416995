import {FRONTEND_URL} from '../api/apiBase';
import {isMobile} from 'react-device-detect';
import {commonSlice} from '../store/reducers/commonReducer';
import credits from '../helpers/credits';

export const isDisabled = (currentState, requiredValues) => {
  let disabled = false;
  for (let req of requiredValues) {
    if (req === 'password' && currentState[req].length < 6) disabled = true;
    if (!currentState[req]?.length) {
      disabled = true;
    }
  }
  return disabled;
};

export const dateFormat = (date, format = 'sr-Latn-BA', extras = {}) => {
  const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', ...extras};

  return date ? new Date(date).toLocaleDateString(format, options) : null;
};

export const extractTime = (date) => {
  const localDate = new Date(date).toLocaleTimeString('sr-Sr');
  return localDate;
};

export const removeEmpty = (data) => {
  const output = {};
  for (const d in data) {
    if (data[d]) output[d] = data[d];
  }
  return output;
};

export const userLogout = (dispatch = null, action = null) => {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  localStorage.removeItem('uuid');
  localStorage.removeItem('confirmed');

  if (dispatch) dispatch(action());
};

export const setLocalStorage = (data) => {
  localStorage.setItem('token', data.token);
  localStorage.setItem('uuid', data.uuid);
  localStorage.setItem('confirmed', data.confirmed);
};

export const useShareClickHandler = (event, articleId, articleTitle, dispatch) => {
  event.preventDefault();
  let options = {
    url: `${FRONTEND_URL}/detail/${articleId}`,
    [isMobile ? 'text' : 'message']: articleTitle,
  };
  if (navigator.canShare) {
    navigator
      .share(options)
      .then(() => console.log('Share was successful.'))
      .catch((error) => console.log('Sharing failed', error));
  } else {
    // console.log(fallback, message);
    dispatch(commonSlice.actions.setNotification({type: 'warning', message: "Your system doesn't support sharing files."}));
    console.log(`Your system doesn't support sharing files.`);
  }
};

export const calculator = (price, articleType, year = null) => {
  const type = getType(articleType, year);

  if (UPPER_LIMIT[type] <= price) return [];

  const findCredits = credits.filter((credit) => {
    return credit['Min. iznos'] <= price && price <= credit['Max. iznos'] && credit['Vrsta kredita'] === type;
  });

  const rs = findCredits.filter((credit) => credit['Entitet'] === 'RS').slice(-1)[0];
  const fbih = findCredits.filter((credit) => credit['Entitet'] === 'FBIH').slice(-1)[0];

  if (rs && fbih) return [rs, fbih].map((credit) => generateMonthly(credit['Kamata'], credit['Period otplate'] * 12, price));

  return [];
};

const getType = (articleType, year) => {
  const types = {
    Nekretnine: 'Stambeni',
    Vozila: 'Lizing',
  };
  if (articleType === 'Vozila' && year <= new Date().getFullYear() - 7) return 'Nenamjenski';
  return types[articleType] ?? 'Nenamjenski';
};

const generateMonthly = (interest, months, price) => {
  const multiplayer = Math.pow(1 + interest / 1200, months);
  let anuit;
  if (interest < 0.1) {
    anuit = price / months;
  } else {
    anuit = (price * interest * multiplayer) / (1200 * (multiplayer - 1));
  }
  return Math.round(anuit * 100) / 100;
};

export function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);

  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  const ab = new ArrayBuffer(byteString.length);

  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], {type: mimeString});
  return blob;
}
export const updateArticleKeys = [
  'price',
  'description',
  'title',
  'exchange',
  'urgent',
  'featured',
  'negotable',
  'fixed',
  'subtitle',
  'userId',
  'soldAt',
  'discounted',
  'articleType',
  'cityId',
  'conditions',
];
export function cleanUpArticle(article) {
  const cleanedUpArticle = {};
  for (const key in article) {
    if (updateArticleKeys.indexOf(key) > -1) {
      cleanedUpArticle[key] = article[key] === 'on' ? true : article[key];
    }
  }
  cleanedUpArticle['loanInstallment'] = article.loanInstallment[0];
  return cleanedUpArticle;
}

export const UPPER_LIMIT = {
  Nenamjenski: 50000,
  Stambeni: 300000,
  Lizing: 200000,
};

export const compare = (a, b) => {
  if (!isNaN(a?.name) && !isNaN(b.name)) return b - a;
  if (a?.name < b?.name) {
    return -1;
  }
  if (a?.name > b?.name) {
    return 1;
  }
  return 0;
};

export const excludeNewOrUsed = (category, subcategory, subsubcategory, subsubsubcategory) => {
  const toExclude = {
    category: [81, 87, 88, 89, 97],
    subcategory: [
      633, 721, 722, 723, 724, 725, 726, 727, 728, 729, 730, 731, 732, 733, 734, 735, 763, 737, 738, 739, 740, 741, 742, 743, 744, 745, 746,
      761, 762, 763, 764, 765, 766, 767, 768, 834, 835,
    ],
    subsubcategory: [
      2025, 2027, 2028, 2033, 2038, 2137, 2140, 2294, 2295, 2296, 2297, 2298, 2299, 2300, 2301, 2302, 2303, 2304, 2305, 2306, 2307, 2308,
      2309, 2310, 2311, 2312, 2313, 2314, 2315, 2316, 2534, 2585,
    ],
    subsubsubcategory: [2945, 2946, 2947],
  };
  return (
    toExclude.category.includes(category) ||
    toExclude.subcategory.includes(subcategory) ||
    toExclude.subsubcategory.includes(subsubcategory) ||
    toExclude.subsubsubcategory.includes(subsubsubcategory)
  );
};

import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
} from 'react-share';
import {SocialIcon} from 'react-social-icons';

const ICON_SIZE = 40;

const ICON_STYLE = {height: ICON_SIZE, width: ICON_SIZE};

export default function SocialShare({url}) {
  return (
    <div className="share-icons-holder">
      <FacebookShareButton size={ICON_SIZE} url={url}>
        <SocialIcon style={ICON_STYLE} network="facebook" />
      </FacebookShareButton>
      <FacebookMessengerShareButton size={32}>
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" width={ICON_SIZE} height={ICON_SIZE}>
          <defs>
            <radialGradient id="g1" cx="7.5" cy="45" r="49.5" gradientUnits="userSpaceOnUse">
              <stop offset="0" stopColor="#0099ff" />
              <stop offset=".6" stopColor="#a033ff" />
              <stop offset=".9" stopColor="#ff5280" />
              <stop offset="1" stopColor="#ff7061" />
            </radialGradient>
          </defs>
          <style></style>
          <path
            id="Layer"
            className="s4"
            d="m45 21.8c0 12.6-9.8 21.9-22.5 21.9q-3.4-0.1-6.5-0.9c-0.4-0.1-0.8-0.1-1.2 0.1l-4.5 1.9q-0.4 0.2-0.8 0.2-0.5 0-0.9-0.3-0.3-0.2-0.6-0.6-0.2-0.4-0.2-0.8l-0.1-4c0-0.5-0.3-1-0.6-1.3-4.4-3.9-7.1-9.6-7.1-16.2 0-12.5 9.8-21.8 22.5-21.8 12.7 0 22.5 9.3 22.5 21.8z"
          />
          <path
            id="Layer"
            className="s5"
            d="m9 28.2l6.6-10.5q0.4-0.6 1-1 0.5-0.4 1.2-0.5 0.7-0.1 1.4 0 0.7 0.2 1.3 0.6l5.2 4q0.2 0.1 0.4 0.2 0.2 0 0.5 0 0.2 0 0.4 0 0.2-0.1 0.4-0.2l7.1-5.4c0.9-0.7 2.1 0.4 1.5 1.4l-6.6 10.5q-0.4 0.6-1 1-0.5 0.4-1.2 0.5-0.7 0.1-1.4 0-0.7-0.2-1.3-0.6l-5.2-4q-0.2-0.1-0.4-0.2-0.2 0-0.5 0-0.2 0-0.4 0-0.2 0.1-0.4 0.2l-7.1 5.4c-0.9 0.7-2.1-0.4-1.5-1.4z"
          />
        </svg>
      </FacebookMessengerShareButton>
      <ViberShareButton size={32} url={url}>
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" width={ICON_SIZE} height={ICON_SIZE}>
          <path
            id="Layer"
            className="s0"
            d="m42.8 15.8v6.7c0 8.7-7.1 15.8-15.8 15.8h-7.9l-6.6 6.5c-0.4 0.4-1.3 0.1-1.3-0.5v-7.6c-5.4-2.6-9-8.1-9-14.2v-6.7c0-8.7 7.1-15.8 15.8-15.8h9c8.7 0 15.8 7.1 15.8 15.8z"
          />
          <path
            id="Layer"
            className="s1"
            d="m13.5 43.8l-1 1c-0.4 0.4-1.3 0.1-1.3-0.5v-7.6c-5.4-2.6-9-8.1-9-14.2v-6.7c0-8.7 7.1-15.8 15.8-15.8h2.2c-8.6 0-15.7 7.1-15.7 15.8v6.7c0 6.1 3.5 11.6 9 14.2z"
          />
          <path
            id="Layer"
            className="s2"
            d="m33.8 28.3c-0.2 0.9-0.7 1.8-1.4 2.5-0.9 1-2.2 1.5-3.8 1.5q-0.6 0-1.3-0.2c-3.6-0.5-8.6-2.3-11.8-5.5l-0.1-0.1c-3.2-3.2-5-8.2-5.5-11.8-0.4-2.1 0.1-3.9 1.3-5.1 0.7-0.7 1.6-1.2 2.5-1.4q0.2 0 0.3 0l3.7 0.7c0.3 0 0.5 0.2 0.6 0.5l1.1 5.3c0 0.3-0.1 0.5-0.2 0.7l-2.2 2.2c1.8 3.8 3.8 5.8 7.4 7.4l2.2-2.2c0.2-0.1 0.4-0.2 0.7-0.2l5.3 1.1c0.3 0.1 0.5 0.3 0.6 0.6l0.6 3.7q0.1 0.1 0 0.3z"
          />
          <g id="Layer">
            <path id="Layer" className="s3" d="m18.4 16.2l0.8-0.8-0.8 0.8z" />
            <path id="Layer" className="s3" d="m25.8 23.6l0.8-0.8-0.8 0.8z" />
            <path
              id="Layer"
              className="s3"
              d="m33.1 30q-0.3 0.4-0.7 0.8c-0.9 1-2.2 1.5-3.8 1.5q-0.6 0-1.3-0.2c-3.6-0.5-8.6-2.3-11.8-5.5l-0.1-0.1c-3.2-3.2-5-8.2-5.5-11.8-0.4-2.1 0.1-3.9 1.3-5.1q0.4-0.4 0.8-0.7c-0.8 1.2-1 2.7-0.7 4.4 0.5 3.6 2.3 8.6 5.5 11.8l0.1 0.1c3.2 3.2 8.2 5 11.8 5.5q0.7 0.1 1.3 0.1 1.8 0 3.1-0.8z"
            />
          </g>
          <g id="Layer">
            <path
              id="Layer"
              className="s2"
              d="m28.5 17.3c0 0.4-0.3 0.7-0.7 0.7-0.5 0-0.8-0.3-0.8-0.7 0-1.7-1.3-3-3-3-0.4 0-0.7-0.4-0.7-0.8 0-0.4 0.3-0.7 0.7-0.7 2.5 0 4.5 2 4.5 4.5z"
            />
            <path
              id="Layer"
              className="s2"
              d="m31.5 17.3q0 0.4 0 0.8c-0.1 0.4-0.5 0.7-0.9 0.6-0.4 0-0.7-0.4-0.6-0.8q0-0.3 0-0.6c0-3.4-2.7-6-6-6q-0.3 0-0.7 0c-0.4 0-0.8-0.3-0.8-0.7 0-0.4 0.3-0.8 0.7-0.8q0.4 0 0.8 0c4.1 0 7.5 3.3 7.5 7.5z"
            />
            <path
              id="Layer"
              className="s2"
              d="m34.5 17.3q0 1.2-0.3 2.4c-0.1 0.4-0.5 0.6-0.9 0.5-0.4-0.1-0.6-0.5-0.5-0.9q0.2-1 0.2-2c0-5-4-9-9-9q-0.3 0-0.7 0c-0.4 0-0.8-0.3-0.8-0.7 0-0.4 0.3-0.8 0.7-0.8q0.4 0 0.8 0c5.8 0 10.5 4.7 10.5 10.5z"
            />
          </g>
          <g id="Layer"></g>
          <g id="Layer"></g>
          <g id="Layer"></g>
          <g id="Layer"></g>
          <g id="Layer"></g>
          <g id="Layer"></g>
          <g id="Layer"></g>
          <g id="Layer"></g>
          <g id="Layer"></g>
          <g id="Layer"></g>
          <g id="Layer"></g>
          <g id="Layer"></g>
          <g id="Layer"></g>
          <g id="Layer"></g>
          <g id="Layer"></g>
        </svg>
      </ViberShareButton>
      <WhatsappShareButton size={32} url={url}>
        <SocialIcon style={ICON_STYLE} network="whatsapp" />
      </WhatsappShareButton>
      <TelegramShareButton size={32} url={url}>
        <SocialIcon style={ICON_STYLE} network="telegram" />
      </TelegramShareButton>
      <TwitterShareButton size={32} url={url}>
        <SocialIcon style={ICON_STYLE} network="twitter" />
      </TwitterShareButton>
      <LinkedinShareButton size={32} url={url}>
        <SocialIcon style={ICON_STYLE} network="linkedin" />
      </LinkedinShareButton>
    </div>
  );
}

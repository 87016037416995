import {useContext, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useParams, useHistory} from 'react-router-dom';
import {RequestContext} from '../App';
import Comments from '../components/comments';
import Modal from '../components/modal';
import '../styles/detail.scss';

function DetailComments() {
  const {api: socket} = useContext(RequestContext);
  const {id} = useParams();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const {article} = useSelector((state) => state.articles);
  const {user, currentUser} = useSelector((state) => state.auth);
  const [question, setQuestion] = useState();
  const [questionNumber, setQuestionNumber] = useState();
  const history = useHistory();
  const isMyProfile = article?.userId === currentUser?.id;

  useEffect(() => {
    const get = async () => {
      await socket.getArticle(id, dispatch);
    };
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleAddQuestion = () => {
    if (Object.keys(currentUser).length === 0) {
      // history.push('/login');
      history.push({
        pathname: '/login',
        location: window.location.pathname,
      });
    } else {
      setIsOpen((prev) => !prev);
    }
  };

  const handleNewQuestion = async (e) => {
    e.preventDefault();
    if (!question?.length) return;
    await socket.getUser(user.uuid, dispatch);
    await socket.addQuestion({articleId: article.id, userId: currentUser.id, body: question}, dispatch, user?.token);
    await socket.createNotification(
      {
        body: `Novo pitanje - ${currentUser.userName}`,
        notificationType: 'question',
        userId: article.user.id,
        seen: false,
        link: window.location.pathname,
      },
      user?.token
    );
    setIsOpen(false);
  };

  const closeModal = () => setIsOpen(false);

  return (
    <div className="detail mt-30">
      <div className="detail_title detail_title_back">
        <div className="container">
          <h1>
            <Link to={`/detail/${article?.uuid}`}>{article?.title}</Link>
          </h1>
        </div>
      </div>
      <div className="section">
        <div className="container">
          {/* Ukoliko bude htio da vrati javna pitanja, uncomment prvi h2 */}
          {/* <h2 className="title">Pitanja i odgovori ({article?.questions?.length})</h2>*/}
          <h2 className="title">Pitanja i odgovori ({questionNumber})</h2>
          <div className="separator"></div>
          <Comments numberOfComments={setQuestionNumber} />
          <Modal isOpen={isOpen} onRequestClose={closeModal}>
            <div className="col-md-12">
              <label className="form_default_input">
                <textarea type="text" placeholder="Upišite vaše pitanje" onChange={(e) => setQuestion(e.target.value)}></textarea>
                <button className="button w-100" onClick={handleNewQuestion}>
                  Dodaj pitanje
                </button>
              </label>
            </div>
          </Modal>
          <div className="row row_20">
            {!article?.soldAt && (
              <div className="col-md-6">
                <button disabled={isMyProfile} className="button w-100 mb-2" onClick={handleAddQuestion}>
                  Postavi pitanje
                </button>
              </div>
            )}
            <div className="col-md-6">
              <Link to={`/detail/${id}`} className="button button_light w-100">
                Povratak na oglas
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailComments;

import {useState} from 'react';
import {v4 as uuid} from 'uuid';

// export default function List({filteredCategories, clickHandler, pickCategory}) {
//   const multipleCategories = filteredCategories?.map(({id: catId, name, subcategories}) => (
//     <ul key={uuid()}>
//       <li onClick={() => pickCategory(name)}>{name}</li>
//       <li onClick={() => clickHandler(`${catId}`, `${subcategories[0].id}`, `${name}, ${subcategories[0].name}`)}>
//         {subcategories[0] && <b>{subcategories[0].name}</b>}
//       </li>
//     </ul>
//   ));

//   const singleCategory = filteredCategories[0]?.subcategories?.map(({id, name}) => (
//     <ul key={uuid()}>
//       <li>{filteredCategories[0].name}</li>
//       <li onClick={() => clickHandler(filteredCategories[0].id, id, `${filteredCategories[0].name}, ${name}`)}>
//         <b>{name}</b>
//       </li>
//     </ul>
//   ));

//   return filteredCategories?.length > 1 ? multipleCategories : singleCategory;
// }

export default function List({
  categories,
  subcategories,
  subSubCategories,
  subSubSubCategories,
  pickCategory,
  pickSubcategory,
  pickSubSubCategory,
  pickSubSubSubCategory,
}) {
  const [activeCategory, setActiveCategory] = useState();
  const [activeSubCategory, setActiveSubCategory] = useState();
  const [activeSubSubCategory, setActiveSubSubCategory] = useState();
  const [activeSubSubSubCategory, setActiveSubSubSubCategory] = useState();

  return (
    <div>
      {categories && (
        <>
          <strong>Kategorije</strong>
          <ul key={uuid()}>
            {categories?.map((category) => (
              <li
                key={uuid()}
                className={category.id === activeCategory ? 'active_category_objava' : ''}
                onClick={() => {
                  pickCategory(category);
                  setActiveCategory(category.id);
                  setActiveSubCategory(null);
                }}
              >
                {category.name}
              </li>
            ))}
          </ul>
        </>
      )}
      {subcategories && (
        <>
          <strong>Potkategorije</strong>
          <ul key={uuid()}>
            {subcategories?.map((subCategory) => (
              <li
                key={uuid()}
                className={subCategory.id === activeSubCategory ? 'active_category_objava' : ''}
                onClick={() => {
                  pickSubcategory(subCategory);
                  setActiveSubCategory(subCategory.id);
                }}
              >
                {subCategory.name}
              </li>
            ))}
          </ul>
        </>
      )}
      {subSubCategories && (
        <>
          <strong>Potpotkategorije</strong>
          <ul key={uuid()}>
            {subSubCategories?.map((subSubCategory) => (
              <li
                key={subSubCategory.uuid}
                className={subSubCategory.id === activeSubSubCategory ? 'active_category_objava' : ''}
                onClick={() => {
                  pickSubSubCategory(subSubCategory);
                  setActiveSubSubCategory(subSubCategory.id);
                }}
              >
                {subSubCategory.name}
              </li>
            ))}
          </ul>
        </>
      )}
      {subSubSubCategories && (
        <>
          <strong>Potpotpotkategorije</strong>
          <ul key={uuid()}>
            {subSubSubCategories?.map((subSubSubCategory) => (
              <li
                className={subSubSubCategory.id === activeSubSubSubCategory ? 'active_category_objava' : ''}
                key={uuid()}
                onClick={() => {
                  pickSubSubSubCategory(subSubSubCategory);
                  setActiveSubSubSubCategory(subSubSubCategory.id);
                }}
              >
                {subSubSubCategory.name}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  locations: [],
};

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocations: (state, action) => {
      state.locations = [...action.payload];
      return state;
    },
  },
});

import {Link} from 'react-router-dom';
import Collapse from './collapse';
import {useSelector} from 'react-redux';
import {useState, useRef} from 'react';

export default function Aside({count, setSearchState, order, currentPage, setPage, isActive, handleSearch}) {
  const [active, setActive] = useState(isActive);
  const {locations} = useSelector((state) => state.locations);
  const {categories} = useSelector((state) => state.categories);
  const min = useRef(null);
  const max = useRef(null);

  const minLoan = useRef(null);
  const maxLoan = useRef(null);

  const handleFilterLocation = (e) => {
    setSearchState((prev) => ({...prev, cityId: e.target.value}));
    setPage(1);
  };

  const handleConditions = (e) => {
    setSearchState((prev) => ({...prev, conditions: e.target.value}));
    setPage(1);
  };

  const handlePrice = (e) => {
    if (e.target.name === 'price_min') {
      setSearchState((prev) => ({...prev, payload: {count}, price: {...prev.price, min: min.current.value}}));
    } else {
      setSearchState((prev) => ({...prev, payload: {count}, price: {...prev.price, max: max.current.value}}));
    }
    setPage(1);
  };

  const handleLoan = (e) => {
    if (e.target.name === 'loan_min') {
      setSearchState((prev) => ({...prev, payload: {count}, loanInstallment: {...prev.loanInstallment, min: minLoan.current.value}}));
    } else {
      setSearchState((prev) => ({...prev, payload: {count}, loanInstallment: {...prev.loanInstallment, max: maxLoan.current.value}}));
    }
    setPage(1);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.target.name === 'price_min' || e.target.name === 'price_max') {
        handlePrice(e);
      } else if (e.target.name === 'loan_min' || e.target.name === 'loan_max') {
        handleLoan(e);
      }
    }
  };

  const handlePriceOrNegotiable = (e) => {
    if (e.target.value === 'all') {
      setSearchState((prev) => ({...prev, price: {min: 0, max: Number.MAX_VALUE}}));
    } else if (e.target.value === 'price') {
      setSearchState((prev) => ({...prev, price: {min: Number.MIN_VALUE, max: Number.MAX_VALUE}}));
    } else {
      setSearchState((prev) => ({...prev, price: {min: 0, max: Number.MIN_VALUE}}));
      min.current.value = undefined;
      max.current.value = undefined;
    }
    setPage(1);
  };

  const hadleSellingOrBuying = (e) => {
    if (e.target.value === 'all') {
      setSearchState((prev) => ({...prev, articleType: ''}));
    } else if (e.target.value === 'selling') {
      setSearchState((prev) => ({...prev, articleType: 'prodaja'}));
    } else if (e.target.value === 'renting') {
      setSearchState((prev) => ({...prev, articleType: 'iznajmljivanje'}));
    } else {
      setSearchState((prev) => ({...prev, articleType: 'kupovina'}));
    }
    setPage(1);
  };

  return (
    <aside className={`category_aside ${active ? 'active' : ''}`}>
      <span className="category_aside_close" onClick={() => setActive(!active)}></span>
      <div className="category_aside_wrap">
        <Collapse key="collapse-1" title="Kategorije" elementsCount={categories?.length || 0}>
          <ul className="category_aside_list">
            {categories?.map?.(({name, uuid}) => {
              return (
                <li className="d-flex justify-content-between align-items-center" key={uuid}>
                  <Link to={`/kategorija/${uuid}`}>{name}</Link>
                </li>
              );
            })}
          </ul>
        </Collapse>
        <Collapse key="collapse-2" title="Lokacija/Grad">
          <div className="collapse_default_wrap">
            <label className="form_default_select mb-0">
              <select onChange={handleFilterLocation}>
                <option value="">Sve</option>
                {locations.length &&
                  locations.map(({name, id, uuid}) => (
                    <option key={uuid} value={id}>
                      {name}
                    </option>
                  ))}
              </select>
            </label>
            <button className="button w-100 btn-search" onClick={handleSearch}>
              Pretraži
            </button>
          </div>
        </Collapse>
        <Collapse key="collapse-11" title="Stanje">
          <div className="collapse_default_wrap">
            <label className="form_default_select mb-0">
              <select onChange={handleConditions}>
                <option value="">Sve</option>
                <option key={'novo-uuid'} value={'novo'}>
                  Novo
                </option>
                <option key={'polovno-uuid'} value={'polovno'}>
                  Polovno
                </option>
              </select>
            </label>
            <button className="button w-100 btn-search" onClick={handleSearch}>
              Pretraži
            </button>
          </div>
        </Collapse>
        <Collapse key="collapse-3" title="Cijena">
          <div className="category_aside_range">
            <div className="row row_10">
              <div className="col-6">
                <label>Od (KM)</label>
                <input type="number" name="price_min" ref={min} onBlur={handlePrice} onKeyDown={handleKeyDown} />
              </div>
              <div className="col-6">
                <label>Do (KM)</label>
                <input type="number" name="price_max" ref={max} onBlur={handlePrice} onKeyDown={handleKeyDown} />
              </div>
            </div>
            <button className="button w-100 btn-search" onClick={handleSearch}>
              Pretraži
            </button>
          </div>
        </Collapse>

        <Collapse key="collapse-6" title="Rata">
          <div className="category_aside_range">
            <div className="row row_10">
              <div className="col-6">
                <label>Od (KM)</label>
                <input type="number" name="loan_min" ref={minLoan} onBlur={handleLoan} onKeyDown={handleKeyDown} />
              </div>
              <div className="col-6">
                <label>Do (KM)</label>
                <input type="number" name="loan_max" ref={maxLoan} onBlur={handleLoan} onKeyDown={handleKeyDown} />
              </div>
            </div>
            <button className="button w-100 btn-search" onClick={handleSearch}>
              Pretraži
            </button>
          </div>
        </Collapse>

        <Collapse key="collapse-4" title="Ostali filteri" elementsCount={5}>
          <div className="collapse_default_wrap" onChange={handlePriceOrNegotiable}>
            <label className="form_default_check radio">
              <input type="radio" value="all" name="type-1" defaultChecked />
              <span></span> Sve
            </label>
            <label className="form_default_check radio d-block">
              <input type="radio" value="price" name="type-1" />
              <span></span> Sa unesenom cijenom
            </label>
            <label className="form_default_check radio d-block">
              <input type="radio" value="negotiable" name="type-1" />
              <span></span> Cijena po dogovoru
            </label>
            <button className="button w-100 btn-search" onClick={handleSearch}>
              Pretraži
            </button>
          </div>
          {/* <div className="collapse_default_wrap">
            <label className="form_default_check d-block">
              <input type="checkbox" />
              <span></span> Sa unesenom cijenom
            </label>
            <label className="form_default_check d-block">
              <input type="checkbox" defaultChecked />
              <span></span> Besplatna dostava
            </label>
            <label className="form_default_check d-block">
              <input type="checkbox" />
              <span></span> Cijena po dogovoru
            </label>
          </div> */}
        </Collapse>
        <Collapse key="collapse-5" title="Vrsta artikla" elementsCount={3}>
          <div className="collapse_default_wrap" onChange={hadleSellingOrBuying}>
            <label className="form_default_check radio">
              <input type="radio" value="all" name="type" defaultChecked />
              <span></span> Sve
            </label>
            <label className="form_default_check radio d-block">
              <input type="radio" value="selling" name="type" />
              <span></span> Samo prodaja
            </label>
            <label className="form_default_check radio d-block">
              <input type="radio" value="renting" name="type" />
              <span></span> Samo iznajmljivanje
            </label>
            <label className="form_default_check radio d-block">
              <input type="radio" value="buying" name="type" />
              <span></span> Samo potražnja
            </label>

            <button className="button w-100 btn-search" onClick={handleSearch}>
              Pretraži
            </button>
          </div>
        </Collapse>
      </div>
    </aside>
  );
}

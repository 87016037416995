import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RequestContext} from '../App';
import withSeo from '../components/seo';
import Attribute from './attribute';

const Admin = ({setTitle}) => {
  const {api: socket} = useContext(RequestContext);
  const dispatch = useDispatch();

  //state for add new category
  const [categoryName, setCategoryName] = useState();
  //state for update name of exist category
  const [categoryUpdateName, setCategoryUpdateName] = useState();
  //uuid for update exist category
  const [categoryForUpdate, setCategoryForUpdate] = useState();
  //uuid for delete exist category
  const [categoryUuid, setCategoryUuid] = useState();

  //state for set catId to add new subCategory for this catId
  const [categoryIdForSub, setCategoryIdForSub] = useState();
  // state for setSubCategory name for change
  const [subCategoryName, setSubCategoryName] = useState();
  // setCatId for change subCat
  const [catIdForUpdateSub, setCatIdForUpdatesub] = useState();
  // uuid of subcat for change
  const [subCatForUpdate, setSubCatForUpdate] = useState();
  // name of subcat for change
  const [subCatNameForUpdate, setSubCatNameForUpdate] = useState();
  // id of cat for delete subcat
  const [subCatForDelete, setSubCatForDelete] = useState();
  // uuid of subcat for delete
  const [catIdForDeleteSub, setCatIdForDeleteSub] = useState();

  //ATTRIBUTES STATES
  const [, setCatIdForNewAttribute] = useState();
  const [, setSubCatIdForNewAttribute] = useState();
  const [, setTypeOfNewAttribute] = useState();
  const [, setCatIdUpdateAttribute] = useState();
  const [, setSubCatIdUpdateAttribute] = useState();
  const [, setAttrUuIdForUpdate] = useState();
  const [, setCatIdDeleteAttribute] = useState();
  const [, setSubCatIdDeleteAttribute] = useState();
  const [, setAttrUuidForDelete] = useState();

  const [, setTempAttributeValues] = useState([]);

  const categories = useSelector((state) => state.categories?.categories);
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    setTitle('Admin panel');
  }, []);

  const addNewCategory = () => {
    socket.addNewCategory(categoryName, dispatch);
    setCategoryName('');
  };

  const handleDelete = (e) => {
    setCategoryUuid(e.target.value);
  };

  const deleteCategory = (e) => {
    socket.deleteExistCategory(categoryUuid, dispatch);
    setCategoryUuid('');
  };

  const updateCategory = (e) => {
    socket.updateExistCategory(categoryForUpdate, {name: categoryUpdateName, alias: categoryUpdateName, orderNumber: 0}, dispatch);
    setCategoryUpdateName('');
  };

  const addSubCategory = (e) => {
    socket.addSubCategory({name: subCategoryName, alias: subCategoryName, orderNumber: 0, categoryId: Number(categoryIdForSub)}, dispatch);
    setSubCategoryName('');
  };

  const handleChangeCategoryForSub = async (e) => {
    setCatIdForDeleteSub('');
    setCatIdForNewAttribute('');
    setSubCatIdForNewAttribute('');
    setTypeOfNewAttribute('');
    setCatIdUpdateAttribute('');
    setSubCatIdUpdateAttribute('');
    setAttrUuIdForUpdate('');
    setCatIdDeleteAttribute('');
    setSubCatIdDeleteAttribute('');
    setAttrUuidForDelete('');
    setCatIdForUpdatesub(e.target.value);
    setTempAttributeValues([]);
    setSubCatForDelete('');
    setSubCatForUpdate('');
    setSubCategories(categories.find((category) => category.id === +e.target.value)?.subcategories ?? []);
  };

  const updateSubCategory = (e) => {
    socket.updateSubCategory(
      subCatForUpdate,
      {name: subCatNameForUpdate, alias: subCatNameForUpdate, orderNumber: 0},
      dispatch,
      catIdForUpdateSub
    );
    setSubCatForUpdate('');
    setSubCatNameForUpdate('');
    setCatIdForUpdatesub('');
  };

  const handleCatForDeleteSubCat = async (e) => {
    setCatIdForUpdatesub('');
    setCatIdForNewAttribute('');
    setSubCatIdForNewAttribute('');
    setTypeOfNewAttribute('');
    setCatIdUpdateAttribute('');
    setSubCatIdUpdateAttribute('');
    setAttrUuIdForUpdate('');
    setCatIdDeleteAttribute('');
    setSubCatIdDeleteAttribute('');
    setAttrUuidForDelete('');
    setCatIdForDeleteSub(e.target.value);
    setSubCatForUpdate('');
    setSubCatForDelete('');
    setSubCategories(categories.find((category) => category.id === +e.target.value)?.subcategories ?? []);
  };

  const deleteSubCategory = (e) => {
    socket.deleteSubCategory(subCatForDelete, dispatch, catIdForDeleteSub);
    setSubCatForDelete('');
    setCatIdForDeleteSub('');
  };

  return (
    <section className="section category">
      <div className="container container-admin">
        <div className="row">
          <div className="col-md-6">
            <h1>KATEGORIJE</h1>
            <br />
            <h3>Dodaj novu kategoriju</h3>
            <div className="row">
              <div className="col-md-8">
                <label className="form_default_input">
                  <input
                    type="text"
                    value={categoryName}
                    maxLength={50}
                    placeholder="Unesite naziv kategorije!"
                    onChange={(e) => {
                      setCategoryName(e.target.value);
                    }}
                  />
                </label>
              </div>
              <div className="col-md-4">
                <button disabled={!categoryName} className="button" onClick={addNewCategory}>
                  Dodaj
                </button>
              </div>
            </div>
            <br />
            <h3>Promijeni naziv kategorije</h3>
            <div className="row">
              <div className="col-md-8">
                <label className="form_default_select">
                  <select name="categories" onChange={(e) => setCategoryForUpdate(e.target.value)}>
                    <option value={''}>Izaberite kategoriju</option>
                    {categories.map((category) => {
                      return (
                        <option value={category.uuid} key={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <label className="form_default_input">
                  <input
                    type="text"
                    maxLength={50}
                    value={categoryUpdateName}
                    placeholder="Unesite novi naziv kategorije!"
                    onChange={(e) => setCategoryUpdateName(e.target.value)}
                  />
                </label>
              </div>
              <div className="col-md-4">
                <button disabled={!categoryForUpdate || !categoryUpdateName} className="button button_orange" onClick={updateCategory}>
                  Promijeni
                </button>
              </div>
            </div>
            <h3>Obriši kategoriju</h3>
            <div className="row">
              <div className="col-md-8">
                <label className="form_default_select">
                  <select name="categories" onChange={handleDelete}>
                    <option value={''}>Izaberite kategoriju</option>
                    {categories.map((category) => {
                      return (
                        <option value={category.uuid} key={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </div>
              <div className="col-md-4">
                <button disabled={!categoryUuid} className="button button_red" onClick={deleteCategory}>
                  Obriši
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <h2>PODKATEGORIJE</h2>
            <br />
            <h3>Dodaj novu podkategoriju</h3>
            <label className="form_default_select">
              <select value={categoryIdForSub} name="categories" onChange={(e) => setCategoryIdForSub(e.target.value)}>
                <option value="">Izaberite kategoriju</option>
                {categories.map((category) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
              </select>
            </label>
            <h5>Iz padajućeg menija izaberite kategoriju za koju želite dodati podkategoriju!</h5>
            <div className="row">
              <div className="col-md-8">
                <label className="form_default_input">
                  <input
                    type="text"
                    maxLength={50}
                    placeholder="Unesite naziv podkategorije!"
                    value={subCategoryName}
                    onChange={(e) => setSubCategoryName(e.target.value)}
                  />
                </label>
              </div>
              <div className="col-md-4">
                <button disabled={!categoryIdForSub || !subCategoryName} onClick={addSubCategory} className="button">
                  Dodaj
                </button>
              </div>
            </div>
            <h3>Promijeni naziv podkategorije</h3>
            <div className="row">
              <div className="col-md-6">
                <label className="form_default_select">
                  <select className="subcategories" value={catIdForUpdateSub} onChange={handleChangeCategoryForSub}>
                    <option value={''}>Izaberite kategoriju</option>
                    {categories.map((category) => {
                      return (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </div>
              <div className="col-md-6">
                <label className="form_default_select">
                  <select
                    disabled={!catIdForUpdateSub}
                    className="subcategories"
                    value={subCatForUpdate}
                    onChange={(e) => {
                      setSubCatForUpdate(e.target.value);
                    }}
                  >
                    <option value="">Izaberite podkategoriju</option>
                    {subCategories?.map((subCategory) => {
                      return (
                        <option key={subCategory.id} value={subCategory.uuid}>
                          {subCategory.name}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <label className="form_default_input">
                  <input
                    type="text"
                    maxLength={50}
                    placeholder="Unesite novi naziv podkategorije!"
                    value={subCatNameForUpdate}
                    onChange={(e) => setSubCatNameForUpdate(e.target.value)}
                  />
                </label>
              </div>
              <div className="col-md-4">
                <button disabled={!subCatForUpdate || !subCatNameForUpdate} className="button button_orange" onClick={updateSubCategory}>
                  Promijeni
                </button>
              </div>
            </div>
            <h3>Obriši podkategoriju</h3>
            <div className="row">
              <div className="col-md-4">
                <label className="form_default_select">
                  <select value={catIdForDeleteSub} className="subcategories" onChange={handleCatForDeleteSubCat}>
                    <option value="">Izaberite kategoriju</option>
                    {categories.map((category) => {
                      return (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </div>
              <div className="col-md-4">
                <label className="form_default_select">
                  <select
                    disabled={!catIdForDeleteSub}
                    className="subcategories"
                    value={subCatForDelete}
                    onChange={(e) => {
                      setSubCatForDelete(e.target.value);
                    }}
                  >
                    <option value="">Izaberite podkategoriju</option>
                    {subCategories.map((subCategory) => {
                      return (
                        <option key={subCategory.id} value={subCategory.uuid}>
                          {subCategory.name}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </div>
              <div className="col-md-4">
                <button disabled={!subCatForDelete || !catIdForDeleteSub} className="button button_red" onClick={deleteSubCategory}>
                  Obriši
                </button>
              </div>
            </div>
          </div>
        </div>

        <Attribute />
      </div>
    </section>
  );
};

export default withSeo(Admin);

import {useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RequestContext} from '../App';
import Modal from './modal';

function StoreHead(props) {
  const {api: socket} = useContext(RequestContext);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const {user} = useSelector((state) => state.auth);

  const handleMessage = async (e) => {
    e.preventDefault();
    if (!message.length) return;
    await socket.createMessage(
      {senderId: props?.currentUser?.id, recieverId: props?.userProfile?.id, body: message, title: 'Privatna poruka'},
      dispatch,
      user?.token
    );
    closeModal();
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div
      className="profile_head mt-30"
      style={{backgroundImage: 'url("/images/user_bg.jpg")', textAlign: 'left', padding: '30px 0px', borderRadius: '10px'}}
    >
      <div className="container">
        <div className="row g-15">
          <div className="col-md-4" style={{textAlign: 'center', padding: '0px'}}>
            <div className="profile_head_image">
              <figure>
                <img src={props.image} alt="store" />
              </figure>
            </div>

            <h1 className="pl-3">{props.username}</h1>
            {props.isMyProfile ? (
              ''
            ) : (
              <div className="profile_head_buttons">
                <div onClick={openModal} className="button">
                  Pošaljite poruku
                </div>
              </div>
            )}
          </div>
          <div className="offset-md-4 col-md-4 pr-md-5">
            <div className="store-info">
              <div className="mb-md-4">
                <h4 className="store-heading mb-0">LOKACIJA</h4>
                <p>{props.cityName}</p>
              </div>
              <div className="mb-md-4">
                <h4 className="store-heading mb-0">ADRESA</h4>
                <p>{props.address}</p>
              </div>
              <div>
                <h4 className="store-heading mb-0">TELEFON</h4>
                <p>{props.phoneNumber}</p>
              </div>
              <div>
                <h4 className="store-heading mb-0">E-MAIL</h4>
                <p>{props.email}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal onRequestClose={closeModal} isOpen={isOpen}>
        <div className="col-md-12">
          <label className="form_default_input">
            <textarea type="text" placeholder="Molimo upišite poruku korisniku." onChange={(e) => setMessage(e.target.value)}></textarea>
            <button className="button w-100" onClick={handleMessage}>
              Pošalji poruku
            </button>
          </label>
        </div>
      </Modal>
    </div>
  );
}

export default StoreHead;

import {Link} from 'react-router-dom';
import {useEffect, useContext} from 'react';
import {useDispatch} from 'react-redux';
import MessagesLeft from '../components/messagesLeft';
import {dateFormat} from '../helpers/utilities';
import '../styles/messages.scss';
import {useSelector} from 'react-redux';
import withSeo from '../components/seo';
import {RequestContext} from '../App';
import {SMALL_IMAGE} from '../api/apiBase';

function Poruke({setTitle}) {
  const dispatch = useDispatch();
  const {api: socket} = useContext(RequestContext);
  const {currentUser, user} = useSelector((state) => state.auth);
  const {chats} = useSelector((state) => state.chats);
  const unread = chats?.reduce((acc, chat) => acc + chat.unreadCount, 0) ?? 0;
  const unanswered = chats?.filter((chat) => chat?.messages[0]?.senderId === currentUser?.id);

  useEffect(() => {
    const get = async () => {
      setTitle('Poruke');
      if (user.id) await socket.getChats(user.id, {page: 0, count: 20}, dispatch, user.token);
    };
    get();
  }, [currentUser, user]);

  return (
    <>
      <section className="section messages">
        <div className="container">
          <div className="row">
            <MessagesLeft inbox={unread} unanswered={unanswered?.length} />
            <div className="col-md-8">
              <div className="messages_items">
                {chats?.map((chat, i) => {
                  const otherUser = currentUser?.id === chat?.senderId ? chat?.reciever : chat?.sender;
                  const isMessageUnread = chat?.messages[0]?.senderId !== currentUser?.id && chat?.messages[0]?.seen === false;

                  return (
                    <div className="messages_item" key={i}>
                      <div className="row row_10 messages_item_top">
                        <label className="messages_check">
                          <input type="checkbox" />
                          <span></span>
                        </label>

                        <div className="col-8 messages_item_subject">
                          <h3>
                            <Link to={`/poruka/${chat.uuid}`}>{`${isMessageUnread ? 'NEPROČITANA PORUKA' : 'PORUKA'}`}</Link>
                          </h3>
                        </div>
                      </div>
                      <Link to={`/poruka/${chat.uuid}`}>
                        <div className="row row_10 messages_item_bottom">
                          <div className="col-9 messages_item_user">
                            <figure>
                              <img
                                src={otherUser?.profileImage ? SMALL_IMAGE + otherUser?.profileImage : '/images/user_placeholder.svg'}
                                alt="user"
                              />
                            </figure>
                            <h4>{otherUser.userName}</h4>
                            <p>{chat?.messages[0]?.body}</p>
                          </div>
                          <div className="col-3 messages_item_time">
                            <time>{dateFormat(chat.updatedAt)}</time>
                            <span>{chat.unreadCount}</span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withSeo(Poruke);

// eslint-disable-next-line react-hooks/exhaustive-deps
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'RS',
    'Period otplate': '1',
    'Min. iznos': '1000',
    'Max. iznos': '2000',
    Kamata: '4.7',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'RS',
    'Period otplate': '2',
    'Min. iznos': '2001',
    'Max. iznos': '3000',
    Kamata: '5.49',
    Banka: 'SberBank',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'RS',
    'Period otplate': '3',
    'Min. iznos': '3001',
    'Max. iznos': '4000',
    Kamata: '5.49',
    Banka: 'SberBank',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'RS',
    'Period otplate': '4',
    'Min. iznos': '4001',
    'Max. iznos': '7000',
    Kamata: '5.49',
    Banka: 'SberBank',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'RS',
    'Period otplate': '5',
    'Min. iznos': '7001',
    'Max. iznos': '10000',
    Kamata: '5.49',
    Banka: 'SberBank',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'RS',
    'Period otplate': '6',
    'Min. iznos': '10001',
    'Max. iznos': '15000',
    Kamata: '5.49',
    Banka: 'SberBank',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'RS',
    'Period otplate': '7',
    'Min. iznos': '15001',
    'Max. iznos': '20000',
    Kamata: '5.49',
    Banka: 'SberBank',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'RS',
    'Period otplate': '8',
    'Min. iznos': '20001',
    'Max. iznos': '30000',
    Kamata: '5.49',
    Banka: 'SberBank',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'RS',
    'Period otplate': '9',
    'Min. iznos': '30001',
    'Max. iznos': '40000',
    Kamata: '5.49',
    Banka: 'SberBank',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'RS',
    'Period otplate': '10',
    'Min. iznos': '40001',
    'Max. iznos': '50000',
    Kamata: '5.49',
    Banka: 'SberBank',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'FBIH',
    'Period otplate': '1',
    'Min. iznos': '1000',
    'Max. iznos': '2000',
    Kamata: '6',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'FBIH',
    'Period otplate': '2',
    'Min. iznos': '2001',
    'Max. iznos': '3000',
    Kamata: '6',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'FBIH',
    'Period otplate': '3',
    'Min. iznos': '3001',
    'Max. iznos': '4000',
    Kamata: '6',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'FBIH',
    'Period otplate': '4',
    'Min. iznos': '4001',
    'Max. iznos': '7000',
    Kamata: '6',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'FBIH',
    'Period otplate': '5',
    'Min. iznos': '7001',
    'Max. iznos': '10000',
    Kamata: '6',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'FBIH',
    'Period otplate': '6',
    'Min. iznos': '10001',
    'Max. iznos': '15000',
    Kamata: '7',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'FBIH',
    'Period otplate': '7',
    'Min. iznos': '15001',
    'Max. iznos': '20000',
    Kamata: '7',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'FBIH',
    'Period otplate': '8',
    'Min. iznos': '20001',
    'Max. iznos': '30000',
    Kamata: '7',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'FBIH',
    'Period otplate': '9',
    'Min. iznos': '30001',
    'Max. iznos': '40000',
    Kamata: '7',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Nenamjenski',
    Entitet: 'FBIH',
    'Period otplate': '10',
    'Min. iznos': '40001',
    'Max. iznos': '50000',
    Kamata: '7',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '5',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '3.45',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '6',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '3.95',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '7',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '3.95',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '8',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '3.95',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '9',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '3.95',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '10',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '3.95',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '11',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.2',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '12',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.2',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '13',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.2',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '14',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.2',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '15',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.2',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '16',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.5',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '17',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.5',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '18',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.5',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '19',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.5',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '20',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.5',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '21',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.5',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '22',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.5',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '23',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.5',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '24',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.5',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'RS',
    'Period otplate': '25',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.5',
    Banka: 'NLB BL',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '5',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '3.49',
    Banka: 'Raiffeisen',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '6',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '7',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '8',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '9',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '10',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '11',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.48',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '12',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.48',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '13',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.48',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '14',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.48',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '15',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.48',
    Banka: 'Sparkasse',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '16',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.99',
    Banka: 'Raiffeisen',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '17',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.99',
    Banka: 'Raiffeisen',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '18',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.99',
    Banka: 'Raiffeisen',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '19',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.99',
    Banka: 'Raiffeisen',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '20',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.99',
    Banka: 'Raiffeisen',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '21',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.99',
    Banka: 'Raiffeisen',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '22',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.99',
    Banka: 'Raiffeisen',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '23',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.99',
    Banka: 'Raiffeisen',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '24',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.99',
    Banka: 'Raiffeisen',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Stambeni',
    Entitet: 'FBIH',
    'Period otplate': '25',
    'Min. iznos': '30000',
    'Max. iznos': '300000',
    Kamata: '4.99',
    Banka: 'Raiffeisen',
    'Vrsta Kamate': 'Promjenljiva',
    'Napomena:':
      'Prvi 5 godina je kamata fiksna. Poslije se preračunava na bazi 6-mjesečnog ili 12-mjesečnog EURRIBORA. Banka ima pravo da traži dodatne instrumente obezbjeđenja',
    'Troškovi i uslovi':
      'Mogući troškovi koji zavise od izbora banke su: naknada za obradu kredita, naknada za održavanje kreditnog računa, trošak mjenice, troškovi ovjere saglasnosti o zapljeni primanja kod notara ili u opštini za sve učesnike kreditnog procesa, polica riziko osiguranja za kredit/ polica životnog osiguranja, trošak zaloga depozita (ako je obezbjeđenje kredita depozit), trošak Paketa usluga (samo ukoliko je to uslov korištenja proizvoda) ',
  },
  {
    'Vrsta kredita': 'Lizing',
    Entitet: 'BiH',
    'Period otplate': '7',
    'Min. iznos': '10000',
    'Max. iznos': '200000',
    Kamata: '5.5',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'PDV nije predmet lizinga. Lizing kuća ima pravo da traži dodatne instrumente obezbjeđenja. Obavezno kasko osiguranje',
    'Troškovi i uslovi': 'Učešće min. 10% na cijuenu bez PDV-a',
  },
  {
    'Vrsta kredita': 'Lizing',
    Entitet: 'RS',
    'Period otplate': '7',
    'Min. iznos': '10000',
    'Max. iznos': '200000',
    Kamata: '5.5',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'PDV nije predmet lizinga. Lizing kuća ima pravo da traži dodatne instrumente obezbjeđenja. Obavezno kasko osiguranje',
    'Troškovi i uslovi': 'Učešće min. 10% na cijuenu bez PDV-a',
  },
  {
    'Vrsta kredita': 'Lizing',
    Entitet: 'FBIH',
    'Period otplate': '7',
    'Min. iznos': '10000',
    'Max. iznos': '200000',
    Kamata: '5.5',
    'Vrsta Kamate': 'Fiksna',
    'Napomena:': 'PDV nije predmet lizinga. Lizing kuća ima pravo da traži dodatne instrumente obezbjeđenja. Obavezno kasko osiguranje',
    'Troškovi i uslovi': 'Učešće min. 10% na cijuenu bez PDV-a',
  },
];

import {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {RequestContext} from '../App';
import {articlesSlice} from '../store/reducers/articlesReducer';

export default function AdminKorisnik() {
  const {id} = useParams();
  const {users, pikArticles, localPikArticles, hasMorePikArticles, user} = useSelector((state) => state.auth);

  const {articles} = useSelector((state) => state.articles.setForDownload);
  const {api: socket} = useContext(RequestContext);
  const [currentUser, setCurrentUser] = useState();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState();

  const getUserArticles = async (getUser) => {
    await socket.getPikUserArticles({name: getUser.pikName, page, userId: getUser.id}, dispatch);
  };

  useEffect(() => {
    if (id) {
      const getUser = users.find(({uuid}) => uuid === id);
      setCurrentUser(getUser);
      getUserArticles(getUser);
    }
    return () => {
      setCurrentUser([]);
    };
  }, [id]);

  const handleMore = async () => {
    if (category) {
      await socket.getMorePikUserArticlesByCategory(
        {name: currentUser.pikName, page: page + 1, userId: currentUser.id, category},
        dispatch
      );
    } else {
      await socket.getMorePikUserArticles(
        {
          name: currentUser.pikName,
          page: page + 1,
          userId: currentUser.id,
        },
        dispatch
      );
    }
    setPage((prev) => prev + 1);
  };

  const handleAddToQueue = (id) => {
    if (localPikArticles.includes(id)) return;
    dispatch(
      articlesSlice.actions.queueForDownload({
        userId: currentUser.id,
        article: [id],
      })
    );
  };

  const handleDequeue = async () => {
    await socket.getExternalArticle(
      {
        ids: articles,
        userId: id,
      },
      dispatch,
      user?.token
    );
  };

  const handleCategory = async (e) => {
    setCategory(e.target.value);
    await socket.getPikUserArticlesByCategory(
      {name: currentUser.pikName, page: 1, userId: currentUser.id, category: e.target.value},
      dispatch
    );
    setPage(1);
  };
  return (
    <section className="section category">
      <div className="container container-admin">
        <div className="col-12">
          <p>
            Naziv pik profila <b>{currentUser?.name}</b>
          </p>
          <hr />
          <p>
            Lista artikala.
            <b className="text-red"> Crvenom bojom su obiljezeni artikli koji nisu preuzeti,</b>
            <b className="text-blue"> plavom na listi za preuzimanje</b>
            <b className="text-green"> , zelenom oglasi koji su preuzeti.</b>
          </p>
          <hr />
          <p>
            <b>Broj oglasa za preuzimanje {articles?.length ?? 0}</b>
          </p>

          <button className="button" onClick={handleDequeue}>
            Preuzmi
          </button>
        </div>
        <div className="col-12">
          <label className="form_default_select">
            <select name="kategorija" onChange={handleCategory}>
              <option hidden>Izaberite categoriju</option>
              {pikArticles?.kategorije?.map((cat) => (
                <option key={cat.name} value={cat.id}>
                  {cat.name}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="12">
          <div className="row">
            {pikArticles?.artikli?.map(({id, image, title, kategorija}) => (
              <div key={id} className="col-2 p-2 pik" onClick={() => handleAddToQueue(id)}>
                <div download={articles.includes(id)} disabled={localPikArticles.includes(id)}>
                  <h4>{title}</h4>
                  <p>{kategorija}</p>
                  <img className="w-100" src={image} alt={title} />
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            {hasMorePikArticles && (
              <button className="button mx-auto" onClick={handleMore}>
                Ucitaj jos
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

import {Link} from 'react-router-dom';
import '../styles/detail.scss';

function DetailInfo() {
  return (
    <div className="detail">
      <div className="detail_title detail_title_back">
        <div className="container">
          <h1>
            <Link to="/detail">AUDI A3 SPORTBACK 1.6 TDI 2017 NAVI AUTOMATIK</Link>
          </h1>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="row text_only">
            <div className="col-12">
              <h2>Heading 2</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the 1500s, when an unknown printer took. Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                took.
              </p>
              <h3>Heading 3</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the 1500s, when an unknown printer took. Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                took.
              </p>
              <h4>Heading 4</h4>
              <ul>
                <li>Lorem Ipsum is simply dummy text</li>
                <li>Lorem Ipsum is simply dummy text</li>
                <li>Lorem Ipsum is simply dummy text</li>
              </ul>
              <h5>Heading 5</h5>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the 1500s, when an unknown printer took. Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                took.
              </p>
              <h6>Heading 6</h6>
              <ol>
                <li>Lorem Ipsum is simply dummy text</li>
                <li>Lorem Ipsum is simply dummy text</li>
                <li>Lorem Ipsum is simply dummy text</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailInfo;

import {useState, useMemo, useCallback} from 'react';

import DataListInput from 'react-datalist-input';

export const Input = ({defaultValue = '', label, value = '', type = 'text', disabled, icon = 'icon_user', name, handleChange}) => (
  <div className="profile_settings_input">
    <label className="form_default_input form_default_w_icon">
      {label}
      <input
        type={type}
        name={name}
        placeholder={label}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        onChange={handleChange}
      />
      <img src={`/images/${icon}.svg`} alt="user" />
    </label>
    <button className="profile_settings_btn save">
      <img src="/images/double_check.svg" alt="check" />
    </button>
  </div>
);

export const DataList = ({data, name, handleChange, value}) => {
  const [item, setItem] = useState({name: ''});

  const onSelect = useCallback((selectedItem) => {
    setItem(selectedItem);
    handleChange((prev) => ({...prev, cityId: selectedItem.id}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items = useMemo(
    () =>
      data.map((oneItem) => ({
        label: oneItem.name,
        key: oneItem.uuid,
        ...oneItem,
      })),
    [data]
  );
  return (
    <div className="profile_settings_input">
      <label className="form_default_input form_default_w_icon">
        Lokacija
        <DataListInput name={name} placeholder={value} value={item.name} items={items} onSelect={onSelect} />
      </label>
    </div>
  );
};

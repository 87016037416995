import {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {RequestContext} from '../App';
import List from '../components/list';
import {categoriesSlice} from '../store/reducers/categoriesReducer';
import {newArticleSlice} from '../store/reducers/newArticleReducers';

export const initialState = {
  userId: '',
  locationId: '',
  categoryId: '',
  subcategoryId: null,
  subsubcategoryId: null,
  subsubsubcategoryId: null,
  featured: '',
  conditions: '',
  price: '',
  description: '',
  title: '',
  articleType: 'prodaja',
  exchange: '',
  urgent: '',
  negotable: '',
  fixed: '',
  category: '',
  subtitle: '',
  attributes: [],
};

function Objava1() {
  const history = useHistory();
  const {api: socket} = useContext(RequestContext);
  const user = useSelector((state) => state.auth);
  const locations = useSelector((state) => state.locations.locations);
  const {categories, subSubCategories} = useSelector((state) => state.categories);
  const [stateSubSubSubCategories, setStateSubSubSubCategories] = useState([]);
  const [subCategories, setSubcategories] = useState([]);
  const dispatch = useDispatch();
  const newArticle = useSelector((state) => state.newArticle) || {};
  const [state, setState] = useState({...newArticle});

  const pickCategory = async (category) => {
    setSubcategories(category.subcategories);

    dispatch(categoriesSlice.actions.setSubSubCategories([]));

    setStateSubSubSubCategories([]);
    dispatch(
      newArticleSlice.actions.updateState({
        ...state,
        ...newArticle,
        categoryId: category.id,
        subcategoryId: null,
        subsubcategoryId: null,
        subsubsubcategoryId: null,
        category: category.name,
      })
    );
    setState({...state, categoryId: category.id, subcategoryId: null, subsubcategoryId: null, subsubsubcategoryId: null});
  };

  const pickSubcategory = async (subcategory) => {
    await socket.getSubSubCategories(subcategory.id, dispatch);
    setStateSubSubSubCategories([]);
    dispatch(
      newArticleSlice.actions.updateState({
        ...state,
        ...newArticle,
        subcategoryId: subcategory.id,
        subsubcategoryId: null,
        subsubsubcategoryId: null,
      })
    );
    setState({...state, subcategoryId: subcategory.id, subsubcategoryId: null, subsubsubcategoryId: null});
  };

  const pickSubSubCategory = (subsubcategory) => {
    var originalSubSubSubCategories = [...subsubcategory.subsubsubcategories].reverse();
    var sortedSubSubSubCategories = originalSubSubSubCategories.sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1));

    setStateSubSubSubCategories(sortedSubSubSubCategories);
    dispatch(
      newArticleSlice.actions.updateState({...state, ...newArticle, subsubcategoryId: subsubcategory.id, subsubsubcategoryId: null})
    );
    setState({...state, subsubcategoryId: subsubcategory.id, subsubsubcategoryId: null});
  };

  const pickSubSubSubCategory = (subsubsubcategory) => {
    dispatch(newArticleSlice.actions.updateState({...state, ...newArticle, subsubsubcategoryId: subsubsubcategory.id}));
    setState({...state, subsubsubcategoryId: subsubsubcategory.id});
  };

  useEffect(() => {
    if (!!newArticle?.categoryId) {
      dispatch(newArticleSlice.actions.resetState());
      dispatch(categoriesSlice.actions.resetNewArticleState());
      return;
    }

    const locationId = locations?.find((location) => location.id === user.user.cityId)?.id;
    console.log(locations, user?.user?.cityId);
    dispatch(newArticleSlice.actions.updateState({...initialState, articleType: 'prodaja', userId: user.currentUser.id, locationId}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRadio = (articleType, saleOnly = null) => {
    if (saleOnly) {
      dispatch(newArticleSlice.actions.updateState({...state, ...newArticle, articleType, saleOnly: true}));
      setState({...state, articleType});
    } else {
      dispatch(newArticleSlice.actions.updateState({...state, ...newArticle, articleType, saleOnly: undefined}));
      setState({...state, articleType});
    }
  };

  const handleContinue = (e) => {
    e.preventDefault();
    history.push('/objava-2');
  };

  const isDisabled = () => {
    if ((subSubCategories?.length && !newArticle.subsubcategoryId) || (stateSubSubSubCategories?.length && !newArticle.subsubsubcategoryId))
      return true;
    return !(!!newArticle.articleType?.length && !!newArticle.categoryId && !!newArticle.subcategoryId);
  };

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="jumbotron">
            <div className="row mb-3">
              <div className="col-lg-6">
                <h2 className="title">Kratak opis i tip oglasa</h2>
              </div>
              <div className="col-lg-6 text-right">
                <div className="form_default_steps">
                  <span>Koraci objave</span>
                  <ul>
                    <li className="active">1</li>
                    <li>2</li>
                    <li>3</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <label className="form_default_option w-100">
                  <input type="radio" name="type" onClick={() => handleRadio('prodaja')} defaultChecked />
                  <span>
                    <b>Prodaja</b>
                  </span>
                </label>
              </div>
              <div className="col-md-4">
                <label className="form_default_option w-100">
                  <input type="radio" name="type" onClick={() => handleRadio('kupovina')} />
                  <span>
                    <b>Kupovina</b>
                  </span>
                </label>
              </div>
              <div className="col-md-4">
                <label className="form_default_option w-100">
                  <input type="radio" name="type" onClick={() => handleRadio('iznajmljivanje')} />
                  <span>
                    <b>Iznajmljivanje</b>
                  </span>
                </label>
              </div>
            </div>
            {false && (
              <>
                <p>
                  Upišite 2-5 riječi koje opisuju vaš artikal. Na primjer: <b>Audi A4 karavan 1.9 TDI SPORTBACK 2017</b>
                </p>
                <label className="form_default_input">
                  <input type="text" placeholder="Upišite kratak opis" />
                </label>
              </>
            )}
          </div>
          <div className="jumbotron">
            {false && (
              <>
                <h2 className="title">Izaberite kategoriju</h2>
                <div className="separator"></div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="form_default_option w-100">
                      <input type="radio" name="cat" defaultChecked />
                      <span>Sugerisane kategorije</span>
                    </label>
                  </div>
                  <div className="col-md-6">
                    <label className="form_default_option w-100">
                      <input type="radio" name="cat" />
                      <span>Izaberite sami kategoriju</span>
                    </label>
                  </div>
                </div>
              </>
            )}

            <div className="container">
              <div className="row">
                <div className="search_categories col-md-3 col-6">
                  <List categories={categories} pickCategory={pickCategory} />
                </div>

                {!!subCategories.length && (
                  <div className="search_categories col-md-3 col-6">
                    <List subcategories={subCategories} pickSubcategory={pickSubcategory} />
                  </div>
                )}

                {!!subSubCategories.length && (
                  <div className="search_categories col-md-3 col-6">
                    <List subSubCategories={subSubCategories} pickSubSubCategory={pickSubSubCategory} />
                  </div>
                )}

                {!!stateSubSubSubCategories.length && (
                  <div className="search_categories col-md-3 col-6">
                    <List subSubSubCategories={stateSubSubSubCategories} pickSubSubSubCategory={pickSubSubSubCategory} />
                  </div>
                )}
              </div>
            </div>
            {!newArticle.subcategoryId && (
              <ul className="errors col-lg-6 mb-4">
                <li>Molimo Vas izaberite potkategoriju!</li>
              </ul>
            )}
            <div className="row">
              <div className="offset-md-3 col-md-6">
                <button disabled={isDisabled()} onClick={handleContinue} className="button w-100">
                  <b>Nastavite</b>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Objava1;

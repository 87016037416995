import React, {memo, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Post from '../components/post';
import Pagination from '../components/pagination';
import '../styles/category.scss';
import {useSelector, useDispatch} from 'react-redux';
import {compose} from '@reduxjs/toolkit';
import {RequestContext} from '../App';
import AsideCategory from '../components/aside-category';
import withSeo from '../components/seo';
import useDidMountEffect from '../hooks/useDidMountEffect';
import MobileFilters from '../components/mobileFilters';
import useQuery from '../hooks/useQuery';
import {filtersSlice} from '../store/reducers/filtersReducer';

function Kategorija({setTitle}) {
  const {api: socket} = useContext(RequestContext);
  const query = useQuery();

  const {id} = useParams();
  const dispatch = useDispatch();
  const {categories} = useSelector((state) => state.categories);
  const {filters} = useSelector((state) => state);
  const {filtered: articles} = useSelector((state) => state.articles);
  const currentCatgory = categories.find((category) => category.uuid === id);

  const [searchState, setSearchState] = useState({categoryId: currentCatgory?.id});
  const [count, setCount] = useState(20);
  const [order, setOrder] = useState('newest');
  const [page, setPage] = useState(query.get('page') ?? 1);

  const [openModalFilter, setOpenModalFilter] = useState();

  useEffect(() => {
    setTitle(currentCatgory?.name);
    const startingData = async (categories) => {
      const catId = categories.find((category) => category.uuid === id);
      dispatch(filtersSlice.actions.addCategory(catId.id));
      await socket.getFilteredByCategory({...filters, where: {...filters.where, categoryId: catId?.id}}, dispatch, 'setFiltered');
    };
    if (!!categories.length) startingData(categories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, categories.length]);

  const handleSetPage = (value) => {
    setPage(value);
    handleSearch();
  };

  const handleSearch = async (e) => {
    e?.preventDefault();
    await socket.getFilteredByCategory(
      {
        ...filters,
      },
      dispatch,
      'setFiltered'
    );
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: document.querySelector('.container').clientHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const closeModalFilters = () => {
    setOpenModalFilter(false);
  };

  const handleOrder = (e) => {
    setOrder(e.target.value);
    setPage(1);
    dispatch(filtersSlice.actions.updateOrder(e.target.value));
    handleSearch();
  };

  const handlePerPage = (e) => {
    setCount(e.target.value);
    setPage(1);
    dispatch(filtersSlice.actions.updatePerPage(+e.target.value));
    handleSearch();
  };

  useDidMountEffect(() => {
    scrollToTop();
  }, [page]);

  const filterMobile = (e) => {
    e.preventDefault();
    setOpenModalFilter(true);
  };
  useEffect(() => {
    if (query.get('page')) setPage(query.get('page'));
  }, [query.get('page')]);

  return (
    <>
      <section className="section category">
        <div className="container">
          <div className="row row_20">
            <div className="col-md-5 category_results"></div>
            <div className="col-md-7 category_options">
              <div className="category_option">
                <label>Sortiraj:</label>
                <select value={filters?.order ?? order} onChange={handleOrder}>
                  <option value={'newest'}>Najnovije</option>
                  <option value={'oldest'}>Najstarije</option>
                  <option value={'lowest'}>Cijena - najnižoj</option>
                  <option value={'highest'}>Cijena - najvišoj</option>
                </select>
              </div>
              <div className="category_option">
                <label>Prikaži:</label>
                <select value={filters?.payload?.count ?? count} onChange={handlePerPage}>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 category_left">
              <button className="category_aside_toggle" onClick={filterMobile}>
                <img src="/images/filter.svg" alt="filter" /> Filtriraj rezultate
              </button>
              <AsideCategory
                count={count}
                order={order}
                currentPage={page}
                setPage={setPage}
                setSearchState={setSearchState}
                active={openModalFilter}
                handleSearch={handleSearch}
              />
            </div>
            <div className="col-lg-9 col-md-8 category_right">
              <div className="row row_20 posts_4">
                {articles?.articles?.map((article) => (
                  <Post
                    key={article.id}
                    classProps="col no-wrap"
                    articleprops={{...article}}
                    categories={categories}
                    setOpenModalFilter={setOpenModalFilter}
                  />
                ))}
              </div>
              {!!articles?.total && (
                <Pagination articles={articles} setPage={handleSetPage} page={page} perPage={count} catId={currentCatgory?.id} />
              )}
            </div>
          </div>
        </div>
      </section>

      {!!openModalFilter && (
        <MobileFilters
          isOpen={openModalFilter}
          onRequestClose={closeModalFilters}
          setSearchState={setSearchState}
          setPage={setPage}
          count={count}
          handleSearch={handleSearch}
          searchState={searchState}
        />
      )}
    </>
  );
}

export default compose(memo, withSeo)(Kategorija);

import {Link, useLocation} from 'react-router-dom';

export default function ProfileList({active, sold, uuid}) {
  const location = useLocation();
  return (
    <ul className="profile_list">
      <li>
        <Link to={{pathname: `/aktivne-objave/${uuid}`, state: {prevPath: location.pathname}}}>
          <img src="/images/list_active.svg" alt="icon" />
          Aktivne objave
          <span className="badge">{active?.length || 0}</span>
        </Link>
      </li>
      <li>
        <Link to={{pathname: `/zavrsene-objave/${uuid}`, state: {prevPath: location.pathname}}}>
          <img src="/images/list_finished.svg" alt="icon" />
          Kupljeno/prodato
          <span className="badge">{sold?.length || 0}</span>
        </Link>
      </li>
      {/* <li>
        <Link to="/aktivne-objave">
          <img src="/images/list_bought.svg" alt="icon" />
          Kupljeni artikli
          <span className="badge">10</span>
        </Link>
      </li> */}
    </ul>
  );
}

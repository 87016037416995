import {useContext, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {RequestContext} from '../App';
import withSeo from '../components/seo';
import {isDisabled} from '../helpers/utilities';
import '../styles/login.scss';

function Login({setTitle}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const {api: socket} = useContext(RequestContext);
  const [loginData, setLoginData] = useState({
    userData: '',
    password: '',
  });
  const requiredValues = ['userData', 'password'];
  const onSubmit = async (e) => {
    e.preventDefault();
    await socket.login({...loginData}, dispatch, history.push, history.location.location, '/potvrda-email');
  };
  const handleChange = (e) => {
    setLoginData({...loginData, [e.target.name]: e.target.value});
  };

  const showPassword = (e) => {
    const passwordField = document.querySelector('.show-password');
    if (passwordField.type === 'password') {
      passwordField.type = 'text';
    } else {
      passwordField.type = 'password';
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && loginData?.password?.length > 5) {
      onSubmit(e);
    }
  };

  useEffect(() => {
    setTitle('Prijava');
  }, []);

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="jumbotron">
            <h2 className="title">Login - Prijava</h2>
            <div className="separator"></div>
            <div className="row">
              <div className="col-lg-6">
                <h5 className="form_default_label">Korisničko ime ili email</h5>
                <label className="form_default_input form_default_w_icon">
                  <input type="text" onChange={handleChange} name="userData" placeholder="Korisničko ime ili email" />
                  <img src="/images/icon_user.svg" alt="user" />
                </label>
              </div>
              <div className="col-lg-6">
                <h5 className="form_default_label">Vaša šifra</h5>
                <label className="form_default_input form_default_w_icon">
                  <input
                    type="password"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    className="show-password"
                    name="password"
                    placeholder="Lozinka"
                  />
                  <img src="/images/show_password.svg" alt="lock" onClick={showPassword} />
                </label>
              </div>
            </div>
            <div className="row row_10">
              <div className="col-6 text-left">
                <Link to="/zaboravljena-sifra" className="form_default_link">
                  Zaboravljena šifra?
                </Link>
              </div>
              <div className="col-lg-6">
                <Link to="/uslovi-koristenja" className="form_default_link">
                  Uslovi korištenja
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <button
                  type="button"
                  disabled={isDisabled(loginData, requiredValues) || loginData?.password?.length < 6}
                  onClick={onSubmit}
                  className="button w-100"
                >
                  <b>Prijavite se</b>
                </button>
              </div>
              <div className="col-lg-6">
                <Link to="/registracija" className="button button_light w-100">
                  Nemate nalog? Registrujte se
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withSeo(Login);

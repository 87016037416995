import {useContext, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {RequestContext} from '../App';
import TextEditor from '../components/textEditor';
import {removeEmpty} from '../helpers/utilities';
import {newArticleSlice} from '../store/reducers/newArticleReducers';

function Objava4() {
  const {api: socket} = useContext(RequestContext);
  const newArticle = useSelector((state) => state.newArticle);
  const {
    user: {token},
  } = useSelector((state) => state.auth);
  const {images: uploadedImages} = useSelector((state) => state.images);
  const dispatch = useDispatch();
  const history = useHistory();
  const placeholder = 'Po želji doradite dodatne uslove prodaje. Tekst će ostati spašen tako da ga možete koristiti za buduće artikle.';

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const data = removeEmpty(newArticle);
    data.cityId = data.locationId;
    if (data.featured === 'on') data.featured = true;
    if (data.discounted === 'on') data.discounted = true;
    if (data.exchange === 'on') data.exchange = true;
    await socket.createArticle({...data, token, images: Array.from(new Set(uploadedImages))}, dispatch, history.push, '/detail/', token);
  };

  const handleChange = (description) => {
    dispatch(
      newArticleSlice.actions.updateState({
        ...newArticle,
        description,
      })
    );
  };

  const goToFirstPage = () => {
    history.push('/objava-1');
  };

  const isDisabled = () => {
    if (!newArticle || newArticle.description === '"<p><br></p>"') {
      return true;
    }
    return !!!newArticle?.description?.length;
  };

  useEffect(() => {
    if (!newArticle || !newArticle.categoryId) {
      goToFirstPage();
    }
  }, []);

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="jumbotron">
            <div className="row mb-3">
              <div className="col-lg-6">
                <h2 className="title">Dodatne informacije</h2>
              </div>
              <div className="col-lg-6 text-right">
                <div className="form_default_steps">
                  <span>Koraci objave</span>
                  <ul>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li className="active">4</li>
                  </ul>
                </div>
              </div>
            </div>
            <h3>Dodatne informacije</h3>
            <p>Po želji doradite dodatne uslove prodaje. Tekst će ostati spašen tako da ga možete koristiti za buduće artikle.</p>
            <TextEditor changeHandler={handleChange} placeholder={placeholder} />
            {(newArticle?.description?.length === 0 || newArticle.description === '"<p><br></p>"') && (
              <ul className="errors col-lg-6 mb-4">
                <li>Unesite opis artikla!</li>
              </ul>
            )}
            <div className="row">
              <div className="col-md-6">
                <Link to="/objava-3" className="button button_light w-100">
                  Korak nazad
                </Link>
              </div>
              <div className="col-md-6">
                <button onClick={handleFormSubmit} disabled={isDisabled()} className="button w-100">
                  <b>Objavi!</b>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Objava4;

import {Link, useHistory} from 'react-router-dom';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import {RequestContext} from '../App';
import {useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {SMALL_IMAGE} from '../api/apiBase';

export default function HeaderUser() {
  const history = useHistory();
  const {buttonProps, isOpen, setIsOpen} = useDropdownMenu();
  const {currentUser, user} = useSelector((state) => state.auth);
  const {api: socket} = useContext(RequestContext);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    setIsOpen(false);
    await socket.logout({uuid: currentUser.uuid, id: currentUser.id}, dispatch, history.push, '/');
    // socket.addToken(null);
  };
  const close = () => {
    setIsOpen(false);
  };
  return (
    <div className="header_user">
      <button {...buttonProps}>
        <img src={currentUser.profileImage ? SMALL_IMAGE + currentUser.profileImage : '/images/user_placeholder.svg'} alt="user" />
        <span>{currentUser?.companyName ?? currentUser.userName}</span>
      </button>
      <div className={'default_dropdown right ' + (isOpen ? 'visible' : '')} role="menu">
        <Link onClick={close} to="/moj-profil">
          Moj profil
        </Link>
        <Link onClick={close} to="/postavke">
          Postavke
        </Link>
        <Link onClick={close} to={'/favoriti/' + user.id}>
          Oglasi koje pratim
        </Link>
        {/* <Link onClick={close} to="#">
          Privatnost
        </Link> */}
        <Link onClick={handleLogout} to="/login">
          Odjava
        </Link>
      </div>
    </div>
  );
}

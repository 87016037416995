import {useState, useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {MEDIUM_IMAGE} from '../api/apiBase';
import {RequestContext} from '../App';
import Modal from './modal';

function ProfileHead(props) {
  const {userProfile, currentUser, user} = useSelector((state) => state.auth);
  const isMyProfile = Object.keys(userProfile).length === 0;
  const image = isMyProfile
    ? currentUser?.profileImage
      ? MEDIUM_IMAGE + currentUser?.profileImage
      : '/images/user_placeholder.svg'
    : userProfile?.profileImage
    ? MEDIUM_IMAGE + userProfile?.profileImage
    : '/images/user_placeholder.svg';

  const [isOpen, setIsOpen] = useState(false);
  const {api: socket} = useContext(RequestContext);
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleMessage = (e) => {
    e.preventDefault();
    if (!message.length) return;
    socket.createMessage(
      {senderId: currentUser.id, recieverId: userProfile.id, body: message, title: 'Privatna poruka'},
      dispatch,
      user?.token
    );

    closeModal();
  };

  const handleUpload = async (e) => {
    const image = e.target.files[0];
    const imageForm = new FormData();
    imageForm.append('image', image);
    await socket.editProfileImage(
      {
        imageForm,
        userId: currentUser.uuid,
        userEmail: currentUser.email,
        userName: currentUser.userName,
        name: currentUser.name,
        telephone: currentUser.telephone,
        address: currentUser.address,
        cityId: currentUser.cityId,
        companyName: currentUser.companyName,
        companyPin: currentUser.companyPin,
        vat: currentUser.vat,
      },
      dispatch,
      user?.token
    );
  };

  return (
    <div className="profile_head" style={{backgroundImage: 'url(images/' + (props.myProfile ? 'myprofile_bg' : 'user_bg.') + '.jpg)'}}>
      <div className="container">
        <div className="profile_head_image mt-4">
          {window.location.pathname === '/postavke' ? (
            <>
              <div className="detail_slider_circle svg_edit">
                <img src="/images/edit.svg" alt="edit" />
              </div>
              <input type="file" className="file-input" onChange={handleUpload} />
            </>
          ) : (
            ''
          )}

          <figure>
            <img src={image} alt="user" />
          </figure>
        </div>

        <h1>{props.username}</h1>

        {props.myProfile ? null : (
          <div className="profile_head_buttons">
            <button className="button" onClick={openModal}>
              Pošaljite poruku
            </button>
          </div>
        )}
      </div>
      <Modal onRequestClose={closeModal} isOpen={isOpen}>
        <div className="col-md-12">
          <label className="form_default_input">
            <textarea type="text" placeholder="Molimo upišite poruku korisniku." onChange={(e) => setMessage(e.target.value)}></textarea>
            <button className="button w-100" onClick={handleMessage}>
              Pošalji poruku
            </button>
          </label>
        </div>
      </Modal>
    </div>
  );
}

export default ProfileHead;

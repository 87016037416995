import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  token: undefined,
  password: undefined,
  email: undefined,
};

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      return state;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
      return state;
    },
    resetState: (state) => {
      state = {...initialState};
      return state;
    },
  },
});

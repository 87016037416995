import {useContext, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import Modal from './modal';
import {RequestContext} from '../App';
import '../styles/components/footer.scss';
function Footer() {
  const user = useSelector((state) => state.auth.user);
  const categories = useSelector((state) => state.categories.categories);
  const [openModal, setOpenModal] = useState(false);
  const [question, setQuestion] = useState({email: '', question: ''});
  const closeModal = () => setOpenModal(false);
  const {api: socket} = useContext(RequestContext);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setQuestion((prev) => ({...prev, [e.target.name]: e.target.value}));
  };

  const handleQuestion = async (e) => {
    e.preventDefault();
    if (!question.email.length || !question.question.length) return;
    await socket.askQuestion(question, dispatch);
    setQuestion({email: '', question: ''});
    setOpenModal(false);
  };

  return (
    <>
      <footer className="footer">
        <div className="container footer_wrapper">
          <div className="row">
            {!user.token && (
              <div className="col-lg-3">
                <h5>Korisnik</h5>
                <ul>
                  <li>
                    <Link to="/login">Login - Prijava</Link>
                  </li>
                  <li>
                    <Link to="/zaboravljena-sifra">Zaboravljena šifra</Link>
                  </li>
                  <li>
                    <Link to="/registracija">Registracija</Link>
                  </li>
                </ul>
              </div>
            )}
            <div className="col-lg-3">
              <h5>Artikli</h5>
              <ul>
                <li>
                  <Link to="/objava-1">Objava</Link>
                </li>
              </ul>
            </div>
            <div className={`col-lg-${user.token ? '6' : '3'}`}>
              {' '}
              <h5>Kategorije</h5>
              <ul className="footer_categories">
                {categories.map((category) => (
                  <li key={category.uuid}>
                    <Link to={`/kategorija/${category.uuid}`}>{category.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-lg-3">
              <h5>Lista</h5>
              <ul>
                <li>
                  <button role="link" href="#" onClick={() => setOpenModal(true)}>
                    Kontakt
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer_copyright">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-6">
                <p>
                  Copyright © 2022 <b>Lista.ba</b> Sva prava zadržana.
                </p>
              </div>
              <div className="col-md-6">
                <ul>
                  <li>
                    <Link to="/uslovi-koristenja">Uslovi korištenja</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <Modal onRequestClose={closeModal} isOpen={openModal}>
        <div className="col-md-12">
          <label className="form_default_input mb-2">
            <input type="email" placeholder="Molimo upišite vas email." name="email" value={question.email} onChange={handleChange} />
          </label>
          <label className="form_default_input">
            <textarea
              type="text"
              placeholder="Ukoliko imate pitanje, možete nas kontaktirati putem ove forme, a mi ćemo se potruditi da vam odgovorimo u najkraćem roku!"
              name="question"
              onChange={handleChange}
            >
              {question.question}
            </textarea>
            <button className="button w-100" onClick={handleQuestion}>
              Postavi pitanje
            </button>
          </label>
        </div>
      </Modal>
    </>
  );
}

export default Footer;

import React, {memo, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Post from '../components/post';
import Pagination from '../components/pagination';
import '../styles/category.scss';
import {useSelector, useDispatch} from 'react-redux';
import {RequestContext} from '../App';
import AsideSubCategory from '../components/aside-subcategory';
import useDidMountEffect from '../hooks/useDidMountEffect';
import MobileFilters from '../components/mobileFilters';
import useQuery from '../hooks/useQuery';

function Potkategorija() {
  const {api: socket} = useContext(RequestContext);
  const {id, subCategoryId} = useParams();
  const query = useQuery();
  const dispatch = useDispatch();
  const {categories} = useSelector((state) => state.categories);
  const {requiredAttributes} = useSelector((state) => state.attributes);
  const {filtered: articles} = useSelector((state) => state.articles);
  const currentSubcategory = categories.find(({uuid}) => uuid === id)?.subcategories.filter(({uuid}) => uuid === subCategoryId)[0];
  const [searchState, setSearchState] = useState({});

  const [currentSubSubCategory, setCurrentSubSubCategory] = useState();
  const [currentSubSubSubCategory, setcurentSubSubSubCategory] = useState();

  const [openModalFilter, setOpenModalFilter] = useState();

  const [count, setCount] = useState(20);
  const [order, setOrder] = useState('newest');
  const [page, setPage] = useState(query.get('page') ?? 1);
  useEffect(() => {
    const getMyApi = async () => {
      if (currentSubcategory) {
        await socket.getFilteredByCategory(
          {
            where: {
              subcategoryId: currentSubcategory.id,
            },
            payload: {page, count},
            order,
          },
          dispatch,
          'setFiltered',
          'category'
        );

        await socket.getRequiredAttributes(currentSubcategory.id, currentSubSubCategory, currentSubSubSubCategory, dispatch);
      }
    };
    getMyApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategoryId, JSON.stringify(currentSubcategory), page]);

  const handleSearch = async (e, updatedState = {}) => {
    e?.preventDefault();
    await socket.getFilteredByCategory(
      {
        where: {
          ...searchState,
          subcategoryId: currentSubcategory?.id,
          subsubcategoryId: currentSubSubCategory,
          subsubsubcategoryId: currentSubSubSubCategory,
          ...updatedState,
        },
        payload: {page, count},
        order,
      },
      dispatch,
      'setFiltered',
      'category'
    );
  };

  const closeModalFilters = () => {
    setOpenModalFilter(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: document.querySelector('.container').clientHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  useDidMountEffect(() => {
    scrollToTop();
  }, [page]);

  const filterMobile = (e) => {
    e.preventDefault();
    setOpenModalFilter(true);
  };

  useEffect(() => {
    if (query.get('page')) setPage(query.get('page'));
  }, [query.get('page')]);
  return (
    <>
      <section className="section category">
        <div className="container">
          <div className="row row_20">
            <div className="col-md-5 category_results"></div>
            <div className="col-md-7 category_options">
              <div className="category_option">
                <label>Sortiraj:</label>
                <select
                  value={order}
                  onChange={(e) => {
                    setOrder(e.target.value);
                    setPage(1);
                  }}
                >
                  <option value={'newest'}>Najnovije</option>
                  <option value={'oldest'}>Najstarije</option>
                  <option value={'lowest'}>Cijena - najnižoj</option>
                  <option value={'highest'}>Cijena - najvišoj</option>
                </select>
              </div>
              <div className="category_option">
                <label>Prikaži:</label>
                <select
                  value={count}
                  onChange={(e) => {
                    setCount(e.target.value);
                    setPage(1);
                  }}
                >
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 category_left">
              <button className="category_aside_toggle" onClick={filterMobile}>
                <img src="/images/filter.svg" alt="filter" /> Filtriraj rezultate
              </button>
              <AsideSubCategory
                count={count}
                order={order}
                currentPage={page}
                setPage={setPage}
                subCatId={currentSubcategory?.id}
                setSearchState={setSearchState}
                searchState={searchState}
                requiredAttributes={requiredAttributes}
                setCurrentSubSubCategory={setCurrentSubSubCategory}
                setcurentSubSubSubCategory={setcurentSubSubSubCategory}
                isActive={openModalFilter}
                handleSearch={handleSearch}
              />
            </div>
            <div className="col-lg-9 col-md-8 category_right">
              <div className="row row_20 posts_4">
                {articles?.articles?.map((article) => (
                  <Post
                    key={article.id}
                    classProps="col no-wrap"
                    articleprops={{...article}}
                    categories={categories}
                    setOpenModalFilter={setOpenModalFilter}
                  />
                ))}
              </div>
              {!!articles?.total && (
                <Pagination articles={articles} setPage={setPage} page={page} perPage={count} subCatId={currentSubcategory?.id} />
              )}
            </div>
          </div>
        </div>
      </section>

      {!!openModalFilter && (
        <MobileFilters
          isOpen={openModalFilter}
          onRequestClose={closeModalFilters}
          setSearchState={setSearchState}
          setPage={setPage}
          count={count}
          requiredAttributes={requiredAttributes}
          setCurrentSubSubCategory={setCurrentSubSubCategory}
          setcurentSubSubSubCategory={setcurentSubSubSubCategory}
          handleSearch={handleSearch}
          searchState={searchState}
        />
      )}
    </>
  );
}

export default memo(Potkategorija);

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  email: undefined,
};

export const newUserSlice = createSlice({
  name: 'newUser',
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
      return state;
    },
    resetState: (state) => {
      state = initialState;
      return state;
    },
  },
});

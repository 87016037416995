import {useSelector, useDispatch} from 'react-redux';
import {useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import withSeo from '../components/seo';
import {newArticleSlice} from '../store/reducers/newArticleReducers';
import {categoriesSlice} from '../store/reducers/categoriesReducer';

export const initialState = {
  userId: '',
  locationId: '',
  categoryId: '',
  subcategoryId: '',
  featured: '',
  conditions: '',
  price: '',
  description: '',
  title: '',
  articleType: '',
  exchange: '',
  urgent: '',
  negotable: '',
  fixed: '',
  category: '',
  subtitle: '',
  attributes: [],
};
function Objava({setTitle}) {
  const isGuestUser = !!useSelector((state) => state.auth.user.token);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    setTitle('Objava artikla');
    dispatch(newArticleSlice.actions.resetState());
    dispatch(categoriesSlice.actions.resetNewArticleState());
  }, []);
  const handleChoice = (e, value) => {
    e.preventDefault();
    dispatch(newArticleSlice.actions.updateState({...initialState, articleType: value}));
    setTimeout(history.push, 100, '/objava-1');
  };
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 article_type">
              <a href="/" onClick={(e) => handleChoice(e, 'prodaja')}>
                <h3>Prodaja/Kupovina/Iznajmljivanje</h3>
                <div>
                  <p>Želim da prodam/kupim/iznajmim artikl.</p>
                  <button className="blue">+</button>
                </div>
              </a>
            </div>
            <div className="col-md-6 article_type">
              <a href="/" onClick={(e) => handleChoice(e, 'posao')}>
                <h3>Posao</h3>
                <div>
                  <p>Želim da nađem posao ili da zaposlim.</p>
                  <button className="orange">+</button>
                </div>
              </a>
            </div>
            <div className="col-md-6 article_type">
              <a href="/" onClick={(e) => handleChoice(e, 'usluge')}>
                <h3>Servis ili usluga</h3>
                <div>
                  <p>Nudim raličite servise ili usluge</p>
                  <button className="red">+</button>
                </div>
              </a>
            </div>
          </div>
          {!isGuestUser && (
            <div className="jumbotron">
              <h2>Morate biti prijavljeni da objavite artikal na Lista.ba</h2>
              <div className="separator"></div>
              <Link to="/login" className="button_w_icon header_add">
                <span>
                  <img src="/images/user.svg" alt="user" />
                </span>
                PRIJAVITE SE
              </Link>
              <Link to="/registracija" className="button_w_icon button_w_icon_green header_add">
                <span>
                  <img src="/images/user.svg" alt="user" />
                </span>
                NAPRAVITE PROFIL
              </Link>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default withSeo(Objava);

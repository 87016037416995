import {useContext, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {RequestContext} from '../App';
import Post from '../components/post';

export default function Favoriti() {
  const {id} = useParams();
  const {favorites} = useSelector((state) => state.articles);
  const {user} = useSelector((state) => state.auth);
  const {api: socket} = useContext(RequestContext);
  const dispatch = useDispatch();

  useEffect(() => {
    const get = async () => {
      user?.token && (await socket.getFavorites({userId: id}, dispatch, user.token));
    };
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="title">Oglasi koje pratim</h2>
            </div>
          </div>
          <div className="separator"></div>
          <div className="row row_20 posts_5">
            {favorites.length > 0 &&
              favorites.map((article) => (
                // <div className="col" key={article.uuid}>
                <Post key={article.uuid} articleprops={{...article}} />
                // </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.scss';

import {LightgalleryItem} from 'react-lightgallery';

import {LARGE_IMAGE} from '../api/apiBase';

export const Sw = ({images}) => {
  return (
    <>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        speed={500}
        loop={false}
        touchRatio={1.5}
        navigation={false}
        effect={'flip'}
        pagination={{clickable: true}}
        className="mySwiper"
      >
        {images.map((image) => (
          <SwiperSlide key={image.uuid}>
            <LightgalleryItem key={image.uuid} src={LARGE_IMAGE + image.url} group="images">
              <img src={LARGE_IMAGE + image.url} alt="" />
            </LightgalleryItem>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

import {useSelector} from 'react-redux';
import {Spinner} from 'react-spinners-css';

export default function Loading() {
  const isLoading = useSelector((state) => state.common.isLoading);
  return isLoading ? (
    <div className="loader-holder">
      <Spinner size={200} color={'#0d1931'} />
    </div>
  ) : null;
}

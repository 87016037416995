import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {Route} from 'react-router-dom';
import {commonSlice} from '../store/reducers/commonReducer';
export const isGuestUser = () => !!localStorage.getItem('token');

export function PrivateRoute({component: Component, onlyAdmin = false, ...rest}) {
  // eslint-disable-next-line
  const isLoggedIn = !!useSelector((state) => state.auth.user.token) || isGuestUser();
  const isAdmin = useSelector((state) => state.auth.user.role === 'admin');
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (onlyAdmin && isLoggedIn && isAdmin) {
          return <Component {...props} />;
        } else if (onlyAdmin && !isAdmin) {
          return history.push('/');
        }
        if (isLoggedIn) {
          return <Component {...props} />;
        } else {
          setTimeout(() => {
            dispatch(
              commonSlice.actions.setNotification({
                message: 'Niste prijavljeni, molimo da se prijavite',
                type: 'error',
              })
            );
          }, 50);
          return history.push('/login');
        }
      }}
    ></Route>
  );
}
export function OnlyPublicRoute({component: Component, rest}) {
  // eslint-disable-next-line
  const isGuest = useSelector((state) => state.auth.user.token) == undefined;
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isGuest) {
          return <Component {...props} />;
        } else {
          return history.push('/');
        }
      }}
    ></Route>
  );
}

export const useUnconfirmedHandler = () => {
  const dispatch = useDispatch();
  return {
    message: () =>
      dispatch(commonSlice.actions.setNotification({message: 'Niste potvridli vas nalog, molimo da potvrdite', type: 'warning'})),
  };
};

export const isCurrentUser = (user, currentUser) => user?.uuid === currentUser?.uuid;

export const isCompany = (role) => role === 'pravno_lice' || role === 'prodavnica';

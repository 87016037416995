import {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {RequestContext} from '../App';
import {v4 as uuid} from 'uuid';
import {CheckBox, InputNumber, Options} from '../components/formInputs';
import {newArticleSlice} from '../store/reducers/newArticleReducers';
import useDidMountEffect from '../hooks/useDidMountEffect';
import {excludeNewOrUsed} from '../helpers/utilities';

function Objava2() {
  const history = useHistory();
  const {api: socket} = useContext(RequestContext);
  const {user} = useSelector((state) => state.auth);
  const {subcategoryId, subsubcategoryId, subsubsubcategoryId} = useSelector((state) => state.newArticle);
  const attributes = useSelector((state) => state.attributes.attributes) || [];
  const {locations} = useSelector((state) => state.locations) || [];
  const newArticle = useSelector((state) => state.newArticle) || {};
  const [state, setState] = useState({...newArticle});
  const dispatch = useDispatch();
  const requiredAttributes = attributes?.filter((attr) => attr.required === true);

  const [models, setModels] = useState();
  const [selectedModel, setSelectedModel] = useState();

  const [attrError, setAttrError] = useState({
    flag: false,
    message: '',
  });

  useEffect(() => {
    const get = async () => {
      if (!newArticle || !newArticle.categoryId) {
        goToFirstPage();
      }
      await socket.getAttributes(
        {parentId: subcategoryId, subSubCategoryId: subsubcategoryId, subsubSubCategoryId: subsubsubcategoryId},
        dispatch
      );
    };
    checkRequiredAttributes();
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidMountEffect(() => {
    checkRequiredAttributes();
  }, [requiredAttributes.length, state]);

  const handleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
    dispatch(newArticleSlice.actions.updateState({...newArticle, [e.target.name]: e.target.value}));
  };

  const handleContinue = (e) => {
    e.preventDefault();
    history.push('/objava-3');
  };

  const goToFirstPage = () => {
    history.push('/objava-1');
  };

  const checkRequiredAttributes = () => {
    let temp = true;

    for (let attr of requiredAttributes) {
      const isValid = state.attributes.find((atr) => Number(atr.attributeValueId) === Number(attr.id));
      if (!isValid) {
        temp = false;
        break;
      } else {
        temp = true;
      }
    }

    if (!temp) {
      setAttrError({flag: true, message: 'Molimo Vas da popunite sva obavezna polja!'});
    } else {
      setAttrError({flag: false, message: ''});
    }
  };

  const addArticles = (e) => {
    const addAttribute = newArticle.attributes.filter((attribute) => attribute.name !== e.target.name);
    const attributeExists = newArticle.attributes.find((attribute) => attribute.name === e.target.name);
    if (!attributeExists || (e.target.value.length && attributeExists.value !== e.target.value)) {
      if (e.target.name === 'Model') {
        addAttribute.push({
          name: e.target.name,
          value: e.target.value,
          attributeId: e.target.getAttribute('parentid'),
          attributeValueId: e.target.getAttribute('parentid'),
          orderNumber: e.target.getAttribute('orderid'),
        });
      } else {
        addAttribute.push({
          name: e.target.name,
          value: e.target.value,
          attributeId: e.target.getAttribute('parentid'),
          attributeValueId: e.target.getAttribute('attrid'),
          orderNumber: e.target.getAttribute('orderid'),
        });
      }
    }
    dispatch(
      newArticleSlice.actions.updateState({
        ...newArticle,
        attributes: [...addAttribute],
      })
    );

    setState({...state, attributes: [...addAttribute]});
  };

  const buttonDisabled = () => {
    if (newArticle?.title?.length && !attrError.flag) {
      return false;
    } else {
      return true;
    }
  };

  const generateOptions = (option) => {
    const name = option.required ? `${option.name}*` : option.name;
    const type = option.type;
    if (type === 'number' || type === 'text') {
      return (
        <InputNumber
          name={name}
          handleChange={addArticles}
          value={newArticle.attributes?.find((attribute) => attribute.name === name)?.value ?? ''}
          parentid={option.parentId}
          attrid={option.id}
          orderid={option.orderNumber}
          min={0}
        />
      );
    } else if (type === 'checkbox') {
      return (
        <CheckBox
          key={uuid()}
          name={name}
          clickHandler={addArticles}
          parentid={option.parentId}
          attrid={option.id}
          orderid={option.orderNumber}
          checked={newArticle.attributes?.find((attribute) => attribute.name === name)?.value === 'on'}
        />
      );
    } else if (type === 'select') {
      return (
        <Options
          key={uuid()}
          name={name}
          value={option.values}
          onChange={addArticles}
          parentid={option.parentId}
          attrid={option.id}
          orderid={option.orderNumber}
          selected={newArticle.attributes?.find((attribute) => attribute.name === name)?.value}
          models={models}
          setModels={setModels}
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
        />
      );
    }
  };

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="jumbotron">
            <div className="row mb-3">
              <div className="col-lg-6">
                <h2 className="title">Informacije o artiklu</h2>
              </div>
              <div className="col-lg-6 text-right">
                <div className="form_default_steps">
                  <span>Koraci objave</span>
                  <ul>
                    <li>1</li>
                    <li className="active">2</li>
                    <li>3</li>
                  </ul>
                </div>
              </div>
            </div>
            <h3>Naslov artikla</h3>
            <p>Upišite naslov vašeg artikla, to je naslov koji će korisnici vidjeti prilikom pretrage.</p>
            <label className="form_default_input">
              <input type="text" maxLength={50} placeholder="Upišite naslov" name="title" value={state.title} onChange={handleChange} />
            </label>
            <br />
            {!excludeNewOrUsed(newArticle.categoryId, subcategoryId, subsubcategoryId, subsubsubcategoryId) && (
              <>
                <h5>Stanje artikla</h5>
                <label className="form_default_select">
                  <select name="conditions" onChange={handleChange}>
                    <option value="polovno">Polovno</option>
                    <option value="novo">Novo</option>
                  </select>
                </label>
              </>
            )}
            <label className="form_default_select">
              <h5>Lokacija artikla</h5>
              <select name="locationId" onChange={handleChange} value={newArticle?.locationId || user?.cityId}>
                {locations.map((location) => (
                  <option value={location.id}>{location.name}</option>
                ))}
              </select>
            </label>
            <br />
            {attributes?.length > 0 && (
              <div>
                <h3>Detaljne informacije</h3>
                <div className="separator"></div>
              </div>
            )}
            <div className="row mb-3">{attributes.map((attribute) => generateOptions(attribute))}</div>
            {newArticle?.title?.length === 0 && (
              <ul className="errors col-lg-6 mb-4">
                <li>Molimo Vas unesite naslov artikla!</li>
              </ul>
            )}
            {!!attrError.message.length && (
              <ul className="errors col-lg-6 mb-4">
                <li>{attrError?.message}</li>
              </ul>
            )}
            <div className="row">
              <div className="col-md-6">
                <Link to="/objava-1" className="button button_light w-100">
                  Korak nazad
                </Link>
              </div>
              <div className="col-md-6">
                <button onClick={handleContinue} disabled={buttonDisabled()} className="button w-100">
                  <b>Nastavite</b>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Objava2;

import {withScriptjs, withGoogleMap, GoogleMap, Marker} from 'react-google-maps';
import Geocode from 'react-geocode';

Geocode.setApiKey('AIzaSyCL6FlEkfgtdHJeUaViEu2vxGEYQUzt-3g');

export default function MapContainer() {
  const onMarkerDragEnd = (event) => {};

  const MapWithAMarker = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap defaultZoom={8} defaultCenter={{lat: 44.75874, lng: 19.21437}}>
        <Marker draggable={true} onDragEnd={onMarkerDragEnd} position={{lat: 44.75874, lng: 19.21437}}></Marker>
      </GoogleMap>
    ))
  );

  return (
    <MapWithAMarker
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCL6FlEkfgtdHJeUaViEu2vxGEYQUzt-3g&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{height: `100%`}} />}
      containerElement={<div style={{height: `400px`}} />}
      mapElement={<div style={{height: `100%`}} />}
    />
  );
}

import Underhead from '../components/underhead';

function UseOfTerms() {
  return (
    <>
      <Underhead title="Uslovi korištenja" items={[{link: '', title: 'Uslovi korištenja'}]} />
      <div className="section">
        <div className="container">
          <div className="row text_only">
            <div className="col-12">
              <h2>
                Pažljivo pročitajte Uslove korištenja (u daljem tekstu:Uslovi). Lista.ba zadržava pravo promjene Uslova bez prethodnog
                obavještenja ili najave.
              </h2>
              <h3>Uslovi korištenja</h3>
              <h4>Član 1.</h4>
              <p>
                Svako ko koristi web stranice www.lista.ba i/ili prateće aplikacije (android i IOS) u daljem tekstu Platforma, i bez obzira
                da li je registrovan ili ne, smatra se Korisnikom usluga Platforme u daljem tekstu Korisnik, i na svakog Korisnika se
                primjenjuju svi uslovi.
              </p>
              <h4>Član 2.</h4>{' '}
              <p>
                Svaki Korisnik, koji koristi Platformu, prihvata bez pogovora sve Uslove korištenja, a ako ne želi onda je dužan da prekine
                posjećivanje/korištenje Platforme. Osim Uslova, na svakog Korisnika se primjenju i svi važeći zakoni na teritoriji Bosne i
                Hercegovine.
              </p>{' '}
              <h4>Član 3.</h4>{' '}
              <p>
                Ukoliko Korisnik, osim pregledavanja oglasa i kupovine, želi i da postavi svoj oglas, mora se registrovati, i tom prilikom
                popuniti svoje lične podatke i podatke za kontakt (Ime i prezime, grad, telefon). Za istinitost i tačnost ostavljenih
                podataka prilikom registracije, garantuje i odgovoran je Korisnik.
              </p>{' '}
              <h4>Član 4.</h4>{' '}
              <p>
                Platforma tj. njen vlasnik Lista.BA D.O.O. u daljem tekstu Lista.ba nije odgovorna niti dužna provjeravati
                istinitost/tačnost/vjerodostojnost/zakonitost objava, u vidu teksta, slika, sadržaja sa drugih web lokacija koje su sa
                Platformom povezane putem linka a koje objavljuje Korisnik prilikom korištenja Platforme a kod unosa oglasa.
              </p>{' '}
              <h4>Član 5.</h4>
              <p>
                Korisnici na Platformi, koja je oglasni prostor u isključivom vlasništvu izdavača Lista.ba (kao npr. štampano izadnje
                oglasa), u za to predviđene kategorije, u i za svoje ime i račun nude prodaju, kupovinu,iznajmljivanje, pružanje ili
                potražnju usluga i/ili proizvoda. Svi registrovani Korisnici koji objavljuju oglas, su sami odgovorni za tačnost,
                vjerodostojnost, istinitost kao i to da su svi navodi (artikli, tekstovi, slike,...) u objavljenom oglasu u skladu sa
                zakonom Bosne i Hercegovine. Iz gore navedenog, svi oglasi i/ili dijelovi istog(artikli, poruke, tekstovi, prikazi,
                fotografije i drugi materijali), objavljeni na Platformi i/ili preneseni i/ili povezani linkom sa neke druge web lokacije su
                isključiva odgovornost registrovanog Korisnika koji je objavio taj oglas iz kojeg podaci potiču.
              </p>{' '}
              <h4>Član 6.</h4>
              <p>
                Svaki Korisnik se obavezuje da će postavljati sadržaj koji je istinit, tačan, vjerodostojan i da nije u suprotnosti niti sa
                jedanim zakonom Bosne i Hrecegovine i pripadajućih entiteta odnosno Distrikta niti ga istim krši.
              </p>
              <h4>Član 7.</h4>{' '}
              <p>
                Lista.ba ima i zadržava pravo da zabrani pristup i/ili da obriše svakog Korisnika ili njegov pojedinačni i svaki sadržaj sa
                Platforme bez prethodnog obavještenja i/ili obrazloženja.
              </p>{' '}
              <h4>Član 8.</h4>{' '}
              <p>
                Svaki Korisnik koji prodaje/kupuje/iznajmljuje/zakupljuje robu ili usluge preko Platforme, samostalno odgovora za
                ispunjavanje svih zakonskih obaveze koje iz toga mogu proizaći. Lista.ba neće niti je u obavezi da vrši zakonske provjere
                oglasa niti provjeru poslovanja samog Korisnika bio on registrovan ili ne. To je obaveza i dužnost Korisnika. Zaštita
                podataka
              </p>
              <h4>Član 9.</h4>{' '}
              <p>
                Korisnik prilikom registracije na Platformu, dozvoljava Lista.ba da koristi i obrađuje podatke koje Korisnik tom prilikom
                unese i/ili podatke koje naknadno izmjeni a odnose se na podatke koji se ostavljaju prilikom registracije.{' '}
              </p>
              <h4>Član 10.</h4>{' '}
              <p>
                Lista.ba čini sve kako bi zaštitila privatnost i sigurnost njenih registrovanih Korisnika na Platformi, a sve u okviru
                &quot;Zakona o zaštiti ličnih podataka&quot; Bosne i Hercegovine.
              </p>
              <h4> Član 11.</h4>{' '}
              <p>
                Lične podatke registrovanog Korisnika, Lista.ba je ovlaštena proslijediti trećim licima samo i samo ako postoji zahtjev
                i/ili nalog nadležnog organa i/ili je takvo prosljeđivanje propisano zakonom i/ili je zahtjevano od strane vlasnika podataka
                isključivo pismenim zahtjevom.
              </p>{' '}
              <h4>Član 12.</h4>{' '}
              <p>
                Lista.ba će podatke dobijene od Korisnika upotrebljavati samo u svrhu kupovine/prodaje/iznajmljivanja između Korisnika, i u
                svrhu unutrašnje obrade podataka za normalno i ispravno funkcionisanje Platforme.
              </p>
              <h4>Član 13.</h4>
              <p>Korisnik ima pravo da zahtijeva da mu Lista.ba dostavi podatke o tome koje lične podatke tog korisnika posjeduje.</p>
              <h4>Član 14.</h4>
              <p>
                Korisnik ima pravo da zatraži uklanjanje(privremeno ili trajno) svih njegovih javno objavljenih podataka na stranici.
                Korisnik može da pošalje zahtjev za uklanjanje podataka i/ili naloga na kontakt formi na Platformi prilikom čega je dužan da
                u poruci ostavi svoj kontakt telefon koji se podudara sa kontakt telefonom u samom profilu Korisnika i da ostavi ime
                profila.
              </p>{' '}
              <h4>Član 15.</h4>
              <p>
                Lista.ba ne preuzima odgovornost po bilo kojem osnovu, ukoliko korisnik sam svojevoljno ili nesvojevoljno, otkrije svoje
                podatke trećim licima na način da im oda korisničko ime i šifru (user name i password) ili im te podatke učini dostupnim ili
                dostavi na neki drugi način.{' '}
              </p>
              <h4>Član 16.</h4>
              <p>
                Korištenjem Platforme, saglasni ste da podaci/informacije koje ste predali (osim ličnih podataka) budu javno dostupni na
                uvid svima koji su za iste zainteresovani, u skladu s ovim Pravilnikom. Korisnik pristaje da njegovu e-mail adresu ili broj
                telefona Lista.ba upotrijebi na Platformi u svrhu slanja obavještenja o statusu Korisničkog oglasa, za dostavu računa za
                korištenje ostalih usluga Platforme na koje je Korisnik pristao, kao i u svrhu slanja ostalih vrsta obavještenja vezanih za
                oglas koji je Korisnik objavio ili uz samo oglašavanje. Korištenjem Platforme saglasni ste da primate promotivna i/ili druga
                obavještenja putem e-mail-a, Viber poruka i/ili drugih sredstava komunikacije koji su navedeni na Vašem oglašivačkom računu.
              </p>{' '}
              <h4>Član 17.</h4>{' '}
              <p>
                Korisnik se obavezuje da neće kršiti autorska prava trećih lica i da za sav objavljeni sadržaj ima pravo korištenja.
                Garancija i ograničenje odgovornosti{' '}
              </p>
              <h4>Član 18.</h4>
              <p>
                Lista.ba ne garantuje: - za istinitost, vjerodostojnost i tačnost podataka na samim oglasima, slikama, tekstovima ili drugim
                objavama Korisnika, uključujući njihove lične podatke, - za kvalitet, sigurnost, izgled kao na oglasu niti za legalnost
                samog čina prodaje/kupovine/iznajmljivanja proizvoda ili usluga - da na Platformi neće biti grešaka ili prekida sa radom -
                da na Platformi neće biti povreda autorskih prava od Korisnika i od strane trećih lica - da će Korisnici u svakom trenutku
                koristiti stranice legalno
              </p>
              <h4>Član 19.</h4>
              <p>
                Lista.ba neće raditi niti se obavezuje na: - povremenu ili stalnu provjeru tačnosti podataka od strane Korisnika ili trećih
                lica, i - povremenu ili stalnu provjeru zakonitosti prodaje/kupovine/iznajmljivanja proizvoda ili usluga Odgovornost{' '}
              </p>
              <h4>Član 20.</h4>
              <p>
                Lista.ba se, do maksimalne zakonom dopuštene odgovornosti, izuzima od bilo kakve štete, gubitka ili povrede koji mogu
                nastati direktno ili posredno (indirektno) zbog: -korištenja Platforme, -korištenja stranica na koje vode linkovi sa
                Platforme a koje je objavio Korisnik ili Lista.ba, -informacija pronađenih na Platformi, -postupaka koji nisu ili jesu
                preduzeti u vezi s informacijama na Platformi, -postupaka Korisnika (jednog ili više zajedno ili pojedinačno), -korištenja,
                nemogućnosti korištenja ili zloupotrebe bilo kojeg od gore navedenih stranica ili cijele Platforme. -korištenja drugih
                internet stranica na kojim je sadržaj neovlašteno predstavljen u ime Lista.ba - korištenja stranica koje nisu pod domenom
                www.lista.ba, a gdje je sadržaj neovlašteno predstavljen u ime Lista.ba, predstavljajući se vizuelno kao Platforma
                (kopirajući stranicu www.lista.ba i/ili aplikacije Platforme), ili u bilo kojem drugom smislu Lista.ba ne može snositi
                odgovornost zbog bilo kojeg sadržaja prikazanog na stranicama koje ne podpadaju pod Platformu a na koje može dopustiti
                povremen pristup. Bilo koji takav pristup ne može se tumačiti kao veze treće strane sa Lista.ba, niti podžavanja takvih
                stranica/sadržaja od strane Lista.ba. Platforma može sadržati veze (linkove) prema drugim stranicama, ali ne odgovara
                sadržaj, usluge ili proizvode do kojih se došlo pomoću Platforme na taj način. Platforma može biti povremeno i/ili
                privremeno nedostupna radi različitih tehničkih poteškoća. Lista.ba nije odgovorna za bilo koji pravni postupak i/ili
                pritužbu protiv oglašivača zasnovanu na povredi bilo kojeg autorskog prava i/ili prava intelektualnog vlasništva, obzirom da
                Lista.ba nije ni idejni ni vizuelni niti na bilo koji drugi način autor takvog oglasa, niti ponuđač, niti distributer
                ponuđenog artikla ili usluge. Sudska nadležnost
              </p>{' '}
              <h4>Član 21.</h4>
              <p>
                Sve zahtjeve i sporove koji bi mogli proizaći iz korištenja Platforme i ovih Uslova korištenja, stranke će pokušati riješiti
                sporazumno a u slučaju da ne mogu, nadležan je stvarno nadležni sud u Bijeljini.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UseOfTerms;

import ProfileHead from '../components/profileHead';
import Post from '../components/post';
import '../styles/profile.scss';
import ProfileList from '../components/profileList';
import {useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import withSeo from '../components/seo';
import {useEffect} from 'react';

function AktivneObjave({history, setTitle}) {
  const location = useLocation();
  const {id} = useParams();
  const active = location.pathname === `/aktivne-objave/${id}`;
  const {userProfile, currentUser} = useSelector((state) => state.auth);
  const isMyProfile = currentUser?.uuid === id;
  const activeArticles = isMyProfile
    ? currentUser?.articles?.filter((article) => !article.soldAt && article.status === 'aktivan')
    : userProfile?.articles?.filter((article) => !article.soldAt && article.status === 'aktivan');
  const soldArticles = isMyProfile
    ? currentUser?.articles?.filter((article) => !!article.soldAt)
    : userProfile?.articles?.filter((article) => !!article.soldAt);

  useEffect(() => {
    setTitle(isMyProfile ? 'Moje objave' : userProfile?.name + '- Profil korisnika');
  }, []);

  return (
    <>
      <ProfileHead username={isMyProfile ? currentUser.userName : userProfile.userName} online={true} myProfile={isMyProfile} />
      <section className="section profile">
        <div className="container">
          <ProfileList active={activeArticles} sold={soldArticles} uuid={id} />
          <h2 className="title">
            {active ? 'Aktivne objave' : 'Završene objave'}{' '}
            <span className="badge">{active ? activeArticles?.length : soldArticles?.length}</span>
          </h2>
          <p>
            Prikazano <b>{active ? activeArticles?.length : soldArticles?.length}</b> od{' '}
            <b>{active ? activeArticles?.length : soldArticles?.length}</b> artikala
          </p>
          <div className="separator"></div>
          <div className="row row_20 posts_5">
            {active
              ? activeArticles?.map((article, i) => <Post key={i} articleprops={{...article}} />)
              : soldArticles?.map((article, i) => <Post articleprops={{...article}} />)}
          </div>
        </div>
      </section>
    </>
  );
}

export default withSeo(AktivneObjave);

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {commonSlice} from '../store/reducers/commonReducer';

export default function ToastMessage() {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.common.notification);
  useEffect(() => {
    if (notification.message !== 'xhr poll error' || !notification.message.includes('timeout')) {
      toast(notification.message, {type: notification.type});
    }

    return () => {
      dispatch(commonSlice.actions.setNotification(undefined));
      toast.clearWaitingQueue();
    };
  }, [notification.message, notification.type, dispatch]);
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
      ></ToastContainer>
    </>
  );
}

import {Link} from 'react-router-dom';
import {isCurrentUser} from '../helpers/auth';

export const VerifiedUser = ({user, currentUser}) => {
  return (
    <>
      {user?.confirmed ? (
        <>
          {/* <span className="user_verified_detail">100%</span> */}
          {/* <img src="/images/check_circle.svg" className="img-fluid" alt="detail" /> */}
        </>
      ) : isCurrentUser(user, currentUser) ? (
        <Link to="/potvrda-email">Verifikuj nalog</Link>
      ) : (
        <>
          <span className="not_verified">Korisnik nije verifikovan</span>
          {/* <img src="/images/blocked.svg" className="img-fluid" alt="detail" /> */}
        </>
      )}
    </>
  );
};

import {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {MEDIUM_IMAGE} from '../api/apiBase';
import {RequestContext} from '../App';
import MapContainer from '../components/map';
import Pagination from '../components/pagination';
import Post from '../components/post';
import ProfileReview from '../components/profileReview';
import withSeo from '../components/seo';
import StoreHead from '../components/storeHead';
import StoreNavmenu from '../components/storeNavmenu';
import useDidMountEffect from '../hooks/useDidMountEffect';

function Prodavnica({setTitle}) {
  const dispatch = useDispatch();
  const {api: socket} = useContext(RequestContext);
  const {id} = useParams();
  const {currentUser, userProfile, user} = useSelector((state) => state.auth);
  const {storesArticles} = useSelector((state) => state.articles);
  const {locations} = useSelector((state) => state.locations);
  const city = locations?.find((location) => location.id === userProfile?.cityId);
  const isMyProfile = currentUser?.id === userProfile?.id;
  const [search, setSearch] = useState({});
  const [page, setPage] = useState(1);
  const {impressions} = useSelector((state) => state.impressions);

  const [impressionPage, setImpressionPage] = useState(1);
  const [impressionCount] = useState(20);

  const [activeList, setActiveList] = useState('artikli');

  const image = userProfile?.profileImage ? MEDIUM_IMAGE + userProfile?.profileImage : '/images/user_placeholder.svg';

  useEffect(() => {
    const getUserCB = async () => {
      if (!Object.keys(userProfile).length || id !== userProfile.uuid) {
        await socket.getUserProfile(id, dispatch);
      } else {
        await socket.getStoresArticles(
          {
            where: {userId: userProfile?.id},
            payload: {
              page: 1,
              count: 12,
            },
          },
          dispatch
        );
      }
      setTitle('Prodavnica');
    };
    getUserCB();
  }, [userProfile]);

  useDidMountEffect(async () => {
    await socket.getStoresArticles(
      {
        where: search,
        payload: {
          page: 1,
          count: page * 12,
        },
      },
      dispatch
    );
  }, [search, page]);

  useDidMountEffect(async () => {
    await socket.getImpressions(userProfile?.id, {page: 1, count: 20}, dispatch, user?.token);
  }, [userProfile, impressionPage]);

  const handleMore = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSearch((prev) => ({...prev, userId: userProfile?.id}));
    setPage(page + 1);
  };

  return (
    <>
      <div className="container" style={{padding: '20px'}}>
        <StoreHead
          image={image}
          email={userProfile?.email}
          username={userProfile?.userName}
          address={userProfile?.address}
          phoneNumber={userProfile?.telephone}
          cityName={city?.name}
          isMyProfile={isMyProfile}
          currentUser={currentUser}
          userProfile={userProfile}
        />

        <StoreNavmenu activeList={activeList} setActiveList={setActiveList} setSearch={setSearch} id={userProfile?.id} />

        {(activeList === 'artikli' || activeList === 'izdvojeni' || activeList === 'na_akciji') && (
          <>
            <div className="row row_20 posts_5">
              {storesArticles.articles?.map((article, index) => {
                return <Post key={index} articleprops={{...article}} />;
              })}
            </div>
            <div className="row mt-3">
              <div className="col-12 text-center">
                {storesArticles?.hasNext && (
                  <button className="button" onClick={handleMore}>
                    Učitaj više
                  </button>
                )}
              </div>
            </div>
          </>
        )}

        {activeList === 'o_nama' &&
          (userProfile?.description ? (
            <div dangerouslySetInnerHTML={createMarkup(JSON.parse(userProfile.description))}></div>
          ) : (
            <h3>Prodavnica nema opis</h3>
          ))}
        {activeList === 'mapa' && <MapContainer address={userProfile?.address} cityName={userProfile?.cityId} />}
        {activeList === 'kontakt' && <h2>Kontakt..</h2>}
        {activeList === 'dojmovi' && (
          <>
            <h2 className="title">
              Ukupno dojmova <span className="badge">{impressions?.total}</span>
            </h2>
            <div className="separator"></div>
            <div>
              {impressions?.impressions?.length ? (
                impressions?.impressions?.map((i) => (
                  <ProfileReview
                    key={i.id}
                    currentUser={currentUser}
                    currentUserId={currentUser?.uuid}
                    id={id}
                    user={i.sender}
                    article={i.article}
                    comment={i.comment}
                    createdAt={i.createdAt}
                    rating={i.rating}
                    impressionsSender={user.impressionsSender}
                  />
                ))
              ) : (
                <div className="detail_short">
                  <div className="container">
                    <p>Prodavnica nema nijedan ostavljen dojam!</p>
                  </div>
                </div>
              )}
            </div>
            {!!impressions?.total && (
              <Pagination articles={impressions} perPage={impressionCount} page={impressionPage} setPage={setImpressionPage} />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default withSeo(Prodavnica);

function createMarkup(html) {
  return {__html: html};
}

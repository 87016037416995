import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  messages: [],
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.locations = [...action.payload];
      return state;
    },
    resetState: (state) => {
      state = initialState;
      return state;
    },
  },
});

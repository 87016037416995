import {useState} from 'react';
import {Link} from 'react-router-dom';
import useMedia from 'use-media';

function StoreNavmenu({activeList, setActiveList, search, setSearch, id}) {
  const [currentState, setCurrentState] = useState();
  const notMobile = useMedia({minWidth: 480});

  const handleChangeList = (e) => {
    setActiveList(e.target.name);
    if (e.target.name === 'izdvojeni') {
      setSearch({userId: id, featured: true});
      if (currentState) {
        setSearch((prev) => ({...prev, title: currentState}));
      }
    } else if (e.target.name === 'na_akciji') {
      setSearch({userId: id, discounted: true});
      if (currentState) {
        setSearch((prev) => ({...prev, title: currentState}));
      }
    } else if (e.target.name === 'artikli') {
      setSearch({userId: id});
      if (currentState) {
        setSearch((prev) => ({...prev, title: currentState}));
      }
    }
  };

  const searchInShop = (e) => {
    setSearch({userId: id, title: currentState});
    setActiveList('artikli');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // searchInShop(e);
      setSearch({userId: id, title: currentState});
      setActiveList('artikli');
    }
  };

  return (
    <div className="row mt-3">
      <div className="col-12">
        <div className={`d-md-flex home_tabs ${!notMobile ? 'w-100' : ''}`}>
          <ul className={notMobile ? 'align-self-center' : 'd-flex justify-content-around w-100 mb-3'}>
            <li>
              <Link
                onClick={(e) => {
                  handleChangeList(e);
                }}
                name="artikli"
                className={activeList === 'artikli' ? 'active' : ''}
                to="#"
              >
                Artikli
              </Link>
            </li>
            <li>
              <Link
                onClick={(e) => {
                  handleChangeList(e);
                }}
                name="izdvojeni"
                className={activeList === 'izdvojeni' ? 'active ' : ''}
                to="#"
              >
                Izdvojeni
              </Link>
            </li>
            <li>
              <Link
                onClick={(e) => {
                  handleChangeList(e);
                }}
                name="na_akciji"
                className={activeList === 'na_akciji' ? 'active' : ''}
                to="#"
              >
                Na akciji
              </Link>
            </li>
            <li>
              <Link
                onClick={(e) => {
                  handleChangeList(e);
                }}
                name="o_nama"
                className={activeList === 'o_nama' ? 'active' : ''}
                to="#"
              >
                O nama
              </Link>
            </li>
            <li>
              <Link
                onClick={(e) => {
                  handleChangeList(e);
                }}
                name="dojmovi"
                className={activeList === 'dojmovi' ? 'active' : ''}
                to="#"
              >
                Dojmovi
              </Link>
            </li>
            {/* <li>
              <Link
                onClick={(e) => {
                  handleChangeList(e);
                }}
                name="mapa"
                className={activeList === 'mapa' ? 'active' : ''}
                to="#"
              >
                Mapa
              </Link>
            </li> */}
            {/* <li>
              <Link
                onClick={(e) => {
                  handleChangeList(e);
                }}
                name="kontakt"
                className={activeList === 'kontakt' ? 'active' : ''}
                to="#"
              >
                Kontakt
              </Link>
            </li> */}
            {/* <li>
              <Link
                onClick={(e) => {
                  handleChangeList(e);
                }}
                name="radno_vrijeme"
                className={activeList === 'radno_vrijeme' ? 'active' : ''}
                to="#"
              >
                Radno vrijeme
              </Link>
            </li> */}
          </ul>
          <div className={`header_search ${notMobile ? 'ml-auto' : ' m-auto'}`}>
            <button onClick={searchInShop}>
              <img src="/images/search.svg" alt="search" />
            </button>
            <input
              type="text"
              placeholder="Pretraga unutar prodavnice"
              onChange={(e) => setCurrentState(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoreNavmenu;

import {useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RequestContext} from '../App';
import Attribute from './attribute';

const initialState = {
  name: '',
  alias: '',
  orderNumber: 0,
  subSubCategoryId: null,
};

const initialStateEdit = {
  name: '',
  alias: '',
};

const Admin2 = () => {
  const {api: socket} = useContext(RequestContext);
  const dispatch = useDispatch();
  const {categories, subCategories, subSubCategories} = useSelector((state) => state.categories);
  const [stateSubCategories, setStateSubCategories] = useState([]);
  const [stateSubSubCategories, setStateSubSubCategories] = useState([]);
  const [stateSubSubSubCategories, setStateSubSubSubCategories] = useState([]);
  const [newSubSubSubCategory, setNewSubSubSubCategory] = useState(initialState);
  const [editSubSubSubCategory, setEditSubSubSubCategory] = useState(initialStateEdit);
  const [editId, setEditId] = useState(null);
  const [deleteSubSubSubCategory, setDeleteSubSubSubCategory] = useState(null);

  const handleChangeCategory = (e, flag) => {
    if (flag === 'new') {
      setNewSubSubSubCategory({...newSubSubSubCategory, subSubCategoryId: null});
      socket.getSubCategories(e.target.value, dispatch);
      setStateSubSubCategories([]);
    } else if (flag === 'edit') {
      const temp = categories.filter((c) => c.id === Number(e.target.value))[0].subcategories;
      setStateSubCategories(temp);
      setEditSubSubSubCategory(initialStateEdit);
      setDeleteSubSubSubCategory(null);
      setEditId(null);
      setStateSubSubSubCategories([]);
    }
  };

  const handleChangeSubCategory = (e, flag) => {
    if (flag === 'new') {
      setNewSubSubSubCategory({...newSubSubSubCategory, subSubCategoryId: null});
      const temp = subCategories.filter((s) => s.id === Number(e.target.value))[0].subsubcategories;
      setStateSubSubCategories(temp);
    } else if (flag === 'edit') {
      socket.getSubSubCategories(Number(e.target.value), dispatch);
      setEditSubSubSubCategory(initialStateEdit);
      setDeleteSubSubSubCategory(null);
      setEditId(null);
      setStateSubSubSubCategories([]);
    }
  };

  const handleChangeSubSubCategory = (e, flag) => {
    if (flag === 'new') {
      if (e.target.value) setNewSubSubSubCategory({...newSubSubSubCategory, subSubCategoryId: Number(e.target.value)});
      else setNewSubSubSubCategory({...newSubSubSubCategory, subSubCategoryId: null});
    } else if (flag === 'edit') {
      const temp = subSubCategories.filter((ssc) => ssc.id === Number(e.target.value))[0].subsubsubcategories;
      setStateSubSubSubCategories(temp);
      setEditSubSubSubCategory(initialStateEdit);
      setDeleteSubSubSubCategory(null);
      setEditId(null);
    }
  };

  const handleChangeSubSubSubCategory = (e) => {
    const temp = stateSubSubSubCategories.filter((sssc) => sssc.id === Number(e.target.value))[0].name;
    setEditId(Number(e.target.value));
    setEditSubSubSubCategory({...editSubSubSubCategory, name: temp, alias: temp});
    setDeleteSubSubSubCategory(Number(e.target.value));
  };

  const addNewSubSubSubCategory = async () => {
    await socket.addNewSubSubSubCategory(newSubSubSubCategory, dispatch);
  };

  const updateSubSubSubCategory = async () => {
    await socket.updateSubSubSubCategory(editId, editSubSubSubCategory, dispatch);
  };

  const removeSubSubSubCategory = async () => {
    await socket.deleteSubSubSubCategory(deleteSubSubSubCategory, dispatch);
  };

  return (
    <section className="section category">
      <div className="container container-admin">
        <div className="col-12">
          <h2>POTPOTPOTKATEGORIJE</h2>
          <br />
          <h3>Dodaj novu potpotpotkategoriju</h3>
        </div>
        <div className="row">
          <div className="col-3">
            <label className="form_default_select">
              <select onChange={(e) => handleChangeCategory(e, 'new')}>
                <option value="">Izaberite kategoriju</option>
                {categories.map((category) => (
                  <option key={category.uuid} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="col-3">
            <label className="form_default_select">
              <select onChange={(e) => handleChangeSubCategory(e, 'new')}>
                <option value="">Izaberite potkategoriju</option>
                {subCategories?.map((subCategory) => (
                  <option value={subCategory.id} key={subCategory.uuid}>
                    {subCategory.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="col-3">
            <label className="form_default_select">
              <select onChange={(e) => handleChangeSubSubCategory(e, 'new')}>
                <option value="">Izaberite potpotkategoriju</option>
                {stateSubSubCategories.map((s) => (
                  <option value={s.id} key={s.uuid}>
                    {s.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="col-3">
            <label className="form_default_input">
              <input
                type="text"
                maxLength={50}
                placeholder="Unesite naziv"
                onChange={(e) => setNewSubSubSubCategory({...newSubSubSubCategory, name: e.target.value, alias: e.target.value})}
              />
            </label>
          </div>
        </div>
        <div className="offset-10 col-2">
          <button
            disabled={!newSubSubSubCategory.subSubCategoryId || !newSubSubSubCategory.name.length}
            onClick={addNewSubSubSubCategory}
            className="button button_blue"
          >
            Potvrdi
          </button>
        </div>
        <div className="separator mt-2"></div>
        <h3>Izmijeni/Оbriši potpotpotkategoriju</h3>
        <div className="row">
          <div className="col-3">
            <label className="form_default_select">
              <select onChange={(e) => handleChangeCategory(e, 'edit')}>
                <option value="">Izaberite kategoriju</option>
                {categories?.map((c) => (
                  <option key={c.uuid} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="col-3">
            <label className="form_default_select">
              <select onChange={(e) => handleChangeSubCategory(e, 'edit')}>
                <option value="">Izaberite potkategoriju</option>
                {stateSubCategories?.map((sc) => (
                  <option key={sc.uuid} value={sc.id}>
                    {sc.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="col-3">
            <label className="form_default_select">
              <select onChange={(e) => handleChangeSubSubCategory(e, 'edit')}>
                <option value="">Izaberite potpotkategoriju</option>
                {subSubCategories?.map((ssc) => (
                  <option key={ssc.uuid} value={ssc.id}>
                    {ssc.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="col-3">
            <label className="form_default_select">
              <select onChange={handleChangeSubSubSubCategory}>
                <option value="">Izaberite potpotpotkategoriju</option>
                {stateSubSubSubCategories.map((sssc) => (
                  <option key={sssc.uuid} value={sssc.id}>
                    {sssc.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="offset-3 col-6">
            <label className="form_default_input">
              <input
                type="text"
                maxLength={50}
                placeholder="Unesite naziv"
                value={editSubSubSubCategory.name}
                onChange={(e) => setEditSubSubSubCategory({...editSubSubSubCategory, name: e.target.value, alias: e.target.value})}
              />
            </label>
          </div>
          <div className="col-3">
            <button
              disabled={!editSubSubSubCategory.name.length || !editId}
              className="button button_orange mr-2"
              onClick={updateSubSubSubCategory}
            >
              Izmijeni
            </button>
            <button onClick={removeSubSubSubCategory} disabled={!deleteSubSubSubCategory} className="button button_red">
              Obriši
            </button>
          </div>
        </div>
        <Attribute isSubSubSub />
      </div>
    </section>
  );
};

export default Admin2;

import Compress from 'compress.js';
import {useContext, useState, useEffect} from 'react';
import ImageUploading from 'react-images-uploading';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {RequestContext} from '../App';
import {calculator} from '../helpers/utilities';
import useDidMountEffect from '../hooks/useDidMountEffect';
import {commonSlice} from '../store/reducers/commonReducer';
import {newArticleSlice} from '../store/reducers/newArticleReducers';

async function resizeImageFn(file) {
  const base64Response = await fetch(file.data_url);
  const blob = await base64Response.blob();

  const compress = new Compress();
  const resizedImage = await compress.compress([blob], {
    size: 2, // the max size in MB, defaults to 2MB
    quality: 0.9, // the quality of the image, max is 1,
    // maxWidth: 1200, // the max width of the output image, defaults to 1920px
    maxHeight: 800, // the max height of the output image, defaults to 1920px
    resize: true, // defaults to true, set false if you do not want to resize the image width and height
  });
  const img = resizedImage[0];
  const base64str = img.data;
  const imgExt = img.ext;
  const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt);
  return resizedFiile;
}

const pause = (ms) => Promise.resolve(setTimeout, pause);

function Objava3() {
  const history = useHistory();
  const {api: socket} = useContext(RequestContext);
  const newArticle = useSelector((state) => state.newArticle) || {};
  const [images, setImages] = useState([]);
  const [state, setState] = useState({...newArticle});
  const [isNegotable, setIsNegotable] = useState(newArticle?.negotable);
  let loanInstallment = 0;
  const dispatch = useDispatch();
  const {currentUser, user} = useSelector((state) => state.auth);

  const handleChange = (e) => {
    if (e.target.name === 'price') {
      const articlePrice = e.target.value;
      const articleType = newArticle?.category.split(',')[0];
      loanInstallment = calculator(Number(articlePrice), articleType, null);
    }
    setState({...state, [e.target.name]: e.target.value});
    dispatch(
      newArticleSlice.actions.updateState({
        ...newArticle,
        [e.target.name]: e.target.value,
        loanInstallment: loanInstallment[0],
      })
    );
  };

  useDidMountEffect(() => {
    if (isNegotable) {
      setState({...state, price: 0, loanInstallment: 0});
      dispatch(
        newArticleSlice.actions.updateState({
          ...newArticle,
          price: 0,
          loanInstallment: 0,
          negotable: true,
        })
      );
    } else {
      dispatch(
        newArticleSlice.actions.updateState({
          ...newArticle,
          negotable: '',
        })
      );
    }
  }, [isNegotable]);

  const handleChecbox = (e) => {
    if (!state[e.target.name]?.length) {
      setState({...state, [e.target.name]: e.target.value});
      dispatch(
        newArticleSlice.actions.updateState({
          ...newArticle,
          [e.target.name]: e.target.value,
        })
      );
    } else {
      setState({...state, [e.target.name]: ''});
      dispatch(
        newArticleSlice.actions.updateState({
          ...newArticle,
          [e.target.name]: '',
        })
      );
    }
  };

  const addImageCallback = async (pic, index) => {
    try {
      dispatch(commonSlice.actions.setIsLoading(true));

      for (let i of index) {
        await pause(100);
        const image = await resizeImageFn(pic[i]);
        const form = new FormData();
        form.append('image', image);
        await socket.addImages(form, dispatch, user?.token);
      }

      setImages(pic);
    } catch (error) {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  };

  const onImageRemove = (index) => {
    const newImages = images.filter((img, i) => i !== index);
    setImages(newImages);
  };

  const handleContinue = (e) => {
    e.preventDefault();
    history.push('/objava-4');
  };

  const isDisabled = () => {
    if (['kupovina', 'iznajmljivanje'].includes(newArticle?.articleType)) return false;
    if (!newArticle || (!newArticle.price && !newArticle.negotable)) {
      return true;
    }
    return !(!!newArticle.price.length || !!newArticle.negotable);
  };

  const goToFirstPage = () => {
    history.push('/objava-1');
  };

  useEffect(() => {
    if (!newArticle || !newArticle.categoryId) {
      goToFirstPage();
    }
  }, []);

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="jumbotron">
            <div className="row mb-3">
              <div className="col-lg-6">
                <h2 className="title">Cijena i slike</h2>
              </div>
              <div className="col-lg-6 text-right">
                <div className="form_default_steps">
                  <span>Koraci objave</span>
                  <ul>
                    <li>1</li>
                    <li>2</li>
                    <li className="active">3</li>
                  </ul>
                </div>
              </div>
            </div>
            {!newArticle?.saleOnly && (
              <>
                <h3>Cijena artikla</h3>
                <p>
                  Obratite pažnju prilikom određivanja cijene proizvoda, s obzirom da je to <b>najbitniji dio objave</b>.
                </p>
              </>
            )}
            {!['kupovina', 'iznajmljivanje'].includes(newArticle?.articleType) && (
              <>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="form_default_input form_default_w_icon">
                      <input
                        type="number"
                        disabled={isNegotable}
                        placeholder="Upišite cijenu"
                        name={'price'}
                        onChange={handleChange}
                        value={state.price}
                      />
                      <img src="/images/km.svg" alt="km" />
                    </label>
                  </div>
                  <div className="col-md-6">
                    <label className="form_default_option w-100">
                      <input type="checkbox" name="negotable" onChange={() => setIsNegotable(!isNegotable)} checked={isNegotable} />
                      <span>Cijena po dogovoru</span>
                    </label>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="form_default_option w-100">
                      <input type="checkbox" name="fixed" onChange={handleChecbox} checked={state.fixed === 'on'} />
                      <span>Fiksno</span>
                    </label>
                  </div>
                  <div className="col-md-6">
                    <label className="form_default_option w-100">
                      <input type="checkbox" name="exchange" onChange={handleChecbox} checked={state.exchange === 'on'} />
                      <span>Zamjena</span>
                    </label>
                  </div>
                </div>
                <div className="row mb-3">
                  {currentUser?.isShop ? (
                    <div className="col-md-6">
                      <label className="form_default_option w-100">
                        <input type="checkbox" name="discounted" onChange={handleChecbox} checked={state.discounted === 'on'} />
                        <span>Akcija</span>
                      </label>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </>
            )}
            <h3>Dodajte slike</h3>
            <p>
              Pored cijene i opisa, korisnike najviše privlače i autentične slike Vašeg artikla. Ukoliko imate poteškoća sa dodavanjem
              velikih slika, prvo ih pokušajte smanjiti.<b> Maksimalan broj fotografija je 20.</b>
            </p>
            <div className="form_default_image">
              <div>
                <h3 className="no-mobile">Ovde dodajte Vaše slike</h3>
                <ImageUploading
                  multiple
                  value={images}
                  dataURLKey="data_url"
                  onChange={addImageCallback}
                  maxFileSize={20242880}
                  maxNumber={20}
                >
                  {({imageList, onImageUpload, isDragging, dragProps}) => (
                    // write your building UI
                    <>
                      <div className="upload__image-wrapper ">
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <div onClick={() => onImageRemove(index)} class="post_info post_hide">
                              X
                            </div>
                            <img src={image['data_url']} alt="" />
                          </div>
                        ))}
                        {imageList.length < 20 && (
                          <button
                            style={isDragging ? {color: 'red'} : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                            hasimages={!!images?.length}
                          >
                            Dodajte fotografiju
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </ImageUploading>{' '}
              </div>
              <div>
                <img src="/images/drag_drop.svg" alt="drag_drop" className="img-fluid no-mobile" />
              </div>
            </div>
            {!['kupovina', 'iznajmljivanje'].includes(newArticle?.articleType) &&
              newArticle?.price?.length === 0 &&
              newArticle?.negotable?.length === 0 && (
                <ul className="errors col-lg-6 mb-4">
                  <li>Unesite cijenu artikla ili odabredite opciju "Cijena po dogovoru"!</li>
                </ul>
              )}
            <div className="row">
              <div className="col-md-6">
                <Link to="/objava-2" className="button button_light w-100">
                  Korak nazad
                </Link>
              </div>
              <div className="col-md-6">
                <button onClick={handleContinue} disabled={isDisabled()} className="button w-100">
                  Nastavite
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Objava3;

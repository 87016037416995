import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  notification: {
    message: undefined,
    type: undefined,
  },
  isLoading: false,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.notification = {...action.payload};
      return state;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

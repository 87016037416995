export const SvgHome = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.009" height="21.513" viewBox="0 0 22.009 21.513">
      <path
        id="Home"
        d="M94.954,86.156,84.843,77.295a.743.743,0,0,0-.98,0L73.753,86.156a.743.743,0,1,0,.98,1.118l.783-.686V97.645a.743.743,0,0,0,.743.743H92.448a.743.743,0,0,0,.743-.743V86.588l.783.686a.743.743,0,0,0,.98-1.118ZM82.637,96.9V90.778H86.07V96.9H82.637ZM91.7,85.291V96.9H87.557V90.034a.743.743,0,0,0-.743-.743h-4.92a.743.743,0,0,0-.743.743V96.9H77V85.291s0,0,0-.006l7.351-6.443L91.7,85.285S91.7,85.289,91.7,85.291Z"
        transform="translate(-73.349 -76.976)"
        fill="#515c6f"
        stroke="#515c6f"
        strokeWidth="0.2"
      />
    </svg>
  );
};
export const SvgSearch = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.199" height="15.199" viewBox="0 0 15.199 15.199">
      <path
        id="Search"
        d="M18.984,17.95l-3.257-3.257a6.584,6.584,0,1,0-1.034,1.034l3.257,3.257a.731.731,0,0,0,1.034-1.034Zm-8.4-2.246a5.12,5.12,0,1,1,5.12-5.12A5.12,5.12,0,0,1,10.583,15.7Z"
        transform="translate(-4 -4)"
        fill="#515c6f"
      />
    </svg>
  );
};
export const SvgUserDark = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.892" height="20.892" viewBox="0 0 20.892 20.892">
      <path
        id="Profile"
        d="M19.136,14.8a13.234,13.234,0,0,0-3.228-1.94,17.557,17.557,0,0,1-2.48-1.384,8.706,8.706,0,0,0,2.158-5.234,8.227,8.227,0,0,0-.63-3.777C14.388,1.341,13.118,0,10.3,0S6.2,1.341,5.636,2.467a8.218,8.218,0,0,0-.631,3.777,8.7,8.7,0,0,0,2.158,5.234,17.471,17.471,0,0,1-2.48,1.384A13.244,13.244,0,0,0,1.455,14.8,6.33,6.33,0,0,0,0,18.85a1.716,1.716,0,0,0,1.715,1.742H18.876a1.716,1.716,0,0,0,1.716-1.743A6.332,6.332,0,0,0,19.136,14.8Zm.046,4.374a.429.429,0,0,1-.306.128H1.715a.429.429,0,0,1-.3-.128.435.435,0,0,1-.124-.307,5.139,5.139,0,0,1,1.079-3.157,13.075,13.075,0,0,1,2.894-1.7,14.114,14.114,0,0,0,2.788-1.6l.869-.821-.754-.926a7.448,7.448,0,0,1-1.87-4.422,7.114,7.114,0,0,1,.493-3.2c.59-1.168,1.77-1.76,3.512-1.76s2.921.592,3.511,1.759a7.118,7.118,0,0,1,.493,3.2,7.443,7.443,0,0,1-1.871,4.422l-.754.927.869.821a14.145,14.145,0,0,0,2.788,1.6,13.018,13.018,0,0,1,2.894,1.7A5.016,5.016,0,0,1,19.3,18.87.429.429,0,0,1,19.182,19.177Z"
        transform="translate(0.15 0.15)"
        fill="#515c6f"
        stroke="#515c6f"
        strokeWidth="0.3"
      />
    </svg>
  );
};
export const SvgMessage = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.693" height="17.467" viewBox="0 0 18.693 17.467">
      <g id="comment" transform="translate(0.25 0.25)">
        <path
          id="Path_180"
          data-name="Path 180"
          d="M15.693-1.332H2.5A2.5,2.5,0,0,0,0,1.169V9.242a2.5,2.5,0,0,0,2.492,2.5v3.663l5.264-3.663h7.935a2.5,2.5,0,0,0,2.5-2.5V1.169a2.5,2.5,0,0,0-2.5-2.5ZM17.128,9.242a1.437,1.437,0,0,1-1.435,1.435H7.423L3.559,13.366V10.677H2.5A1.437,1.437,0,0,1,1.067,9.242V1.169A1.437,1.437,0,0,1,2.5-.266H15.693a1.437,1.437,0,0,1,1.435,1.435Zm0,0"
          transform="translate(-0.001 1.332)"
          fill="#515c6f"
          stroke="#515c6f"
          strokeWidth="0.5"
        />
        <path
          id="Path_181"
          data-name="Path 181"
          d="M171.293,131.172h8.455v1.066h-8.455Zm0,0"
          transform="translate(-166.424 -127.405)"
          fill="#515c6f"
          stroke="#515c6f"
          strokeWidth="0.5"
        />
        <path
          id="Path_182"
          data-name="Path 182"
          d="M171.293,211.172h8.455v1.066h-8.455Zm0,0"
          transform="translate(-166.424 -205.131)"
          fill="#515c6f"
          stroke="#515c6f"
          strokeWidth="0.5"
        />
        <path
          id="Path_183"
          data-name="Path 183"
          d="M171.293,291.172h8.455v1.066h-8.455Zm0,0"
          transform="translate(-166.424 -282.857)"
          fill="#515c6f"
          stroke="#515c6f"
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
};
export const SvgHeart = ({fill, scale = '1', count = null}) => {
  return (
    <svg style={{transform: `scale(${scale})`}} xmlns="http://www.w3.org/2000/svg" width="21.56" height="19.033" viewBox="0 0 21.56 19.033">
      <g id="Group_2511" data-name="Group 2511" transform="translate(0 0)">
        <path
          id="Path_3152"
          data-name="Path 3152"
          d="M15.833,30a5.1,5.1,0,0,0-3.188,1.1,7.262,7.262,0,0,0-1.865,2.243A7.262,7.262,0,0,0,8.915,31.1,5.1,5.1,0,0,0,5.727,30C2.462,30,0,32.67,0,36.212c0,3.826,3.072,6.443,7.721,10.406.79.673,1.685,1.436,2.615,2.249a.674.674,0,0,0,.887,0c.93-.814,1.825-1.576,2.615-2.25,4.649-3.962,7.721-6.58,7.721-10.405C21.56,32.67,19.1,30,15.833,30Z"
          transform="translate(0 -30)"
          fill={fill}
        />
      </g>
      <text x="50%" y="55%" fill="#fff" dominantBaseline="middle" textAnchor="middle" fontSize="12" fontWeight="bold">
        {count}
      </text>
    </svg>
  );
};
export const SvgPrint = ({scale = 1}) => {
  return (
    <svg
      style={{transform: `scale(${scale})`}}
      id="printing"
      xmlns="http://www.w3.org/2000/svg"
      width="21.818"
      height="20.278"
      viewBox="0 0 21.818 20.278"
    >
      <g id="Group_2521" data-name="Group 2521" transform="translate(7.03 16.268)">
        <g id="Group_2520" data-name="Group 2520">
          <path
            id="Path_3155"
            data-name="Path 3155"
            d="M172.007,399.834h-6.3a.727.727,0,1,0,0,1.455h6.3a.727.727,0,0,0,0-1.455Z"
            transform="translate(-164.977 -399.834)"
            fill="#63697b"
          />
        </g>
      </g>
      <g id="Group_2523" data-name="Group 2523" transform="translate(7.03 13.974)">
        <g id="Group_2522" data-name="Group 2522" transform="translate(0 0)">
          <path
            id="Path_3156"
            data-name="Path 3156"
            d="M172.007,346.006h-6.3a.727.727,0,1,0,0,1.455h6.3a.727.727,0,0,0,0-1.455Z"
            transform="translate(-164.977 -346.006)"
            fill="#63697b"
          />
        </g>
      </g>
      <g id="Group_2525" data-name="Group 2525" transform="translate(0 0)">
        <g id="Group_2524" data-name="Group 2524" transform="translate(0 0)">
          <path
            id="Path_3157"
            data-name="Path 3157"
            d="M20.121,23H17.807V18.792a.727.727,0,0,0-.727-.727H4.738a.727.727,0,0,0-.727.727V23H1.7A1.7,1.7,0,0,0,0,24.7v7.317a1.7,1.7,0,0,0,1.7,1.7H4.011v3.9a.727.727,0,0,0,.727.727H17.079a.727.727,0,0,0,.727-.727v-3.9h2.314a1.7,1.7,0,0,0,1.7-1.7V24.7A1.7,1.7,0,0,0,20.121,23ZM5.465,19.52H16.352V23H5.465ZM16.352,36.888H5.466v-5.95H16.352C16.352,31.119,16.352,36.752,16.352,36.888Zm.727-9.617H15.228a.727.727,0,0,1,0-1.455h1.851a.727.727,0,1,1,0,1.455Z"
            transform="translate(0 -18.065)"
            fill="#63697b"
          />
        </g>
      </g>
    </svg>
  );
};
export const SvgInfo = () => {
  return (
    <svg
      id="exclamation_1_"
      data-name="exclamation (1)"
      xmlns="http://www.w3.org/2000/svg"
      width="21.823"
      height="21.823"
      viewBox="0 0 21.823 21.823"
    >
      <g id="Group_2514" data-name="Group 2514">
        <g id="Group_2513" data-name="Group 2513">
          <path
            id="Path_3153"
            data-name="Path 3153"
            d="M10.912,0A10.912,10.912,0,1,0,21.823,10.912,10.905,10.905,0,0,0,10.912,0Zm0,20.118a9.207,9.207,0,1,1,9.207-9.207A9.2,9.2,0,0,1,10.912,20.118Z"
            fill="#63697b"
          />
        </g>
      </g>
      <g id="Group_2516" data-name="Group 2516" transform="translate(10.059 5.493)">
        <g id="Group_2515" data-name="Group 2515">
          <path
            id="Path_3154"
            data-name="Path 3154"
            d="M236.852,128.877a.853.853,0,0,0-.853.852v5.49a.853.853,0,1,0,1.705,0v-5.49A.852.852,0,0,0,236.852,128.877Z"
            transform="translate(-236 -128.877)"
            fill="#63697b"
          />
        </g>
      </g>
      <g id="Group_2518" data-name="Group 2518" transform="translate(9.761 13.732)">
        <g id="Group_2517" data-name="Group 2517">
          <circle id="Ellipse_355" data-name="Ellipse 355" cx="1.151" cy="1.151" r="1.151" fill="#63697b" />
        </g>
      </g>
    </svg>
  );
};
export const SvgCog = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.267" height="22.891" viewBox="0 0 22.267 22.891">
      <path
        id="Path_2946"
        data-name="Path 2946"
        d="M22.308,14.966a8.434,8.434,0,0,0,.072-1.121,6.887,6.887,0,0,0-.083-1.121l2.42-1.884a.585.585,0,0,0,.143-.727L22.57,6.156a.582.582,0,0,0-.7-.262l-2.85,1.145a8.422,8.422,0,0,0-1.931-1.121l-.429-3.028a.577.577,0,0,0-.572-.489H11.506a.565.565,0,0,0-.56.489l-.429,3.028A8.608,8.608,0,0,0,8.585,7.038L5.736,5.893a.569.569,0,0,0-.7.262L2.755,10.114a.542.542,0,0,0,.143.727l2.42,1.884a6.633,6.633,0,0,0-.024,2.241L2.874,16.85a.585.585,0,0,0-.143.727L5.02,21.536a.582.582,0,0,0,.7.262l2.85-1.145A8.422,8.422,0,0,0,10.5,21.774l.429,3.028a.586.586,0,0,0,.572.489h4.578a.556.556,0,0,0,.56-.489l.429-3.028a8.178,8.178,0,0,0,1.931-1.121l2.85,1.145a.569.569,0,0,0,.7-.262l2.289-3.958a.552.552,0,0,0-.143-.727ZM13.8,18.138a4.292,4.292,0,1,1,4.292-4.292A4.3,4.3,0,0,1,13.8,18.138Z"
        transform="translate(-2.662 -2.4)"
        fill="#63697b"
      />
    </svg>
  );
};
export const SvgShare = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.723" height="22.933" viewBox="0 0 20.723 22.933">
      <path
        id="Path_2948"
        data-name="Path 2948"
        d="M20.269,18.21a3.352,3.352,0,0,0-2.256.886L9.8,14.318a3.769,3.769,0,0,0,.1-.806,3.769,3.769,0,0,0-.1-.806L17.92,7.975a3.446,3.446,0,1,0-1.105-2.521,3.769,3.769,0,0,0,.1.806L8.8,10.991a3.454,3.454,0,1,0,0,5.042L17,20.823a3.248,3.248,0,0,0-.092.748,3.362,3.362,0,1,0,3.362-3.362Z"
        transform="translate(-3 -2)"
        fill="#63697b"
      />
    </svg>
  );
};

export const SvgX = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
    <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
  </svg>
);

export const QuestionMark = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="question-circle"
    className="svg-inline--fa fa-question-circle fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    height="40px"
    width="40px"
  >
    <path
      fill="#7c6ed9"
      d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
    ></path>
  </svg>
);

export const SvgEdit = () => (
  <svg id="edit-button" xmlns="http://www.w3.org/2000/svg" width="25.723" height="25.835" viewBox="0 0 25.723 25.835">
    <path
      id="Path_3060"
      data-name="Path 3060"
      d="M112.063.871l-.031-.028a3.219,3.219,0,0,0-4.548.206L95.98,13.643a1.11,1.11,0,0,0-.234.4L94.393,18.1a1.542,1.542,0,0,0,1.46,2.03h0a1.536,1.536,0,0,0,.619-.13l3.917-1.713a1.11,1.11,0,0,0,.375-.269l11.5-12.595A3.223,3.223,0,0,0,112.063.871ZM96.985,17.346l.794-2.38.067-.073,1.5,1.374-.067.073ZM110.628,3.921l-9.78,10.707-1.5-1.374,9.78-10.707a1,1,0,0,1,1.41-.063l.031.028A1,1,0,0,1,110.628,3.921Z"
      transform="translate(-87.386)"
    />
    <path
      id="Path_3061"
      data-name="Path 3061"
      d="M23.2,40.125a1.111,1.111,0,0,0-1.111,1.111v9.43a2.828,2.828,0,0,1-2.825,2.825H5.8a2.828,2.828,0,0,1-2.825-2.825V37.312A2.828,2.828,0,0,1,5.8,34.488h9.744a1.111,1.111,0,1,0,0-2.222H5.8A5.052,5.052,0,0,0,.757,37.312V50.666A5.052,5.052,0,0,0,5.8,55.712H19.266a5.052,5.052,0,0,0,5.046-5.046v-9.43A1.111,1.111,0,0,0,23.2,40.125Z"
      transform="translate(-0.757 -29.877)"
    />
  </svg>
);

export const ArrowUp = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 473.654 473.654"
    style={{'enable-background': 'new 0 0 473.654 473.654'}}
  >
    <circle style={{fill: '#6EC2CB'}} cx="236.827" cy="236.827" r="236.827" />
    <path
      style={{fill: '#FFFFFF'}}
      d="M366.553,238.843c-35.885-35.889-71.766-71.77-107.651-107.655
  c-10.837-10.845-28.907-10.549-39.666,0.213c-35.885,35.881-71.77,71.77-107.651,107.655c-25.564,25.56,14.259,65.08,39.879,39.456
  c29.236-29.236,58.476-58.476,87.712-87.712c29.311,29.307,58.614,58.618,87.925,87.929
  C352.664,304.289,392.184,264.47,366.553,238.843z"
    />
  </svg>
);

export const SvgFeature = () => (
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512">
    <path d="M17.792,7.212A8,8,0,0,0,2,9a7.9,7.9,0,0,0,.9,3.671A5.49,5.49,0,0,0,5.5,23H14V15.414l-2.293,2.293-1.414-1.414L13.586,13a2,2,0,0,1,2.828,0l3.293,3.293-1.414,1.414L16,15.414V23A8,8,0,0,0,17.792,7.212Z" />
  </svg>
);

export const SvgGoBack = () => <img width={'40px'} height={'40px'} src="/images/back-arrow.png" alt="" />;

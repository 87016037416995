import {useContext, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {RequestContext} from '../App';
import Underhead from '../components/underhead';
import {resetPasswordSlice} from '../store/reducers/resetPasswordReducer';
function LostPassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {api: socket} = useContext(RequestContext);
  const email = useRef();
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(resetPasswordSlice.actions.setEmail(email.current.value));
    await socket.forgotPassword({email: email.current.value}, dispatch, history.push, '/ponistavanje-lozinke');
    if (email?.current?.value) {
      email.current.value = '';
    }
  };
  return (
    <>
      <Underhead title="Zaboravljena šifra" items={[{link: '', title: 'Zaboravljena šifra'}]} />
      <section className="section">
        <div className="container">
          <div className="jumbotron">
            <h2 className="title">Promjenite Vašu šifru</h2>
            <p>Molimo Vas da upišete Vašu email adresu kako bi Vam mogli poslati novu šifru.</p>
            <div className="separator"></div>
            <h5 className="form_default_label">Vaša email adresa</h5>
            <label className="form_default_input form_default_w_icon">
              <input type="email" ref={email} placeholder="Unesite Vašu email adresa" />
              <img src="/images/icon_envelope.svg" alt="email" />
            </label>
            <div className="row">
              <div className="col-lg-6">
                <Link to="#" onClick={handleSubmit} className="button w-100">
                  <b>Pošaljite</b>
                </Link>
              </div>
              <div className="col-lg-6">
                <Link to="/registracija" className="button button_light w-100">
                  Nemate nalog? Registrujte se
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LostPassword;

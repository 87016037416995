import {RequestContext} from '../App';
import {useContext, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import Post from '../components/post';
import '../styles/home.scss';
import Modal from '../components/modal';
import withSeo from '../components/seo';

function Home() {
  const dispatch = useDispatch();

  const categories = useSelector((state) => state.categories.categories);
  let {newestArticles} = useSelector((state) => state.articles);
  const {api: socket} = useContext(RequestContext);
  const [activeList, setActiveList] = useState('newest');
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [question, setQuestion] = useState({email: '', question: ''});
  const closeModal = () => setOpenModal(false);

  const handleList = async (e) => {
    setActiveList(e.target.name);
    setPage(1);
    await socket.getArticles({where: {[e.target.name]: true}, payload: {page: 1, count: 24}}, dispatch, 'setNewest');
  };
  const handleMore = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setPage(page + 1);
    await socket.getMoreArticles({where: {[activeList]: true}, payload: {page: page + 1, count: 24}}, dispatch, 'setNewestLoadMore');
  };

  const handleChange = (e) => {
    setQuestion((prev) => ({...prev, [e.target.name]: e.target.value}));
  };

  const handleQuestion = async (e) => {
    e.preventDefault();
    if (!question.email.length || !question.question.length) return;
    await socket.askQuestion(question, dispatch);
    setQuestion({email: '', question: ''});
    setOpenModal(false);
  };

  useEffect(() => {
    const fetchMyApi = async () => {
      await socket.getArticles({where: {newest: true, excluded: 897}, payload: {page: 1, count: 24}}, dispatch, 'setNewest');
    };
    fetchMyApi();
  }, []);

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="title">Lista oglasa</h2>
            </div>
            <div className="col-lg-6 navbar-homepage">
              <ul className="home_tabs">
                <li>
                  <Link to="#" className={activeList === 'newest' ? 'active' : ''} name="newest" onClick={(e) => handleList(e)}>
                    Najnovije
                  </Link>
                </li>
                <li>
                  <Link to="#" className={activeList === 'featured' ? 'active' : ''} name="featured" onClick={(e) => handleList(e)}>
                    Izdvojeno
                  </Link>
                </li>
                <li>
                  <Link to="#" className={activeList === 'isShop' ? 'active' : ''} name="isShop" onClick={(e) => handleList(e)}>
                    Prodavnice
                  </Link>
                </li>
                <li className=" _tabs_more">
                  <Link to="/kategorija">Pogledaj sve</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="separator"></div>
          <div className="row row_20 posts_5">
            {newestArticles?.articles?.map((article) => (
              <Post key={article.id} articleprops={{...article}} categories={categories} />
            ))}
          </div>
          <div className="row mt-3">
            <div className="col-12 text-center">
              {newestArticles?.hasNext && (
                <button className="button" onClick={handleMore}>
                  Učitaj više
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
      <Modal onRequestClose={closeModal} isOpen={openModal}>
        <div className="col-md-12 p-0">
          <label className="form_default_input mb-2">
            <input type="email" placeholder="Molimo upišite vas email." name="email" value={question.email} onChange={handleChange} />
          </label>
          <label className="form_default_input">
            <textarea
              type="text"
              placeholder="Ukoliko imate pitanje, možete nas kontaktirati putem ove forme, a mi ćemo se potruditi da vam odgovorimo u najkraćem roku!"
              name="question"
              onChange={handleChange}
            >
              {question.question}
            </textarea>
            <button className="button w-100" onClick={handleQuestion}>
              Postavi pitanje
            </button>
          </label>
        </div>
      </Modal>
    </>
  );
}

export default withSeo(Home);

import ProfileList from '../components/profileList';
import ProfileHead from '../components/profileHead';
import ProfileInfo from '../components/profileInfo';
import ProfileReviews from '../components/profileReviews';
import ProfileReview from '../components/profileReview';
import Pagination from '../components/pagination';
import '../styles/profile.scss';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {dateFormat} from '../helpers/utilities';
import {useEffect} from 'react';
import {useContext} from 'react';
import {RequestContext} from '../App';
import {useState} from 'react';

function Reviews() {
  const {api: socket} = useContext(RequestContext);
  const dispatch = useDispatch();
  const {id} = useParams();
  const {userProfile, currentUser, user} = useSelector((state) => state.auth);
  const isMyProfile = currentUser?.uuid === id;
  const {impressions} = useSelector((state) => state.impressions);
  const {locations} = useSelector((state) => state.locations);
  const {impressionsReceiver, impressionsSender} = isMyProfile ? currentUser : userProfile;

  const [count] = useState(20);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const get = async () => {
      if (currentUser || userProfile)
        await socket.getImpressions(isMyProfile ? currentUser?.id : userProfile?.id, {page, count}, dispatch, user?.token);
    };
    get();
  }, [currentUser, userProfile, page]);

  const positiveImpressions = impressionsReceiver?.filter((i) => i.rating === 'pozitivan').length;
  const neutralImpressions = impressionsReceiver?.filter((i) => i.rating === 'neutralan').length;
  const negativeImpressions = impressionsReceiver?.filter((i) => i.rating === 'negativan').length;

  const location = locations?.find((location) => location.id === (isMyProfile ? currentUser?.cityId : userProfile?.cityId));

  return (
    <>
      <ProfileHead username={isMyProfile ? currentUser?.userName : userProfile?.userName} online={false} myProfile={isMyProfile} />
      <section className="section profile">
        <div className="container">
          <ProfileList />
          <div className="row">
            <div className="col-lg-7">
              <ProfileReviews positive={positiveImpressions} negative={negativeImpressions} neutral={neutralImpressions} uuid={id} />
            </div>
            <div className="col-lg-5">
              <ProfileInfo
                location={location?.name}
                createdAt={isMyProfile ? dateFormat(currentUser?.createdAt) : dateFormat(userProfile?.createdAt)}
              />
            </div>
          </div>
          <h2 className="title">
            Ukupno dojmova <span className="badge">{impressions?.total}</span>
          </h2>
          <div className="separator"></div>
          <div>
            {impressions?.impressions?.length ? (
              impressions?.impressions?.map((i) => (
                <ProfileReview
                  key={i.id}
                  currentUser={currentUser}
                  currentUserId={currentUser?.uuid}
                  id={id}
                  user={i.sender}
                  article={i.article}
                  comment={i.comment}
                  createdAt={i.createdAt}
                  rating={i.rating}
                  impressionsSender={impressionsSender}
                />
              ))
            ) : (
              <div className="detail_short">
                <div className="container">
                  <p>Korisnik nema nijedan ostavljen dojam!</p>
                </div>
              </div>
            )}
          </div>
          {!!impressions?.total && <Pagination articles={impressions} perPage={count} page={page} setPage={setPage} />}
        </div>
      </section>
    </>
  );
}

export default Reviews;

import {useContext, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {RequestContext} from '../App';
import {Input} from '../components/inputs';

export default function DeleteMe() {
  const {user} = useSelector((state) => state.auth);
  const [password, setPassword] = useState('');
  const {api: socket} = useContext(RequestContext);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await socket.deleteUser({password, uuid: user?.uuid}, dispatch, history, user?.token);
  };
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="profile_settings mt-30">
            <h3>Da bi izbrisali vaš nalog, molimo unesite lozinku</h3>
            <div className="mb-4">
              <Input type="password" name={'currentPassword'} value={password} label={'Unesite lozinku'} handleChange={handleChange} />
            </div>
            <div className="row">
              <div className="col-lg-4">
                <button className="button w-100" onClick={handleSubmit} disabled={password < 6}>
                  Obriši nalog
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  attributes: [],
  requiredAttributes: [],
};

export const attributesSlice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {
    setAttributes: (state, action) => {
      state.attributes = [...action.payload];
      return state;
    },
    setRequiredAttributes: (state, action) => {
      state.requiredAttributes = [...action.payload];
      return state;
    },
    resetState: (state) => {
      state = initialState;
      return state;
    },
  },
});

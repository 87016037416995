import {combineReducers} from '@reduxjs/toolkit';
import {authSlice} from './authReducer';
import {commonSlice} from './commonReducer';
import {categoriesSlice} from './categoriesReducer';
import {articlesSlice} from './articlesReducer';
import {locationsSlice} from './locationsReducer';
import {imagesSlice} from './imagesReducer';
import {resetPasswordSlice} from './resetPasswordReducer';
import {newUserSlice} from './newUserReducer';
import {newArticleSlice} from './newArticleReducers';
import {attributesSlice} from './attributesReducer';
import {notificationsSlice} from './notificationsReducer';
import {messagesSlice} from './messagesReducer';
import {chatsSlice} from './chatsReducer';
import {impressionsSlice} from './impressionsReducer';
import {filtersSlice} from './filtersReducer';
export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  common: commonSlice.reducer,
  categories: categoriesSlice.reducer,
  articles: articlesSlice.reducer,
  locations: locationsSlice.reducer,
  images: imagesSlice.reducer,
  resetPassword: resetPasswordSlice.reducer,
  newUser: newUserSlice.reducer,
  newArticle: newArticleSlice.reducer,
  attributes: attributesSlice.reducer,
  notifications: notificationsSlice.reducer,
  messages: messagesSlice.reducer,
  chats: chatsSlice.reducer,
  impressions: impressionsSlice.reducer,
  filters: filtersSlice.reducer,
});

import {Link} from 'react-router-dom';

export default function ProfileReviews({positive, negative, neutral, uuid}) {
  return (
    <div className="row row_10">
      <div className="col-4">
        <div className="profile_review">
          <h5>Pozitivnih dojmova</h5>
          <Link to={`/dojmovi/${uuid}`} className="like">
            <span>{positive ? positive : 0}</span>
            <figure>
              <img src="/images/like.svg" alt="like" />
            </figure>
          </Link>
        </div>
      </div>
      <div className="col-4">
        <div className="profile_review">
          <h5>Neutralnih dojmova</h5>
          <Link to={`/dojmovi/${uuid}`} className="neutral">
            <span>{neutral ? neutral : 0}</span>
            <figure>
              <img src="/images/neutral.svg" alt="neutral" />
            </figure>
          </Link>
        </div>
      </div>
      <div className="col-4">
        <div className="profile_review">
          <h5>Negativnih dojmova</h5>
          <Link to={`/dojmovi/${uuid}`} className="dislike">
            <span>{negative ? negative : 0}</span>
            <figure>
              <img src="/images/dislike.svg" alt="dislike" />
            </figure>
          </Link>
        </div>
      </div>
    </div>
  );
}

import ReactModal from 'react-modal';
import useMedia from 'use-media';

export default function Modal({isOpen = false, children, onRequestClose}) {
  const notMobile = useMedia({minWidth: 480});
  return (
    <ReactModal
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
        content: {
          backgroundColor: 'rgb(225,225,225)',
          width: notMobile ? '30%' : '85%',
          inset: 'unset',
          position: 'unset',
          height: 'auto',
          padding: '20px 0px',
          margin: notMobile ? '10% auto' : '50% auto',
          // margin: '30% 10px',
        },
      }}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
    >
      {children}
    </ReactModal>
  );
}

export function GalleryModal({isOpen = false, children, onRequestClose}) {
  const notMobile = useMedia({minWidth: 480});

  return (
    <ReactModal
      dialogClassName="gallery-modal"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.95)',
          zIndex: '999999',
        },
        content: {
          position: 'unset',
          inset: 'unset',
          width: notMobile ? '70%' : 'auto',
          margin: notMobile ? '5% auto' : '50% auto',
          height: 'auto',
          padding: notMobile ? '20px' : '0px',
          backgroundColor: 'rgba(0, 0, 0, 1)',
          border: notMobile ? '1px solid rgb(255,255,255)' : '0px',
        },
      }}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
    >
      {children}
    </ReactModal>
  );
}

import {Link, useParams} from 'react-router-dom';
import Collapse from './collapse';
import {useSelector, useDispatch} from 'react-redux';
import {useState, useContext, useEffect, useRef} from 'react';
import {RequestContext} from '../App';
import {compare} from '../helpers/utilities';

export default function AsideSubCategory({
  count,
  order,
  currentPage,
  setPage,
  setSearchState,
  searchState,
  requiredAttributes,
  setCurrentSubSubCategory,
  setcurentSubSubSubCategory,
  isActive,
  handleSearch,
}) {
  const {api: socket} = useContext(RequestContext);
  const dispatch = useDispatch();
  const {id, subCategoryId} = useParams();

  const [selectedSubcategory, setSelectedSubCategory] = useState();
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState();
  const [selectedSubSubSubCategory, setSelectedSubSubSubCategory] = useState();
  const [subSubSubCategories, setSubSubSubCategories] = useState([]);
  const [showModel, setShowModel] = useState(null);
  const [active, setActive] = useState(isActive);
  const {locations} = useSelector((state) => state.locations);
  const {categories, subSubCategories} = useSelector((state) => state.categories);
  const subCategories = categories.find(({uuid}) => uuid === id)?.subcategories;

  const [currentCategory] = useState(() => categories.find((category) => category.uuid === id));
  const subcategoryId = subCategories?.find((subCategory) => subCategoryId === subCategory?.uuid)?.id;
  const min = useRef(null);
  const max = useRef(null);

  const minLoan = useRef(null);
  const maxLoan = useRef(null);

  useEffect(() => {
    const fetchMyApi = async () => {
      if (subCategoryId && categories.length) await socket.getSubSubCategories(subcategoryId, dispatch);
    };
    fetchMyApi();
    setSelectedSubCategory(subCategoryId);
  }, [subCategoryId, categories.length]);

  const pickSubSubCategory = async (subSubCategory) => {
    var originalSubSubSubCategories = [...subSubCategory.subsubsubcategories].reverse();
    var sortedSubSubSubCategories = originalSubSubSubCategories.sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1));

    setSubSubSubCategories(sortedSubSubSubCategories);
    setSelectedSubSubCategory(subSubCategory.uuid);
    setSelectedSubSubSubCategory(null);
    setCurrentSubSubCategory(subSubCategory.id);
    setcurentSubSubSubCategory(null);
    setSearchState((prev) => ({
      ...prev,
      subsubcategoryId: subSubCategory.id,
      subsubsubcategoryId: undefined,
    }));
    await handleSearch(null, {...searchState, subsubcategoryId: subSubCategory.id, subsubsubcategoryId: undefined});
    await socket.getRequiredAttributes(null, subSubCategory.id, null, dispatch);
  };

  const pickSubsubSubCategory = async (subSubSubCategory) => {
    setSelectedSubSubSubCategory(subSubSubCategory.uuid);
    setcurentSubSubSubCategory(subSubSubCategory.id);
    setSearchState((prev) => ({
      ...prev,
      subsubsubcategoryId: subSubSubCategory.id,
    }));
    await handleSearch(null, {...searchState, subsubsubcategoryId: subSubSubCategory.id});
    await socket.getRequiredAttributes(null, null, subSubSubCategory.id, dispatch);
  };

  const handleFilterLocation = (e) => {
    setSearchState((prev) => ({...prev, cityId: Number(e.target.value)}));
  };

  const handlePrice = (e) => {
    if (e.target.name === 'price_min') {
      setSearchState((prev) => ({...prev, payload: {count}, price: {...prev.price, min: min.current.value}}));
    } else {
      setSearchState((prev) => ({...prev, payload: {count}, price: {...prev.price, max: max.current.value}}));
    }
  };

  const handleConditions = (e) => {
    setSearchState((prev) => ({...prev, conditions: e.target.value}));
  };

  const handlePriceOrNegotiable = (e) => {
    if (e.target.value === 'all') {
      setSearchState((prev) => ({...prev, price: {min: 0, max: Number.MAX_VALUE}}));
    } else if (e.target.value === 'price') {
      setSearchState((prev) => ({...prev, price: {min: Number.MIN_VALUE, max: Number.MAX_VALUE}}));
    } else {
      setSearchState((prev) => ({...prev, price: {min: 0, max: Number.MIN_VALUE}}));
      min.current.value = undefined;
      max.current.value = undefined;
    }
  };

  const hadleSellingOrBuying = (e) => {
    if (e.target.value === 'all') {
      setSearchState((prev) => ({...prev, articleType: ''}));
    } else if (e.target.value === 'selling') {
      setSearchState((prev) => ({...prev, articleType: 'prodaja'}));
    } else if (e.target.value === 'renting') {
      setSearchState((prev) => ({...prev, articleType: 'iznajmljivanje'}));
    } else {
      setSearchState((prev) => ({...prev, articleType: 'kupovina'}));
    }
  };

  const handleLoan = (e) => {
    if (e.target.name === 'loan_min') {
      setSearchState((prev) => ({...prev, payload: {count}, loanInstallment: {...prev.loanInstallment, minLoan: minLoan.current.value}}));
    } else {
      setSearchState((prev) => ({...prev, payload: {count}, loanInstallment: {...prev.loanInstallment, maxLoan: maxLoan.current.value}}));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.target.name === 'price_min' || e.target.name === 'price_max') {
        handlePrice(e);
      } else if (e.target.name === 'loan_min' || e.target.name === 'loan_max') {
        handleLoan(e);
      }
    }
  };

  const handleTerms = (e) => {
    const attributeId = e.target.name.split('_')[0];
    if (e.target.name.includes('min')) {
      setSearchState((prev) => ({
        ...prev,
        payload: {count},
        terms: {...(prev.terms ?? {}), [attributeId]: {...(prev.terms?.[attributeId] ?? {}), min: e.target.value}},
      }));
    } else if (e.target.name.includes('max')) {
      setSearchState((prev) => ({
        ...prev,
        payload: {count},
        terms: {...(prev.terms ?? {}), [attributeId]: {...(prev.terms?.[attributeId] ?? {}), max: e.target.value}},
      }));
    } else {
      setSearchState((prev) => ({
        ...prev,
        payload: {count},
        terms: {...(prev.terms ?? {}), [attributeId]: {...(prev.terms?.[attributeId] ?? {}), max: e.target.value}},
      }));
    }
  };

  const handleTermsSelect = (e) => {
    const attributeId = e.target.name.split('_')[0];
    if (e.target.name.includes('min')) {
      setSearchState((prev) => ({
        ...prev,
        payload: {count},
        terms: {...(prev.terms ?? {}), [attributeId]: {...(prev.terms?.[attributeId] ?? {}), min: e.target.value}},
      }));

      return;
    }
    if (e.target.name.includes('max')) {
      setSearchState((prev) => ({
        ...prev,
        payload: {count},
        terms: {...(prev.terms ?? {}), [attributeId]: {...(prev.terms?.[attributeId] ?? {}), max: e.target.value}},
      }));

      return;
    }
    if (e?.target?.dataset?.name === 'Marka') {
      setShowModel(requiredAttributes.find((attr) => attr.name === 'Marka').values.find((attr) => attr.name === e.target.value));
    }
    setSearchState((prev) => ({...prev, payload: {count}, terms: {...prev.terms, [e.target.name]: {value: e.target.value}}}));
  };

  useEffect(() => {
    setShowModel(null);
  }, [subCategoryId]);

  const showModelM = () => {
    const a = {...showModel, values: showModel.modelAttributeValues, name: 'Model', type: 'select'};

    return a;
  };
  const renderAttributes = () => {
    if (showModel) {
      const findMake = requiredAttributes.findIndex((make) => make.name === 'Marka');
      showModelM();
      return [requiredAttributes[findMake], showModelM(), ...requiredAttributes.slice(findMake + 1)].map((attr) => {
        return renderByType(attr, handleTerms, handleTermsSelect, handleSearch);
      });
    }
    return requiredAttributes?.map((attr) => {
      return renderByType(attr, handleTerms, handleTermsSelect, handleSearch);
    });
  };
  return (
    <aside className={`category_aside ${active ? 'active' : ''}`}>
      <span className="category_aside_close" onClick={() => setActive(!active)}></span>
      <div className="category_aside_wrap">
        <Collapse key="collapse-1" title="Kategorije" elementsCount={categories?.length + (currentCategory?.subcategories?.length || 0)}>
          <ul className="category_aside_list">
            {categories?.map?.(({name, uuid}) => {
              return (
                <li className={`d-flex justify-content-between align-items-center ${uuid === id ? 'has-children' : ''}`} key={uuid}>
                  {uuid === id && <Link to={`/kategorija/${uuid}?page=1`}>{name}</Link>}
                  {uuid === id && (
                    <ul className="category_aside_list">
                      {subCategories?.map?.(({name, uuid}) => {
                        return (
                          <li
                            className={`d-flex justify-content-between align-items-center ${uuid === subCategoryId ? 'has-children' : ''}`}
                            key={uuid}
                          >
                            <Link
                              to={`/kategorija/${id}/potkategorija/${uuid}?page=1`}
                              onClick={() => {
                                setSearchState({});
                                setSelectedSubSubCategory(null);
                                setSelectedSubSubSubCategory(null);
                                setSubSubSubCategories([]);
                                setSelectedSubCategory(uuid);
                                setCurrentSubSubCategory(null);
                                setcurentSubSubSubCategory(null);
                                min.current.value = undefined;
                                max.current.value = undefined;
                              }}
                            >
                              {name}
                            </Link>

                            {uuid === selectedSubcategory && (
                              <ul>
                                {subSubCategories.map((ssc) => (
                                  <li className={selectedSubSubCategory === ssc.uuid ? 'has-children' : ''}>
                                    <Link to="#" onClick={() => pickSubSubCategory(ssc)}>
                                      {ssc.name}
                                    </Link>
                                    <ul className={!!subSubSubCategories.length && selectedSubSubCategory === ssc.uuid ? 'subsubsub' : ''}>
                                      {!!subSubSubCategories.length &&
                                        ssc.uuid === selectedSubSubCategory &&
                                        subSubSubCategories.map((sssc) => (
                                          <li className={selectedSubSubSubCategory === sssc.uuid ? 'has-children' : ''}>
                                            <Link to="#" onClick={() => pickSubsubSubCategory(sssc)}>
                                              {sssc.name}
                                            </Link>
                                          </li>
                                        ))}
                                    </ul>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </Collapse>
        <Collapse key="collapse-2" title="Lokacija/Grad">
          <div className="collapse_default_wrap">
            <label className="form_default_select mb-0">
              <select value={searchState.cityId || ''} onChange={handleFilterLocation}>
                <option value="">Sve</option>
                {locations.length &&
                  locations.map(({name, id, uuid}) => (
                    <option key={uuid} value={id}>
                      {name}
                    </option>
                  ))}
              </select>
            </label>
            <button className="button w-100 btn-search" onClick={handleSearch}>
              Pretraži
            </button>
          </div>
        </Collapse>
        <Collapse key="collapse-11" title="Stanje">
          <div className="collapse_default_wrap">
            <label className="form_default_select mb-0">
              <select onChange={handleConditions}>
                <option value="">Sve</option>
                <option key={'novo-uuid'} value={'novo'}>
                  Novo
                </option>
                <option key={'polovno-uuid'} value={'polovno'}>
                  Polovno
                </option>
              </select>
            </label>
            <button className="button w-100 btn-search" onClick={handleSearch}>
              Pretraži
            </button>
          </div>
        </Collapse>
        <Collapse key="collapse-3" title="Cijena">
          <div className="category_aside_range">
            <div className="row row_10">
              <div className="col-6">
                <label>Od (KM)</label>
                <input type="number" name="price_min" ref={min} onBlur={handlePrice} />
              </div>
              <div className="col-6">
                <label>Do (KM)</label>
                <input type="number" name="price_max" ref={max} onBlur={handlePrice} />
              </div>
            </div>
            <button className="button w-100 btn-search" onClick={handleSearch}>
              Pretraži
            </button>
          </div>
        </Collapse>

        <Collapse key="collapse-6" title="Rata">
          <div className="category_aside_range">
            <div className="row row_10">
              <div className="col-6">
                <label>Od (KM)</label>
                <input type="number" ref={minLoan} name="loan_min" onBlur={handleLoan} onKeyDown={handleKeyDown} />
              </div>
              <div className="col-6">
                <label>Do (KM)</label>
                <input type="number" ref={maxLoan} name="loan_max" onBlur={handleLoan} onKeyDown={handleKeyDown} />
              </div>
            </div>
            <button className="button w-100 btn-search" onClick={handleSearch}>
              Pretraži
            </button>
          </div>
        </Collapse>

        <Collapse key="collapse-4" title="Ostali filteri" elementsCount={5}>
          <div className="collapse_default_wrap" onChange={handlePriceOrNegotiable}>
            <label className="form_default_check radio">
              <input type="radio" value="all" name="type-1" defaultChecked />
              <span></span> Sve
            </label>
            <label className="form_default_check radio d-block">
              <input type="radio" value="price" name="type-1" />
              <span></span> Sa unesenom cijenom
            </label>
            <label className="form_default_check radio d-block">
              <input type="radio" value="negotiable" name="type-1" />
              <span></span> Cijena po dogovoru
            </label>
            <button className="button w-100 btn-search" onClick={handleSearch}>
              Pretraži
            </button>
          </div>
        </Collapse>
        <Collapse key="collapse-5" title="Vrsta artikla" elementsCount={3}>
          <div className="collapse_default_wrap" onChange={hadleSellingOrBuying}>
            <label className="form_default_check radio">
              <input type="radio" value="all" name="type" defaultChecked />
              <span></span> Sve
            </label>
            <label className="form_default_check radio d-block">
              <input type="radio" value="selling" name="type" />
              <span></span> Samo prodaja
            </label>
            <label className="form_default_check radio d-block">
              <input type="radio" name="type" value="renting" />
              <span></span> Samo iznajmljivanje
            </label>
            <label className="form_default_check radio d-block">
              <input type="radio" value="buying" name="type" />
              <span></span> Samo potražnja
            </label>
            <button className="button w-100 btn-search" onClick={handleSearch}>
              Pretraži
            </button>
          </div>
        </Collapse>
        {renderAttributes()}
      </div>
      {active && (
        <button onClick={() => setActive(!active)} className="btn-close-modal">
          <img src="/images/search.svg" alt="" />
        </button>
      )}
    </aside>
  );
}

const renderByType = (attribute, handleTerms, handleTermsSelect, handleSearch) => {
  if (attribute?.type === 'select') {
    return CollapseOptions(attribute, handleTermsSelect, handleSearch);
  }
  if (attribute?.type === 'number') {
    return CollapseRange(attribute, handleTerms, handleSearch);
  }
};

const CollapseOptions = (attribute, handleTermsSelect, handleSearch) => {
  if (!attribute.hasRange) {
    return (
      <Collapse key={attribute.id} title={attribute.name} open={false}>
        <div className="collapse_default_wrap">
          <label className="form_default_select mb-0">
            <select name={attribute.id} defaultValue onChange={handleTermsSelect} data-name={attribute.name}>
              <option value="">SVE</option>
              {[...attribute.values].sort(compare).map(({name, id, uuid}) => (
                <option key={uuid} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </label>
          <button className="button w-100 btn-search" onClick={handleSearch}>
            Pretraži
          </button>
        </div>
      </Collapse>
    );
  }
  return (
    <Collapse key={attribute.id} title={attribute.name} open={false}>
      <div className="collapse_default_wrap">
        <div className="row">
          <div className="col-6">
            <label className="form_default_select mb-0">
              <select name={`${attribute.id}_min`} defaultValue onChange={handleTermsSelect} data-name={attribute.name}>
                <option value="">Od</option>
                {[...attribute.values].sort(compare).map(({name, id, uuid}) => (
                  <option key={uuid} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="col-6">
            <label className="form_default_select mb-0">
              <select name={attribute.id} defaultValue onChange={handleTermsSelect} data-name={attribute.name}>
                <option value="">Do</option>
                {[...attribute.values].sort(compare).map(({name, id, uuid}) => (
                  <option key={uuid} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
        <button className="button w-100 btn-search" onClick={handleSearch}>
          Pretraži
        </button>
      </div>
    </Collapse>
  );
};

const CollapseRange = (attribute, handleTerms, handleSearch) => (
  <Collapse key={attribute.id} title={attribute.name} open={false}>
    <div className="category_aside_range">
      <div className="row row_10">
        <div className="col-6">
          <label>Od ({attribute.name})</label>
          <input type="number" name={`${attribute.id}_min`} onBlur={handleTerms} />
        </div>
        <div className="col-6">
          <label>Do ({attribute.name})</label>
          <input type="number" name={`${attribute.id}_max`} onBlur={handleTerms} />
        </div>
      </div>
      <button className="button w-100 btn-search" onClick={handleSearch}>
        Pretraži
      </button>
    </div>
  </Collapse>
);

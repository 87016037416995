import {Link} from 'react-router-dom';
import Pagination from '../components/pagination';
import '../styles/notifications.scss';
import {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RequestContext} from '../App';
import {dateFormat} from '../helpers/utilities';
import useDidMountEffect from '../hooks/useDidMountEffect';
import {SMALL_IMAGE} from '../api/apiBase';

function Notifikacije() {
  const dispatch = useDispatch();
  const {api: socket} = useContext(RequestContext);
  const {user} = useSelector((state) => state.auth);
  const {notifications} = useSelector((state) => state.notifications);
  const [count] = useState(20);
  const [page, setPage] = useState(1);
  const [notificationArticles, setNotificationArticles] = useState([]);
  let {newestArticles} = useSelector((state) => state.articles);

  useEffect(() => {
    const fetchMyApi = async () => {
      user?.id && (await socket.getNotifications(user?.id, {page: 1, count: 20}, dispatch, user?.token));
    };
    fetchMyApi();
  }, [user]);

  useEffect(() => {
    const getNotifications = async () => {
      if (notifications?.notifications && notifications?.notifications.length > 0) {
        var articleIds = getNotificationArticles('get');
        await socket.getArticles({where: {articleId: articleIds}, payload: {page: 1, count: 24}}, dispatch, 'setNewest');
      }
    };
    getNotifications();
  }, [notifications]);

  useEffect(() => {
    if (newestArticles?.articles && newestArticles.articles.length > 0) {
      getNotificationArticles('set');
    }
  }, [newestArticles]);

  const scrollToTop = () => {
    window.scrollTo({
      top: document.querySelector('.container').clientHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  useDidMountEffect(() => {
    const fetchMyApi = async () => {
      if (user?.id) await socket.getNotifications(user?.id, {page, count}, dispatch, user?.token);
    };
    fetchMyApi();
    scrollToTop();
  }, [page, user?.id]);

  const setSeenNotification = async (uuid) => {
    await socket.setSeenNotification(uuid, user?.token);
  };

  const deleteNotification = async (uuid) => {
    await socket.deleteNotification(uuid, dispatch);
    await socket.getNotifications(user?.id, {page, count}, dispatch, user?.token);
  };

  const getNotificationArticles = (action) => {
    let notificationArticleIds = [];
    let notificationArticlesArr = [];
    notifications?.notifications?.forEach((notification, i) => {
      let splitString = '';

      if (notification.link.indexOf('pitanja') > -1) {
        splitString = '/pitanja-odgovori/';
      } else if (notification.link.indexOf('detail') > -1) {
        splitString = '/detail/';
      } else if (notification.link.indexOf('dojmovi') > -1) {
        splitString = '/dojmovi/';
      }

      const linkSplit = splitString.length > 0 ? notification.link.split(splitString) : [];
      const articleId = linkSplit.length > 0 ? linkSplit[1] : '';
      let findArticle = {};

      if (articleId) {
        findArticle = newestArticles?.articles?.find((article) => article.uuid === articleId);
      }

      notificationArticlesArr.push(findArticle ? findArticle : {});
      notificationArticleIds.push(articleId);
    });
    if (action === 'get') {
      return notificationArticleIds;
    } else {
      setNotificationArticles(notificationArticlesArr);
    }
  };

  return (
    <>
      <section className="section notifications">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="notifications_items">
                {notifications?.notifications?.map((item, i) => (
                  <div className={'notifications_item' + (item.seen === false ? ' active' : '')} key={i}>
                    <div className="notifications_item_body">
                      <Link onClick={() => setSeenNotification(item.uuid)} to={item.link}>
                        <figure>
                          <img src="/images/logo_circle.svg" alt="logo" />
                        </figure>
                        <p>{item.body}</p>
                        <time>{dateFormat(item.createdAt)}</time>

                        {notificationArticles.length > 0 && notificationArticles[i] && notificationArticles[i].title && (
                          <div className="notification_article">
                            {notificationArticles[i].images?.length > 0 ? (
                              <img
                                src={SMALL_IMAGE + notificationArticles[i].images[0].url}
                                alt="logo"
                                className="notification_article_image"
                                width="45"
                              ></img>
                            ) : (
                              <img src="/images/placeholder_post.jpg" alt="logo" className="notification_article_image" width="45"></img>
                            )}
                            <p className="notification_article_title">{notificationArticles[i].title}</p>
                          </div>
                        )}
                      </Link>
                    </div>
                    <div className="notifications_item_options">
                      <button className="delete" onClick={() => deleteNotification(item.uuid)}>
                        <img src="/images/trash_white.svg" alt="trash" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              {notifications?.total ? (
                <Pagination page={page} setPage={setPage} perPage={count} articles={notifications} />
              ) : (
                <span>
                  <b>Nemate obavještenja!</b>
                </span>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Notifikacije;

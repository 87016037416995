import {useState} from 'react';
import {Helmet} from 'react-helmet';
import {FRONTEND_URL, MEDIUM_IMAGE} from '../api/apiBase';

const withSeo =
  (Component) =>
  ({...props}) => {
    const [title, setTitle] = useState(null);
    const [descripton, setDescripton] = useState(null);
    const [image, setImage] = useState(null);
    return (
      <>
        {!title || !descripton || !image ? (
          <>
            <Helmet
              meta={[
                {
                  property: 'og:url',
                  content: window.location.href,
                },
                {
                  property: 'og:type',
                  content: 'web',
                },
                {
                  property: 'og:title',
                  content: title ?? 'Lista.ba',
                },
                {
                  property: 'og:description',
                  content: descripton ?? 'Kupoprodaja, iznajmljivanje, potraznja, sve na jednom mjestu',
                },
                {
                  property: 'og:image',
                  content: !!image ? `${MEDIUM_IMAGE}/${image}` : `${FRONTEND_URL}/images/placeholder_post.jpg`,
                },
              ]}
            >
              <title>{title ?? 'Lista.ba'}</title>
            </Helmet>
            <Component {...props} setTitle={setTitle} setDescripton={setDescripton} setImage={setImage} />
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

export default withSeo;

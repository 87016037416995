import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useParams, useHistory} from 'react-router-dom';
import {LARGE_IMAGE} from '../api/apiBase';
import {RequestContext} from '../App';
import '../styles/detail.scss';
import {calculator, cleanUpArticle, excludeNewOrUsed} from '../helpers/utilities';
import withSeo from '../components/seo';
import {CategoryLink} from '../components/categoryLink';
import {CheckBox, Options, InputNumber} from '../components/formInputs';
import {v4 as uuid} from 'uuid';
import TextEditor from '../components/textEditor';
import ImageGallaryComponent from '../components/slider';
import Compress from 'compress.js';
import ImageUploading from 'react-images-uploading';
import Modal from '../components/modal';
import useMedia from 'use-media';
import {commonSlice} from '../store/reducers/commonReducer';

const uniqueImages = (images) => {
  if (!images?.length) return [];
  const unique = [];

  for (const img of images) {
    if (!unique.find((image) => image.uuid === img.uuid)) unique.push(img);
  }
  return unique;
};

const articleTypeEnum = ['prodaja', 'iznajmljivanje', 'kupovina'];

const pause = (ms) => Promise.resolve(setTimeout, ms);
async function resizeImageFn(file) {
  const base64Response = await fetch(file.data_url);
  const blob = await base64Response.blob();

  const compress = new Compress();
  const resizedImage = await compress.compress([blob], {
    size: 2, // the max size in MB, defaults to 2MB
    quality: 0.9, // the quality of the image, max is 1,
    maxWidth: 1200, // the max width of the output image, defaults to 1920px
    maxHeight: 800, // the max height of the output image, defaults to 1920px
    resize: true, // defaults to true, set false if you do not want to resize the image width and height
  });
  const img = resizedImage[0];
  const base64str = img.data;
  const imgExt = img.ext;
  const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt);
  return resizedFiile;
}
export function EditArticle({setTitle, setDesription, setImage}) {
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => setOpenModal(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const {api: socket} = useContext(RequestContext);
  const {article} = useSelector((state) => state.articles);
  const {currentUser, user} = useSelector((state) => state.auth);
  const {locations} = useSelector((state) => state.locations);
  const {id} = useParams();
  const {categories} = useSelector((state) => state.categories);
  const getAttributeValue = (lookup, values) => values?.find?.((value) => lookup === value.id);
  const [subCategories, setSubCategories] = useState();
  const attributes = useSelector((state) => state.attributes.attributes) || [];
  const [updateArticleValues, setUpdateArticleValues] = useState({});
  const [updateMajorArticleState, setUpdateMajorArticleState] = useState();
  const notMobile = useMedia({minWidth: 480});
  let loanInstallment = [];
  const bottom = notMobile ? '20px' : '70px';
  const dontHaveImage = ['Ljepota i Zdravlje', 'Video igre', 'Kolekcionarstvo', 'Antikviteti', 'Hrana i piće', 'Bebe', 'Poslovi'];
  const [placeholderImageSrc, setPlaceholderImageSrc] = useState('');
  const [selectedImage, setSelectedImage] = useState(0);

  useEffect(() => {
    const getCategories = async () => {
      await socket.getArticle(id, dispatch);
      await socket.getCategories({}, dispatch);
    };
    getCategories();
    setSubCategories(getAttributeValue(article?.categoryId, categories)?.subcategories);
    const attributes = async () => {
      if (article?.subcategoryId) {
        setUpdateArticleValues(article?.values);
        setUpdateMajorArticleState(article);
        await socket.getAttributes(
          {parentId: article.subcategoryId, subSubCategoryId: article.subsubcategoryId, subsubSubCategoryId: article.subsubsubcategoryId},
          dispatch
        );
      }
    };
    attributes();

    if (article?.categoryId) {
      var placeholderImgSrc = '';
      var categoryName = categories?.find((category) => category.id === article?.categoryId)?.name;
      if (dontHaveImage.indexOf(categoryName) === -1) {
        placeholderImgSrc = `/images/cat${article?.categoryId}-lg.svg`;
      } else {
        placeholderImgSrc = `/images/placeholder_post.jpg`;
      }
      setPlaceholderImageSrc(placeholderImgSrc);
    }
    if (article?.userId && user.id && article.userId !== user.id) {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, article?.subcategoryId, categories.length, JSON.stringify(subCategories), attributes.length]);

  const handleUpdate = async (e) => {
    await socket.updateArticle(
      {
        uuid: id,
        payload: {article: cleanUpArticle(updateMajorArticleState), values: updateArticleValues},
      },
      dispatch,
      history,
      user.token
    );
  };

  const handleCheckbox = (e) => {
    if (e.target.name === 'negotable' && e.target.checked) {
      loanInstallment = [0, 0];
      setUpdateMajorArticleState((prev) => ({...prev, loanInstallment, price: 0}));
    }

    setUpdateMajorArticleState((prev) => ({...prev, [e.target.name]: !prev[e.target.name]}));
  };

  const updateArticle = (e) => {
    const addAttribute = updateArticleValues.filter((attribute) => attribute.name.replace('*', '') !== e.target.name);
    const attributeExists = updateArticleValues.find?.((attribute) => attribute.name.replace('*', '') === e.target.name);
    if (!attributeExists || (e.target.value.length && attributeExists.value !== e.target.value)) {
      addAttribute.push({
        name: e.target.name.replace('*', ''),
        value: e.target.value,
        attributeId: e.target.getAttribute('parentid'),
        attributeValueId: parseInt(e.target.getAttribute('attrid')),
        articleId: article?.id,
        orderNumber: parseInt(e.target.getAttribute('orderId')),
      });
    }
    setUpdateArticleValues(addAttribute);
  };

  const handleSold = async (confirm) => {
    if (confirm) await socket.markAsSold({uuid: article?.uuid, status: 'prodan'}, dispatch, history, user?.token);
    setOpenModal(false);
  };

  const handleDescription = (e) => {
    setUpdateMajorArticleState((prev) => ({...prev, description: e}));
  };
  const handleMajor = (e) => {
    if (e.target.name === 'price') {
      const articlePrice = e.target.value;
      const articleType = categories?.filter((category) => category.id === article?.categoryId)[0].name;
      loanInstallment = calculator(Number(articlePrice), articleType, null);
      if (articlePrice > 0) {
        setUpdateMajorArticleState((prev) => ({...prev, negotable: false}));
      }

      if (!loanInstallment.length) loanInstallment = [0, 0];
    }
    setUpdateMajorArticleState((prev) => ({...prev, [e.target.name]: e.target.value, loanInstallment}));
  };

  const handleDeleteImage = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await socket.deleteImage({uuid: e.target.id}, dispatch, user.token);
    setSelectedImage(0);
  };

  const addImageCallback = async (pic, index) => {
    try {
      dispatch(commonSlice.actions.setIsLoading(true));

      for (let i of index) {
        await pause(100);
        const image = await resizeImageFn(pic[i]);
        const form = new FormData();
        form.append('image', image);
        await socket.appendImage({form, articleId: article?.id}, dispatch, null, user?.token, setNewImages);
      }
      setImages(pic);
    } catch (error) {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  };
  const generateOptions = (option) => {
    const name = option.name;
    const type = option.type;
    if (type === 'number') {
      return (
        <InputNumber
          name={name}
          key={option.uuid}
          handleChange={updateArticle}
          value={updateArticleValues?.find?.((attribute) => attribute.name === name)?.value ?? ''}
          parentid={option.parentId}
          attrid={option.id}
          orderid={option.orderNumber}
        />
      );
    } else if (type === 'checkbox') {
      return (
        <CheckBox
          key={uuid()}
          name={name}
          clickHandler={updateArticle}
          parentid={option.parentId}
          attrid={option.id}
          orderid={option.orderNumber}
          checked={updateArticleValues?.find?.((attribute) => attribute.name === name)?.value === 'on'}
        />
      );
    } else if (name.includes('Marka')) {
      const ModelName = updateArticleValues?.find?.((attribute) => attribute.name.replace('*', '') === name)?.value;
      const makes =
        attributes.find(({name}) => name.includes('Marka'))?.values?.find(({name}) => name === ModelName)?.modelAttributeValues ?? [];

      return (
        <>
          <Options
            key={uuid()}
            name={name}
            value={option.values}
            onChange={updateArticle}
            parentid={option.parentId}
            attrid={option.id}
            orderid={option.orderNumber}
            selected={updateArticleValues?.find?.((attribute) => attribute.name.replace('*', '') === name)?.value}
          />
          <Options
            key={uuid()}
            name={'Model'}
            value={makes}
            onChange={updateArticle}
            parentid={option.parentId}
            attrid={option.id}
            orderid={option.orderNumber}
            selected={updateArticleValues?.find?.((attribute) => attribute.name === 'Model')?.value}
          />
        </>
      );
    }
    return (
      <Options
        key={uuid()}
        name={name}
        value={option.values}
        onChange={updateArticle}
        parentid={option.parentId}
        attrid={option.id}
        orderid={option.orderNumber}
        selected={updateArticleValues?.find?.((attribute) => attribute.name.replace('*', '') === name)?.value}
      />
    );
  };

  const [btnFixedStyle] = useState({position: 'fixed', bottom, right: '20px', width: '30%', zIndex: 5});
  const handleScroll = () => {};

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {passive: false});

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleArticleType = (e) => {
    setUpdateMajorArticleState((prev) => ({
      ...prev,
      articleType: e.target.value,
    }));
  };

  return (
    <div className="detail edit-page">
      <div className="container">
        <div className="detail_slider">
          <div className="detail_slider_wrap">
            <div className="detail_slider_circles left"></div>
            <div className="detail_slider_circles right"></div>
            <ImageGallaryComponent isEdit={true} selectedImage={selectedImage} setSelectedImage={setSelectedImage} className="mb-3">
              {uniqueImages(article?.images).map(({url, uuid}) => (
                <a href={LARGE_IMAGE + url} className="lightbox-link">
                  <button className="delete-image" id={uuid} onClick={handleDeleteImage}>
                    <img src="/images/trash_white.svg" alt="Remove" />
                  </button>
                  <img src={LARGE_IMAGE + url} className="img-fluid" alt="detail" />
                </a>
              ))}
            </ImageGallaryComponent>
            {!article?.images?.length && (
              <div className="detail_slider_item">
                <img src={placeholderImageSrc} className="img-fluid no-image" alt="detail" />
              </div>
            )}
          </div>
        </div>
        <div className="form_default_image">
          <div>
            <h3>Ovde dodajte Vaše slike</h3>
            <ImageUploading
              multiple
              value={images}
              dataURLKey="data_url"
              onChange={addImageCallback}
              maxFileSize={25242880}
              maxNumber={Math.abs(25 - (article?.images?.length ?? 0))}
              onError={(e, b) => {
                console.log(e, b);
              }}
            >
              {({imageList, onImageUpload, onImageRemove, isDragging, dragProps}) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  {imageList.map((image, index) => (
                    <div key={index} className="image-item">
                      <img src={image['data_url']} alt="" width="100" />
                      <div className="image-item__btn-wrapper">
                        <button
                          className="remove-image"
                          onClick={async (e) => {
                            e.preventDefault();
                            await socket.deleteImage({uuid: newImages[index]}, dispatch, user?.token);
                            setImages((prev) => [...prev.filter((_, i) => i !== index)]);
                            setNewImages((prev) => [...prev.filter((_, i) => i !== index)]);
                            onImageRemove(index);
                          }}
                        >
                          {' '}
                          <img src="/images/trash_white.svg" alt="Remove" />
                        </button>
                      </div>
                    </div>
                  ))}
                  {imageList.length < 20 && (
                    <button
                      disabled={article?.images?.length === 20}
                      style={isDragging ? {color: 'red'} : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Dodajte fotografiju
                    </button>
                  )}
                </div>
              )}
            </ImageUploading>{' '}
          </div>
          <div>
            <img src="/images/drag_drop.svg" alt="drag_drop" className="img-fluid" />
          </div>
        </div>
      </div>

      <div className="detail_user edit-page">
        <div className="container">
          <div className="row row_20">
            <div className="col-md-4 col-3">
              <Link to={`/user/${article?.user?.uuid}`}>
                <div className="detail_user_profile">
                  <img src="/images/user_placeholder.svg" alt="user" />
                  <div>
                    <h5>{article?.user?.name}</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-2 col-4 align-self-center">
              <label className="form_default_option w-100 mb-0">
                <button className="button finish-sale" key={uuid()} name="soldAt" onClick={() => setOpenModal(true)}>
                  Završi prodaju
                </button>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="detail_info">
        <div className="container">
          <div className="row row_10">
            {article?.cityId && (
              <div className="col-lg-3 col-6">
                <h5 className="detail_info_label">Lokaciju:</h5>
                <div className="detail_info_box">
                  <CategoryLink details={getAttributeValue(article?.cityId, locations)} />
                </div>
              </div>
            )}
            <div className="col-lg-3 col-6">
              <h5 className="detail_info_label">Kategorija:</h5>
              <div className="detail_info_box">
                <CategoryLink details={getAttributeValue(article?.categoryId, categories)} />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <h5 className="detail_info_label">Potkategorija:</h5>
              <div className="detail_info_box">
                <CategoryLink details={getAttributeValue(article?.subcategoryId, subCategories)} />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <h5 className="detail_info_label">Stanje:</h5>
              <div className="detail_info_box">{article?.conditions === 'novo' ? 'Novo' : 'Polovno'}</div>
            </div>
            <div className="col-lg-3 col-6">
              <h5 className="detail_info_label">Zamjena:</h5>
              <div className="detail_info_box">{article?.exchange ? 'Da' : 'Ne'}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="detail_features">
        <div className="container">
          <h2 className="title">Osnovni detalji</h2>
          <div className="separator"></div>
          <div className="detail_short">
            <div className="container">
              <h3>Naslov artikla</h3>
              <label className="form_default_input">
                <input
                  type="text"
                  placeholder="Upišite naslov"
                  name="title"
                  value={updateMajorArticleState?.title}
                  onChange={handleMajor}
                />
              </label>
              <br />
              <div className="row row_20"></div>
            </div>
            <div className="container">
              <h3>Dodatne informacije</h3>
              {updateMajorArticleState?.description?.length && (
                <TextEditor changeHandler={handleDescription} text={updateMajorArticleState?.description} />
              )}
            </div>

            <div className="container">
              <div className="row mb-3">
                <div className="col-md-4">
                  <label className="form_default_input form_default_w_icon">
                    <input
                      type="text"
                      placeholder="Upišite cijenu"
                      name={'price'}
                      onChange={handleMajor}
                      value={updateMajorArticleState?.price}
                    />
                    <img src="/images/km.svg" alt="km" />
                  </label>
                </div>
                <div className="col-md-4">
                  <label className="form_default_option w-100">
                    <input
                      key={uuid()}
                      type="checkbox"
                      name="negotable"
                      onChange={handleCheckbox}
                      checked={updateMajorArticleState?.negotable}
                    />
                    <span>Cijena po dogovoru</span>
                  </label>
                </div>

                <div className="col-md-4">
                  <label className="form_default_option w-100">
                    <input key={uuid()} type="checkbox" name="fixed" onChange={handleCheckbox} checked={updateMajorArticleState?.fixed} />
                    <span>Fiksno</span>
                  </label>
                </div>

                <div className="col-md-4">
                  <label className="form_default_option w-100">
                    <input
                      key={uuid()}
                      type="checkbox"
                      name="exchange"
                      onChange={handleCheckbox}
                      checked={updateMajorArticleState?.exchange}
                    />
                    <span>Zamjena</span>
                  </label>
                </div>
                {currentUser?.isShop && (
                  <div className="col-md-4">
                    <label className="form_default_option w-100">
                      <input
                        key={uuid()}
                        type="checkbox"
                        name="discounted"
                        onChange={handleCheckbox}
                        checked={updateMajorArticleState?.discounted}
                      />
                      <span>Akcija</span>
                    </label>
                  </div>
                )}
                <div className="col-md-4">
                  <label className="form_default_select">
                    <select name="articleType" defaultValue={article?.articleType} onChange={handleArticleType}>
                      {articleTypeEnum.map((type) => (
                        <option value={type}>{type}</option>
                      ))}
                    </select>
                  </label>
                </div>
                <div className="col-md-4">
                  <label className="form_default_select">
                    <select name="cityId" value={updateMajorArticleState?.cityId} onChange={handleMajor}>
                      {locations?.map((location) => (
                        <option value={location.id}>{location.name}</option>
                      ))}
                    </select>
                  </label>
                </div>
                {!excludeNewOrUsed(
                  article?.categoryId,
                  article?.subcategoryId,
                  article?.subsubcategoryId,
                  article?.subsubsubcategoryId
                ) && (
                  <div className="col-md-4">
                    <label className="form_default_select">
                      <select name="conditions" onChange={handleMajor} value={updateMajorArticleState?.conditions}>
                        <option value="polovno">Polovno</option>
                        <option value="novo">Novo</option>
                      </select>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <h2 className="title">Osobine</h2>
          <div className="separator"></div>
          <dl className="row">{attributes.map((attribute) => generateOptions(attribute))}</dl>
        </div>
      </div>
      <Modal onRequestClose={closeModal} isOpen={openModal}>
        <div className="col-md-12">
          <label className="form_default_input mt-4">
            <strong className="mt-4">Napomena</strong>
            Klikom na potvrdi, Vaš proizvod će biti označen kao zarvšen, i nećete biti u mogućnosti da više uređujete oglas ili mu
            promijenite status. Da li ste sigurni da želite da nastavite?
          </label>
          <div className="row mt-4">
            <button className="button w-100 col-4 offset-1" onClick={() => handleSold(false)}>
              Otkaži
            </button>
            <button className="button w-100 col-4 offset-2" onClick={() => handleSold(true)}>
              Potvrdi
            </button>
          </div>
        </div>
      </Modal>
      <button className="button" style={btnFixedStyle} onClick={handleUpdate}>
        Sačuvaj
      </button>
    </div>
  );
}

export default withSeo(EditArticle);

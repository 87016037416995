import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  where: {excluded: 897},
  payload: {
    page: 1,
    count: 20,
  },
  order: 'newest',
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    addCategory: (state, action) => {
      state.where = {
        ...initialState.where,
        categoryId: action.payload,
      };
      state.payload = {...initialState.payload};
      return state;
    },
    addSubCategory: (state, action) => {
      state.where = {
        ...initialState.where,
        categoryId: state.where.categoryId,
        subcategoryId: action.payload,
      };
      state.payload = {...initialState.payload};
      return state;
    },
    addSubSubCategory: (state, action) => {
      state.where = {
        ...initialState.where,
        categoryId: state.where.categoryId,
        subcategoryId: state.where.subcategoryId,
        subsubcategoryId: action.payload,
      };
      state.payload = {...initialState.payload};
      return state;
    },
    addSubSubSubCategory: (state, action) => {
      state.where = {
        ...initialState.where,
        categoryId: state.where.categoryId,
        subcategoryId: state.where.subcategoryId,
        subsubcategoryId: state.where.subsubcategoryId,
        subsubsubcategoryId: action.payload,
      };
      state.payload = {...initialState.payload};
      return state;
    },
    updateTerms: (state, action) => {
      state.where = {...state.where, ...action.payload};
      state.payload = {...initialState.payload};
      return state;
    },
    updateRange: (state, action) => {
      state.where[action.payload.key] = {...(state.where[action.payload.key] ?? {}), ...action.payload.value};
      state.payload = {...initialState.payload};
      return state;
    },
    updatePage: (state, action) => {
      state.payload = {...state.payload, page: action.payload};
      return state;
    },
    updatePerPage: (state, action) => {
      state.payload = {...state.payload, count: action.payload};
      return state;
    },
    updateOrder: (state, action) => {
      state.order = action.payload;
      return state;
    },
  },
});

import {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ImageUploading from 'react-images-uploading';
import {Link} from 'react-router-dom';
import {MEDIUM_IMAGE} from '../api/apiBase';
import {RequestContext} from '../App';
import {Input, DataList} from '../components/inputs';
import ProfileHead from '../components/profileHead';
import '../styles/profile.scss';
import {isCompany} from '../helpers/auth';
import {useHistory} from 'react-router-dom';
import TextEditor from '../components/textEditor';

function Postavke() {
  const {currentUser, user} = useSelector((state) => state.auth);
  const [editUser, seteditUser] = useState();
  const [, setNewDescription] = useState();
  const {locations} = useSelector((state) => state.locations);
  const {api: socket} = useContext(RequestContext);
  const placeholder = 'Recite nešto više o svojoj prodavnici! Zašto ste baš vi pravo mjesto za kupovinu?';
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser?.profileImage) seteditUser((prev) => ({...prev, profileImage: currentUser.profileImage}));
    seteditUser(currentUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentUser)]);

  const handleChange = (e) => {
    seteditUser((prev) => ({...prev, [e.target.name]: e.target.value}));
  };

  const handleCheckbox = (e) => {
    seteditUser((prev) => ({...prev, [e.target.name]: !prev[e.target.name]}));
  };

  const onDrop = async (image) => {
    await socket.createImage(image[0], dispatch, user?.token);
  };

  const handleLogout = () => {
    socket.logout({uuid: currentUser.uuid, id: currentUser.id}, dispatch, history.push, '/');
    socket.addToken(null);
  };

  const changeDescription = (description) => {
    seteditUser((prev) => ({...prev, description: description}));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    await socket.editUser({uuid: editUser.uuid, payload: editUser}, dispatch, user.token);
  };

  const location = locations?.find((location) => location.id === currentUser?.cityId);
  return (
    <>
      <ProfileHead name={editUser?.name} image={MEDIUM_IMAGE + editUser?.profileImage} online={true} myProfile={true} />
      <section className="section profile">
        <div className="container">
          <div className="profile_settings mt-30">
            <h3>Osnovne informacije</h3>
            <div className="mb-4">
              <Input name={'userName'} value={editUser?.userName} label={'Korisnicko ime'} disabled />
              <Input name={'name'} value={editUser?.name} label={'Ime i prezime'} handleChange={handleChange} />
              <Input name={'email'} value={editUser?.email} label={'Email'} type={'email'} disabled icon={'icon_envelope'} />
              <Input
                name={'telephone'}
                value={editUser?.telephone}
                label={'Broj telefona'}
                type={'tel'}
                icon={'icon_phone'}
                handleChange={handleChange}
              />
              <Input name={'address'} value={editUser?.address} label={'Adresa'} icon={'icon_address'} handleChange={handleChange} />
              <DataList name={'cityId'} data={locations} value={location?.name} handleChange={seteditUser} />
              {isCompany(editUser?.role) && (
                <>
                  <label for="edit-shop">Opis prodavnice</label>
                  <TextEditor
                    id="edit-shop"
                    changeHandler={changeDescription}
                    text={editUser?.description}
                    setNewDescription={setNewDescription}
                    placeholder={placeholder}
                  />
                </>
              )}
              <ImageUploading
                // {...images}
                withIcon={false}
                withLabel={false}
                withPreview={true}
                fileContainerStyle={{backgroundColor: '#4175DF', boxShadow: 'none'}}
                buttonStyles={{backgroundColor: '#fff', color: '#000', padding: '10px 15px'}}
                fileSizeError="Slika je prevelika"
                fileTypeError="Tip fotografije nije podržan"
                buttonText="Dodaj profilnu sliku"
                singleImage={true}
                onChange={(e) => {
                  onDrop(e);
                  e.value = null;
                }}
                imgExtension={['.jpg', '.png']}
                maxFileSize={5242880}
              />
            </div>
            {isCompany(editUser?.role) && (
              <>
                <h3>Podaci o pravnom licu</h3>
                <Input name={'companyName'} value={editUser?.companyName} label={'Naziv firme'} handleChange={handleChange} />
                <Input name={'companyPin'} value={editUser?.companyPin} label={'JIB'} handleChange={handleChange} />
                <label className="form_default_option w-50">
                  <input type="checkbox" name="vat" checked={editUser?.vat} onChange={handleCheckbox} />
                  <span>PDV obveznik</span>
                </label>
              </>
            )}
            <div className="row">
              <div className="col-lg-4">
                <button className="button w-100" onClick={handleUpdate}>
                  Sačuvaj izmjene
                </button>
              </div>
            </div>
          </div>
          <ul className="profile_list">
            <li>
              <Link to="promjena-sifre">
                <img src="/images/cog.svg" alt="icon" />
                Promjena šifre
              </Link>
            </li>
            <li>
              <Link to="/obrisi-me">
                <img src="/images/cog.svg" alt="icon" />
                Obriši nalog
              </Link>
            </li>
            <li>
              <Link onClick={handleLogout}>
                <img src="/images/cog.svg" alt="icon" />
                Odjava
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}

export default Postavke;

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  impressions: [],
};

export const impressionsSlice = createSlice({
  name: 'impressions',
  initialState,
  reducers: {
    setImpressions: (state, action) => {
      state.impressions = action.payload;
      return state;
    },
    resetState: (state) => {
      state = initialState;
      return state;
    },
  },
});

import {useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RequestContext} from '../App';
import Underhead from '../components/underhead';
function VerifyEmail() {
  const dispatch = useDispatch();
  const {api: socket} = useContext(RequestContext);
  const {user} = useSelector((state) => state.auth);

  useEffect(() => {
    const get = async () => {
      if (user.uuid) await socket.getUser(user.uuid, dispatch);
    };
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(user)]);

  return (
    <>
      <Underhead title="Potvrda email adrese" items={[{link: '', title: 'Potvrda email adrese'}]} />
      <section className="section">
        <div className="container">
          <div className="jumbotron">
            <h2 className="title">Potvrdite Vašu email adresu</h2>
            <h5 className="form_default_label">
              Da potvdite Vaš nalog, potrebno je da kliknete na aktivacioni link, koji ste dobili na Vašu email adresu.
            </h5>
            <div className="separator"></div>
          </div>
        </div>
      </section>
    </>
  );
}

export default VerifyEmail;

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  categories: [],
  subCategories: [],
  subSubCategories: [],
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = [...action.payload];
      return state;
    },
    setSubcategories: (state, action) => {
      state.subCategories = action.payload;
      return state;
    },
    setSubSubCategories: (state, action) => {
      state.subSubCategories = action.payload;
      return state;
    },
    resetState: (state) => {
      state = initialState;
      return state;
    },
    resetNewArticleState: (state) => {
      state.subCategories = [];
      state.subSubCategories = [];
      return state;
    },
  },
});

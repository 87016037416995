import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useParams, useHistory} from 'react-router-dom';
import {LARGE_IMAGE} from '../api/apiBase';
import {RequestContext} from '../App';
import {SvgHeart, SvgPrint, SvgShare, SvgGoBack} from '../components/svg';
import '../styles/detail.scss';
import Modal, {GalleryModal} from '../components/modal';
import {calculator, excludeNewOrUsed} from '../helpers/utilities';
import withSeo from '../components/seo';
import {VerifiedUser} from '../components/verifiedUser';
import {CategoryLink} from '../components/categoryLink';
import {ImageModalGallery} from '../components/slider';
import {SMALL_IMAGE} from '../api/apiBase';
import useMedia from 'use-media';
import {articlesSlice} from '../store/reducers/articlesReducer';
import SocialShare from '../components/social-share';
import useQuery from '../hooks/useQuery';
import {Sw} from '../components/swiper.js';

const articleTypeMap = {
  prodaja: 'prodaju',
  kupovina: 'kupovinu',
  iznajmljivanje: 'iznajmljivanje',
};

export function Detail({setTitle, setDesription, setImage}) {
  const history = useHistory();
  const query = useQuery();

  const notMobile = useMedia({minWidth: 480});
  const [openModal, setOpenModal] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [openThirdModal, setOpenThirdModal] = useState(false);
  const [openImpressionModal, setImpressionModal] = useState(false);
  const [openGalleryModal, setOpenGalleryModal] = useState(false);
  const [impression, setImpression] = useState('');
  const [ratingType, setRatingType] = useState('neutralan');
  const dispatch = useDispatch();
  const {api: socket} = useContext(RequestContext);
  const {article} = useSelector((state) => state.articles);
  const {currentUser, user} = useSelector((state) => state.auth);
  const {locations} = useSelector((state) => state.locations);
  const {id} = useParams();
  const {categories} = useSelector((state) => state.categories);
  const getAttributeValue = (lookup, values) => values?.find((value) => lookup === value.id);
  const subCategories = getAttributeValue(article?.categoryId, categories)?.subcategories;
  const findYear = article?.values?.find((value) => value.name === 'godiste')?.value;
  const isShop = article?.user?.isShop;
  const {impressionsSender} = currentUser;
  const isImpressionExist = impressionsSender?.find((i) => i.articleId === article?.id);
  const [selectedImage] = useState(0);
  const isMyArticleAndNotFinished = article?.userId === currentUser.id && !article?.soldAt;
  const [showTelephone, setShowTelephone] = useState(false);
  const [imagesArray, setImagesArray] = useState([]);
  const dontHaveImage = [];
  const [placeholderImageSrc, setPlaceholderImageSrc] = useState('');
  const [filesToShare, setFilesToShare] = useState([]);

  const isRequested = article?.salesRequests
    .map((saleRequest) => {
      return saleRequest.customerId === currentUser.id;
    })
    .find((isTrue) => isTrue === true);

  const isCustomer = article?.salesRequests
    .map((saleRequest) => {
      return saleRequest.customerId === currentUser.id && saleRequest.status === 'kupljen';
    })
    .find((isTrue) => isTrue === true);

  const [complain, setComplain] = useState('');
  const [[rs, fbih], setCredit] = useState([]);
  const [favorite, setFavorite] = useState(0);

  const handleComplain = async () => {
    if (!complain?.length) return;
    await socket.addComplain(
      {name: currentUser?.name, email: currentUser?.email, complain, articleUrl: window.location.href, title: article?.title},
      dispatch,
      user?.token
    );
    setOpenModal(false);
  };

  useEffect(() => {
    if (article?.categoryId) {
      const credit = calculator(article?.price, getAttributeValue(article?.categoryId, categories)?.name, findYear);
      if (credit.length) setCredit(credit);

      var placeholderImgSrc = '';
      var categoryName = '';
      if (categories && categories.length > 0) {
        categoryName = categories?.find((category) => category.id === article?.categoryId).name;
      }
      if (dontHaveImage.indexOf(categoryName) === -1) {
        placeholderImgSrc = `/images/cat${article?.categoryId}-lg.svg`;
      } else {
        placeholderImgSrc = `/images/placeholder_post.jpg`;
      }
      setPlaceholderImageSrc(placeholderImgSrc);
    }
    if (article?.images?.length) {
      var images = article?.images.slice().reverse();
      setImagesArray(images);

      createImgBlob();
    }

    if (article && currentUser && article.userId === currentUser?.id && !!query.get('buyRequest') && !article?.soldAt) {
      setOpenThirdModal(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article, currentUser]);

  const createImgBlob = async () => {
    var imgUrl = LARGE_IMAGE + article?.images[0]?.url;

    const response = await fetch(imgUrl);
    const blob = await response.blob();
    const file = new File([blob], 'test.jpg', {type: blob.type});
    setFilesToShare([file]);
  };

  useEffect(() => {
    const get = async () => {
      await socket.getArticle(id, dispatch);
    };
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFavorite(article?.favorites?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article?.favorites?.length]);
  useEffect(() => {
    setTitle(article?.title);
    setTimeout(article?.description);
    setImage(article?.images?.[0]?.url);
    return () => {
      setTitle(null);
      setTimeout(null);
      setImage(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article?.title, article?.description, article?.images?.length]);

  useEffect(() => {
    return () => {
      dispatch(articlesSlice.actions.setArticle(null));
    };
  }, []);

  const handleFavorite = async (e) => {
    if (!currentUser?.id || currentUser.id === article.userId) return;
    if (!article?.favorites.find((favorite) => favorite.userId === currentUser?.id)) {
      setFavorite((prev) => prev + 1);
    } else {
      if (favorite > 0) setFavorite((prev) => prev - 1);
    }
    await socket.createFavorites({userId: currentUser.id, entityId: article.id, entityType: 'article'}, dispatch, user?.token);
    await socket.getArticle(id, dispatch);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    history.push(`/detail/${id}/edit`);
  };

  const handleBuying = async (e) => {
    await socket.createSaleRequest({customerId: currentUser?.id, articleId: article?.id, status: 'na_cekanju'}, dispatch, user?.token);
    await socket.createNotification(
      {
        body: `Korisnik ${currentUser?.userName} uputio je zahtjev za kupovinu Vašeg artikla!`,
        notificationType: 'article',
        userId: article?.userId,
        seen: false,
        link: window.location.pathname + '?buyRequest=true',
      },
      user?.token
    );
    await socket.getArticle(id, dispatch);
    setOpenSecondModal(false);
  };

  const closeModal = () => setOpenModal(false);
  const closeSecondModal = () => setOpenSecondModal(false);
  const closeThirdModal = () => setOpenThirdModal(false);
  const closeImpressionModal = () => setImpressionModal(false);
  const closeGalleryModal = () => setOpenGalleryModal(false);

  const markAsSold = async (data) => {
    await socket.markAsSold(data, dispatch, history, user?.token);
    setOpenThirdModal(false);
  };

  const finishSale = async (dataUpdateSaleRequest, dataMarkAsSold, customerId) => {
    await socket.updateSaleRequest(
      dataUpdateSaleRequest,
      'Uspješno ste prihvatili zahtjev za kupovinu ovog artikla!',
      dispatch,
      user?.token
    );
    markAsSold(dataMarkAsSold);
    await socket.createNotification(
      {
        body: `Korisnik ${currentUser?.userName} prihvatio je zahtjev za kupovinu traženog artikla!`,
        notificationType: 'article',
        userId: customerId,
        seen: false,
        link: window.location.pathname,
      },
      user?.token
    );
  };

  const rejectOffer = async (data, customerId) => {
    await socket.updateSaleRequest(data, 'Uspješno ste odbili zahtjev za kupovinu ovog artikla!', dispatch, user?.token);
    await socket.createNotification(
      {
        body: `Korisnik ${currentUser?.userName} odbio je zahtjev za kupovinu traženog artikla!`,
        notificationType: 'article',
        userId: customerId,
        seen: false,
        link: window.location.pathname,
      },
      user?.token
    );
    await socket.getArticle(id, dispatch);
  };

  const handleImpression = async (e) => {
    if (impression) {
      await socket.createImpressions(
        {senderId: currentUser?.id, receiverId: article?.user?.id, articleId: article?.id, rating: ratingType, comment: impression},
        dispatch,
        user?.token
      );
      await socket.createNotification(
        {
          body: `Korisnik ${currentUser?.userName} Vam je ostavio dojam!`,
          notificationType: 'article',
          userId: article?.userId,
          seen: false,
          link: `/dojmovi/${article?.user.uuid}`,
        },
        user?.token
      );
      closeImpressionModal();
      history.push(`/`);
    }
  };

  const handleRating = (e) => {
    setRatingType(e.target.alt);
  };

  const handleCall = (event, phoneNumber) => {
    if (window?.Android) {
      event.preventDefault();
      window?.Android?.callPhone(phoneNumber);
    }
    if (window?.webkit) {
      event.preventDefault();

      window?.webkit?.callPhone(phoneNumber);
    }
  };
  const isFavorite = currentUser?.id && !!article?.favorites?.find(({userId}) => userId === currentUser?.id);

  const finishSaleClass = !!article?.salesRequests?.length ? 'button button_dark sale_inqury' : 'button button_dark';

  return (
    <div className="detail">
      <div className="container img">
        <div className="detail_slider">
          <div className="detail_slider_wrap" sold={article?.soldAt}>
            {notMobile && !!article?.images?.length && <Sw images={imagesArray} />}
            {!notMobile && (
              <div className="detail_image_wrapper">
                <Sw images={imagesArray} />
                <div className="detail_boxes ">
                  <div>
                    <div onClick={history.goBack}>
                      <SvgGoBack />
                    </div>
                  </div>
                  <div className="article_details_info">
                    <div className="detail_price_box  green  ">{!!article?.soldAt ? 'Završeno' : article?.articleType}</div>
                    <div className="detail_price_box  green">{article?.conditions === 'novo' ? 'Novo' : 'Polovno'}</div>
                    {article?.discounted ? <div className="detail_price_box red">Akcija</div> : ''}
                  </div>
                </div>
              </div>
            )}
            {!article?.images?.length && (
              <div className="detail_slider_item">
                <img src={placeholderImageSrc} className="img-fluid no-image" alt="detail" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="detail_title pb-2">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-10">
              <h1>{article?.title}</h1>
            </div>
            <div className="col-2 text-end share">
              {!notMobile && (
                <div
                  className="detail_slider_circle"
                  title="Podijeli"
                  onClick={() => {
                    window?.Android?.share?.(`https://lista.ba:8443/detail/${article?.uuid}`);
                    window?.webkit?.share({
                      url: `https://lista.ba:8443/detail/${article?.uuid}`,
                    });
                    if (navigator.canShare) {
                      navigator
                        .share({
                          title: 'Lista.ba',
                          url: `https://lista.ba:8443/detail/${article?.uuid}`,
                          text: article?.title,
                          files: filesToShare,
                        })
                        .then(() => {
                          console.log('succsess');
                        })
                        .catch((error) => {
                          console.log('device doesnt support sharing');
                        });
                    } else {
                    }
                  }}
                >
                  <SvgShare />
                </div>
              )}
              {notMobile && <SocialShare url={`https://lista.ba:8443/detail/${article?.uuid}`} />}
            </div>
          </div>
        </div>
      </div>

      <div className="detail_price container">
        <div className="row">
          {article?.articleType !== 'kupovina' && (
            <>
              <div className="col-md-auto col-4">
                <div className="detail_price_info_label txt-left">Cijena</div>
                <div className="detail_price_box blue">{article?.price ? article?.price + ',00 KM' : 'Po dogovoru'}</div>
              </div>
              <div className="col-md-auto col-8">
                {article?.price > 500 && rs && fbih && <div className="detail_price_info_label txt-left">Rata kredita već od:</div>}
                {article?.price > 500 && rs && <div className="detail_price_box blue credit me-2">{rs} KM za RS</div>}
                {article?.price > 500 && fbih && <div className="detail_price_box blue m-0"> {fbih} KM za FBIH</div>}
              </div>
            </>
          )}
          <div className="col-12 col-md-4 native-icons">
            <div className="detail_slider_circle" title="Broj pratilaca oglasa" onClick={handleFavorite}>
              <SvgHeart fill={isFavorite ? '#e51a1a' : '#ccc'} count={favorite} />
            </div>
            <div
              className="detail_slider_circle"
              title="Odštampaj oglas"
              onClick={() => {
                window.print();
              }}
            >
              <SvgPrint />
            </div>
            {isMyArticleAndNotFinished && (
              <div onClick={handleEdit} className="detail_slider_circle svg_edit button">
                <span>Izmjeni oglas</span>
              </div>
            )}
          </div>
          {notMobile ? (
            <>
              <div sold={article?.soldAt} className="detail_price_box green ml-auto sale_status align-self-center">
                {!!article?.soldAt ? 'Završeno' : article?.articleType}
              </div>
              {!excludeNewOrUsed(article?.categoryId, article?.subcategoryId, article?.subsubcategoryId, article?.subsubsubcategoryId) && (
                <div className="detail_price_box green align-self-center">{article?.conditions === 'novo' ? 'Novo' : 'Polovno'}</div>
              )}
              {notMobile && article?.discounted ? <div className="detail_price_box red align-self-center">Akcija</div> : ''}
            </>
          ) : (
            ''
          )}
        </div>
      </div>

      <div className="detail_user pt-2 pb-2 mb-0 mt-0">
        <div className="container">
          <div className="row row_20">
            <div className="col-12 ">
              <div className="row align-items-center">
                <Link className="col-8" to={isShop ? `/prodavnica/${article?.user?.uuid}` : `/user/${article?.user?.uuid}`}>
                  <img
                    className="detail_user_image"
                    src={article?.user?.profileImage ? SMALL_IMAGE + article?.user?.profileImage : '/images/user_placeholder.svg'}
                    alt="user"
                  />
                  <strong className="mr5 user_name_text">{article?.user?.userName}</strong>
                  <VerifiedUser user={article?.user} currentUser={currentUser} />
                </Link>

                {!notMobile && article?.user?.telephone && (
                  <div className="col-md-6 col-4 p0">
                    <a
                      href={'tel:' + article.user.telephone}
                      onClick={(e) => {
                        handleCall(e, article.user.telephone);
                      }}
                    >
                      <div className="telephone_icon">
                        <img src="/images/telephone_ring.svg" className="tel_icon" alt="phone" />
                      </div>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="detail_info">
        <div className="container">
          <div className="row row_10">
            {article?.cityId && (
              <div className="col-lg-3 col-6">
                <h5 className="detail_info_label">Lokacija:</h5>
                <div className="detail_info_box">
                  <CategoryLink details={getAttributeValue(article?.cityId, locations)} />
                </div>
              </div>
            )}
            <div className="col-lg-2 col-6">
              <h5 className="detail_info_label">Kategorija:</h5>
              <div className="detail_info_box">
                <CategoryLink details={getAttributeValue(article?.categoryId, categories)} isLink="kategorija" />
              </div>
            </div>
            <div className="col-lg-2 col-6">
              <h5 className="detail_info_label">Potkategorija:</h5>
              <div className="detail_info_box">
                <CategoryLink
                  details={getAttributeValue(article?.subcategoryId, subCategories)}
                  isLink={{name: 'kategorija', uuid: getAttributeValue(article?.categoryId, categories)?.uuid}}
                  isNestedLink="potkategorija"
                />
              </div>
            </div>
            <div className="col-lg-2 col-6">
              <h5 className="detail_info_label">Stanje:</h5>
              {!excludeNewOrUsed(article?.categoryId, article?.subcategoryId, article?.subsubcategoryId, article?.subsubsubcategoryId) && (
                <div className="detail_info_box">{article?.conditions === 'novo' ? 'Novo' : 'Polovno'}</div>
              )}
            </div>
            <div className="col-lg-1 col-6">
              <h5 className="detail_info_label">Zamjena:</h5>
              <div className="detail_info_box">{article?.exchange ? 'Da' : 'Ne'}</div>
            </div>
            {article?.user && (
              <div className="col-lg-2 col-6">
                <h5 className="detail_info_label">Telefon:</h5>
                {!showTelephone && (
                  <div className="detail_info_box pointer" onClick={() => setShowTelephone(true)}>
                    Prikaži
                  </div>
                )}
                {showTelephone && <div className="detail_info_box">{article.user.telephone}</div>}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="detail_date">
        <div className="container">
          <div className="row row_10">
            <div className="col-4 ">
              <div className="detail_date_box">
                <span>Objavljeno:</span>
                <b>{new Date(article?.createdAt).toLocaleDateString('de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'})}</b>
              </div>
            </div>
            <div className="col-4 ">
              <div className="detail_date_box">
                <span>Obnovljeno:</span>
                <b>{new Date(article?.manualUpdatedAt).toLocaleDateString('de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'})}</b>
              </div>
            </div>
            <div className="col-4">
              <div className="detail_date_box">
                <span>Broj pregleda:</span>
                <b>{article?.hits || 0}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="detail_short">
        <div className="container">
          <h2 className="title">Kratak opis</h2>
          <div className="separator"></div>
          {article?.description ? (
            <div dangerouslySetInnerHTML={createMarkup(JSON.parse(article.description))}></div>
          ) : (
            <p>Korisnik nije ostavio opis za ovaj proizvod</p>
          )}
          {isCustomer && article?.soldAt ? (
            <div className="row row_20">
              <div className="col-md-4 offset-md-4">
                <button
                  disabled={!isCustomer || isImpressionExist}
                  className="button button_green"
                  onClick={() => setImpressionModal(true)}
                >
                  <strong>Ostavite dojam!</strong>
                </button>
              </div>
            </div>
          ) : (
            <div className="row row_20">
              <div className="col-md-4">
                <Link to={`/pitanja-odgovori/${id}`} className="button button_dark">
                  Pitanja i odgovori
                </Link>
              </div>
              {!article?.soldAt && (
                <div className="col-md-4" onClick={() => setOpenModal(true)}>
                  <button className="button button_dark">Prijavi oglas</button>
                </div>
              )}
              {!article?.soldAt && (
                <div className="col-md-4">
                  {article?.userId !== currentUser?.id ? (
                    <button
                      className="button button_dark"
                      disabled={isRequested || !currentUser?.id}
                      onClick={() => {
                        setOpenSecondModal(true);
                      }}
                    >
                      Kupi artikal
                    </button>
                  ) : (
                    <button className={finishSaleClass} onClick={() => setOpenThirdModal(true)}>
                      {!!article?.salesRequests?.length ? 'Zahtjev za kupovinu' : `Završi ${articleTypeMap[article?.articleType]}`}
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="detail_features">
        <div className="container">
          <h2 className="title">Osobine</h2>
          <div className="separator"></div>
          <dl className="row">
            {article?.values?.map((value, index) => (
              <>
                <dt className="col-md-3 col-6" key={value?.uuid}>
                  {value?.name}
                </dt>
                <dd className="col-md-3 col-6" key={value?.uuid + index}>
                  <b>{value?.value === 'on' ? 'Da' : value?.value}</b>
                </dd>
              </>
            ))}
          </dl>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="title">Slični artikli</h3>
              <div className="separator"></div>
            </div>
          </div>
        </div>
      </div>

      <Modal onRequestClose={closeModal} isOpen={openModal}>
        <div className="col-md-12">
          <div onClick={closeThirdModal} className="close-third">
            <img className="modal_user_image" src="/images/close-button.svg" alt="detail" />
          </div>
          <label className="form_default_input">
            <textarea
              type="text"
              placeholder="Molimo upišite, šta nije uredu sa ovim oglasom."
              onChange={(e) => setComplain(e.target.value)}
            ></textarea>
            <button className="button w-100" onClick={handleComplain}>
              Dodaj pitanje
            </button>
          </label>
        </div>
      </Modal>

      <Modal onRequestClose={closeSecondModal} isOpen={openSecondModal}>
        <div className="col-md-12">
          <label className="form_default_input mt-4">
            <strong className="mt-4 mb-3">NAPOMENA</strong>
            Opcija "Kupi artikal" se koristi samo u slučaju ako ste zainteresovani za kupovinu određenog artikla, te ste se o tome već
            dogovorili sa prodavcem! Prema tome, molimo Vas da ovu opciju ne zloupotrebljavate, jer se to automatski odražava na Vaš dojam
            koji Vam ostavljaju prodavači!
          </label>
          <div className="row mt-4 mb-4">
            <div className="col-6 text-center">
              <button className="button" onClick={closeSecondModal}>
                Otkaži
              </button>
            </div>
            <div className="col-6 text-center">
              <button className="button" onClick={handleBuying}>
                Kupi
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal onRequestClose={closeThirdModal} isOpen={openThirdModal}>
        <div className="col-md-12">
          <img class="modal_user_image close_modal" onClick={closeThirdModal} src="/images/reject.svg" alt="detail" />
          <label className="form_default_input mt-4">
            <strong className="mt-4">Napomena</strong>
            Klikom na potvrdi, Vaš proizvod će biti označen kao zarvšen, i nećete biti u mogućnosti da više uređujete oglas ili mu
            promijenite status. Da li ste sigurni da želite da nastavite?
          </label>

          {article?.salesRequests.find((saleRequest) => saleRequest.status === 'na_cekanju') ? (
            <label className="form_default_input mt-4">
              <strong className="mt-4">Korisnici koji su uputili zahtjev za kupovinu Vašeg artikla: </strong>
            </label>
          ) : (
            <>
              <label className="form_default_input mt-4">
                <strong className="mt-4">Nema zahtjeva za kupovinu! Ipak završi prodaju? </strong>
              </label>
              <div className="row mt-4">
                <button className="button w-100 col-4 offset-1" onClick={closeThirdModal}>
                  Otkaži
                </button>
                <button className="button w-100 col-4 offset-2" onClick={() => markAsSold({uuid: article?.uuid, status: 'prodan'})}>
                  Potvrdi
                </button>
              </div>
            </>
          )}
          <div className="container">
            {article?.salesRequests?.map((saleRequest) => {
              if (saleRequest.status === 'na_cekanju')
                return (
                  <div className="row mx-md-2 mb-2 align-items-center">
                    <>
                      <div className="col-md-2 col-3 mb-2">
                        <Link to={`/user/${saleRequest?.customer?.uuid}`}>
                          <img
                            className="modal_user_image"
                            src={
                              saleRequest?.customer?.profileImage
                                ? SMALL_IMAGE + saleRequest?.customer?.profileImage
                                : '/images/user_placeholder.svg'
                            }
                            alt="user"
                          />
                        </Link>
                      </div>
                      <div className="col-md-4 col-3 text-start">{`${saleRequest.customer.userName}`}</div>{' '}
                      <div className="col-md-2 col-3">
                        <img
                          className="modal_user_image"
                          src="/images/check_circle.svg"
                          alt="detail"
                          onClick={() =>
                            finishSale(
                              {uuid: saleRequest.uuid, status: 'kupljen'},
                              {uuid: article?.uuid, status: 'prodan'},
                              saleRequest.customerId
                            )
                          }
                        />{' '}
                      </div>
                      <div className="col-md-1 col-3">
                        <img
                          className="modal_user_image"
                          src="/images/reject.svg"
                          alt="detail"
                          onClick={() => rejectOffer({uuid: saleRequest.uuid, status: 'odbijen'}, saleRequest.customerId)}
                        />{' '}
                      </div>
                    </>
                  </div>
                );
              return null;
            })}
          </div>
        </div>
      </Modal>

      <Modal onRequestClose={closeImpressionModal} isOpen={openImpressionModal}>
        <div className="col-md-12">
          <label className="form_default_input">
            <textarea
              type="text"
              placeholder="Ostavite dojam o poslovanju sa ovim korisnikom, te na taj način pomozite ostalim kupcima!"
              name="question"
              onChange={(e) => setImpression(e.target.value)}
            ></textarea>
            <div className="row row_10">
              <div className="col-4 col-lg-4">
                <figure>
                  <img
                    src={ratingType === 'pozitivan' ? `/images/like.svg` : `/images/like_green.svg`}
                    alt="pozitivan"
                    onClick={handleRating}
                  />
                </figure>
              </div>
              <div className="col-4 col-lg-4">
                <figure>
                  <img
                    src={ratingType === 'neutralan' ? `/images/neutral.svg` : `/images/neutral_blue.svg`}
                    alt="neutralan"
                    onClick={handleRating}
                  />
                </figure>
              </div>
              <div className="col-4 col-lg-4">
                <figure>
                  <img
                    src={ratingType === 'negativan' ? `/images/dislike.svg` : `/images/dislike_red.svg`}
                    alt="negativan"
                    onClick={handleRating}
                  />
                </figure>
              </div>
            </div>
            <button className="button w-100" onClick={handleImpression}>
              Ostavite dojam!
            </button>
          </label>
        </div>
      </Modal>

      <GalleryModal className="p-0 m-0" onRequestClose={closeGalleryModal} isOpen={openGalleryModal}>
        {notMobile && (
          <ImageModalGallery selectedImage={selectedImage}>
            {article?.images?.map(({url}) => {
              return (
                <div key={url} className="lightbox-link">
                  <img src={LARGE_IMAGE + url} className="img-fluid" alt="detail" />
                </div>
              );
            })}
          </ImageModalGallery>
        )}
        {!notMobile && (
          <ImageModalGallery selectedImage={selectedImage}>
            {imagesArray?.map(({url}) => {
              return (
                <div key={url} className="lightbox-link">
                  <img src={LARGE_IMAGE + url} className="img-fluid" alt="detail" />
                </div>
              );
            })}
          </ImageModalGallery>
        )}
        <button onClick={closeGalleryModal} className="close_modal_button">
          ZATVORI
        </button>
      </GalleryModal>
    </div>
  );
}

export default withSeo(Detail);

function createMarkup(html) {
  return {__html: html};
}

import React, {useContext, useEffect, useState} from 'react';
import ImageUploading from 'react-images-uploading';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {RequestContext} from '../App';
import {isDisabled, removeEmpty} from '../helpers/utilities';
import {v4 as uuid} from 'uuid';
import {isCompany} from '../helpers/auth';
import Compress from 'compress.js';
import withSeo from '../components/seo';
import TextEditor from '../components/textEditor';
import Modal from '../components/modal';
const initialValue = {
  userName: '',
  name: '',
  email: '',
  password: '',
  role: '',
  city: '',
  cityId: null,
  address: '',
  telephone: '',
  profileImage: '',
  companyName: '',
  description: '',
  gender: '',
};

function Registracija({setTitle}) {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState(initialValue);
  const dispatch = useDispatch();
  const {api: socket} = useContext(RequestContext);
  const [prevStep, setPrevStep] = useState(1);

  const stepFunction = (count, prevStep) => {
    setStep(count);
    setPrevStep(setPrevStep);
  };
  useEffect(() => {
    const run = async () => {
      setTitle('Registracija');
      await socket.getLocations({}, dispatch);
    };
    run();
  }, []);

  return (
    <>
      <section className="section">
        <div className="container">
          {step === 1 ? (
            <Step1
              prevStep={prevStep}
              setPrevStep={setPrevStep}
              currentState={userData}
              formHandler={setUserData}
              parentCallback={stepFunction}
            />
          ) : null}
          {step === 2 ? (
            <Step2
              prevStep={prevStep}
              setPrevStep={setPrevStep}
              currentState={userData}
              formHandler={setUserData}
              parentCallback={stepFunction}
            />
          ) : null}
          {step === 3 ? (
            <Step3
              prevStep={prevStep}
              setPrevStep={setPrevStep}
              currentState={userData}
              formHandler={setUserData}
              parentCallback={stepFunction}
            />
          ) : null}
          {step === 4 ? (
            <Step4
              prevStep={prevStep}
              setPrevStep={setPrevStep}
              currentState={userData}
              formHandler={setUserData}
              parentCallback={stepFunction}
            />
          ) : null}
        </div>
      </section>
    </>
  );
}

const Step1 = ({formHandler, parentCallback, setPrevStep}) => {
  const handleChoice = (e, value) => {
    e.preventDefault();
    formHandler((prev) => ({...prev, role: value}));
    parentCallback(2);
    setPrevStep(1);
  };
  return (
    <div className="jumbotron">
      <div className="row">
        <div className="col-lg-6">
          <h2 className="title">Vrsta Vašeg naloga</h2>
        </div>
        <div className="col-lg-6 text-right">
          <div className="form_default_steps">
            <span>Koraci registracije</span>
            <ul>
              <li className="active">1</li>
              <li>2</li>
              <li>3</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="separator"></div>
      <div className="row">
        <div className="col-lg-6">
          <button className="button button_light w-100" onClick={(e) => handleChoice(e, 'korisnik')}>
            <img src="/images/icon_male.svg" alt="male" className="mr-2" />
            <b>Običan profil</b>
          </button>
        </div>
        <div className="col-lg-6">
          <button className="button button_light w-100" onClick={(e) => handleChoice(e, 'prodavnica')}>
            <img src="/images/icon_tie.svg" alt="tie" className="mr-2" />
            <b>Prodavnica</b>
          </button>
        </div>
      </div>
    </div>
  );
};

const Step2 = ({parentCallback, formHandler, currentState}) => {
  const {api: socket} = useContext(RequestContext);
  const [formHasError, setFormHasError] = useState([]);
  const [disableForm, setDisableForm] = useState(true);
  const dispatch = useDispatch();
  const [images, setImages] = useState('');
  const {locations} = useSelector((state) => state.locations);
  const [currentLocations, setCurrentLocations] = useState(locations);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [question, setQuestion] = useState({
    email: '',
    question: '',
    subject: 'Problemi sa registracijom',
  });
  const closeModal = () => setOpenModal(false);

  const handleChange = (e) => {
    setQuestion((prev) => ({...prev, [e.target.name]: e.target.value}));
  };

  const handleQuestion = async (e) => {
    e.preventDefault();
    if (!question.email.length || !question.question.length) return;
    await socket.askQuestion(question, dispatch);
    setQuestion({email: '', question: ''});
    setOpenModal(false);
  };

  const addImageCallback = async (pic) => {
    const image = await resizeImageFn(pic[0]);
    const form = new FormData();
    form.append('image', image);
    setImages(pic);
    await socket.createImage(form, dispatch);
  };
  const changeHandler = (e) => {
    setFormIsDirty(true);
    if (!e.target.value.length) setDisableForm(true);
    formHandler({...currentState, [e.target.name]: e.target.value});
    setDisableForm(isDisabled({...currentState, [e.target.name]: e.target.value}, ['email', 'userName']));
  };

  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleBlur = async (e) => {
    if (!e.target.value) {
      return setDisableForm(true);
    }
    await socket.isTaken({userData: e.target.value, fieldName: e.target.name}, dispatch, setFormHasError);
  };

  const changeLocationsHandler = (e) => {
    const cityId = locations.find((location) => location.name === e.target.value)?.id;
    formHandler({...currentState, city: e.target.value, cityId});
    const filteredLocations = locations.filter((location) => location.name.toLowerCase().includes(e.target.value.toLowerCase()));
    setCurrentLocations([...filteredLocations]);
  };

  const handleLocation = (name) => {
    const cityId = locations.find((location) => location.name === name)?.id;
    formHandler({...currentState, city: name, cityId});
    setCurrentLocations([]);
  };

  const setGender = (gender) => {
    formHandler({...currentState, gender});
    console.log(currentState);
  };

  useEffect(() => {
    setDisableForm(isDisabled(currentState, ['email', 'userName']));
  }, []);

  return (
    <>
      <div className="jumbotron">
        <div className="row">
          <div className="col-lg-6">
            <h2 className="title">Informacije o Vama</h2>
          </div>
          <div className="col-lg-6 text-right">
            <div className="form_default_steps">
              <span>Koraci registracije</span>
              <ul>
                <li>1</li>
                <li className="active">2</li>
                <li>3</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="separator"></div>
        <div className="row">
          {isCompany(currentState.role) && (
            <div className="col-lg-6">
              <h5 className="form_default_label">Naziv prodavnice</h5>
              <label className="form_default_input form_default_w_icon">
                <input
                  type="text"
                  value={currentState.companyName}
                  onBlur={handleBlur}
                  onChange={changeHandler}
                  name="companyName"
                  placeholder="Naziv firme"
                />
                <img src="/images/icon_user.svg" alt="user" />
              </label>
            </div>
          )}
          <div className="col-lg-6">
            <h5 className="form_default_label">Korisničko ime *</h5>
            <label className="form_default_input form_default_w_icon">
              <input
                type="text"
                value={currentState.userName}
                onBlur={handleBlur}
                onChange={changeHandler}
                name="userName"
                placeholder="Korisničko ime"
              />
              <img src="/images/icon_user.svg" alt="user" />
            </label>
          </div>
          <div className="col-lg-6">
            <h5 className="form_default_label">Email adresa *</h5>
            <label className="form_default_input form_default_w_icon">
              <input
                type="email"
                value={currentState.email}
                onBlur={handleBlur}
                onChange={changeHandler}
                name="email"
                placeholder="Email adresa"
              />
              <img src="/images/icon_envelope.svg" alt="envelope" />
            </label>
          </div>
          <div className="col-lg-6">
            <h5 className="form_default_label">Ime</h5>
            <label className="form_default_input form_default_w_icon">
              <input type="text" value={currentState.name} onChange={changeHandler} name="name" placeholder="Vaše ime" />
              <img src="/images/icon_user.svg" alt="user" />
            </label>
          </div>
          <div className="col-lg-6">
            <h5 className="form_default_label">Adresa</h5>
            <label className="form_default_input form_default_w_icon">
              <input type="text" value={currentState.address} onChange={changeHandler} name="address" placeholder="Adresa" />
              <img src="/images/icon_address.svg" alt="address" />
            </label>
          </div>
          <div className="col-lg-6">
            <h5 className="form_default_label">Vaša šifra *</h5>
            <label className="form_default_input form_default_w_icon">
              <input type="password" value={currentState.password} onChange={changeHandler} name="password" placeholder="Password" />
              <img src="/images/icon_lock.svg" alt="lock" />
            </label>
          </div>
          <div className="col-lg-6">
            <h5 className="form_default_label">Grad *</h5>
            <label className="form_default_input form_default_w_icon">
              <input type="text" value={currentState.city} onChange={changeLocationsHandler} name="city" placeholder="Lokacija" />
            </label>
            {!!currentState.city.length && !currentLocations.find(({name}) => name === currentState?.city) && (
              <div className="suggestions row p-2">
                {currentLocations.map(({name}) => (
                  <span onClick={() => handleLocation(name)} className="col-12 suggestion" key={uuid()}>
                    {name}
                  </span>
                ))}
              </div>
            )}
          </div>
          {currentState?.role !== 'prodavnica' && (
            <div className="col-lg-6">
              <h5 className="form_default_label">Vaš pol</h5>
              <label className="form_default_gender" onClick={() => setGender('female')}>
                <input type="radio" name="gender" />
                <span>
                  <img src="/images/icon_female.svg" alt="female" /> Ženski pol
                </span>
              </label>
              <label className="form_default_gender" onClick={() => setGender('male')}>
                <input type="radio" name="gender" />
                <span>
                  <img src="/images/icon_male.svg" alt="male" /> Muški pol
                </span>
              </label>
            </div>
          )}
        </div>
        <div className="form_default_image">
          <div>
            <h3>Ovde dodajte Vaše slike</h3>
            <ImageUploading value={images} dataURLKey="data_url" onChange={addImageCallback} maxFileSize={20242880} maxNumber={5}>
              {({imageList, onImageUpload, isDragging, dragProps}) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  {imageList.length < 5 && (
                    <button style={isDragging ? {color: 'red'} : undefined} onClick={onImageUpload} {...dragProps}>
                      Dodajte fotografiju
                    </button>
                  )}
                  {imageList.map((image, index) => (
                    <div key={index} className="image-item">
                      <img src={image['data_url']} alt="" width="100" />
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading>{' '}
          </div>
          <div>
            <img src="/images/drag_drop.svg" alt="drag_drop" className="img-fluid" />
          </div>
        </div>
        <div className="row mx-0 justify-content-between">
          <label className="form_default_check">
            <input type="checkbox" onClick={() => setTermsAccepted(!termsAccepted)} />
            <span></span>
            <button role="link">* Slažem se sa uslovima korištenja</button>
          </label>
          <label>
            <button className="text-underline" role="link" onClick={() => setOpenModal(true)}>
              Ako imate problem kod registracijem kliknite ovdje
            </button>
          </label>
        </div>
        {(!!formHasError.length ||
          currentState?.userame?.length === 0 ||
          currentState?.password?.length < 6 ||
          !termsAccepted ||
          currentState?.email?.length === 0 ||
          currentState?.cityId === undefined ||
          currentState?.cityId === null) &&
          formIsDirty && (
            <>
              <div className="w-100">
                <strong>Molimo Vas uredite sledeća polja da bi nastavili:</strong>
              </div>
              <ul className="errors col-lg-6 mb-4">
                {formHasError.map((error) => (
                  <li>{mapNames[error]}</li>
                ))}
                {currentState?.userame?.length === 0 && <li>Molimo Vas unesite korisničko ime!</li>}
                {currentState?.email?.length === 0 && <li>Molimo Vas unesite email adresu!</li>}
                {currentState?.password?.length < 6 && <li>Molimo Vas izaberite lozinku od minimum 6 karaktera!</li>}
                {(currentState?.cityId === undefined || currentState?.cityId === null) && (
                  <li>Molimo Vas izaberite grad iz ponuđene liste!</li>
                )}
                {!termsAccepted && <li>Molimo Vas da prihvatite uslove korištenja!</li>}
              </ul>
            </>
          )}
        <div className="row">
          <div className="col-lg-6">
            <button onClick={() => parentCallback(1, 2)} className="button button_light w-100">
              Povratak na korak 1
            </button>
          </div>
          <div className="col-lg-6">
            <button
              disabled={
                disableForm ||
                !termsAccepted ||
                !!formHasError.length ||
                currentState?.password?.length < 6 ||
                currentState?.cityId === undefined ||
                currentState?.cityId === null
              }
              onClick={() => parentCallback(3, 2)}
              className="button w-100"
            >
              <b>Nastavite</b>
            </button>
          </div>
        </div>
      </div>
      <Modal onRequestClose={closeModal} isOpen={openModal}>
        <div className="col-md-12">
          <label className="form_default_input mb-2">
            <input type="email" placeholder="Molimo upišite vas email." name="email" value={question.email} onChange={handleChange} />
          </label>
          <label className="form_default_input">
            <textarea
              type="text"
              placeholder="Ako imate problem kod registracije, molimo vas na nam opišete vaš problem, mi ćemo se potruditi da vam pomognemo."
              name="question"
              onChange={handleChange}
            >
              {question.question}
            </textarea>
            <button className="button w-100" onClick={handleQuestion}>
              Postavi pitanje
            </button>
          </label>
        </div>
      </Modal>
    </>
  );
};

const Step3 = ({parentCallback, currentState, formHandler}) => {
  const history = useHistory();
  const profileImage = useSelector((state) => state.images.profileImage);

  const placeholder = 'Recite nešto više o svojoj prodavnici! Zašto ste baš vi pravo mjesto za kupovinu?';
  const handleChange = (e) => {
    if (e.target.name === 'telephone') {
      formHandler({...currentState, [e.target.name]: e.target.value.replace(/^\+387|^00387|^387|\s+/g, '').trim()});
    } else {
      formHandler({...currentState, [e.target.name]: e.target.value});
    }
  };
  const {api: socket} = useContext(RequestContext);
  const message = useSelector((state) => state.common.notification);
  const dispatch = useDispatch();
  const formSubmit = async (e) => {
    e.preventDefault();
    formHandler({...currentState});
    const registerData = removeEmpty(currentState);
    await socket.register({...registerData, telephone: '+387' + registerData.telephone}, dispatch, history.push, '/potvrda-email');
  };
  const requiredValues = ['telephone'];
  useEffect(() => {
    if (message?.type === 'success') parentCallback(4);
  }, [message, parentCallback]);

  const [description, setDescription] = useState('');

  const changeDescription = (descriptionText) => {
    setDescription(descriptionText);
  };

  useEffect(() => {
    formHandler({...currentState, description});
  }, [description]);

  useEffect(() => {
    formHandler({...currentState, profileImage});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileImage, formHandler]);

  return (
    <div className="jumbotron">
      <div className="row">
        <div className="col-lg-6">
          <h2 className="title">Broj telefona</h2>
        </div>
        <div className="col-lg-6 text-right">
          <div className="form_default_steps">
            <span>Koraci registracije</span>
            <ul>
              <li>1</li>
              <li>2</li>
              <li className="active">3</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="separator"></div>
      <div className="row">
        <div className="col-lg-6">
          <h5 className="form_default_label">Zemlja</h5>
          <label className="form_default_select">
            <select>
              <option>Bosna i Hercegovina (+387)</option>
            </select>
          </label>
        </div>
        <div className="col-lg-6">
          <h5 className="form_default_label">Broj telefona (format 65123456)</h5>
          <label className="form_default_input form_default_w_icon">
            <input type="text" name="telephone" value={currentState.telephone} onChange={handleChange} />
            <img src="/images/icon_mobile.svg" alt="mobile" />
          </label>
        </div>
        {currentState?.role === 'prodavnica' ? (
          <div className="col-lg-12 mt-3">
            <h5 className="form_default_label">Recite nešto više o svojoj prodavnici</h5>
            <TextEditor changeHandler={changeDescription} placeholder={placeholder} />
          </div>
        ) : (
          ''
        )}
        <div className="col-lg-6">
          <button onClick={() => parentCallback(2, 3)} className="button button_light w-100">
            Povratak na korak 2
          </button>
        </div>
        <div className="col-lg-6">
          <button disabled={isDisabled(currentState, requiredValues)} onClick={formSubmit} className="button w-100">
            <b>Registruj me!</b>
          </button>
        </div>
      </div>
    </div>
  );
};

const Step4 = () => {
  return (
    <div className="jumbotron form_default_congratulation">
      <img src="/images/congratulation.jpg" alt="congratulation" className="img-fluid" />
      <h2>Čestitamo!</h2>
      <h3>Uspiješno ste kreirali nalog.</h3>
      <Link to="/">
        <img src="/images/check_circle.svg" alt="check" />
      </Link>
    </div>
  );
};

async function resizeImageFn(file) {
  const base64Response = await fetch(file.data_url);
  const blob = await base64Response.blob();

  const compress = new Compress();
  const resizedImage = await compress.compress([blob], {
    size: 2, // the max size in MB, defaults to 2MB
    quality: 0.9, // the quality of the image, max is 1,
    maxWidth: 1200, // the max width of the output image, defaults to 1920px
    maxHeight: 800, // the max height of the output image, defaults to 1920px
    resize: true, // defaults to true, set false if you do not want to resize the image width and height
  });
  const img = resizedImage[0];
  const base64str = img.data;
  const imgExt = img.ext;
  const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt);
  return resizedFiile;
}

const mapNames = {
  userName: 'Korisnicko ime je zauzeto!',
  email: 'Email je zauzet!',
};

export default withSeo(Registracija);

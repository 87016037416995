import React from 'react';
import '../styles/components/downloadapps.scss';

function DownloadApps({setShowMobileApps}) {
  return (
    <div className="apps_holder">
      <div className="apps_inner">
        <div className="close_apps" onClick={() => setShowMobileApps(false)}>
          &#10005;
        </div>
        <div className="app_que">Preuzmite Lista.ba mobilnu aplikaciju.</div>
        <div className="apps_btn_holder">
          <a href="https://play.google.com/store/apps/details?id=ba.lista" target="_blank" rel="noreferrer">
            <div className="apps_btns">
              <img src="/images/playstore.png" alt="google play" />
            </div>
          </a>

          <a href="https://apps.apple.com/ba/app/lista-ba/id1619945859" target="_blank" rel="noreferrer">
            <div className="apps_btns">
              <img src="/images/applestore.png" alt="ios gallery" />
            </div>
          </a>
          <a href="https://appgallery.huawei.com/app/C106951887" target="_blank" rel="noreferrer">
            <div className="apps_btns">
              <img src="/images/huaweistore.png" alt="huawei gallery" />
            </div>
          </a>
          {/* <a href="https://www.apple.com/" target="_blank">
                        <div className="apps_btns">iOS</div>
                    </a> */}
        </div>
      </div>
    </div>
  );
}

export default DownloadApps;

import React, {useState, useRef, useEffect} from 'react';

function Collapse(props) {
  const [active, setActive] = useState(props.open);
  const body = useRef(null);
  useEffect(() => {
    if (props.open || active) {
      body.current.style.maxHeight = Math.max(props.elementsCount * 60 ?? 0, body.current.scrollHeight) + 'px';
    } else {
      body.current.style.maxHeight = '180px';
    }
  }, [props.open, active, props.elementsCount]);
  const toggleCollapse = (e) => {
    e.preventDefault();
    setActive(!active);
  };
  return (
    <div className={'collapse_default' + (props.open || active ? ' open' : '')}>
      <button className="collapse_default_head" onClick={toggleCollapse}>
        {props.title}
      </button>
      <div className="collapse_default_body" ref={body} style={{display: active ? '' : 'none'}}>
        {props.children}
      </div>
    </div>
  );
}
export default Collapse;

import React from 'react';
import {v4 as uuid} from 'uuid';
import {compare} from '../helpers/utilities';

// eslint-disable-next-line
String.prototype.titelizeAttribute = function () {
  return this.split('-')
    .map((title) => title[0].toUpperCase() + title.slice(1))
    .join(' ');
};
export const Options = ({
  name,
  value,
  onChange,
  selected,
  parentid,
  attrid,
  orderid,
  models,
  setModels,
  selectedModel,
  setSelectedModel,
}) => {
  if (!value.length) return null;
  const localMarkOnChange = (e) => {
    if (e.target.name === 'Marka*') {
      const [temp] = value.filter((v) => v.name === e.target.value);
      setModels(temp?.modelAttributeValues.map((make) => ({...make, parentid: temp.id, attrid: make.id})));

      setSelectedModel('');
    }
  };

  const localModelOnChange = (e) => {
    setSelectedModel(e.target.value);
  };
  return (
    <>
      <div className="col-md-6">
        <h5 className="form_default_label">{name.titelizeAttribute()}</h5>
        <label className="form_default_select">
          <select
            key={uuid()}
            name={name}
            onChange={(e) => {
              onChange(e);
              localMarkOnChange(e);
            }}
            value={selected}
            parentid={parentid}
            attrid={attrid}
            orderid={orderid}
          >
            <option></option>
            {[...value]?.sort(compare).map((val) => (
              <option key={uuid()} value={val.name}>
                {val.name}
              </option>
            ))}
          </select>
        </label>
      </div>

      {name === 'Marka*' && (
        <div className="col-md-6">
          <h5 className="form_default_label">Model</h5>
          <label className="form_default_select">
            <select
              name="Model"
              onChange={(e) => {
                onChange(e);
                localModelOnChange(e);
              }}
              value={selectedModel}
              parentid={models && models[0].parentid}
            >
              <option value=""></option>
              {models &&
                models.map((model) => {
                  return (
                    <option key={uuid()} value={model.name}>
                      {model.name}
                    </option>
                  );
                })}
            </select>
          </label>
        </div>
      )}
    </>
  );
};

export function InputNumber({name, handleChange, value, parentid, attrid, orderid}) {
  return (
    <div className="col-md-6">
      <h5 className="form_default_label">{name.titelizeAttribute()}</h5>
      <label className="form_default_input">
        <input
          type="text"
          placeholder={`Upišite ${name.titelizeAttribute()}`}
          value={value}
          name={name}
          onChange={handleChange}
          parentid={parentid}
          attrid={attrid}
          orderid={orderid}
          min={0}
        />
      </label>
    </div>
  );
}

export function CheckBox({name, clickHandler, checked, parentid, attrid, orderid}) {
  return (
    <div className="col-md-4">
      <label className="form_default_option w-100">
        <input
          key={uuid()}
          type="checkbox"
          name={name}
          onChange={clickHandler}
          checked={checked}
          parentid={parentid}
          attrid={attrid}
          orderid={orderid}
        />
        <span>{name.titelizeAttribute()}</span>
      </label>
    </div>
  );
}

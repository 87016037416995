import {useState, useEffect, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {SMALL_IMAGE} from '../api/apiBase';
import {calculator} from '../helpers/utilities';
import '../styles/components/post.scss';
import {RequestContext} from '../App';

function Post({articleprops, categories, classProps, setOpenModalFilter}) {
  const {title, price, uuid, images, featured, categoryId, soldAt, articleType, id, article_attributes, discounted} = articleprops;
  const year = article_attributes?.find((a) => a.name === 'godiste')?.value;
  const image = SMALL_IMAGE + images?.[images.length - 1]?.url;
  const [rs, setCredit] = useState();
  const getAttributeValue = (lookup, values) => values?.find((value) => lookup === value.id);
  const {api: socket} = useContext(RequestContext);
  const dispatch = useDispatch();
  const {user} = useSelector((state) => state.auth);

  useEffect(() => {
    if (categoryId && categories?.length) {
      const credit = calculator(price, getAttributeValue(categoryId, categories)?.name, year);
      if (!!credit.length) setCredit(credit);
    }
  }, [categories?.length, categoryId]);

  const handleFavorite = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    await socket.createFavorites({userId: user.id, entityId: id, entityType: 'article'}, dispatch, user?.token);
  };

  return (
    <div className={classProps ?? 'col-md-2 col-sm-6'} key={uuid}>
      <article className={`${soldAt ? 'post sold' : 'post'} ${featured ? 'featured' : ''} ${discounted ? 'discounted' : ''}`}>
        <Link to={`/detail/${uuid}`} onClick={() => setOpenModalFilter?.(false)}>
          <figure>
            {!!images?.[0]?.url ? (
              <img src={image} alt="logo" className="img-fluid" />
            ) : (
              <img src={`/images/cat${categoryId}-lg.svg`} alt="logo" className="img-fluid no-image" />
            )}
            {window.location.pathname === '/favoriti' && (
              <div onClick={handleFavorite} className="post_info post_hide">
                X
              </div>
            )}
            {articleType === 'prodaja' && !soldAt && price > 500 && rs?.[0] && (
              <div className="detail_price_box blue credit post-credit">Rata već od {rs?.[0]} KM</div>
            )}
          </figure>
          {featured && !discounted && !soldAt && <div className="post_info post_sale">Izdvojeno</div>}
          {discounted && <div className="post_info post_sale post_special">Akcija</div>}
          <div className="title-box">
            <p>{title}</p>
            {(articleType === 'prodaja' || articleType === 'iznajmljivanje') && (
              <div className="post_info post_price">{price ? price + ',00 KM' : 'Dogovor'}</div>
            )}
            {articleType === 'kupovina' && <div className="detail_price_box blue credit post-credit red">Kupujem</div>}
          </div>
        </Link>
      </article>
    </div>
  );
}

export default Post;

import {useDispatch} from 'react-redux';
import {filtersSlice} from '../store/reducers/filtersReducer';
function Pagination({articles, perPage, setPage, page, title}) {
  const dispatch = useDispatch();
  const totalPages = Math.ceil(articles?.total / perPage) ?? 0;
  const handlePageChange = (active) => {
    setPage(active);
    dispatch(filtersSlice.actions.updatePage(active));
  };

  return (
    <div className="pagination">
      <div className="pagination-wrapper">
        {page !== 1 && (
          <button onClick={() => handlePageChange(page - 1)} type="button" className="page-item sides">
            &lt;
          </button>
        )}
        <button onClick={() => handlePageChange(1)} type="button" className={'page-item ' + (page === 1 ? 'active' : '')}>
          {1}
        </button>
        {page > 3 && <div className="separator">...</div>}
        {page === totalPages && totalPages > 3 && (
          <button onClick={() => handlePageChange(page - 2)} type="button" className="page-item">
            {page - 2}
          </button>
        )}
        {page > 2 && (
          <button onClick={() => handlePageChange(page - 1)} type="button" className="page-item">
            {page - 1}
          </button>
        )}
        {page !== 1 && page !== totalPages && (
          <button onClick={() => handlePageChange(page)} type="button" className="page-item active">
            {+page}
          </button>
        )}
        {page < totalPages - 1 && (
          <button onClick={() => handlePageChange(+page + 1)} type="button" className="page-item">
            {+page + 1}
          </button>
        )}
        {page === 1 && totalPages > 3 && (
          <button onClick={() => handlePageChange(+page + 2)} type="button" className="page-item">
            {+page + 2}
          </button>
        )}
        {page < totalPages - 2 && <div className="separator">...</div>}
        <button onClick={() => handlePageChange(totalPages)} type="button" className={'page-item ' + (page === totalPages ? 'active' : '')}>
          {totalPages}
        </button>
        {page !== totalPages && (
          <button onClick={() => handlePageChange(+page + 1)} type="button" className="page-item sides">
            &gt;
          </button>
        )}
      </div>
    </div>
  );
}

export default Pagination;

import {Link} from 'react-router-dom';
import Underhead from '../components/underhead';
import '../styles/components/404.scss';

function NotFound() {
  return (
    <>
      <Underhead title="Page Not Found" items={[{link: '', title: '404'}]} />
      <div className="container not_found">
        <h1>404</h1>
        <h2>Stranica nije pronađena.</h2>
        <Link to="/" className="button">
          Početna
        </Link>
      </div>
    </>
  );
}

export default NotFound;

import {useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {RequestContext} from '../App';
import * as _ from 'lodash';

export default function AdminKorisnici() {
  const {api: socket} = useContext(RequestContext);
  const dispatch = useDispatch();
  const {users} = useSelector((state) => state.auth);
  const [newPikName, setNewPikName] = useState({});
  const [pagination, setPagination] = useState({
    page: 0,
    count: 20,
    next: true,
  });
  const [, setFindUser] = useState();
  const getUsers = async () => {
    await socket.getUserAdmin(pagination, dispatch);
  };

  const handlePrev = () => {
    setPagination((prev) => ({...prev, page: prev.page - 1}));
  };

  const handleNext = () => {
    setPagination((prev) => ({...prev, page: prev.page + 1}));
  };

  useEffect(() => {
    getUsers();
  }, [pagination.page]);

  useEffect(() => {
    setPagination((prev) => ({...prev, next: users?.length > 0}));
    setNewPikName({});
  }, [JSON.stringify(users)]);

  const handleSetName = (e) => {
    setNewPikName({...newPikName, [e.target.name]: e.target.value});
  };

  const handleAdd = (e, uuid) => {
    e.preventDefault();
    socket.addPikName({uuid, pikName: newPikName[uuid]}, dispatch);
  };

  const handleRemove = (e, uuid) => {
    e.preventDefault();
    socket.addPikName({uuid, pikName: null}, dispatch);
    setNewPikName({...newPikName, [e.target.name]: ''});
  };

  const callApi = async (param) => {
    if (!param.length || param.length < 3) {
      await socket.getUserAdmin({page: 0, count: 20, next: true}, dispatch);
      setPagination({page: 0, count: 20, next: true});
      return;
    }
    await socket.getUserAdmin({userData: param}, dispatch);
  };
  const debounced = useCallback(_.debounce(callApi, 300), []);
  const handleFindUsers = (e) => {
    setFindUser(e.target.value);
    debounced(e.target.value);
  };

  return (
    <section className="section category">
      <div className="container container-admin">
        <div className="col-12">
          <div className="search">
            <input type="text" name="findUser" className="form-control" placeholder="Ime ili email korisnika" onChange={handleFindUsers} />
          </div>
        </div>
        <div className="col-12">
          <h2 className="my-3">Lista korisnika</h2>
          <div className="row">
            <div className="col-12">
              <ul className="profile_list">
                {users?.map(({userName, pikName, uuid}) => (
                  <li key={uuid} className="row p-2 align-items-center">
                    <div className="p-2 col-4">
                      <Link to={pikName ? `/admin/korisnici/${uuid}` : '/admin/korisnici'}>{userName}</Link>
                    </div>
                    {pikName ? (
                      <>
                        <div className="p-2 col-5 form_default_input my-1">
                          <input className="" type="text" placeholder="Pik ime" value={pikName} readOnly disabled />
                        </div>
                        <div className="p-2 col-3">
                          <button className="button button_red" onClick={(e) => handleRemove(e, uuid)}>
                            Ukloni pik ime
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="p-2 col-5 form_default_input my-1">
                          <input onChange={handleSetName} name={uuid} type="text" placeholder="Pik ime" value={newPikName[uuid] ?? ''} />
                        </div>
                        <div className="p-2 col-3">
                          <button className="button" onClick={(e) => handleAdd(e, uuid)}>
                            Dodaj pik ime
                          </button>
                        </div>
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row justify-content-between">
            <button disabled={pagination.page === 0} className="button" onClick={handlePrev}>
              Nazad
            </button>
            <button disabled={!pagination.next} className="button" onClick={handleNext}>
              Napred
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  profileImage: '',
  images: [],
};

export const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    setProfileImage: (state, action) => {
      state.profileImage = action.payload;
      return state;
    },
    setImages: (state, action) => {
      state.images = [...state.images, action.payload];
      return state;
    },
    resetState: (state) => {
      state = initialState;
      return state;
    },
  },
});
